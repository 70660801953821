<template>
  <div class="top-container">
    <div class="randomizer">
      <div class="d-flex">
        <input type="number" class="form-control me-1" style="width: 100px;" v-model.number="sampleSize" placeholder="Enter sample size" @change="validateSampleSize" />
        <button class="btn btn-primary" @click="handleGenerateAndAssign">Generate and Assign</button>
      </div>
      <div class="square">
        <div class="label control-label">Control</div>
        <div class="label treatment-label">Treatment</div>
        <div
          v-for="(participant, index) in participants"
          :key="index"
          class="circle"
          :class="participant.group"
          :style="{ top: participant.y + 'px', left: participant.x + 'px', transition: 'all 1s' }"
        ></div>
      </div>
    </div>
    <div class="averages ms-3">
      <h3 style="fc-stats">Averages</h3>
      <table>
        <thead>
          <tr>
            <th>Variable</th>
            <th>Overall</th>
            <th>Control</th>
            <th>Treatment</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(average, key) in averages" :key="key">
            <td>{{ key }}</td>
            <td>{{ average.sample }}</td>
            <td>{{ average.control }}</td>
            <td>{{ average.treatment }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sampleSize: 10,
      participants: [],
      sampleData: [],
      averages: {
        female: { sample: 0, control: 0, treatment: 0 },
        age: { sample: 0, control: 0, treatment: 0 },
        income: { sample: 0, control: 0, treatment: 0 },
        school: { sample: 0, control: 0, treatment: 0 },
        daysSick: { sample: 0, control: 0, treatment: 0 },
      },
      score: [0,0,0,0],
      clicks: 0,
    };
  },
  methods: {
    validateSampleSize() {
      if (this.sampleSize < 10) {
        this.sampleSize = 10;
      } else if (this.sampleSize > 160) {
        this.sampleSize = 160;
      }
    },
    generateParticipants() {
      this.participants = [];
      this.sampleData = [];
      const squareSize = 370; // Adjusted square size to avoid overlap
      for (let i = 0; i < this.sampleSize; i++) {
        this.participants.push({
          x: Math.random() * squareSize + 5,
          y: Math.random() * (squareSize - 20) + 20,
          group: '', // Will be assigned later
        });
        this.sampleData.push({
          female: Math.random() > 0.5 ? 1 : 0,
          age: Math.floor(Math.random() * 50) + 20,
          income: Math.floor(Math.random() * 100000) + 20000,
          school: Math.floor(Math.random() * 10) + 8,
          daysSick: Math.floor(Math.random() * 10) + 1,
        });
      }
    },
    handleGenerateAndAssign() {
      this.clicks++;
      if(this.clicks >= 10) this.score[0] = 1;
      if(this.sampleSize >= 100) this.score[1] = 1;
      if(this.sampleSize <= 20) this.score[2] = 1;
      if(this.sampleSize >20 && this.sampleSize < 100) this.score[3] = 1;
      this.generateParticipants();
      setTimeout(() => {
        this.assignGroups();
        this.calculateAverages();
      }, 1000);
      this.emitScore();
    },
    emitScore() {
      this.$emit('score', this.score);
    },
    assignGroups() {
      this.shuffle(this.participants);
      const squareSize = 400;
      const midX = squareSize / 2;
      const stackSpacing = 25; // Spacing between stacked circles
      const stackWidth = 30; // Width of each stack
      const maxPerColumn = Math.floor(squareSize / stackSpacing) - 2; // Max circles per column
      let controlCount = 0;
      let treatmentCount = 0;

      for (let i = 0; i < this.participants.length; i++) {
        if (i < this.sampleSize / 2) {
          this.participants[i].group = 'Control';
          this.participants[i].x = (Math.floor(controlCount / maxPerColumn) * stackWidth) + 5; // Left side stacks
          this.participants[i].y = (controlCount % maxPerColumn) * stackSpacing + 30; // Stack neatly
          controlCount++;
        } else {
          this.participants[i].group = 'Treatment';
          this.participants[i].x = midX + (Math.floor(treatmentCount / maxPerColumn) * stackWidth) + 5; // Right side stacks
          this.participants[i].y = (treatmentCount % maxPerColumn) * stackSpacing + 30; // Stack neatly
          treatmentCount++;
        }
      }
    },
    calculateAverages() {
      let controlSum = { female: 0, age: 0, income: 0, school: 0, daysSick: 0 };
      let treatmentSum = { female: 0, age: 0, income: 0, school: 0, daysSick: 0 };
      let controlCount = 0;
      let treatmentCount = 0;

      for (let i = 0; i < this.sampleData.length; i++) {
        const data = this.sampleData[i];
        const group = this.participants[i].group;
        if (group === 'Control') {
          controlSum.female += data.female;
          controlSum.age += data.age;
          controlSum.income += data.income;
          controlSum.school += data.school;
          controlSum.daysSick += data.daysSick;
          controlCount++;
        } else if (group === 'Treatment') {
          treatmentSum.female += data.female;
          treatmentSum.age += data.age;
          treatmentSum.income += data.income;
          treatmentSum.school += data.school;
          treatmentSum.daysSick += data.daysSick;
          treatmentCount++;
        }
      }

      const totalSum = {
        female: controlSum.female + treatmentSum.female,
        age: controlSum.age + treatmentSum.age,
        income: controlSum.income + treatmentSum.income,
        school: controlSum.school + treatmentSum.school,
        daysSick: controlSum.daysSick + treatmentSum.daysSick,
      };

      this.averages = {
        female: {
          sample: (totalSum.female / this.sampleSize).toFixed(2),
          control: (controlSum.female / controlCount).toFixed(2),
          treatment: (treatmentSum.female / treatmentCount).toFixed(2),
        },
        age: {
          sample: (totalSum.age / this.sampleSize).toFixed(2),
          control: (controlSum.age / controlCount).toFixed(2),
          treatment: (treatmentSum.age / treatmentCount).toFixed(2),
        },
        income: {
          sample: formatCurrency(totalSum.income / this.sampleSize),
          control: formatCurrency(controlSum.income / controlCount),
          treatment: formatCurrency(treatmentSum.income / treatmentCount),
        },
        school: {
          sample: (totalSum.school / this.sampleSize).toFixed(2),
          control: (controlSum.school / controlCount).toFixed(2),
          treatment: (treatmentSum.school / treatmentCount).toFixed(2),
        },
        daysSick: {
          sample: (totalSum.daysSick / this.sampleSize).toFixed(2),
          control: (controlSum.daysSick / controlCount).toFixed(2),
          treatment: (treatmentSum.daysSick / treatmentCount).toFixed(2),
        },
      };
    },
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
  },
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: 'USD',
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 
  }).format(Math.round(value));
};

</script>

<style scoped>
.top-container {
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.randomizer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.square {
  width: 400px;
  height: 400px;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 12px 3px;
  position: relative;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: grey;
  transition: all 1s ease;
}
.Control {
  background-color: blue;
}
.Treatment {
  background-color: red;
}
.label {
  position: absolute;
  top: 0;
  width: 50%;
  text-align: center;
  font-weight: bold;
}
.control-label {
  left: 0;
}
.treatment-label {
  right: 0;
}
.averages table tr td {
  border: 1px dashed #000;
  padding: 5px;
}
.averages td {
  width: 100px;
}
</style>
