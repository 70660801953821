<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg rounded" :style="navBarColor">
      <div class="container-fluid">
        <router-link class="navbar-brand nolink" to="/">
          <img class="progo-logo" :src="ProGoLoGo" />
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <!-- ADD LINK TO COURSE MAIN PAGE WHEN IN A COURSE -->
            <li class="nav-item fw600" v-if="course && course != 'login'">
              <router-link class="nav-link active" aria-current="page" :to="coursePath"><span :class="navTextColor">{{ course }}</span></router-link>
            </li>
            <!-- ADD DROPDOWN FOR COURSES WHEN NOT IN A COURSE -->
            <li class="nav-item dropdown fw600" v-if="!course">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Courses
              </a>
              <ul class="dropdown-menu">
                <li><router-link class="dropdown-item" to="/macro">ECN 211: Macroeconomic Principles</router-link></li>
                <li><router-link class="dropdown-item" to="/micro">ECN 212: Microeconomic Principles</router-link></li>
                <li><router-link class="dropdown-item" to="/stats">GBS 221: Business Statistics</router-link></li>
              </ul>
            </li>
            <!-- ADD TOC FOR COURSES -->
            <li class="nav-item dropdown fw400" v-if="showTOC">
              <statsTOC v-if="showTOC=='stats'" />
            </li>
          </ul>
          <!-- USER MENU -->
          <ul class="navbar-nav" v-if="user">
            <li class="nav-item dropdown fw600">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="fc-offwhite">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                  </svg>
                  {{ user }}
                </span>
              </a>
              <ul class="dropdown-menu">
                <li v-if="user === 'brian.goegan@paradisevalley.edu'"><router-link class="dropdown-item" to="/controlpanel">Control Panel</router-link></li>
                <li><router-link class="dropdown-item" to="/stats/gradebook">Gradebook</router-link></li>
                <li><router-link class="dropdown-item" to="/settings">Settings</router-link></li>
                <li><button href="#" class="dropdown-item" @click="signUserOut">Log Out</button></li>
              </ul>
            </li>
          </ul>
          <router-link class="fw600 nolink fc-white" to="/login" v-if="!user">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
              <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
            </svg>
            Log In
          </router-link>
          <!-- <form class="d-flex" role="search">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success" type="submit">Search</button>
          </form> -->
        </div>
      </div>
    </nav>
  </div>

</template>


<script>
import { auth } from '../main';
import { signOut } from 'firebase/auth';

import statsTOC from './StatsTOC.vue';

import ProGoLoGo from '../assets/ProGoLoGo.png';

export default {
    name: 'NavBar',
    props: ['user'],
    components : {
      statsTOC,
    },
    data() {
      return {
        coursePath: '/',
        course: null,
        navBarColor: 'background-color: var(--banner_color); color: var(--text_color);',
        navTextColor: 'fc-text',
        showTOC: null,
        ProGoLoGo,
      };
    },
    watch: {
      '$route'(to) {
        this.coursePath = this.basePath(to.path,1);
        this.matchCourse(this.coursePath);
      }
    },
    created() {
      this.coursePath = this.basePath(this.$route.path,1);
      this.matchCourse(this.coursePath);
    },
    methods: {
      matchCourse(coursePath) {
        switch (coursePath) {
          case '/': 
            this.course = 'Business Statistics';  
            this.navBarColor = 'background-color: var(--business_statistics);';
            this.navTextColor = 'fc-white';
            this.showTOC = 'stats';
            break;
          case '/stats': 
            this.course = 'Business Statistics'; 
            this.navBarColor = 'background-color: var(--business_statistics);';
            this.navTextColor = 'fc-white';
            this.showTOC = 'stats';
            break;
          case '/micro': 
            this.course = 'Microeconomic Principles'; 
            this.navBarColor = 'background-color: var(--microeconomics)';
            this.navTextColor = 'fc-banner';
            this.showTOC = null;
            break;
          case '/macro': 
            this.course = 'Macroeconomic Principles'; 
            this.navBarColor = 'var(--macroeconomics)';
            this.navTextColor = 'fc-banner';
            this.showTOC = null;
            break;
          case '/login':
          this.course = 'login'; 
          this.navBarColor = 'background-color: var(--banner_color)';
          this.showTOC = null;
        }
      },
      basePath(route, segement) {
        const segments = route.split('/'); // Split the path by '/'
        return `/${segments[segement]}`; // Access the second element (first after empty string due to leading '/')
      },
      signUserOut() {
        try{
          signOut(auth);
          this.$router.push('/login');
        } catch (e) {
          console.error("Logout failed:", e);
        }
      },
    }
}

</script>

<style scoped>
.navbar {
    font-family: 'Rubik', sans-serif;
    padding: 0;
}
.navbar-brand {
    font-weight: 700; 
    font-size: 1.5em;
    color: white;
}
.progo-logo {
    width: 175px;
}
</style>