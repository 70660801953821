<template>
  <div class="modal">
    <div class="modal-content">
      <span class="close" @click="$emit('close')">&times;</span>
      <h3>Grade Changer Express</h3>
      <p>Student: {{ student.userInfo.email }}</p>
      <p>Header: {{ header }}</p>
      <div class="input-row mb-3">
        <div class="input-group" style="width: 200px;">
          <input class="form-control" v-model="newGrade" />
          <button class="btn btn-outline-secondary" @click="update">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['student', 'header', 'grade'],
  data() {
    return {
      newGrade: '',  
    };
  },
  created() {
    this.newGrade = this.grade;
  },
  methods: {
    update() {
      const payload = {
        id: this.student.id,
        header: this.header,
        grade: this.newGrade,
      };
      this.$emit('update', payload);
    }
  }
};
</script>

<style scoped>
/* Add your modal styles here */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>