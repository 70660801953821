import { createApp } from 'vue';
import App from './App.vue';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBP7I3mjdr3ymivNx8YpJaOb--bpGNRNcc",
    authDomain: "progo-learning.firebaseapp.com",
    projectId: "progo-learning",
    storageBucket: "progo-learning.appspot.com",
    messagingSenderId: "433730001006",
    appId: "1:433730001006:web:4f8a35fbdc5265ca512e7f",
    measurementId: "G-FJ9VGLE62Y"
  };
  
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
// const analytics = getAnalytics(firebaseApp);
export const database = getFirestore(firebaseApp);

// Import the router
import { router } from './pages/router';
  
// Create and mount the Vue application
createApp(App).use(router).mount('#app');

// Other functions needed across different pages
import katex from 'katex';
import 'katex/dist/katex.min.css';

export const renderEq = (equation) => {
  return katex.renderToString(equation, { throwOnError: false });
}

// SET UP USER DATA
export const setUpStatsData = async () => {
  const docRef = doc(database, "users", auth.currentUser.uid);
  let docSnap = await getDoc(docRef);
  let data = docSnap.data();

  // ADD STATS FOLDER IF IT'S MISSING
  if(!data.stats){
    await setDoc(docRef, {
      stats: {}
    }, {merge: true});
    docSnap = await getDoc(docRef);
    data = docSnap.data();
  }
  // ADD PROBLEM SETS TO USER DATA IF THEY AREN'T THERE
  if(!data.stats.problemSets){
    await setDoc(docRef, {
      stats: {
        problemSets: {
          ch1: [], ch2: [], ch3: [], ch4: [], ch5: [], ch6: [], ch7: [], ch8: [], ch9: [], ch10: [], ch11: [], ch12: [], ch13: [], ch14: [],
        }
      }
    }, {merge: true});
    docSnap = await getDoc(docRef);
    data = docSnap.data();
  }
  // ADD TEXTBOOK RESPONSES IF THEY AREN'T THERE
  if(!data.stats.textbookResponses){
    await setDoc(docRef, {
      stats: {
        textbookResponses: {
          ch1: {}, ch2: {}, ch3: {}, ch4: {}, ch5: {}, ch6: {}, ch7: {}, ch8: {}, ch9: {}, ch10: {}, ch11: {}, ch12: {}, ch13: {}, ch14: {},
        }
      }
    }, {merge: true});
    docSnap = await getDoc(docRef);
    data = docSnap.data();
  }
  // ADD GRADEBOOK IF MISSING
  if(!data.stats.gradeBook){
    await setDoc(docRef, {
      stats: {
        gradeBook: {
          ch0: {ec: 0, lesson: 0}, ch1: {}, ch2: {}, ch3: {}, ch4: {}, ch5: {}, ch6: {}, ch7: {}, ch8: {}, ch9: {}, ch10: {}, ch11: {}, ch12: {}, ch13: {}, ch14: {},
        }
      }
    }, {merge: true});
    docSnap = await getDoc(docRef);
    data = docSnap.data();
  }
}


// STATS FUNCTIONS


export const compare = (value1, value2, tolerance) => {
  if(value1 == value2) return true;
  if(!value1 || !value2) return false;
  if (isNaN(tolerance)) {
    value1 = value1.toLowerCase().replace(/\s/g, '').replace(/"/g, '');
    value2 = value2.toLowerCase().replace(/\s/g, '').replace(/"/g, '');
    return value1 === value2;
  } else {
      let val1 = value1.toString().includes(',') ? value1.replace(/,/g, '') : value1;
      let val2 = value2.toString().includes(',') ? value2.replace(/,/g, '') : value2;
      if (val1.toString().includes('%')) {
        val1 = val1.replace('%', '');
        val1 = Number(val1) / 100;
      }
      if (val2.toString().includes('%')) {
        val2 = val2.replace('%', '');
        val2 = Number(val2) / 100;
      }
      const num1 = Number(val1);
      const num2 = Number(val2);
      return Math.abs(num1 - num2) <= tolerance;
  }
};

import { create, all } from 'mathjs';
const math = create(all);

export const linearRegression = (x, y) => {
  // Convert x to a two-dimensional array if it is one-dimensional
  let indX = Array.isArray(x[0]) ? x : x.map((val) => [val]);

  // Add a column of ones to indX for the intercept term
  if (indX[0].length === 1) {
    indX = indX.map((val) => [1, ...val]);
  } else {
    indX = indX.map((val) => [1, ...val]);
  }

  // Convert y to a two-dimensional array if it is one-dimensional
  if (!Array.isArray(y[0])) {
    y = y.map((val) => [val]);
  }

  return math.multiply(math.multiply(math.inv(math.multiply(math.transpose(indX), indX)),math.transpose(indX)),y);
};


