<template>
  <span v-html="renderedEquation" class="wrap"></span>
</template>
  
<script>
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default {
  name: 'MathEquation',
  props: {
    equation: {
      type: String,
      required: true,
    },
  },
  computed: {
    renderedEquation() {
      return katex.renderToString(this.equation, { throwOnError: false });
    },
  },
};
</script>

<style scoped>
  .wrap {
    white-space: normal;
    word-wrap: break-word;
  }
</style>
