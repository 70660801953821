<template>

  <div class="container col-md-6 offset-md-3">
      
      <!-- #region CHAPTER AND TITLE -->
      <div class="container">
      <div class="book-title-div">
          <span class="fw600 em1-50">Chapter Four</span><br>
          <span class="fw800 em3 fc-stats">The Improbable Legacy of Thomas Bayes</span>
      </div>
      </div>
      <!-- #endregion -->
      
      <!-- SUB NAVIGATION BAR -->
      <nav>
      <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
          <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
          <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
          <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
      </div>
      </nav>
      
      <!-- SUB NAV BAR CONTENT -->
      <div class="tab-content" id="nav-tabContent">
      <!-- TEXTBOOK -->
      <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
  
        <VideoBox class="mt-4" videoTitle="The Improbable Legacy of Thomas Bayes" videoUrl="https://www.youtube.com/embed/CxSc--hpZus" startAt="0"/>
  
          <!-- #region MODULE 1 -->
          <div v-if="userData" class="intructions-module rounded container">
              <h3 class="fc-stats">All About that Bayes</h3>
              <p>Bayes' Theoreom is a powerful tool that allows us to update our beliefs about the world as we gather more information. The equation for Bayes' Theorem is:</p>
              <p class="d-flex justify-content-center align-items-center" ref="bayesEquation">
                <span class="em1-25"><MathEquation :equation="'P(A|B) = '"/></span>
                <span class="em1-50"><MathEquation :equation="'\\frac{P(B|A)P(A)}{P(B)}'"/></span>
              </p>

              <VideoBox videoUrl="https://www.youtube.com/watch?v=9TDjifpGj-k" videoTitle="You Know I'm All About that Bayes" startAt="3" />
  
              <div class="mt-3" v-if="userData">
                <h4 class="fc-prove">Prove Your Understanding</h4>
                <p>
                  Bayes' theorem might not look spectacular as a formula, but its real magic lies in how it helps us rethink the probability 
                  of an event when we get new information. To get the hang of it, try answering the three questions below. The trickiest part 
                  is figuring out what 'A' and 'B' represent in the formula. Here's a tip: the last sentence of the question usually gives you 
                  the clues you need to identify 'A' and 'B'. Once you've got them, just read the formula like a sentence: "The probability of 
                  'A' given 'B'..." and replace 'A' and 'B' with the terms they stand for.
                </p>
              </div>

              <div class="d-flex justify-content-center align-items-center">
                <div id="moduleOneCarousel" class="carousel carousel-dark slide carousel-wrapper">
                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#moduleOneCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#moduleOneCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#moduleOneCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  </div>
                  <div class="carousel-inner">

                    <!-- QUESTION 1 -->
                    <div class="carousel-item active">
                      <div class="d-block w-100">
                        <div class="carousel-title">Question 1</div>
                        <div class="carousel-question">
                          An email service provider has a spam filter that classifies 
                          {{ userData.stats.problemSets.ch4.p1.pBGivenA }}% 
                          of actual spam emails correctly, but flags 
                          {{ userData.stats.problemSets.ch4.p1.pB }}% 
                          of all emails as spam. Suppose that 
                          {{ userData.stats.problemSets.ch4.p1.pA }}% 
                          of all emails are spam. If an email is flagged as spam by the filter, what is the probability that it is indeed spam?
                          <div class="input-group mb-3 mt-3">
                            <span class="input-group-text" ref="m1q1">?</span>
                            <input type="text" class="form-control" id="m1q1" :value="userData.stats.textbookResponses.ch4.mod1.p1" :style="checkM1Q1">
                            <button class="btn btn-outline-secondary" type="button" @click="computeM1Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- QUESTION 2 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="carousel-title">Question 2</div>
                        <div class="carousel-question">
                          A retailer categorizes customers into two groups: loyal 
                          ({{ userData.stats.problemSets.ch4.p2.pA }}%) 
                          and non-loyal 
                          ({{ 100 - userData.stats.problemSets.ch4.p2.pA }}%). 
                          Approximately 
                          {{ userData.stats.problemSets.ch4.p2.pB }}% 
                          of customers make a purchase when they visit the retailer, but loyal customers purchase something 
                          {{ userData.stats.problemSets.ch4.p2.pBGivenA }}% 
                          of the time. If a customer is known to have made a purchase, what is the probability that they are a loyal customer?
                          <div class="input-group mb-3 mt-3">
                            <span class="input-group-text" ref="m1q2">?</span>
                            <input type="text" class="form-control" id="m1q2" :value="userData.stats.textbookResponses.ch4.mod1.p2" :style="checkM1Q2">
                            <button class="btn btn-outline-secondary" type="button" @click="computeM1Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- QUESTION 3 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="carousel-title">Question 3</div>
                        <div class="carousel-question">
                          A diagnostic test for a disease has a sensitivity (true positive rate) of {{ userData.stats.problemSets.ch4.p3.pBGivenA }}%. 
                          Around {{ userData.stats.problemSets.ch4.p3.pA }}% of the population has the disease, and overall about 
                          {{ userData.stats.problemSets.ch4.p3.pB }}% of people who take the test will test positive. If a person tests positive, 
                          what is the probability that they have the disease?
                          <div class="input-group mb-3 mt-3">
                            <span class="input-group-text" ref="m1q3">?</span>
                            <input type="text" class="form-control" id="m1q3" :value="userData.stats.textbookResponses.ch4.mod1.p3" :style="checkM1Q3">
                            <button class="btn btn-outline-secondary" type="button" @click="computeM1Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    

                  </div>

                  <button class="carousel-control-prev" type="button" data-bs-target="#moduleOneCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">P</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#moduleOneCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">N</span>
                  </button>
                </div>
              </div>
  
              <CalculatorLinks :score="mod1Score" :scoreTotal="6"/>
          </div> 
          <!-- #endregion -->
          
          <!-- #region MODULE 2 -->
          <div v-if="userData" class="intructions-module rounded container">
              <h3 class="fc-stats">P(B)</h3>
              <p>
                So far, we have only encountered questions where P(B) is given to us. But in most real-world situations, P(B) isn't always given or obvious. 
                Instead, we need to think about what P(B) represents. Click through the slides below. 
              </p>

              <!-- START BAYES CAROUSEL -->
              <div class="d-flex justify-content-center align-items-center">
                <div id="bayesCarousel" class="carousel carousel-dark slide bayes-carousel">
                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#bayesCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#bayesCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#bayesCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#bayesCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#bayesCarousel" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#bayesCarousel" data-bs-slide-to="5" aria-label="Slide 6"></button>
                  </div>
                  <div class="carousel-inner">

                    <!-- ITEM 0 -->
                    <div class="carousel-item active">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <p>
                            The image below represents Bayes' theorem as a kind of Venn diagram. The area of the circle on the left represents event A, 
                            the area of the circle on the right represents event B, and the overlap represents outcomes where both A and B happen. 
                            All three circles sit inside a larger rectangle that represents the sample space, which is the set of all possible outcomes. 
                          </p>
                          <div class="text-center"><img :src="bayesSampleSpace" width="80%" class="mb-3"></div>
                          <p>
                            Let's break down Bayes' theorem into its components in the next slides.
                          </p>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- ITEM 1 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <p>
                            First, let's think about P(A|B), the probability the theorem is trying to find. 
                            On our diagram, this is the area of the overlap between A and B divided by the area of B. This is the probability that A happens 
                            given that B has happened. You can see that "given B" constrains us to the universe inside Event B, and finds the probability that 
                            A also happens.
                          </p>
                          <div class="text-center"><img :src="bayesPAB" width="80%" class="mb-3"></div>
                          <p>In other words, it is the area of Event A & B divided by the area of Event B.</p>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- ITEM 2 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <p>
                            Bayes' Theorem breaks down P(A|B) into component pieces 
                            which are often easy to identify in the wild. The first term in the equation is P(B|A), the probability of B given A. Just like P(A|B), 
                            this is the area of the overlap between A and B divided by the area of A. This is the probability that B happens given that A has happened.
                          </p>
                          <div class="text-center"><img :src="bayesPBA" width="80%" class="mb-3"></div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- ITEM 3 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <p>P(A) is the probability of A happening. This is the area of the circles representing A divided by the area of the sample space.
                          </p>
                          <div class="text-center"><img :src="bayesPA" width="80%" class="mb-3"></div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- ITEM 4 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <p>P(B) is the probability of B happening. This is the area of the circles representing B divided by the area of the sample space.</p>
                          <div class="text-center"><img :src="bayesPB" width="80%" class="mb-3"></div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- ITEM 5 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <p>
                            The last term in the equation is P(B), the probability of B happening. This is the area of the circle representing B divided by the area 
                            of the sample space. This is the probability that B happens, regardless of whether A happens or not. In practice, however, this is usually 
                            not obvious. Instead, we need to break this into two component pieces: P(B|A)*P(A) + P(B|¬A)*P(¬A). The first term is the probability 
                            that B happens given that A happens, multiplied by the probability that A happens. The second term is the probability that B happens given 
                            that A <strong>doesn't</strong> happen, multiplied by the probability that A <strong>doesn't</strong> happen. 
                          </p>
                          <div class="text-center"><img :src="bayesPB2" width="100%" class="mb-3"></div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    

                  </div>

                  <button class="carousel-control-prev" type="button" data-bs-target="#bayesCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">P</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#bayesCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">N</span>
                  </button>
                </div>
              </div>
              <!-- END BAYES CAROUSEL -->

              <p>The last slide gives way to a new formulation for P(B):</p>
              <p class="d-flex justify-content-center align-items-center" ref="bayesEquation">
                <span class="em1-25"><MathEquation :equation="'P(B) = '"/></span>
                <span class="em1-25" ref="pb1"><MathEquation :equation="' P(B|A)P(A) '"/></span>
                <span class="em1-25" ref="pb2"><MathEquation :equation="' + P(B|\\lnot A)P(\\lnot A)'"/></span>
              </p>
              <p>
                Hover over the equation for more information. You'll see that we have broken P(B) into two parts: the probability that B <i>and</i> A both happen, 
                plus the probability that B happens but A <i>doesn't happen</i>.  

              </p>
  
              <div class="mt-3" v-if="userData">
                <h4 class="fc-prove">Prove Your Understanding</h4>
                <p>
                  Let's work on getting P(B) with our new formulation! 
                </p>
              </div>

              <div class="d-flex justify-content-center align-items-center">
                <div id="moduleTwoCarousel" class="carousel carousel-dark slide carousel-wrapper">
                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#moduleTwoCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#moduleTwoCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#moduleTwoCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  </div>
                  <div class="carousel-inner">

                    <!-- QUESTION 1 -->
                    <div class="carousel-item active">
                      <div class="d-block w-100">
                        <div class="carousel-title">Question 1</div>
                        <div class="carousel-question">
                          An email service provider has a spam filter that classifies 
                          {{ userData.stats.problemSets.ch4.p4.pBGivenA }}% 
                          of actual spam emails correctly, but flags 
                          {{ userData.stats.problemSets.ch4.p4.pBnotA }}% 
                          of non-spam emails as spam (a false positive). Suppose that 
                          {{ userData.stats.problemSets.ch4.p4.pA }}% 
                          of all emails are spam. What is the probability that an email is flagged as spam, regardless of whether it is spam or not?
                          <div class="input-group mb-3 mt-3">
                            <span class="input-group-text" ref="m2q1">?</span>
                            <input type="text" class="form-control" id="m2q1" :value="userData.stats.textbookResponses.ch4.mod2.p1" :style="checkM2Q1">
                            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- QUESTION 2 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="carousel-title">Question 2</div>
                        <div class="carousel-question">
                          A retailer categorizes customers into two groups: loyal 
                          ({{ userData.stats.problemSets.ch4.p5.pA }}%) 
                          and non-loyal 
                          ({{ 100 - userData.stats.problemSets.ch4.p5.pA }}%). 
                          Loyal customers purchase something 
                          {{ userData.stats.problemSets.ch4.p5.pBGivenA }}% 
                          of the time, while non-loyal customers purchase something 
                          {{ userData.stats.problemSets.ch4.p5.pBnotA }}%
                          of the time. What is the probability that a customer makes a purchase, regardless of whether they are loyal or not?
                          <div class="input-group mb-3 mt-3">
                            <span class="input-group-text" ref="m2q2">?</span>
                            <input type="text" class="form-control" id="m2q2" :value="userData.stats.textbookResponses.ch4.mod2.p2" :style="checkM2Q2">
                            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- QUESTION 3 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="carousel-title">Question 3</div>
                        <div class="carousel-question">
                          A diagnostic test for a disease has a sensitivity (true positive rate) of {{ userData.stats.problemSets.ch4.p6.pBGivenA }}% and 
                          a specificity (true negative rate) of {{ 100 - userData.stats.problemSets.ch4.p6.pBnotA }}%. The prevalence of the disease in the population is 
                          {{ userData.stats.problemSets.ch4.p6.pA }}%. What is the probability that a person will test positive for the disease, regardless of whether they have it or not?
                          <div class="input-group mb-3 mt-3">
                            <span class="input-group-text" ref="m2q3">?</span>
                            <input type="text" class="form-control" id="m2q3" :value="userData.stats.textbookResponses.ch4.mod2.p3" :style="checkM2Q3">
                            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    

                  </div>

                  <button class="carousel-control-prev" type="button" data-bs-target="#moduleTwoCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">P</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#moduleTwoCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">N</span>
                  </button>
                </div>
              </div>
  
              <CalculatorLinks :score="mod2Score" :scoreTotal="6"/>
          </div> 
          <!-- #endregion -->
          
          <!-- #region MODULE 3 -->
          <div v-if="userData" class="intructions-module rounded container">
              <h3 class="fc-stats">Bayesian Updating</h3>
              <p>
                Bayes' Theorem is a <strong>big deal</strong>. A lot of Very Smart People are avowed "Bayesians" who see the theorem as the most fundamental 
                idea in all of statistics. As such, it's worth spending some time on it. Below is a carousel of videos on the topic of Bayes' Theorem. Some of them 
                are short and others are long, but they are all useful. Watch them over lunch. Put one on before bed. Listen to them while you're doing the dishes. 
                But give them each your full attention and take time afterwards to reflect and summarize what you have learned. It will pay large dividends 
                well into your future. 
              </p>

              <!-- START BAYES Video CAROUSEL -->
              <div class="d-flex justify-content-center align-items-center">
                <div id="bayesVideoCarousel" class="carousel carousel-dark slide bayes-carousel">
                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#bayesVideoCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#bayesVideoCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#bayesVideoCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#bayesVideoCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#bayesVideoCarousel" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#bayesVideoCarousel" data-bs-slide-to="5" aria-label="Slide 6"></button>
                  </div>
                  <div class="carousel-inner">

                    <div class="carousel-item active">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <VideoBox videoUrl="https://www.youtube.com/embed/NEqHML98RgU" videoTitle="Think Rationally via Bayes' Rule" startAt="0" />
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <VideoBox videoUrl="https://www.youtube.com/embed/w4RLfVxTGH4" videoTitle="Why you think you're right -- even if you're wrong" startAt="0" />
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <VideoBox videoUrl="https://www.youtube.com/embed/4hHA-oqpNig" videoTitle="How to systematically approach truth" startAt="0" />
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <VideoBox videoUrl="https://www.youtube.com/embed/HZGCoVF3YvM" videoTitle="Bayes theorem, the geometry of changing beliefs" startAt="0" />
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <VideoBox videoUrl="https://www.youtube.com/embed/R13BD8qKeTg" videoTitle="The Bayesian Trap" startAt="0" />
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="bayes-carousel-question">
                          <VideoBox videoUrl="https://www.youtube.com/embed/82q3uYw6MuY" videoTitle="Bayes Theorem and some of the mysteries it has solved" startAt="0" />
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    

                  </div>

                  <button class="carousel-control-prev" type="button" data-bs-target="#bayesVideoCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">P</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#bayesVideoCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">N</span>
                  </button>
                </div>
              </div>
              <!-- END BAYES CAROUSEL -->

              <h3>Bayes' Theorem</h3>
              <p>Let's take a look at Bayes' Theorem in its final form:</p>
              <p class="d-flex justify-content-center align-items-center" ref="bayesEquation">
                <span class="em1-25"><MathEquation :equation="'P(A|B) = '"/></span>
                <span class="em1-50"><MathEquation :equation="'\\frac{P(B|A)P(A)}{P(B|A)P(A) + P(B|\\lnot A)P(\\lnot A)}'"/></span>
              </p>
              <p>This equation looks tough, but the more you get to know it the simpler it becomes. And with it, you can better assess information and evidence!</p>
              <div class="mt-3" v-if="userData">
                <h4 class="fc-prove">Prove Your Understanding</h4>
                <p>
                  Now that we have our fully formed Bayes' Theorem, let's work through the questions below.  
                </p>
              </div>

              <div class="d-flex justify-content-center align-items-center">
                <div id="moduleThreeCarousel" class="carousel carousel-dark slide carousel-wrapper">
                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#moduleThreeCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#moduleThreeCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#moduleThreeCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#moduleThreeCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                  </div>
                  <div class="carousel-inner">

                    <!-- QUESTION 1 -->
                    <div class="carousel-item active">
                      <div class="d-block w-100">
                        <div class="carousel-title">Question 1</div>
                        <div class="carousel-question"> 
                          A doctor is called to see a sick child. The doctor knows that about 
                          {{ userData.stats.problemSets.ch4.p7.flu }}% 
                          of sick children in the region have the flu, while the remainder of sick children have the measles. About 
                          {{ userData.stats.problemSets.ch4.p7.measlesAndRash }}% 
                          of children who have the measles have a rash. About 
                          {{ userData.stats.problemSets.ch4.p7.fluAndRash }}% 
                          of children who have the flu have a rash. Upon examining the child, the doctor finds a rash. What is the probability that the child has the measles? 
                          <div class="input-group mb-3 mt-3">
                            <span class="input-group-text" ref="m3q1">?</span>
                            <input type="text" class="form-control" id="m3q1" :value="userData.stats.textbookResponses.ch4.mod3.p1" :style="checkM3Q1">
                            <button class="btn btn-outline-secondary" type="button" @click="computeM3Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- QUESTION 2 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="carousel-title">Question 2</div>
                        <div class="carousel-question">
                          Students who get help from the professor during office hours are 
                          {{ userData.stats.problemSets.ch4.p8.multiplier }}x 
                          more likely to get an A on the exam than students who do not get help from the professor during office hours. About 
                          {{ userData.stats.problemSets.ch4.p8.helpSeekers }}% 
                          of students get help from the professor during office hours. If you learn that a student got an A on the exam, 
                          what is the probability that they got help from the professor during office hours? 
                          <div class="input-group mb-3 mt-3">
                            <span class="input-group-text" ref="m3q2">?</span>
                            <input type="text" class="form-control" id="m3q2" :value="userData.stats.textbookResponses.ch4.mod3.p2" :style="checkM3Q2">
                            <button class="btn btn-outline-secondary" type="button" @click="computeM3Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- QUESTION 3 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="carousel-title">Question 3</div>
                        <div class="carousel-question">
                          Suppose a drug test is 
                          {{ userData.stats.problemSets.ch4.p9.sensitive }}% sensitive and 
                          {{ userData.stats.problemSets.ch4.p9.specific }}% specific. That is, the test will produce 
                          {{ userData.stats.problemSets.ch4.p9.sensitive }}% true positive results for drug users and 
                          {{ userData.stats.problemSets.ch4.p9.specific }}% true negative results for non-drug users. Suppose that 
                          {{ userData.stats.problemSets.ch4.p9.users }}% of people are users of the drug. If a randomly selected individual tests positive, 
                          what is the probability he or she is a user?
                          <div class="input-group mb-3 mt-3">
                            <span class="input-group-text" ref="m3q3">?</span>
                            <input type="text" class="form-control" id="m3q3" :value="userData.stats.textbookResponses.ch4.mod3.p3" :style="checkM3Q3">
                            <button class="btn btn-outline-secondary" type="button" @click="computeM3Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- QUESTION 4 -->
                    <!-- Aras drives to work [d]% of the time, walks to works [w]% of the time, or otherwise rides his bike to work. He is late to work [dl]% of the time he drives, [wl]% of the time he walks, and [bl]% of the time he bikes. Aras was late to work today. What is the probability that he walked? -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="carousel-title">Question 4</div>
                        <div class="carousel-question">
                          Aras drives to work 
                          {{ userData.stats.problemSets.ch4.p10.d }}% 
                          of the time, walks to works 
                          {{ userData.stats.problemSets.ch4.p10.w }}% 
                          of the time, or otherwise rides his bike to work. He is late to work 
                          {{ userData.stats.problemSets.ch4.p10.dl }}% 
                          of the time he drives, 
                          {{ userData.stats.problemSets.ch4.p10.wl }}% 
                          of the time he walks, and 
                          {{ userData.stats.problemSets.ch4.p10.bl }}% 
                          of the time he bikes. Aras was late to work today. What is the probability that he walked?
                          <div class="input-group mb-3 mt-3">
                            <span class="input-group-text" ref="m3q4">?</span>
                            <input type="text" class="form-control" id="m3q4" :value="userData.stats.textbookResponses.ch4.mod3.p4" :style="checkM3Q4">
                            <button class="btn btn-outline-secondary" type="button" @click="computeM3Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <button class="carousel-control-prev" type="button" data-bs-target="#moduleThreeCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">P</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#moduleThreeCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">N</span>
                  </button>
                </div>
              </div>
  
              <CalculatorLinks :score="mod3Score" :scoreTotal="8"/>
          </div> 
          <!-- #endregion -->
          
  
          <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/nXTDKuvHawc" startAt="0"/>
          
          <!-- #region DATA CHANGER -->
          <div v-if="userData" class="data-changer">
            <div class="em1-50 fw600">Questions Reset</div>
            <div>You can reset the questions with randomly generated numbers for more practice.</div>
            <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
          </div>
          <!-- #endregion -->

      </div>
      <!-- EXPLORATORY LESSON -->
      <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
          <div class="row mt-4">
          <div class="col-4">
              <img :src="ExploratoryLessonIcon" width="100%">
          </div>
          <div class="col-8">
              <h3 class="fc-stats">Exploratory Lesson 4</h3>
              <div style="text-align: justify;">
                The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will apply Bayes' Theorem to so many problems and scenarios you'll be absolutely sick of it! 
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
              You have scored {{ lssnScore }} out of 20 points on this lesson.
              </div>
          </div>
      
          </div>
          <div class="d-flex align-items-center justify-content-center mt-4">
          <router-link to="/stats/4/lesson" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
          </div>
      </div>
      <!-- SPREADSHEET ASSIGNMENT -->
      <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
          <div class="row mt-4">
          <div class="col-4">
              <img :src="SpreadsheetAssignmentIcon" width="100%">
          </div>
          <div class="col-8">
              <h3 class="fc-stats">Spreadsheet Assignment 5</h3>
              <div style="text-align: justify;">
                <p>
                  The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will use Monte Carlo simulation to obtain the 
                  probabilities needed to use Bayes' Theorem to prove a paradox. Here is that paradox:
                </p>
                <p>
                  A family has two children. You learn that one of them is a girl. What is the probability that the other child is also a girl?   Answer: <b>33%</b>. 
                </p>
                <p>
                  A family has two children. You learn that one of them is a girl <u>named Sarah</u>. What is the probability that the other child is also a girl?   Answer: <b>50%</b>.
                </p>
                <p>
                  WHAT!? How can the name of the child change the probability!? This is the paradox you will explore in this assignment.
                </p>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
              You have scored {{ assnScore }} out of 30 points on this assignment.
              </div>
          </div>
      
          </div>
          <div class="d-flex align-items-center justify-content-center mt-4">
          <router-link to="/stats/4/assignment" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
          </div>
      </div>

    </div>
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
  import { Popover } from 'bootstrap';

  import MathEquation from '@/components/MathEquation.vue';
  import CalculatorLinks from '@/components/CalculatorLinks.vue';
  import VideoBox from '@/components/VideoBox.vue';
  
  import { create, all } from 'mathjs'
  const math = create(all);
  
  import probabilibot from '@/assets/Probabilibot.png';
  import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
  import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
  import bayesSampleSpace from '@/assets/bayesSampleSpace.png';
  import bayesPA from '@/assets/bayesPA.png';
  import bayesPB from '@/assets/bayesPB.png';
  import bayesPAB from '@/assets/bayesPAB.png';
  import bayesPB2 from '@/assets/bayesPB2.png';
  import bayesPBA from '@/assets/bayesPBA.png';
  
  export default {
    name: "statsMainPage",
    components: {
      MathEquation,
      CalculatorLinks,
      VideoBox,
    },
    props: ['tab'],
    data() {
      return {
        userData: null,
        setUpStatsData,
        mod1Score: 0,
        mod2Score: 0,
        mod3Score: 0,
        assnScore: 0,
        lssnScore: 0,
        modalTitle: 'Loading...',
        modalMessage: '',
        probabilibot,
        ExploratoryLessonIcon,
        SpreadsheetAssignmentIcon,
        bayesSampleSpace,
        bayesPA,
        bayesPB,
        bayesPAB,
        bayesPB2,
        bayesPBA,
        renderEq,
        compare,
      }
    },
    created() {
      this.loadUserInformation();    
    },
    methods: {
      async loadUserInformation() {
          await this.setUpStatsData();
          await this.pullProblemSet();
          await this.setPopovers();
      },
      async pullProblemSet() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        
        // ADD VALUES FOR THE CHAPTER PROBLEM SET
        if(Object.keys(data.stats.problemSets.ch4).length == 0){
          await setDoc(docRef, {
            stats: {
              problemSets: {
                ch4: {
                  p1: this.bayesTheoremProblem1(),
                  p2: this.bayesTheoremProblem2(),
                  p3: this.bayesTheoremProblem3(),
                  p4: this.bayesTheoremProblem4(),
                  p5: this.bayesTheoremProblem5(),
                  p6: this.bayesTheoremProblem6(),
                  p7: this.bayesTheoremProblem7(),
                  p8: this.bayesTheoremProblem8(),
                  p9: this.bayesTheoremProblem9(),
                  p10: this.bayesTheoremProblem10(),
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
        if(Object.keys(data.stats.textbookResponses.ch4).length == 0){
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod1: {
                      p1: null,
                      p2: null,
                      p3: null,
                  },
                  mod2: {
                      p1: null,
                      p2: null,
                      p3: null,
                  },
                  mod3: {
                      p1: null,
                      p2: null,
                      p3: null,
                      p4: null,
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER GRADEBOOK IF MISSING
        if(!data.stats.gradeBook.ch4.mod1){
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                ch4: {
                  mod1: [0,0,0],
                  mod2: [0,0,0],
                  mod3: [0,0,0,0],
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        this.userData = data;
        this.mod1Score = math.sum(this.userData.stats.gradeBook.ch4.mod1);
        this.mod2Score = math.sum(this.userData.stats.gradeBook.ch4.mod2);
        this.mod3Score = math.sum(this.userData.stats.gradeBook.ch4.mod3);
        this.assnScore = this.userData.stats.gradeBook.ch4.assignment ? this.userData.stats.gradeBook.ch4.assignment : 0;
        this.lssnScore = this.userData.stats.gradeBook.ch4.lesson ? this.userData.stats.gradeBook.ch4.lesson : 0;
      },
      async setPopovers() {
        await new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (this.userData !== null) {
                    clearInterval(intervalId);
                    resolve(this.userData);
                }
            }, 100); // Check every 100 milliseconds
        });
        const pb1 = new Popover(this.$refs.pb1, {
          container: 'body',
          title: `P(B|A)P(A)`,
          content: `This is the probability that B happens given that A happens, multiplied by the probability that A happens. This is the same as P(B and A)`,
          trigger: 'hover focus',
          placement: 'top',
          html: true,
        });
        const pb2 = new Popover(this.$refs.pb2, {
          container: 'body',
          title: `P(B|¬A)P(¬A)`,
          content: `The symbol '¬' means 'not'. This is the probability that B happens given that A <i>does not</i> happen, multiplied by the probability that A <i>does not</i> happen. This is the same as P(B but not A)`,
          trigger: 'hover focus',
          placement: 'top',
          html: true,
        });
        const m1q1 = new Popover(this.$refs.m1q1, {
          container: 'body',
          title: `Bayes' Theorem`,
          content: `It helps to identify 'A', the event we want the probability of, and 'B', the event/information that we have observed. The last sentence puts these in reverse order. We want to know the probability the email is spam, given that it was flagged. So, 'A' is the email being spam and 'B' is the email being flagged as spam. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m1q2 = new Popover(this.$refs.m1q2, {
          container: 'body',
          title: `Bayes' Theorem`,
          content: `It helps to identify 'A', the event we want the probability of, and 'B', the event/information that we have observed. The last sentence puts these in reverse order. We want to know the customer is loyal, given that they made a purchase. So, 'A' is the customer is loyal and 'B' is they made a purchase. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m1q3 = new Popover(this.$refs.m1q3, {
          container: 'body',
          title: `Bayes' Theorem`,
          content: `It helps to identify 'A', the event we want the probability of, and 'B', the event/information that we have observed. The last sentence puts these in reverse order. We want to know the probability the person has the disease, given that they tested positive. So, 'A' is the person has the disease and 'B' is they tested positive. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q1 = new Popover(this.$refs.m2q1, {
          container: 'body',
          title: `P(B)`,
          content: `You still want to identify 'A', the event we want the probability of, and 'B', the event/information that we have observed. But all you need to compute is the probability of B. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q2 = new Popover(this.$refs.m2q2, {
          container: 'body',
          title: `P(B)`,
          content: `You still want to identify 'A', the event we want the probability of, and 'B', the event/information that we have observed. But all you need to compute is the probability of B. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q3 = new Popover(this.$refs.m2q3, {
          container: 'body',
          title: `P(B)`,
          content: `You still want to identify 'A', the event we want the probability of, and 'B', the event/information that we have observed. But all you need to compute is the probability of B. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m3q1 = new Popover(this.$refs.m3q1, {
          container: 'body',
          title: `Bayes' Theorem`,
          content: `Identify 'A' and 'B'. You need to find four values: P(B|A), P(A), P(B|¬A), and P(¬A). Each of those is a sentence you unlock by identifying 'A' and 'B'. Read those sentences aloud! <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m3q2 = new Popover(this.$refs.m3q2, {
          container: 'body',
          title: `Bayes' Theorem`,
          content: `It seems like you don't have all the information you need, but I promise you do. If you need to, just make up a small probability for the chance of getting an A without the professor's help. No matter what you pick, it will still work out! <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m3q3 = new Popover(this.$refs.m3q3, {
          container: 'body',
          title: `Bayes' Theorem`,
          content: `Identify 'A' and 'B'. You need to find four values: P(B|A), P(A), P(B|¬A), and P(¬A). Each of those is a sentence you unlock by identifying 'A' and 'B'. Read those sentences aloud! <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m3q4 = new Popover(this.$refs.m3q4, {
          container: 'body',
          title: `Bayes' Theorem`,
          content: `The hard part on this one is figuring out P(B|¬A). Here is a hint: you need a weighted average of the chance of being late when driving or biking, where the weights are the probabilities for driving or biking. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        m1q1; m1q2; m1q3; pb1; pb2; m2q1; m2q2; m2q3; m3q1; m3q2; m3q3; m3q4;
      },
      async resetAll() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            problemSets: {
              ch4: {
                p1: this.bayesTheoremProblem1(),
                p2: this.bayesTheoremProblem2(),
                p3: this.bayesTheoremProblem3(),
                p4: this.bayesTheoremProblem4(),
                p5: this.bayesTheoremProblem5(),
                p6: this.bayesTheoremProblem6(),
                p7: this.bayesTheoremProblem7(),
                p8: this.bayesTheoremProblem8(),
                p9: this.bayesTheoremProblem9(),
                p10: this.bayesTheoremProblem10(),
              }
            }
          }
        }, {merge: true});
        await setDoc(docRef, {
          stats: {
              textbookResponses: {
                ch4: {
                  mod1: {
                      p1: null,
                      p2: null,
                      p3: null,
                  },
                  mod2: {
                      p1: null,
                      p2: null,
                      p3: null,
                  },
                  mod3: {
                      p1: null,
                      p2: null,
                      p3: null,
                      p4: null,
                  },
                }
              }
          }
        }, {merge: true});
        const docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
      },
      bayesTheoremProblem1() {
        const pBGivenA = math.randomInt(955, 995)/10;
        const pA = math.randomInt(50, 200)/10;
        const pB = pA + math.randomInt(5, 25)/10;
        return {
          pA: pA,
          pB: pB,
          pBGivenA: pBGivenA,
        }
      },
      bayesTheoremProblem2() {
        const pBGivenA = math.randomInt(400, 700)/10;
        const pA = math.randomInt(100, 300)/10;
        const pB = math.round(100*((pBGivenA/100)*(pA/100) + math.randomInt(5, 25)/100),1);
        return {
          pA: pA,
          pB: pB,
          pBGivenA: pBGivenA,
        }
      },
      bayesTheoremProblem3() {
        const pBGivenA = math.randomInt(800, 950)/10;
        const pA = math.randomInt(5, 50)/10;
        const pB = math.round(100*((pBGivenA/100)*(pA/100) + math.randomInt(5, 25)/1000),1);
        return {
          pA: pA,
          pB: pB,
          pBGivenA: pBGivenA,
        }
      },
      bayesTheoremProblem4() {
        const pBGivenA = math.randomInt(950, 990)/10;
        const pBnotA = math.randomInt(5, 25)/10;
        const pA = math.randomInt(20, 150)/10;
        return {
          pA: pA,
          pBGivenA: pBGivenA,
          pBnotA: pBnotA,
        }
      },
      bayesTheoremProblem5() {
        const pBGivenA = math.randomInt(400, 700)/10;
        const pBnotA = math.randomInt(150, 300)/10;
        const pA = math.randomInt(100, 300)/10;
        return {
          pA: pA,
          pBGivenA: pBGivenA,
          pBnotA: pBnotA,
        }
      },
      bayesTheoremProblem6() {
        const pBGivenA = math.randomInt(800, 950)/10;
        const pBnotA = math.randomInt(5, 25)/10;
        const pA = math.randomInt(5, 50)/10;
        return {
          pA: pA,
          pBGivenA: pBGivenA,
          pBnotA: pBnotA,
        }
      },
      bayesTheoremProblem7() {
        const flu = math.randomInt(800, 950)/10;
        const measlesAndRash = math.randomInt(700, 850)/10;
        const fluAndRash = math.randomInt(50, 120)/10;
        return {
          flu: flu,
          measlesAndRash: measlesAndRash,
          fluAndRash: fluAndRash,
        }
      },
      bayesTheoremProblem8() {
        const multiplier = math.randomInt(8, 20);
        const helpSeekers = math.randomInt(50, 160)/10;
        return {
          multiplier: multiplier,
          helpSeekers: helpSeekers,
        }
      },
      bayesTheoremProblem9() {
        const sensitive = math.randomInt(80, 99);
        const specific = math.randomInt(80, 99);
        const users = math.randomInt(3, 20)/10;
        return {
          sensitive: sensitive,
          specific: specific,
          users: users,
        }
      },
      bayesTheoremProblem10() {
        const d = math.randomInt(20, 40);
        const w = math.randomInt(40, 40);
        const bl = math.randomInt(50, 80)/10;
        const wl = math.randomInt(80, 120)/10;
        const dl = math.randomInt(20, 50)/10;
        return {
          d: d,
          w: w,
          bl: bl,
          wl: wl,
          dl: dl,
        }
      },
      async computeM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p1.pBGivenA/100) * (data.p1.pA/100) / (data.p1.pB/100);
          const studValue = document.getElementById('m1q1').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `P(B|A) = Probability the email is flagged as spam given that it is spam. <br> P(A) = Probability the email is spam. <br> P(B) = Probability the email is flagged as spam. <br><br> ${renderEq('P(A|B) = \\frac{P(B|A)P(A)}{P(B)} = \\frac{'+math.round(data.p1.pBGivenA/100,4)+'*'+math.round(data.p1.pA/100,4)+'}{'+math.round(data.p1.pB/100,4)+'} \\approx '+ math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `P(B|A) = Probability the email is flagged as spam given that it is spam. <br> P(A) = Probability the email is spam. <br> P(B) = Probability the email is flagged as spam.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch4.mod1;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod1: {
                    p1: studValue,
                  },
                }
              },
              gradeBook: {
                ch4: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p2.pBGivenA/100) * (data.p2.pA/100) / (data.p2.pB/100);
          const studValue = document.getElementById('m1q2').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `P(B|A) = Probability the customer makes a purchase given that they are loyal. <br> P(A) = Probability the customer is loyal. <br> P(B) = Probability the customer makes a purchase. <br><br> ${renderEq('P(A|B) = \\frac{P(B|A)P(A)}{P(B)} = \\frac{'+math.round(data.p2.pBGivenA/100,4)+'*'+math.round(data.p2.pA/100,4)+'}{'+math.round(data.p2.pB/100,4)+'} \\approx '+ math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `P(B|A) = Probability the customer makes a purchase given that they are loyal. <br> P(A) = Probability the customer is loyal. <br> P(B) = Probability the customer makes a purchase.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch4.mod1;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod1: {
                    p2: studValue,
                  },
                }
              },
              gradeBook: {
                ch4: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p3.pBGivenA/100) * (data.p3.pA/100) / (data.p3.pB/100);
          const studValue = document.getElementById('m1q3').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `P(B|A) = Probability the person tests positive given that they have the disease. <br> P(A) = Probability the person has the disease. <br> P(B) = Probability the person tests positive. <br><br> ${renderEq('P(A|B) = \\frac{P(B|A)P(A)}{P(B)} = \\frac{'+math.round(data.p3.pBGivenA/100,4)+'*'+math.round(data.p3.pA/100,4)+'}{'+math.round(data.p3.pB/100,4)+'} \\approx '+ math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `P(B|A) = Probability the person tests positive given that they have the disease. <br> P(A) = Probability the person has the disease. <br> P(B) = Probability the person tests positive.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch4.mod1;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod1: {
                    p3: studValue,
                  },
                }
              },
              gradeBook: {
                ch4: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p4.pBGivenA/100)*(data.p4.pA/100) + (data.p4.pBnotA/100)*(1-data.p4.pA/100);
          const studValue = document.getElementById('m2q1').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `We can label A as 'Spam' and B as 'Flagged'. P(B) = P(B|A)P(A) + P(B|¬A)P(¬A). <br> P(B|A) = Probability the email is flagged as spam given that it is spam. <br> P(A) = Probability the email is spam. <br> P(B|¬A) = Probability the email is flagged as spam given that it is not spam. <br> P(¬A) = Probability the email is not spam. <br> ${renderEq('P(B) = '+data.p4.pBGivenA/100+'*'+data.p4.pA/100+' + '+data.p4.pBnotA/100+'*'+(1-data.p4.pA/100)+' = '+math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `We can label A as 'Spam' and B as 'Flagged'. P(B) = P(B|A)P(A) + P(B|¬A)P(¬A). <br> P(B|A) = Probability the email is flagged as spam given that it is spam. <br> P(A) = Probability the email is spam. <br> P(B|¬A) = Probability the email is flagged as spam given that it is not spam. <br> P(¬A) = Probability the email is not spam.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch4.mod2;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod2: {
                    p1: studValue,
                  },
                }
              },
              gradeBook: {
                ch4: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p5.pBGivenA/100)*(data.p5.pA/100) + (data.p5.pBnotA/100)*(1-data.p5.pA/100);
          const studValue = document.getElementById('m2q2').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `We can label A as 'Loyal' and B as 'Purchase'. P(B) = P(B|A)P(A) + P(B|¬A)P(¬A). <br> P(B|A) = Probability the customer makes a purchase given that they are loyal. <br> P(A) = Probability the customer is loyal. <br> P(B|¬A) = Probability the customer makes a purchase given that they are not loyal. <br> P(¬A) = Probability the customer is not loyal. <br> ${renderEq('P(B) = '+data.p5.pBGivenA/100+'*'+data.p5.pA/100+' + '+data.p5.pBnotA/100+'*'+(1-data.p5.pA/100)+' = '+math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `We can label A as 'Loyal' and B as 'Purchase'. P(B) = P(B|A)P(A) + P(B|¬A)P(¬A). <br> P(B|A) = Probability the customer makes a purchase given that they are loyal. <br> P(A) = Probability the customer is loyal. <br> P(B|¬A) = Probability the customer makes a purchase given that they are not loyal. <br> P(¬A) = Probability the customer is not loyal.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch4.mod2;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod2: {
                    p2: studValue,
                  },
                }
              },
              gradeBook: {
                ch4: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p6.pBGivenA/100)*(data.p6.pA/100) + (data.p6.pBnotA/100)*(1-data.p6.pA/100);
          const studValue = document.getElementById('m2q3').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `We can label A as 'Positive Test' and B as 'Disease'. P(B) = P(B|A)P(A) + P(B|¬A)P(¬A). <br> P(B|A) = Probability the person tests positive given that they have the disease. <br> P(A) = Probability the person has the disease. <br> P(B|¬A) = Probability the person tests positive given that they do not have the disease. <br> P(¬A) = Probability the person does not have the disease. <br> ${renderEq('P(B) = '+data.p6.pBGivenA/100+'*'+data.p6.pA/100+' + '+data.p6.pBnotA/100+'*'+(1-data.p6.pA/100)+' = '+math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `We can label A as 'Positive Test' and B as 'Disease'. P(B) = P(B|A)P(A) + P(B|¬A)P(¬A). <br> P(B|A) = Probability the person tests positive given that they have the disease. <br> P(A) = Probability the person has the disease. <br> P(B|¬A) = Probability the person tests positive given that they do not have the disease. <br> P(¬A) = Probability the person does not have the disease.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch4.mod2;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod2: {
                    p3: studValue,
                  },
                }
              },
              gradeBook: {
                ch4: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const pBGivenA = data.p7.measlesAndRash/100;
          const pA = 1 - (data.p7.flu/100);
          const pBGivenNotA = data.p7.fluAndRash/100;
          const pNotA = data.p7.flu/100;
          const trueValue = (pBGivenA*pA) / (pBGivenA*pA + pBGivenNotA*pNotA);
          const studValue = document.getElementById('m3q1').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `<p>We can label A as 'Measles' and B as 'Rash'. 
                                <br> P(B|A) = Probability the person has a rash given that they have the measles. 
                                <br> P(A) = Probability the person has the measles. 
                                <br> P(B|¬A) = Probability the person has a rash given that they have the flu. 
                                <br> P(¬A) = Probability the person has the flu. </p>
                                <p>${renderEq('P(A|B) = \\frac{P(B|A)P(A)}{P(B|A)P(A) + P(B|\\lnot A)P(\\lnot A)}')}</p>
                                <p> ${renderEq('= \\frac{'+math.round(data.p7.fluAndRash/100,4)+'*'+math.round(data.p7.flu/100,4)+'}{'+math.round(data.p7.fluAndRash/100,4)+'*'+math.round(data.p7.flu/100,4)+' + '+math.round(data.p7.measlesAndRash/100,4)+'*'+(1-data.p7.flu/100)+'}')}</p>
                                <p> ${renderEq('\\approx '+ math.round(trueValue,4)+'\\approx '+math.round(trueValue*100,2)+'\\%')}</p>
                                `;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `<p>We can label A as 'Measles' and B as 'Rash'. 
                                <br> P(B|A) = Probability the person has a rash given that they have the measles. 
                                <br> P(A) = Probability the person has the measles. 
                                <br> P(B|¬A) = Probability the person has a rash given that they have the flu. 
                                <br> P(¬A) = Probability the person has the flu.</p>`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch4.mod3;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod3: {
                    p1: studValue,
                  },
                }
              },
              gradeBook: {
                ch4: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const pBGivenA = data.p8.multiplier;
          const pA = data.p8.helpSeekers/100;
          const pBGivenNotA = 1;
          const pNotA = 1 - pA;
          const trueValue = (pBGivenA*pA) / (pBGivenA*pA + pBGivenNotA*pNotA);
          const studValue = document.getElementById('m3q2').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `<p>We can label A as 'Got Help' and B as 'Got an A'. 
                                <br> P(B|A) = Probability they got an A given that they got help.
                                <br> P(A) = Probability they got help. 
                                <br> P(B|¬A) = Probability they got an A given that they did not get help.
                                <br> P(¬A) = Probability they did not get help </p>
                                <p>${renderEq('P(A|B) = \\frac{P(B|A)P(A)}{P(B|A)P(A) + P(B|\\lnot A)P(\\lnot A)}')}</p>
                                <p> ${renderEq('= \\frac{'+math.round(data.p8.multiplier,4)+'x*'+math.round(data.p8.helpSeekers/100,4)+'}{'+math.round(data.p8.multiplier,4)+'x*'+math.round(data.p8.helpSeekers/100,4)+' + 1x*'+(1-data.p8.helpSeekers/100)+'}')}</p>
                                <p> ${renderEq('\\approx '+ math.round(trueValue,4)+'\\approx '+math.round(trueValue*100,2)+'\\%')}</p>
                                `;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `<p>We can label A as 'Got Help' and B as 'Got an A'. 
                                <br> P(B|A) = Probability they got an A given that they got help.
                                <br> P(A) = Probability they got help. 
                                <br> P(B|¬A) = Probability they got an A given that they did not get help.
                                <br> P(¬A) = Probability they did not get help </p>`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch4.mod3;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod3: {
                    p2: studValue,
                  },
                }
              },
              gradeBook: {
                ch4: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const pBGivenA = data.p9.sensitive/100;
          const pA = data.p9.users/100;
          const pBGivenNotA = (1-data.p9.specific/100);
          const pNotA = 1 - pA;
          const trueValue = (pBGivenA*pA) / (pBGivenA*pA + pBGivenNotA*pNotA);
          const studValue = document.getElementById('m3q3').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `<p>We can label A as 'Drug User' and B as 'Positive Test'. 
                                <br> P(B|A) = Probability they tested positive given that they are a drug user.
                                <br> P(A) = Probability they are a drug user.
                                <br> P(B|¬A) = Probability they tested positive given that they are not a drug user.
                                <br> P(¬A) = Probability they are not a drug user. </p>
                                <p>${renderEq('P(A|B) = \\frac{P(B|A)P(A)}{P(B|A)P(A) + P(B|\\lnot A)P(\\lnot A)}')}</p>
                                <p> ${renderEq('= \\frac{'+math.round(data.p9.sensitive/100,4)+'*'+math.round(data.p9.users/100,4)+'}{'+math.round(data.p9.sensitive/100,4)+'*'+math.round(data.p9.users/100,4)+' + '+math.round((1-data.p9.specific/100),4)+'*'+(1-data.p9.users/100)+'}')}</p>
                                <p> ${renderEq('\\approx '+ math.round(trueValue,4)+'\\approx '+math.round(trueValue*100,2)+'\\%')}</p>
                                `;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `<p>We can label A as 'Drug User' and B as 'Positive Test'. 
                                <br> P(B|A) = Probability they tested positive given that they are a drug user.
                                <br> P(A) = Probability they are a drug user.
                                <br> P(B|¬A) = Probability they tested positive given that they are not a drug user.
                                <br> P(¬A) = Probability they are not a drug user. </p>`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch4.mod3;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod3: {
                    p3: studValue,
                  },
                }
              },
              gradeBook: {
                ch4: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const walked = data.p10.w/100;
          const drove = data.p10.d/100;
          const biked = 1 - walked - drove;
          const lateWalking = data.p10.wl/100;
          const lateDriving = data.p10.dl/100;
          const lateBiking = data.p10.bl/100;
          const pBGivenA = lateWalking;
          const pA = walked;
          const pBGivenNotA = (drove*lateDriving + biked*lateBiking)/(drove + biked);
          const pNotA = 1 - pA;
          const trueValue = (pBGivenA*pA) / (pBGivenA*pA + pBGivenNotA*pNotA);
          const studValue = document.getElementById('m3q4').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `<p>We can label A as 'Walked' and B as 'Late'. 
                                <br> P(B|A) = Probability Aras was late given that we walked.
                                <br> P(A) = Probability Aras walked.
                                <br> P(B|¬A) = Probability Aras was late given that he did not walk.
                                <br> P(¬A) = Probability Aras did not walk. </p>
                                <p>${renderEq('P(A|B) = \\frac{P(B|A)P(A)}{P(B|A)P(A) + P(B|\\lnot A)P(\\lnot A)}')}</p>
                                <p> ${renderEq('= \\frac{'+math.round(lateWalking,4)+'*'+math.round(walked,4)+'}{'+math.round(lateWalking,4)+'*'+math.round(walked,4)+' + '+math.round(pBGivenNotA,4)+'*'+(1-walked)+'}')}</p>
                                <p> ${renderEq('\\approx '+ math.round(trueValue,4)+'\\approx '+math.round(trueValue*100,2)+'\\%')}</p>
                                `;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `<p>We can label A as 'Walked' and B as 'Late'. 
                                <br> P(B|A) = Probability Aras was late given that we walked.
                                <br> P(A) = Probability Aras walked.
                                <br> P(B|¬A) = Probability Aras was late given that he did not walk.
                                <br> P(¬A) = Probability Aras did not walk. </p>`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch4.mod3;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch4: {
                  mod3: {
                    p4: studValue,
                  },
                }
              },
              gradeBook: {
                ch4: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
    }, 
    computed: {
      checkM1Q1() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch4;
        const trueValue = (data.p1.pBGivenA/100) * (data.p1.pA/100) / (data.p1.pB/100);
        const studValue = this.userData.stats.textbookResponses.ch4.mod1.p1;
        const result = compare(trueValue, studValue, 0.0001);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
      },
      checkM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p2.pBGivenA/100) * (data.p2.pA/100) / (data.p2.pB/100);
          const studValue = this.userData.stats.textbookResponses.ch4.mod1.p2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p3.pBGivenA/100) * (data.p3.pA/100) / (data.p3.pB/100);
          const studValue = this.userData.stats.textbookResponses.ch4.mod1.p3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p4.pBGivenA/100)*(data.p4.pA/100) + (data.p4.pBnotA/100)*(1-data.p4.pA/100);
          const studValue = this.userData.stats.textbookResponses.ch4.mod2.p1;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p5.pBGivenA/100)*(data.p5.pA/100) + (data.p5.pBnotA/100)*(1-data.p5.pA/100);
          const studValue = this.userData.stats.textbookResponses.ch4.mod2.p2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const trueValue = (data.p6.pBGivenA/100)*(data.p6.pA/100) + (data.p6.pBnotA/100)*(1-data.p6.pA/100);
          const studValue = this.userData.stats.textbookResponses.ch4.mod2.p3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const pBGivenA = data.p7.measlesAndRash/100;
          const pA = 1 - (data.p7.flu/100);
          const pBGivenNotA = data.p7.fluAndRash/100;
          const pNotA = data.p7.flu/100;
          const trueValue = (pBGivenA*pA) / (pBGivenA*pA + pBGivenNotA*pNotA);
          const studValue = this.userData.stats.textbookResponses.ch4.mod3.p1;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const pBGivenA = data.p8.multiplier;
          const pA = data.p8.helpSeekers/100;
          const pBGivenNotA = 1;
          const pNotA = 1 - pA;
          const trueValue = (pBGivenA*pA) / (pBGivenA*pA + pBGivenNotA*pNotA);
          const studValue = this.userData.stats.textbookResponses.ch4.mod3.p2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const pBGivenA = data.p9.sensitive/100;
          const pA = data.p9.users/100;
          const pBGivenNotA = (1-data.p9.specific/100);
          const pNotA = 1 - pA;
          const trueValue = (pBGivenA*pA) / (pBGivenA*pA + pBGivenNotA*pNotA);
          const studValue = this.userData.stats.textbookResponses.ch4.mod3.p3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch4;
          const walked = data.p10.w/100;
          const drove = data.p10.d/100;
          const biked = 1 - walked - drove;
          const lateWalking = data.p10.wl/100;
          const lateDriving = data.p10.dl/100;
          const lateBiking = data.p10.bl/100;
          const pBGivenA = lateWalking;
          const pA = walked;
          const pBGivenNotA = (drove*lateDriving + biked*lateBiking)/(drove + biked);
          const pNotA = 1 - pA;
          const trueValue = (pBGivenA*pA) / (pBGivenA*pA + pBGivenNotA*pNotA);
          const studValue = this.userData.stats.textbookResponses.ch4.mod3.p4;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
    },
    mounted() {
      
    },
  }
  </script>
  
<style scoped>
.bayes-carousel {
    width: 100%;
    margin-bottom: 12px;
    border-radius: 15px;
    background-color: var(--border_color);
}
.bayes-carousel .carousel-control-prev, .bayes-carousel .carousel-control-next {
    width: 5%;
}
.bayes-carousel .carousel-item {
    padding: 0 5%;
    transition: transform 0.25s ease, opacity .05s ease-out;
}
.bayes-carousel-question {
    font-weight: 400;
    padding: 12px 24px;
    background-color: white;
    border-bottom: 1px solid var(--border_color);
    border-top: 1px solid var(--border_color);
}
</style>