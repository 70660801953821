<template>
  <div class="video-container">
    <div ref="player"></div>
  </div>
</template>

<script>
export default {
  props: {
    videoId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      player: null,
      videoDuration: 0,
      watchIntervals: new Set(),
      checkInterval: null,
      totalIntervals: null,
      completedPercentage: 0,
    };
  },
  created() {
  },
  methods: {
    onPlayerReady(event) {
      this.videoDuration = event.target.getDuration();
      this.totalIntervals = Math.floor(this.videoDuration / 10);
      this.checkInterval = setInterval(this.trackWatchProgress, 1000);
    },
    onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.PAUSED || event.data === YT.PlayerState.ENDED) {
        clearInterval(this.checkInterval);
      } else if (event.data === YT.PlayerState.PLAYING) {
        this.checkInterval = setInterval(this.trackWatchProgress, 1000);
      }
    },
    trackWatchProgress() {
      const currentTime = this.player.getCurrentTime();
      const currentInterval = Math.floor(currentTime / 10);
      this.watchIntervals.add(currentInterval);
      this.completedPercentage = Math.ceil((this.watchIntervals.size / this.totalIntervals) * 100);
      this.$emit('update-view', {watched: [...this.watchIntervals], total: this.totalIntervals});
    }
  },
  mounted() {
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    window.onYouTubeIframeAPIReady = () => {
      this.player = new YT.Player(this.$refs.player, {
        width: '100%',
        videoId: this.videoId,
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange
        }
      });
    };
  },
  beforeDestroy() {
    clearInterval(this.checkInterval);
  }
};
</script>

<style scoped>
div {
  text-align: center;
}
.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    margin-bottom: 12px;
}
</style>
