<template>
    <div class="loading-screen">Loading <i id="save_weights_spinner" class="fas fa-spinner fa-spin" style="margin-left: 6px;"></i></div>
    <iframe v-if="url" :src="url" scrolling="no" class="assignment-iframe" @load="closeLoadingScreen"></iframe>
</template>

<script>
import { auth } from '@/main';

export default {
    name: 'AssignmentViewer',
    props: ['navVh'],
    data() {
        return {
            userId: auth.currentUser.uid,
            ch: this.$route.params.ch,
            task: this.$route.params.task,
        };
    },
    computed: {
        url() {
            if (this.userId && this.ch) {
                return `https://script.google.com/macros/s/AKfycbx20O2zCZPV9krhw70svBWKEiH7tgzGFFPqHMVuHIo_7ciAvB1A2-ReClP3qneZNG2q_Q/exec?user=${this.userId}&ch=${this.ch}&task=${this.task}&navVh=${this.navVh}`;
            }
            return null;
        }
    },
    mounted() {
        document.body.classList.add('no-scroll');
        this.$nextTick(() => {
            document.documentElement.style.setProperty('--nav-height', this.navVh + 'vh');
        });
    },
    beforeUnmount() {
        document.body.classList.remove('no-scroll');
    },
    methods: {
        closeLoadingScreen() {
            document.querySelector('.loading-screen').style.display = 'none';
        },
    },
};
</script>

<style scoped>
.loading-screen {
    display: flex;
    width: 100%;
    height: calc(100vh - var(--nav-height));
    justify-content: center;
    align-items: center;
    font-size: 3em;
    font-weight: 600;
    margin-top: -20vh;
}
.assignment-iframe {
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(100vh - var(--nav-height));
    border: none;
}
</style>