<template>

<div class="container col-md-6 offset-md-3">
    
    <!-- #region CHAPTER AND TITLE -->
    <div class="container">
    <div class="book-title-div">
        <span class="fw600 em1-50">Chapter Three</span><br>
        <span class="fw800 em3 fc-stats">Never Tell Me the Odds!</span>
    </div>
    </div>
    <!-- #endregion -->
    
    <!-- SUB NAVIGATION BAR -->
    <nav>
    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
        <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
        <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
        <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
    </div>
    </nav>
    
    <!-- SUB NAV BAR CONTENT -->
    <div class="tab-content" id="nav-tabContent">
    <!-- TEXTBOOK -->
    <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">

      <VideoBox class="mt-4" videoTitle="Never Tell Me the Odds!" videoUrl="https://www.youtube.com/embed/KjR3UTBWJ88" startAt="0"/>

        <!-- #region MODULE 1 -->
        <div v-if="userData" class="intructions-module rounded container">
            <h3 class="fc-stats">Counting Rules</h3>
            <p>
                How many different seven-digit phone numbers are there? How many ways are there to arrange the letters in the word "formula"? 
                In a room of 30 people, how many ways can you choose a committee of 5 people? These are all counting problems, and they can be solved 
                using the counting rules. 
            </p>

            <!-- #region COUNTING RULES ACCORDIAN -->
            <div class="accordion" id="countingRulesAccordian">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            Counting Rule #1:&nbsp;<b>Repeated Trials</b>
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#countingRulesAccordian">
                        <div class="accordion-body">
                            <p>
                                If any one of k different mutually exclusive and collectively exhaustive events can occur on each of n trials, 
                                the number of possible outcomes is equal to:
                            </p>
                            <p class="d-flex justify-content-center align-items-center"><MathEquation :equation="'k^n'"/></p>
                            <p>
                                Suppose we roll three six-sided dice. How many outcomes are possible?
                            </p>
                            <p>
                                Each roll is its own trial (n = 3), and there are six different mutually exclusive and collectively exhaustive events 
                                that each roll can result in (1, 2, 3, 4, 5, and 6). So…
                            </p>
                            <p class="d-flex justify-content-center align-items-center"><MathEquation :equation="'6^3 = 216'"/></p>
                            <p>
                                We can also use this rule to answer the question: How many different seven-digit phone numbers are there? 
                                Each digit is like its own 'trial', and there are 10 different digits that can occur on each trial (0-9). So…
                            </p>
                            <p class="d-flex justify-content-center align-items-center"><MathEquation :equation="'10^7 = 10,000,000'"/></p>
                            <p>
                                There are 10 million possible seven-digit phone numbers! 
                            </p>
                            <p>
                                This rule only applies when the same k events can occur on each of n trials. If the events are different, then you need to use 
                                the next counting rule. 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Counting Rule #2:&nbsp;<b>Many Trials</b>
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#countingRulesAccordian">
                        <div class="accordion-body">
                            <p>
                                If there are <MathEquation :equation="'k_1'"/> outcomes for the first trial, <MathEquation :equation="'k_2'"/> outcomes for the second trial, 
                                and so on until <MathEquation :equation="'k_n'"/> outcomes for the nth trial, then the total number of possible outcomes is equal to:
                            </p>
                            <p ref="manyTrials" class="d-flex justify-content-center align-items-center"><MathEquation :equation="'\\overset{n}{\\underset{i=1}{\\prod}} k_i'"/></p>
                            <p>
                                Here is an example. Freshmen, sophomores, juniors, and seniors will all elect their own class representative. 
                                There are 4 candidates for the freshmen, 3 candidates for the sophomores, 5 candidates for 
                                the juniors, and 2 candidates for the seniors. How many different sets of election results are there?
                            </p>
                            <p>
                                Each class election is its own trial, and each trial has its own set of possible mutually 
                                exclusive and collectively exhaustive outcomes. To find the number of possible results, we 
                                simply multiply the number of outcomes for each trial by each other:
                            </p>
                            <p class="d-flex justify-content-center align-items-center"><MathEquation :equation="'\\overset{n}{\\underset{i=1}{\\prod}} k_i = 4 * 3 * 5 * 2 = 120'"/></p>
                            <p>
                                There are 120 different sets of election results possible.
                            </p>
                            <p>
                                Note that thie 'Many Trials' rule is a more general version of the 'Repeated Trials' rule. When the same k events can occur on each of n trials, 
                                then the 'Many Trials' rule simplifies to the 'Repeated Trials' rule.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo">
                            Counting Rule #3:&nbsp;<b>Factorials</b>
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#countingRulesAccordian">
                        <div class="accordion-body">
                            <p>
                                A <b>factorial</b> is the product of all positive integers less than or equal to a given number. 
                                It is denoted by an exclamation point (!). For example, 5! = 5 * 4 * 3 * 2 * 1 = 120.
                            </p>
                            <p>
                                Factorials are used to calculate the number of ways to arrange a set of items. For example, 
                                how many ways can you arrange the letters in the word "formula"?
                            </p>
                            <p>
                                There are 7 letters in the word "formula", so there are 7! ways to arrange them. 
                            </p>
                            <p class="d-flex justify-content-center align-items-center"><MathEquation :equation="'7! = 7*6*5*4*3*2*1 = 5,040'"/></p>
                            <p>
                                This simplifies to 5040 ways to arrange the letters in the word "formula"! For example: "aflmrou", "famorlu", "mofular", etc.
                            </p>
                            <p>
                                Factorials get big fast! For example, 10! = 3,628,800, and 20! = 2,432,902,008,176,640,000. If you shuffle a deck of playing cards, 
                                the number of possible arrangements is:
                            </p>
                            <p>
                                52! = 80,658,175,170,943,878,571,660,636,856,403,766,975,289,505,440,883,277,824,000,000,000,000
                            </p>
                            <p>
                                Thats 80 unvigintillion, 658 vigintillion, 175 novemdecillion, 170 octodecillion, 943 septendecillion, 878 sexdecillion, 571 quindecillion, 660 
                                quattuordecillion, 636 tredecillion, 856 duodecillion, 403 undecillion, 766 decillion, 975 nonillion, 289 octillion, 505 septillion, 440 sextillion, 
                                883 quintillion, 277 quadrillion, 824 trillion possible arrangements!
                            </p>
                            <p>
                                That is more than the number of stars in the observable universe, more than the number of atoms in the Earth, and more than the number of 
                                possible genetic combinations two people could pass on to a baby. Next time you shuffle a deck of cards, take a moment to appreciate the fact 
                                that it is likely the first and only time in the history of the universe that the cards have ever been in that order. It is an arrangement less 
                                likely to exist than you are!
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseTwo">
                            Counting Rule #4:&nbsp;<b>Permutations</b>
                        </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#countingRulesAccordian">
                        <div class="accordion-body">
                            <p>
                                A <b>permutation</b> counts the number of ways there are to <b>arrange</b> <i>k</i> items selected from <i>n</i> total items. 
                                The formula for permutations is:
                            </p>
                            <p class="d-flex justify-content-center align-items-center"><MathEquation :equation="'P(n,k) = \\frac{n!}{(n-k)!}'"/></p>
                            <p>
                                If there are 20 people competing in an Olympic event, how many permutations are there for the top 3 places? Any one of those 
                                20 people could win the gold, any one of the remaining 19 people could win the silver, and any one of the remaining 18 people 
                                could win the bronze. So, the number of permutations would be 20 * 19 * 18 = 6,840. But notice, that is what the permutations 
                                formula gives us:
                            </p>
                            <p class="d-flex justify-content-center align-items-center"><MathEquation :equation="'P(20,3) = \\frac{20!}{(20-3)!} = \\frac{20!}{17!} = 6,840'"/></p>
                            <p>
                                Permutations are used when the order of the items matters. We might wonder how many five-letter permutations can be made from the word 'formula'. 
                                Since the order of the letters matters, we use permutations. There are 7 letters in the word 'formula', so there are 7! ways to arrange them. 
                                But we only want five-letter permutations, so we use the permutation formula to cancel out the selection of the last two letters:
                            </p>
                            <p class="d-flex justify-content-center align-items-center"><MathEquation :equation="'P(7,5) = \\frac{7!}{(7-5)!} = \\frac{7!}{2!} = 2,520'"/></p>
                            <p>
                                There are 2,520 different five-letter permutations that can be made from the word 'formula', such as 'forum', 'flour', 'amruf', etc.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseTwo">
                            Counting Rule #5:&nbsp;<b>Combinations</b>
                        </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#countingRulesAccordian">
                        <div class="accordion-body">
                            <p>
                                A <b>combination</b> counts the number of ways there are to <b>select</b> <i>k</i> items from <i>n</i> total items. 
                                The formula for combinations is:
                            </p>
                            <p class="d-flex justify-content-center align-items-center"><MathEquation :equation="'C(n,k) = \\frac{n!}{k!(n-k)!}'"/></p>
                            <p>
                                If a lottery chooses 5 numbers from a bucket of 50 numbers, how many different combinations of numbers are possible? 
                                The order of the numbers doesn't matter, so we use combinations. There are 50 numbers to choose from, and we are choosing 5 
                                of them, so the number of combinations is:
                            </p>
                            <p class="d-flex justify-content-center align-items-center"><MathEquation :equation="'C(50,5) = \\frac{50!}{5!(50-5)!} = \\frac{50!}{5!45!} = 2,118,760'"/></p>
                            <p>
                                There are 2,118,760 different combinations of 5 numbers that can be chosen from a pool of 50 numbers. In other words, your odds 
                                of winning this lottery with a single ticket are 1 in 2,118,760.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- #endregion -->

            <div class="mt-3" v-if="userData">
              <h4 class="fc-prove">Prove Your Understanding</h4>
              <p>Answer each of the questions in the slides below using the five counting rules.</p>
              
            <div class="d-flex justify-content-center align-items-center">
              <div id="carouselExampleIndicators" class="carousel carousel-dark slide carousel-wrapper">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                </div>
                <div class="carousel-inner">

                  <!-- QUESTION 1 -->
                  <div class="carousel-item active">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 1</div>
                      <div class="carousel-question">
                        If we roll a {{ userData.stats.problemSets.ch3.k[0] }}-sided die a total of {{ userData.stats.problemSets.ch3.n[0] }} times, 
                        how many possible sequences of rolls are there?
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m1q1">?</span>
                          <input type="text" class="form-control" id="m1q1" :value="userData.stats.textbookResponses.ch3.mod1.p1" :style="checkM1Q1">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                  <!-- QUESTION 2 -->
                  <div class="carousel-item">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 2</div>
                      <div class="carousel-question">
                        A confirmation number has {{ userData.stats.problemSets.ch3.k[1] }} letters followed by {{ userData.stats.problemSets.ch3.n[1] }} numbers. 
                        How many possible confirmation numbers are there?
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m1q2">?</span>
                          <input type="text" class="form-control" id="m1q2" :value="userData.stats.textbookResponses.ch3.mod1.p2" :style="checkM1Q2">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                  <!-- QUESTION 3 -->
                  <div class="carousel-item">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 3</div>
                      <div class="carousel-question">
                        How many ways are there to arrange {{ userData.stats.problemSets.ch3.n[2] }} objects?
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m1q3">?</span>
                          <input type="text" class="form-control" id="m1q3" :value="userData.stats.textbookResponses.ch3.mod1.p3" :style="checkM1Q3">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                  <!-- QUESTION 4 -->
                  <div class="carousel-item">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 4</div>
                      <div class="carousel-question">
                        A restaurant has {{ userData.stats.problemSets.ch3.n[3] }} empty tables when {{ userData.stats.problemSets.ch3.k[3] }} parties of people come in asking to be seating. 
                        How many ways are there to seat the parties?
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m1q4">?</span>
                          <input type="text" class="form-control" id="m1q4" :value="userData.stats.textbookResponses.ch3.mod1.p4" :style="checkM1Q4">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                  <!-- QUESTION 5 -->
                  <div class="carousel-item">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 5</div>
                      <div class="carousel-question">
                        A drawing will pick {{ userData.stats.problemSets.ch3.k[4] }} numbers from a bucket of {{ userData.stats.problemSets.ch3.n[4] }} numbers. 
                        The numbers selected will then be arranged by numerical order. How many final arrangements are possible?
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m1q5">?</span>
                          <input type="text" class="form-control" id="m1q5" :value="userData.stats.textbookResponses.ch3.mod1.p5" :style="checkM1Q5">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">P</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">N</span>
                </button>
              </div>
            </div>

            <CalculatorLinks :score="mod1Score" :scoreTotal="5"/>
          </div>
        </div>
        <!-- #endregion -->

        <!-- #region MODULE 2 -->
        <div v-if="userData" class="intructions-module rounded container">
          <h3 class="fc-stats">Simple Probability</h3>
          <p>
            Probability is the numerical value representing the chance that a particular event will occur. 
            Philosophers and mathematicians debate the true meaning of probabilities, given that each trial 
            yields only one outcome. Yet, to those who embrace numbers as friends, it remains intuitive. 
            We can express probability simply as:
          </p>
          <p class="d-flex justify-content-center align-items-center">
            <MathEquation :equation="'P(A) = '"/>
            <span style="font-size: 1.5rem;"><MathEquation :equation="'\\frac{\\text{Number of ways A can happen}}{\\text{Total number of possible outcomes}}'"/></span>
          </p>
          <p>
            For example, if you roll a six-sided die, the probability of rolling a 3 is 1/6 (~16.67%). There is only one way to roll a 3, and there are six possible outcomes. 
          </p>
          <p class="d-flex justify-content-center align-items-center">
            <MathEquation :equation="'P(\\text{Rolling a 3}) = '"/>
            <span style="font-size: 2rem;"><MathEquation :equation="'\\frac{\\text{⚂}}{\\text{⚀⚁⚂⚃⚄⚅}}'"/></span>
            <span style="font-size: 1.5rem;"><MathEquation :equation="' = \\frac{1}{6}'"/></span>
            <span style="font-size: 1rem;"><MathEquation :equation="' = 0.1\\bar{6} \\approx 16.67\\%'"/></span>
          </p>
          <p>
            Take a moment to digest the fact that <MathEquation :equation="'\\frac{1}{6} = 0.1\\bar{6} \\approx 16.67\\%'"/>. Each of these is a different way of expressing a probability. 
            You can also express the probability in 'odds' form, which is the ratio of the number of ways an event can happen to the number of ways it cannot happen. 
            For example, the odds of rolling a 3 are 1:5. There is one way to roll a 3, and five ways to not roll a 3. But I won't ever make you tell me the odds! 
          </p>
          <div class="accordion mb-3" id="bettingOddsAccordian">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOdds" aria-expanded="false" aria-controls="collapseOdds">
                      <span class="em2">⚂⚅</span>&nbsp;A brief but useful tour of betting odds
                    </button>
                </h2>
                <div id="collapseOdds" class="accordion-collapse collapse" data-bs-parent="#bettingOddsAccordian">
                    <div class="accordion-body">
                        <p>
                          We can also express odds as betting odds. For example, if the probability a team wins their game is 60%, then the 
                          odds of them winning are 3:2. This means that for every 3 times they win, they lose 2 times. But the betting odds, 
                          or fractional odds, would be written as 4/6. That means if you bet $6 and the team wins, you would be repaid 
                          $4 plus your original $6 ($10 total). If they lose, you are out the $6. 
                        </p>
                        <p>
                          More common in America is the moneyline odds, which would be written as -150. This means you would have to bet $150 to win $100. 
                          Since the other team has a 40% chance of winning, their odds would be 2:3, or +150 moneyline odds. This means if you bet $100 and they win, 
                          you would be repaid $150 plus your original $100 ($250 total). I'm sure you will agree that this is not confusing at all and you will 
                          never again have to look it up, as I did not need to when writing this explanation.
                        </p>
                        <p>
                          ...but just in case, <a href="https://www.aceodds.com/bet-calculator/odds-converter.html" target="_blank">this website</a> has a handy 
                          conversion tool.
                        </p>
                    </div>
                </div>
            </div>
          </div>  
          <h5>Calculating Simple Probabilities</h5>
          <table>
            <tr>
              <td><CardDraw /></td>
              <td style="padding: 0 0 0 24px;">
                <p>
                  We can use our counting rules to help us calculate both the numerator and denominator of a simple probability. For example, we could calculate 
                  the probability of getting a royal flush in a game of <a href="https://en.wikipedia.org/wiki/Five-card_stud" target="_blank">five-card stud</a>. 
                  A royal flush is the best hand you can get, and it consists of the 10, Jack, Queen, King, and Ace of the same suit.
                </p>
              </td>
            </tr>
          </table>
          <p>
            There are 52 cards in a deck, and 5 cards are drawn. The number of ways to draw a royal flush is the number of ways to draw the 10, Jack, Queen, King, 
            and Ace of the same suit. There are 4 suits, so there are 4 ways to draw a royal flush. The total number of possible hands is the number of ways to draw 
            any 5 cards from a deck of 52 cards. This is a combination problem, and we can use the combination formula to calculate the denominator.
          </p>
          <p class="d-flex justify-content-center align-items-center">
            <MathEquation :equation="'P(\\text{Royal Flush}) = \\frac{4}{C(52,5)} = \\frac{4}{2,598,960} \\approx 0.000001539 \\approx 0.0001539\\%'"/>
            <span style="font-size: 2rem;"><MathEquation :equation="''"/></span>
          </p>
          <p>
            The probability of getting a royal flush in a game of five-card stud is 0.000001539, or about 0.0001539%. It happens around once in 649,739 hands.
          </p>

          <div class="mt-3" v-if="userData">
              <h4 class="fc-prove">Prove Your Understanding</h4>
              <p>Compute the probability for each question.</p>
            <div class="d-flex justify-content-center align-items-center">
              <div id="moduleTwoCarousel" class="carousel carousel-dark slide carousel-wrapper">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#moduleTwoCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#moduleTwoCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#moduleTwoCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">

                  <!-- QUESTION 1 -->
                  <div class="carousel-item active">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 1</div>
                      <div class="carousel-question">
                        From a standard deck of 52 playing cards, you draw the following cards:
                        <div class="d-flex justify-content-center align-items-center em5">
                          <div v-for="card in userData.stats.problemSets.ch3.cards" :key="card" :class="getCardColor(card)">
                            {{ card }}
                          </div>
                        </div>
                        If you draw one more card, what is the probability that it is a Diamond?
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m2q1">?</span>
                          <input type="text" class="form-control" id="m2q1" :value="userData.stats.textbookResponses.ch3.mod2.p1" :style="checkM2Q1">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                  <!-- QUESTION 2 -->
                  <div class="carousel-item">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 2</div>
                      <div class="carousel-question">
                        A bag contains {{ userData.stats.problemSets.ch3.marbles.red }} red, 
                        {{ userData.stats.problemSets.ch3.marbles.blue }} blue, and 
                        {{ userData.stats.problemSets.ch3.marbles.green }} green marbles. 
                        If a marble is drawn at random, what is the probability that it is not red?
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m2q2">?</span>
                          <input type="text" class="form-control" id="m2q2" :value="userData.stats.textbookResponses.ch3.mod2.p2" :style="checkM2Q2">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                  <!-- QUESTION 3 -->
                  <div class="carousel-item">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 3</div>
                      <div class="carousel-question">
                        A fair six-sided die is rolled once. What is the probability of rolling a number greater than {{ userData.stats.problemSets.ch3.dice }}?
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m2q3">?</span>
                          <input type="text" class="form-control" id="m2q3" :value="userData.stats.textbookResponses.ch3.mod2.p3" :style="checkM2Q3">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>  

                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#moduleTwoCarousel" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">P</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#moduleTwoCarousel" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">N</span>
                </button>
              </div>
            </div>

            <CalculatorLinks :score="mod2Score" :scoreTotal="3"/>
          </div>

        </div>
        <!-- #endregion -->

        <!-- #region MODULE 3 -->
        <div v-if="userData" class="intructions-module rounded container">
            <h3 class="fc-stats">Rules of Probability</h3>
            <p>
                The formula for a simple probability always works, but sometimes thinking through what events are possible is not so simple. There are 
                a few rules which can help clarify a problem and make calculating a probability much easier. Before we look at those, however, we need to 
                learn a few terms.
            </p>

            <div class="d-flex justify-content-center align-items-center">
              <div id="rulesOfStatsCarousel" class="carousel carousel-dark slide carousel-wrapper">
                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#rulesOfStatsCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#rulesOfStatsCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#rulesOfStatsCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#rulesOfStatsCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                  </div>
                  <div class="carousel-inner">
  
                    <!-- TERM 1 -->
                    <div class="carousel-item active">
                      <div class="d-block w-100">
                        <div class="carousel-title">Independent Events</div>
                        <div class="carousel-question">
                          <p class="highlight">
                            Two events are independent if the occurrence of one event does not affect the chances of the occurrence of the other event.
                          </p>
                          <small>
                            <b>Example</b><br>
                            If we flip a coin twice, the probability of getting heads on the second flip is <i>independent</i> of the outcome of the first flip. 
                            In fact, if we flip a fair coin 10 times and get heads every time, the probability of getting heads on the 11th flip is still 1/2. 
                            An example of events which are <i>not</i> independent would be drawing a card from a deck and then drawing a second card without 
                            replacing the first card. The probability of drawing a heart on the second draw is dependent on the outcome of the first draw. 
                          </small>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- TERM 2 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="carousel-title">Mutually Exclusive</div>
                        <div class="carousel-question">
                          <p class="highlight">
                            Two events are mutually exclusive if they cannot both occur at the same time.
                          </p>
                          <small>
                            <b>Example</b><br>
                            A coin flip has two mutally exlusive outcomes: heads and tails. If you flip a coin, it cannot land on both heads and tails at the same time. 
                            However, if we flip two coins, the outcomes of the two coins are not mutually exclusive. Both coins can land on heads at the same time. 
                            Mutally exclusive events are useful when computing probabilities, because we can sometime infer one probability from the other. If there are 
                            only two possible outcomes, and they are mutually exclusive, then the probability of one event is 1 minus the probability of the other event. 
                            For example, if a trick coin has a 90% chance of landing on heads, then we can infer that it has a 10% chance of landing on tails.
                          </small>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <!-- TERM 3 -->
                    <div class="carousel-item">
                      <div class="d-block w-100">
                        <div class="carousel-title">Collectively Exhaustive</div>
                        <div class="carousel-question">
                          <p class="highlight">
                            A set of events is collectively exhaustive if one of the events must occur.
                          </p>
                          <small>
                            <b>Example</b><br>
                            When flipping a coin, the outcomes of heads and tails are collectively exhaustive. One of them must occur. If we roll a 
                            six-sided die, the outcomes of rolling a 1, 2, 3, 4, 5, or 6 are collectively exhaustive. One of them must occur. This list 
                            does not have to be comprised of mutally exclusive events. For example, if we consider the outcomes of weather on a given 
                            day (e.g., raining, sunny, cloudy), these can be collectively exhaustive but not mutually exclusive (e.g., it can be both 
                            raining and cloudy at the same time). 
                          </small>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
  
  
                  </div>
  
                  <button class="carousel-control-prev" type="button" data-bs-target="#rulesOfStatsCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">P</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#rulesOfStatsCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">N</span>
                  </button>
                </div>
            </div>
            <h4>Rules and Patterns</h4>
            <p>
              In this video from Crash Course Statistics, make sure to take notes on the rules of probability, including the <b>addition rule</b>, 
              the <b>multiplication rule</b>, and how to calculate <b>joint probabilities</b> and <b>conditional probabilities</b>. 
            </p>
            <!-- Video from Crash Course Statistics -->
            <VideoBox videoTitle="Rules and Patterns of Probability" videoUrl="https://www.youtube.com/embed/OyddY7DlV58" startAt="3"/>

            <div class="mt-3" v-if="userData">
              <h4 class="fc-prove">Prove Your Understanding</h4>
              <p>Below is a spreadsheet filled with values.</p>

              <div class="d-flex justify-content-center align-items-center">
              <div id="rulesAndPatternsCarousel" class="carousel carousel-dark slide carousel-wrapper">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#rulesAndPatternsCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#rulesAndPatternsCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#rulesAndPatternsCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  <button type="button" data-bs-target="#rulesAndPatternsCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div class="carousel-inner">

                  <!-- QUESTION 1 -->
                  <div class="carousel-item active">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 1</div>
                      <div class="carousel-question">
                        The student body is electing representatives to student government for the Junior and Senior classes. Below is a table showing 
                        each candidate's chances of winning. 
                        <table class="election-table">
                          <tr class="et-head">
                            <td class="etc1">Senior Student</td>
                            <td class="etc2">Chance to Win</td>
                            <td class="etc3">&nbsp;</td>
                            <td class="etc4">Junior Student</td>
                            <td class="etc5">Chance to Win</td>
                          </tr>
                          <tr>
                            <td class="etc1">Rob</td>
                            <td class="etc2">{{ userData.stats.problemSets.ch3.elect.rob }}%</td>
                            <td class="etc3">&nbsp;</td>
                            <td class="etc4">Kim</td>
                            <td class="etc5">{{ userData.stats.problemSets.ch3.elect.kim }}%</td>
                          </tr>
                          <tr>
                            <td class="etc1">Sandra</td>
                            <td class="etc2">{{ userData.stats.problemSets.ch3.elect.sandra }}%</td>
                            <td class="etc3">&nbsp;</td>
                            <td class="etc4">Yul</td>
                            <td class="etc5">{{ userData.stats.problemSets.ch3.elect.yul }}%</td>
                          </tr>
                          <tr>
                            <td class="etc1">Tony</td>
                            <td class="etc2">{{ userData.stats.problemSets.ch3.elect.tony }}%</td>
                            <td class="etc3">&nbsp;</td>
                            <td class="etc4">Parvati</td>
                            <td class="etc5">{{ userData.stats.problemSets.ch3.elect.parvati }}%</td>
                          </tr>  
                        </table>
                        What is the probability that {{ userData.stats.problemSets.ch3.elect.senior }} <b>and</b> {{ userData.stats.problemSets.ch3.elect.junior }} both win?
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m3q1">?</span>
                          <input type="text" class="form-control" id="m3q1" :value="userData.stats.textbookResponses.ch3.mod3.p1" :style="checkM3Q1">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                  <!-- QUESTION 2 -->
                  <div class="carousel-item">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 2</div>
                      <div class="carousel-question">
                        The student body is electing representatives to student government for the Junior and Senior classes. Below is a table showing 
                        each candidate's chances of winning. 
                        <table class="election-table">
                          <tr class="et-head">
                            <td class="etc1">Senior Student</td>
                            <td class="etc2">Chance to Win</td>
                            <td class="etc3">&nbsp;</td>
                            <td class="etc4">Junior Student</td>
                            <td class="etc5">Chance to Win</td>
                          </tr>
                          <tr>
                            <td class="etc1">Rob</td>
                            <td class="etc2">{{ userData.stats.problemSets.ch3.elect.rob }}%</td>
                            <td class="etc3">&nbsp;</td>
                            <td class="etc4">Kim</td>
                            <td class="etc5">{{ userData.stats.problemSets.ch3.elect.kim }}%</td>
                          </tr>
                          <tr>
                            <td class="etc1">Sandra</td>
                            <td class="etc2">{{ userData.stats.problemSets.ch3.elect.sandra }}%</td>
                            <td class="etc3">&nbsp;</td>
                            <td class="etc4">Yul</td>
                            <td class="etc5">{{ userData.stats.problemSets.ch3.elect.yul }}%</td>
                          </tr>
                          <tr>
                            <td class="etc1">Tony</td>
                            <td class="etc2">{{ userData.stats.problemSets.ch3.elect.tony }}%</td>
                            <td class="etc3">&nbsp;</td>
                            <td class="etc4">Parvati</td>
                            <td class="etc5">{{ userData.stats.problemSets.ch3.elect.parvati }}%</td>
                          </tr>  
                        </table>
                        What is the probability that {{ userData.stats.problemSets.ch3.elect.senior }} <b>or</b> {{ userData.stats.problemSets.ch3.elect.junior }} win their elections?
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m3q2">?</span>
                          <input type="text" class="form-control" id="m3q2" :value="userData.stats.textbookResponses.ch3.mod3.p2" :style="checkM3Q2">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                  <!-- QUESTION 3 -->
                  <div class="carousel-item">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 3</div>
                      <div class="carousel-question">
                        A bag contains {{ userData.stats.problemSets.ch3.marbles.red }} red, 
                        {{ userData.stats.problemSets.ch3.marbles.blue }} blue, and 
                        {{ userData.stats.problemSets.ch3.marbles.green }} green marbles. 
                        If I draw two marbles at random, what is the probability that I draw one red marble and one blue marble? <br><br>
                        <small>Note: The marbles are drawn without replacement, it the order of the red and blue marble do not matter.</small>
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m3q3">?</span>
                          <input type="text" class="form-control" id="m3q3" :value="userData.stats.textbookResponses.ch3.mod3.p3" :style="checkM3Q3">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                  <!-- QUESTION 4 -->
                  <div class="carousel-item">
                    <div class="d-block w-100">
                      <div class="carousel-title">Question 4</div>
                      <div class="carousel-question">
                        In a group of {{ userData.stats.problemSets.ch3.birthday }} people, what is the probability that at least two people have the same birthday? <br><br>
                        <small>Assume there are no leap day birthdays (i.e. only 365 days in the year) and that birthdays are uniformly distributed. </small>
                        <div class="input-group mb-3 mt-3">
                          <span class="input-group-text" ref="m3q4">?</span>
                          <input type="text" class="form-control" id="m3q4" :value="userData.stats.textbookResponses.ch3.mod3.p4" :style="checkM3Q4">
                          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  </div>

                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#rulesAndPatternsCarousel" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">P</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#rulesAndPatternsCarousel" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">N</span>
                </button>
              </div>
            </div>

            <CalculatorLinks :score="mod3Score" :scoreTotal="12"/>
          </div> 
        </div>
        <!-- #endregion -->

        <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/RWeUExdyoOc" startAt="0"/>
        
        <!-- #region DATA CHANGER -->
        <div v-if="userData" class="data-changer">
          <div class="em1-50 fw600">Questions Reset</div>
          <div>You can reset the questions with randomly generated numbers for more practice.</div>
          <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
        </div>
        <!-- #endregion -->

    </div>
    <!-- EXPLORATORY LESSON -->
    <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
        <div class="row mt-4">
        <div class="col-4">
            <img :src="ExploratoryLessonIcon" width="100%">
        </div>
        <div class="col-8">
            <h3 class="fc-stats">Exploratory Lesson 3</h3>
            <div style="text-align: justify;">
              The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will learn how to apply combinatorics and probability in Google Sheets,. 
              We will also learn about the most powerful tool in all of statistics: <b>Monte Carlo Analysis</b>.
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ lssnScore }} out of 20 points on this lesson.
            </div>
        </div>
    
        </div>
        <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link to="/stats/3/lesson" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
        </div>
    </div>
    <!-- SPREADSHEET ASSIGNMENT -->
    <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
        <div class="row mt-4">
        <div class="col-4">
            <img :src="SpreadsheetAssignmentIcon" width="100%">
        </div>
        <div class="col-8">
            <h3 class="fc-stats">Spreadsheet Assignment 3</h3>
            <div style="text-align: justify;">
              The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will answer questions using the counting rules and then 
              do a Monte Carlo simulation to prove the paradox of the Monty Hall problem. I highly recommend watching 
              <a href="https://www.youtube.com/watch?v=ggDQXlinbME" target="blank">this video on the Monty Hall problem</a> 
              before starting the assignment!
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ assnScore }} out of 30 points on this assignment.
            </div>
        </div>
    
        </div>
        <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link to="/stats/3/assignment" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
        </div>
    </div>
    </div>

</div>

<!-- #region MODAL -->
<div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <img :src="probabilibot" width="20%">
        <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body col" v-html="modalMessage"></div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

</template>

<script>
import { doc, getDoc, setDoc } from "firebase/firestore"; 
import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
import MathEquation from '@/components/MathEquation.vue';
import CalculatorLinks from '@/components/CalculatorLinks.vue';
// import PopOver from '@/components/PopOver.vue';
import { Popover } from 'bootstrap';
import VideoBox from '@/components/VideoBox.vue';
import CardDraw from '@/components/CardDraw.vue';

import { create, all } from 'mathjs'
const math = create(all);

import probabilibot from '@/assets/Probabilibot.png';
import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';

export default {
  name: "statsChapter3",
  components: {
    MathEquation,
    CalculatorLinks,
    // PopOver,
    VideoBox,
    CardDraw,
  },
  props: ['tab'],
  data() {
    return {
      userData: null,
      setUpStatsData,
      mod1Score: 0,
      mod2Score: 0,
      mod3Score: 0,
      assnScore: 0,
      lssnScore: 0,
      modalTitle: 'Loading...',
      modalMessage: '',
      probabilibot,
      ExploratoryLessonIcon,
      SpreadsheetAssignmentIcon,
      renderEq,
      multipleChoice:{
        q1: [
            { value: '1', text: 'P(A or B) = P(A) + P(B) + P(A or B)' },
            { value: '2', text: 'P(A or B) = P(A) + P(B) + P(A and B)' },
            { value: '3', text: 'P(A or B) = P(A) + P(B) - P(A and B)' },
            { value: '4', text: 'P(A or B) = P(A) + P(B) - P(A or B)' },
        ],
        q1ans: '3',
        q2: [
            { value: '1', text: 'P(A and B) = P(A) + P(B) + P(A or B)' },
            { value: '2', text: 'P(A and B) = P(A) + P(B) + P(A and B)' },
            { value: '3', text: 'P(A and B) = P(A) + P(B) - P(A and B)' },
            { value: '4', text: 'P(A and B) = P(A) + P(B) - P(A or B)' },
        ],
        q2ans: '3',
      } 
    }
  },
  created() {
    this.loadUserInformation();    
  },
  methods: {
    async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullProblemSet();
        await this.setPopovers();
    },
    async pullProblemSet() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      let docSnap = await getDoc(docRef);
      let data = docSnap.data();
      
      // ADD VALUES FOR THE CHAPTER PROBLEM SET
      if(Object.keys(data.stats.problemSets.ch3).length == 0){
        await setDoc(docRef, {
          stats: {
            problemSets: {
              ch3: {
                n: [math.randomInt(3,7),math.randomInt(2,7),math.randomInt(4,13),math.randomInt(9,19),math.randomInt(20,99)],
                k: [math.pickRandom([4,6,8,10,12,20]),math.randomInt(2,7),math.randomInt(4,13),math.randomInt(3,8),math.randomInt(4,9)],
                cards: this.pickCards(math.randomInt(3,7)),
                marbles: {
                  red: math.randomInt(2,20),
                  blue: math.randomInt(2,20),
                  green: math.randomInt(2,20),
                },
                dice: math.randomInt(2,5),
                elect: this.setElectionOdds(),
                birthday: math.randomInt(20,30),
              }
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
      if(Object.keys(data.stats.textbookResponses.ch3).length == 0){
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod1: {
                    p1: null,
                    p2: null,
                    p3: null,
                    p4: null,
                    p5: null,
                },
                mod2: {
                    p1: null,
                    p2: null,
                    p3: null,
                },
                mod3: {
                    p1: null,
                    p2: null,
                    p3: null,
                    p4: null,
                },
              }
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      // ADD CHAPTER GRADEBOOK IF MISSING
      if(!data.stats.gradeBook.ch3.mod1){
        await setDoc(docRef, {
          stats: {
            gradeBook: {
              ch3: {
                mod1: [0,0,0,0,0],
                mod2: [0,0,0],
                mod3: [0,0,0,0],
              }
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      this.userData = data;
      this.mod1Score = math.sum(this.userData.stats.gradeBook.ch3.mod1);
      this.mod2Score = math.sum(this.userData.stats.gradeBook.ch3.mod2);
      this.mod3Score = math.sum(this.userData.stats.gradeBook.ch3.mod3);
      this.assnScore = this.userData.stats.gradeBook.ch3.assignment ? this.userData.stats.gradeBook.ch3.assignment : 0;
      this.lssnScore = this.userData.stats.gradeBook.ch3.lesson ? this.userData.stats.gradeBook.ch3.lesson : 0;
    },
    async setPopovers() {
      await new Promise((resolve) => {
          const intervalId = setInterval(() => {
              if (this.userData !== null) {
                  clearInterval(intervalId);
                  resolve(this.userData);
              }
          }, 100); // Check every 100 milliseconds
      });
      const manyTrials = new Popover(this.$refs.manyTrials, {
        container: 'body',
        title: `Many Trials`,
        content: `When we use Σx, it means we need to <i>add</i> all of the values of x together. When we use Πx, it means we need to 
        <i>multiply</i> all of the values of x by each other. For example, if x = {2, 3, 4}, <br> then Σx = 2 + 3 + 4 = 9, <br> but Πx = 2 * 3 * 4 = 24.
        `,
        trigger: 'hover focus',
        placement: 'right',
        html: true,
      });
      const popoverM1Q1 = new Popover(this.$refs.m1q1, {
        container: 'body',
        title: `Counting Rule #1`,
        content: `Use the repeated trials rule to solve this problem.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM1Q2 = new Popover(this.$refs.m1q2, {
        container: 'body',
        title: `Counting Rule #2`,
        content: `Use the many trials rule to solve this problem.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM1Q3 = new Popover(this.$refs.m1q3, {
        container: 'body',
        title: `Counting Rule #3`,
        content: `Use a factorial to solve this problem.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM1Q4 = new Popover(this.$refs.m1q4, {
        container: 'body',
        title: `Counting Rule #4`,
        content: `Order matters in this question, so we need a permutation.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM1Q5 = new Popover(this.$refs.m1q5, {
        container: 'body',
        title: `Counting Rule #5`,
        content: `Did you know that the order of the numbers on your lottery ticket doesn't matter? Use a combination to solve this problem.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM2Q1 = new Popover(this.$refs.m2q1, {
        container: 'body',
        title: `Simple Probability`,
        content: `You may enter your answer in decimal or percentage form, but it must be accurate to four decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM2Q2 = new Popover(this.$refs.m2q2, {
        container: 'body',
        title: `Simple Probability`,
        content: `You may enter your answer in decimal or percentage form, but it must be accurate to four decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM2Q3 = new Popover(this.$refs.m2q3, {
        container: 'body',
        title: `Simple Probability`,
        content: `You may enter your answer in decimal or percentage form, but it must be accurate to four decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM3Q1 = new Popover(this.$refs.m3q1, {
        container: 'body',
        title: `Rules of Probability`,
        content: `You may enter your answer in decimal or percentage form, but it must be accurate to four decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM3Q2 = new Popover(this.$refs.m3q2, {
        container: 'body',
        title: `Rules of Probability`,
        content: `You may enter your answer in decimal or percentage form, but it must be accurate to four decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM3Q3 = new Popover(this.$refs.m3q3, {
        container: 'body',
        title: `Rules of Probability`,
        content: `You may enter your answer in decimal or percentage form, but it must be accurate to four decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM3Q4 = new Popover(this.$refs.m3q4, {
        container: 'body',
        title: `Rules of Probability`,
        content: `This is famously called 'The Birthday Problem', and you can look it up for help. You may enter your answer in decimal or percentage form, but it must be accurate to four decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      manyTrials; popoverM1Q1; popoverM1Q2; popoverM1Q3; popoverM1Q4; popoverM1Q5; popoverM2Q1; popoverM2Q2; popoverM2Q3; popoverM3Q1; popoverM3Q2; popoverM3Q3; popoverM3Q4;
    },
    async resetAll() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      await setDoc(docRef, {
        stats: {
          problemSets: {
            ch3: {
              n: [math.randomInt(3,7),math.randomInt(2,7),math.randomInt(4,13),math.randomInt(9,19),math.randomInt(20,99)],
              k: [math.pickRandom([4,6,8,10,12,20]),math.randomInt(2,7),math.randomInt(4,13),math.randomInt(3,8),math.randomInt(4,9)],
              cards: this.pickCards(math.randomInt(3,7)),
              marbles: {
                red: math.randomInt(2,20),
                blue: math.randomInt(2,20),
                green: math.randomInt(2,20),
              },
              dice: math.randomInt(2,5),
              elect: this.setElectionOdds(),
              birthday: math.randomInt(20,30),
            }
          }
        }
      }, {merge: true});
      await setDoc(docRef, {
        stats: {
            textbookResponses: {
              ch3: {
                mod1: {
                    p1: null,
                    p2: null,
                    p3: null,
                    p4: null,
                    p5: null,
                },
                mod2: {
                    p1: null,
                    p2: null,
                    p3: null,
                },
                mod3: {
                    p1: null,
                    p2: null,
                    p3: null,
                    p4: null,
                },
              }
            }
        }
      }, {merge: true});
      const docSnap = await getDoc(docRef);
      this.userData = docSnap.data();
    },
    pickCards(count) {
      const fullDeck = [
        '🂡', '🂢', '🂣', '🂤', '🂥', '🂦', '🂧', '🂨', '🂩', '🂪', '🂫', '🂭', '🂮', // Spades
        '🂱', '🂲', '🂳', '🂴', '🂵', '🂶', '🂷', '🂸', '🂹', '🂺', '🂻', '🂽', '🂾', // Hearts
        '🃁', '🃂', '🃃', '🃄', '🃅', '🃆', '🃇', '🃈', '🃉', '🃊', '🃋', '🃍', '🃎', // Diamonds
        '🃑', '🃒', '🃓', '🃔', '🃕', '🃖', '🃗', '🃘', '🃙', '🃚', '🃛', '🃝', '🃞'  // Clubs
      ];
      let output = [];
      for(let i = 0; i < count; i++){
        output.push(fullDeck[math.randomInt(0, 51)]);
      }
      return output;
    },
    getCardColor(card) {
      const blackCards = '🂡🂢🂣🂤🂥🂦🂧🂨🂩🂪🂫🂭🂮🃑🃒🃓🃔🃕🃖🃗🃘🃙🃚🃛🃝🃞';
      return blackCards.includes(card) ? 'black-card' : 'red-card';
    },
    setElectionOdds() {
      let tonyOdds = math.randomInt(5,20);
      let sandraOdds = math.round((100 - tonyOdds)*(math.randomInt(30,45)/100),0);
      let robOdds = 100 - tonyOdds - sandraOdds;
      let parvatiOdds = math.randomInt(5,20);
      let yulOdds = math.round((100 - parvatiOdds)*(math.randomInt(30,45)/100),0);
      let kimOdds = 100 - parvatiOdds - yulOdds;
      return {
        tony: tonyOdds,
        sandra: sandraOdds,
        rob: robOdds,
        parvati: parvatiOdds,
        kim: kimOdds,
        yul: yulOdds,
        senior: math.pickRandom(['Rob','Sandra','Tony']),
        junior: math.pickRandom(['Kim','Parvati','Yul']),
      }
    },
    async computeM1Q1() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = math.pow(data.k[0], data.n[0]);
        const studValue = document.getElementById('m1q1').value;
        const result = compare(trueValue, studValue, 0);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The total number of possible sequences of rolls is ${trueValue}. The total number of possible sequences of rolls is ${renderEq(data.k[0]+'^'+data.n[0]+'='+trueValue)}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `Here we have ${data.n[0]} trials, each with ${data.k[0]} possible outcomes. This is a job for Counting Rule #1.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod1;
        if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod1: {
                  p1: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeM1Q2() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = math.pow(26, data.k[1]) * math.pow(10, data.n[1]);
        const studValue = document.getElementById('m1q2').value;
        const result = compare(trueValue, studValue, 0);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The total number of possible confirmation numbers is ${renderEq('26^'+data.k[1]+'*10^'+data.n[1]+'='+trueValue)}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `Here we have ${data.k[1]} letters and ${data.n[1]} numbers. This is a job for Counting Rule #2.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod1;
        if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod1: {
                  p2: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeM1Q3() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = math.factorial(data.n[2]);
        const studValue = document.getElementById('m1q3').value;
        const result = compare(trueValue, studValue, 0);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The total number of possible arrangements is ${renderEq(data.n[2]+'!='+trueValue)}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `Here we have ${data.n[2]} objects. This is a job for Counting Rule #3.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod1;
        if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod1: {
                  p3: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeM1Q4() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = math.factorial(data.n[3]) / math.factorial(data.n[3] - data.k[3]);
        const studValue = document.getElementById('m1q4').value;
        const result = compare(trueValue, studValue, 0);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The total number of possible seating arrangements is ${renderEq('\\frac{'+data.n[3]+'!}{'+data.n[3]+'-'+data.k[3]+'!}='+trueValue)}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `Here we have ${data.n[3]} empty tables and ${data.k[3]} parties of people. Since people might care which table they are at, the order we assign them to one matters. This is a job for Counting Rule #4.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod1;
        if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod1: {
                  p4: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeM1Q5() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = math.combinations(data.n[4], data.k[4]);
        const studValue = document.getElementById('m1q5').value;
        const result = compare(trueValue, studValue, 0);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The total number of possible final arrangements is ${renderEq('\\frac{'+data.n[4]+'!}{'+data.k[4]+'!('+data.n[4]+'-'+data.k[4]+')!} = '+trueValue)}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `Here we have ${data.n[4]} numbers and we are selecting ${data.k[4]} of them. Since the order of the numbers doesn't matter, this is a job for Counting Rule #5.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod1;
        if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod1: {
                  p5: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q1() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const diamonds = "🃂🃃🃄🃅🃆🃇🃈🃉🃊🃋🃍🃎";
        let diamondCount = 0;
        for(let i = 0; i < data.cards.length; i++){
          if(diamonds.includes(data.cards[i])){
            diamondCount++;
          }
        }
        const trueValue = (13 - diamondCount) / (52 - data.cards.length);
        const studValue = document.getElementById('m2q1').value;
        const result = compare(trueValue, studValue, 0.0001);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The probability of drawing a diamond is the number of diamond cards remaining in the deck divided by the total number of cards in the deck. In this case, there were ${diamondCount} diamonds in your hand, leaving ${13-diamondCount} diamonds in the deck. With ${52-data.cards.length} remaining cards to draw from, the probability of drawing a diamond was ${renderEq('\\frac{'+(13-diamondCount)+'}{'+(52-data.cards.length)+'}\\approx'+math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The probability of drawing a diamond is the number of diamond cards remaining in the deck divided by the total number of cards in the deck.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod2;
        if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod2: {
                  p1: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q2() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = 1 - (data.marbles.red / (data.marbles.red + data.marbles.blue + data.marbles.green));
        const studValue = document.getElementById('m2q2').value;
        const result = compare(trueValue, studValue, 0.0001);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The probability of drawing a non-red marble is the number of non-red marbles divided by the total number of marbles. In this case, there were ${data.marbles.red} red marbles, leaving ${data.marbles.blue + data.marbles.green} non-red marbles. With ${data.marbles.red + data.marbles.blue + data.marbles.green} marbles remaining to draw from, the probability of drawing a non-red marble was ${renderEq('\\frac{'+data.marbles.blue+'+'+data.marbles.green+'}{'+(data.marbles.red + data.marbles.blue + data.marbles.green)+'}\\approx'+math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The probability of drawing a non-red marble is the number of non-red marbles divided by the total number of marbles.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod2;
        if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod2: {
                  p2: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q3()  {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = (6-data.dice) / 6;
        const studValue = document.getElementById('m2q3').value;
        const result = compare(trueValue, studValue, 0.0001);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The probability of rolling a value greater than ${data.dice} is the number of values greater than ${data.dice} divided by the total number of values. In this case, there were ${6-data.dice} values greater than ${data.dice}. With 6 values possible in a single roll, the probability of rolling a value greater than ${data.dice} was ${renderEq('\\frac{'+(6-data.dice)+'}{6}\\approx'+math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The probability of rolling a value greater than ${data.dice} is the number of values greater than ${data.dice} divided by the total number of values.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod2;
        if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod2: {
                  p3: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM3Q1() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const seniorOdds = data.elect[data.elect.senior.toLowerCase()] / 100;
        const juniorOdds = data.elect[data.elect.junior.toLowerCase()] / 100;
        const trueValue = seniorOdds * juniorOdds;
        const studValue = document.getElementById('m3q1').value;
        const result = compare(trueValue, studValue, 0.0001);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The probability of both ${data.elect.senior} and ${data.elect.junior} winning is the probability of ${data.elect.senior} winning times the probability of ${data.elect.junior} winning. This is a joint probability of independent events. In this case, the probability of ${data.elect.senior} winning is ${seniorOdds} and the probability of ${data.elect.junior} winning is ${juniorOdds}. The probability of both winning is ${renderEq(seniorOdds+'*'+juniorOdds+'='+math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The probability of both ${data.elect.senior} and ${data.elect.junior} winning is the probability of ${data.elect.senior} winning times the probability of ${data.elect.junior} winning. This is a joint probability of independent events.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod3;
        if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 3}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod3: {
                  p1: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod3: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod3Score = math.sum(gradeMatrix);
      }
    },
    async computeM3Q2() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const seniorOdds = data.elect[data.elect.senior.toLowerCase()] / 100;
        const juniorOdds = data.elect[data.elect.junior.toLowerCase()] / 100;
        const trueValue = seniorOdds + juniorOdds - (seniorOdds * juniorOdds);
        const studValue = document.getElementById('m3q2').value;
        const result = compare(trueValue, studValue, 0.0001);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The probability of either ${data.elect.senior} or ${data.elect.junior} winning is the probability of ${data.elect.senior} winning plus the probability of ${data.elect.junior} winning minus the probability of both winning. Here we are applying the addition rule. In this case, the probability of ${data.elect.senior} winning is ${seniorOdds} and the probability of ${data.elect.junior} winning is ${juniorOdds}. The probability of either winning is ${renderEq(seniorOdds+'+'+juniorOdds+'-'+seniorOdds+'*'+juniorOdds+'='+math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The probability of either ${data.elect.senior} or ${data.elect.junior} winning is the probability of ${data.elect.senior} winning plus the probability of ${data.elect.junior} winning minus the probability of both winning. Here we are applying the addition rule.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod3;
        if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 3}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod3: {
                  p2: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod3: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod3Score = math.sum(gradeMatrix);
      }
    },
    async computeM3Q3() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const red = data.marbles.red;
        const blue = data.marbles.blue;
        const green = data.marbles.green;
        const total = red + blue + green;
        const trueValue = (red/total)*(blue/(total-1)) + (blue/total)*(red/(total-1));
        const studValue = document.getElementById('m3q3').value;
        const result = compare(trueValue, studValue, 0.0001);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `There are two ways to draw one red marble and one blue marble: draw red then blue, or draw blue then red. These events are independent of each other, so if we add their probabilities together we will get the answer. The probability of drawing red then blue is ${renderEq('\\frac{'+red+'}{'+total+'}*\\frac{'+blue+'}{'+(total-1)+'}')} and the probability of drawing blue then red is ${renderEq('\\frac{'+blue+'}{'+total+'}*\\frac{'+red+'}{'+(total-1)+'}')}. So, the probability of drawing one red marble and one blue marble is ${renderEq('\\frac{'+red+'}{'+total+'}*\\frac{'+blue+'}{'+(total-1)+'}+\\frac{'+blue+'}{'+total+'}*\\frac{'+red+'}{'+(total-1)+'}\\approx'+math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `There are two ways to draw one red marble and one blue marble: draw red then blue, or draw blue then red. These events are independent of each other, so if we add their probabilities together we will get the answer.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod3;
        if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 3}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod3: {
                  p3: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod3: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod3Score = math.sum(gradeMatrix);
      }
    },
    async computeM3Q4() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = 1 - (math.permutations(365, data.birthday) / math.pow(365, data.birthday));
        const studValue = document.getElementById('m3q4').value;
        const result = compare(trueValue, studValue, 0.0001);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `There are ${math.pow(365, data.birthday)} ways for these ${data.birthday} people to have birthdays. The number of ways for them to have different birthdays is ${math.permutations(365, data.birthday)}. So, the probability that at least two people share a birthday is ${renderEq('1-\\frac{'+math.permutations(365, data.birthday)+'}{'+math.pow(365, data.birthday)+'}\\approx'+math.round(trueValue,4)+'\\approx'+math.round(trueValue*100,2)+'\\%')}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `Consider instead this question: What is the probability that no two people in a group of ${data.birthday} people have the same birthday? Subtract that from 1 to get the answer here.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook.ch3.mod3;
        if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 3}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch3: {
                mod3: {
                  p4: studValue,
                },
              }
            },
            gradeBook: {
              ch3: {
                mod3: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod3Score = math.sum(gradeMatrix);
      }
    },
  }, 
  computed: {
    checkM1Q1() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = math.pow(data.k[0], data.n[0]);
        const studValue = this.userData.stats.textbookResponses.ch3.mod1.p1;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM1Q2() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = math.pow(26, data.k[1]) * math.pow(10, data.n[1]);
        const studValue = this.userData.stats.textbookResponses.ch3.mod1.p2;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM1Q3() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = math.factorial(data.n[2]);
        const studValue = this.userData.stats.textbookResponses.ch3.mod1.p3;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM1Q4() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = math.factorial(data.n[3]) / math.factorial(data.n[3] - data.k[3]);
        const studValue = this.userData.stats.textbookResponses.ch3.mod1.p4;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM1Q5() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = math.combinations(data.n[4], data.k[4]);
        const studValue = this.userData.stats.textbookResponses.ch3.mod1.p5;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q1() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const diamonds = "🃂🃃🃄🃅🃆🃇🃈🃉🃊🃋🃍🃎";
        let diamondCount = 0;
        for(let i = 0; i < data.cards.length; i++){
          if(diamonds.includes(data.cards[i])){
            diamondCount++;
          }
        }
        const trueValue = (13 - diamondCount) / (52 - data.cards.length);
        const studValue = this.userData.stats.textbookResponses.ch3.mod2.p1;
        const result = compare(trueValue, studValue, 0.0001);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q2() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = 1 - (data.marbles.red / (data.marbles.red + data.marbles.blue + data.marbles.green));
        const studValue = this.userData.stats.textbookResponses.ch3.mod2.p2;
        const result = compare(trueValue, studValue, 0.0001);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q3() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = (6-data.dice) / 6;
        const studValue = this.userData.stats.textbookResponses.ch3.mod2.p3;
        const result = compare(trueValue, studValue, 0.0001);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM3Q1() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const seniorOdds = data.elect[data.elect.senior.toLowerCase()] / 100;
        const juniorOdds = data.elect[data.elect.junior.toLowerCase()] / 100;
        const trueValue = seniorOdds * juniorOdds;
        const studValue = this.userData.stats.textbookResponses.ch3.mod3.p1;
        const result = compare(trueValue, studValue, 0.0001);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM3Q2() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const seniorOdds = data.elect[data.elect.senior.toLowerCase()] / 100;
        const juniorOdds = data.elect[data.elect.junior.toLowerCase()] / 100;
        const trueValue = seniorOdds + juniorOdds - (seniorOdds * juniorOdds);
        const studValue = this.userData.stats.textbookResponses.ch3.mod3.p2;
        const result = compare(trueValue, studValue, 0.0001);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM3Q3() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const red = data.marbles.red;
        const blue = data.marbles.blue;
        const green = data.marbles.green;
        const total = red + blue + green;
        const trueValue = (red/total)*(blue/(total-1)) + (blue/total)*(red/(total-1));
        const studValue = this.userData.stats.textbookResponses.ch3.mod3.p3;
        const result = compare(trueValue, studValue, 0.0001);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM3Q4() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch3;
        const trueValue = 1 - (math.permutations(365, data.birthday) / math.pow(365, data.birthday));
        const studValue = this.userData.stats.textbookResponses.ch3.mod3.p4;
        const result = compare(trueValue, studValue, 0.0001);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
  },
  mounted() {
    
  },
}
</script>

<style>
.black-card {
  color: black;
}

.red-card {
  color: red;
}

.election-table {
  width: 100%;
  border: 1px solid black;
  margin: 12px 0;
}
.election-table .et-head {
  background-color: var(--off_white);
  font-weight: bold;
}
.election-table .etc1 {
  width: 24%;
  padding: 4px;
  border: 1px dashed black;
  
}
.election-table .etc2 {
  width: 24%;
  padding: 4px;
  border: 1px dashed black;
  text-align: center;
}
.election-table .etc3 {
  width: 4%;
}
.election-table .etc4 {
  width: 24%;
  padding: 4px;
  border: 1px dashed black;
}
.election-table .etc5 {
  width: 24%;
  padding: 4px;
  border: 1px dashed black;
  text-align: center;
}
</style>