<template>
    <div class="container col-md-6 offset-md-3">
    
        <!-- #region CHAPTER AND TITLE -->
        <div class="container">
        <div class="book-title-div">
            <span class="fw600 em1-50">Chapter Two</span><br>
            <span class="fw800 em3 fc-stats">Correlation is Not Causation</span>
        </div>
        </div>
        <!-- #endregion -->
        
        <!-- SUB NAVIGATION BAR -->
        <nav>
        <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
            <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
            <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
            <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
        </div>
        </nav>
        
        <!-- SUB NAV BAR CONTENT -->
        <div class="tab-content" id="nav-tabContent">
        <!-- TEXTBOOK -->
        <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">

          <VideoBox class="mt-4" videoTitle="Correlation is Not Causation" videoUrl="https://www.youtube.com/embed/JsK_yH7tipY" startAt="0"/>

            <!-- #region MODULE 1 -->
            <div v-if="userData" class="intructions-module rounded container">
                <h3 class="fc-stats">If Statements</h3>
                <p>
                    To get an understanding of <strong>causation</strong>, we will learn about <strong>if-statements</strong>. Since we are learning 
                    spreadsheets, we will use the IF() function in Google Sheets as our syntax:
                </p>
                <div class="code-box">
                    <img src="https://www.gstatic.com/images/branding/product/1x/sheets_64dp.png" width="50px">
                    <span class="fw600">=IF(logical_expression, value_if_true, value_if_false)</span>
                </div>
                <p>
                    A function is a predefined formula that performs calculations using specific values in a particular order. Functions take 
                    arguments, which are the values that a function uses to perform operations. The <span class="code-style">IF()</span> function takes three arguments:
                    <ul>
                        <li><span class="code-style"><strong>logical_expression</strong></span> - This is the condition that you want to test. It can be a number, expression, or text.</li>
                        <li><span class="code-style"><strong>value_if_true</strong></span> - This is the value that the function returns if the logical_expression is true.</li>
                        <li><span class="code-style"><strong>value_if_false</strong></span> - This is the value that the function returns if the logical_expression is false.</li>
                    </ul>
                </p>
                <h5 class="fc-stats">Logican Expressions</h5>
                <p>
                  A logical expression is a statement that compares two values and returns a 
                  <PopOver direction="top" title="Boolean Values" content='The term comes from 19th Century mathematician George Boole. A "Boolean Value" is one that is <i>either</i> true or false.' buttonText="boolean value"/> 
                  (true or false). For example, we could have the following logical expressions:
                </p>
                <div class="code-box">
                  <p ref="le1"><span style="color:blue;">(1 = 2)</span> ⇨ <span style="color:blue; font-style: italic;">false</span></p>
                  <p ref="le2"><span style="color:blue;">(X &lt;&gt; 4)</span> ⇨ When X = 5 ⇨ <span style="color:blue; font-style: italic;">true</span></p>
                  <p ref="le3"><span style="color:blue;">(SUM(X) >= 100)</span> ⇨ When X = {8, 50, 33, 7} ⇨ <span style="color:blue; font-style: italic;">false</span></p>
                </div> 
                <h5 class="fc-stats">If This Then That Otherwise The Other Thing</h5>
                <p>An if-statement evaluates a logical expression and then returns one value if that expression is true, and another if it is false.</p>
                <div class="d-flex align-items-center justify-content-center"><img :src="ifStatementImg" width="50%"></div>
                <p>Here are some example of if-statements: </p>
                <div class="code-box">
                  <p ref="if1"><span class="code-style">=IF(1 = 2, "This is true", "This is false")</span> ⇨ <span class="code-style">"This is false"</span></p>
                  <p ref="if2"><span class="code-style">=IF(X &lt;&gt; 4, Y + 1, Y - 1)</span> ⇨ When X = 5 & Y = 0 ⇨ <span class="code-style">1</span></p>
                  <p ref="if3"><span class="code-style">=IF(SUM(X) >= 100, 0, Y)</span> ⇨ When X = {8, 50, 33, 7} Y = "Too Small!" ⇨ <span class="code-style">"Too Small!"</span></p>
                </div>  

                <div class="mt-3" v-if="userData">
                  <h4 class="fc-prove">Prove Your Understanding</h4>
                  <p>Below is a spreadsheet filled with values.</p>
                  <div class="table-spreadsheet">
                    <table>
                      <tr>
                        <th>&nbsp;</th>
                        <th>A</th>
                        <th>B</th>
                        <th>C</th>
                      </tr>
                      <tr>
                        <td><b>1</b></td>
                        <td>{{ userData.stats.problemSets.ch2.x[0] }}</td>
                        <td>{{ userData.stats.problemSets.ch2.x[1] }}</td>
                        <td>{{ userData.stats.problemSets.ch2.x[2] }}</td>
                      </tr>
                      <tr>
                        <td><b>2</b></td>
                        <td>{{ userData.stats.problemSets.ch2.x[3] }}</td>
                        <td>{{ userData.stats.problemSets.ch2.x[4] }}</td>
                        <td>{{ userData.stats.problemSets.ch2.y[0] }}</td>
                      </tr>
                      <tr>
                        <td><b>3</b></td>
                        <td>{{ userData.stats.problemSets.ch2.y[1] }}</td>
                        <td>{{ userData.stats.problemSets.ch2.y[2] }}</td>
                        <td>{{ userData.stats.problemSets.ch2.y[3] }}</td>
                      </tr>
                    </table>
                  </div>
                  <p>Determine the result of the if-statements below.</p>
                  <div class="input-group mb-3">
                    <button ref="popoverIF1" class="input-group-text code-style" style="width: 425px;">=IF(A1>C2, "true", "false")</button>
                    <input type="text" class="form-control" placeholder="Determine the result of the IF() function" id="if1-ans" :value="userData.stats.textbookResponses.ch2.mod1.if1" :style="checkIF1">
                    <button class="btn btn-outline-secondary" type="button" @click="computeIF1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                  </div>
                  <div class="input-group mb-3">
                    <button ref="popoverIF2" class="input-group-text code-style" style="width: 425px;">=IF(B2=C3, B2, 10)</button>
                    <input type="text" class="form-control" placeholder="Determine the result of the IF() function" id="if2-ans" :value="userData.stats.textbookResponses.ch2.mod1.if2" :style="checkIF2">
                    <button class="btn btn-outline-secondary" type="button" @click="computeIF2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                  </div>
                  <div class="input-group mb-3">
                    <button ref="popoverIF3" class="input-group-text code-style" style="width: 425px;">=IF(A3&lt;=4, B3, C3)</button>
                    <input type="text" class="form-control" placeholder="Determine the result of the IF() function" id="if3-ans" :value="userData.stats.textbookResponses.ch2.mod1.if3" :style="checkIF3">
                    <button class="btn btn-outline-secondary" type="button" @click="computeIF3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                  </div>
                  <div class="input-group mb-3">
                    <button ref="popoverIF4" class="input-group-text code-style" style="width: 425px;">=IF(A3+C1&lt;7, A1 + A2, B1 + B2)</button>
                    <input type="text" class="form-control" placeholder="Determine the result of the IF() function" id="if4-ans" :value="userData.stats.textbookResponses.ch2.mod1.if4" :style="checkIF4">
                    <button class="btn btn-outline-secondary" type="button" @click="computeIF4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                  </div>
                  <div class="input-group mb-3">
                    <button ref="popoverIF5" class="input-group-text code-style" style="width: 425px;">=IF(C2&lt;&gt;B1, MAX(A1,A2,A3), MIN(B1,B2,B3))</button>
                    <input type="text" class="form-control" placeholder="Determine the result of the IF() function" id="if5-ans" :value="userData.stats.textbookResponses.ch2.mod1.if5" :style="checkIF5">
                    <button class="btn btn-outline-secondary" type="button" @click="computeIF5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                  </div>
                <CalculatorLinks :score="mod1Score" :scoreTotal="10"/>
              </div> 
            </div>
            <!-- #endregion -->

            <!-- #region MODULE 2 -->
            <div v-if="userData" class="intructions-module rounded container">
              <h3 class="fc-stats">Post hoc ergo propter hoc</h3>
                <p>
                  In Latin, 'Post hoc ergo propter hoc' means 'after this, therefore because of this'. It is the setup of a common fallacy whereby 
                  one assumes that because one event follows another, the first event caused the second. This is a common mistake in statistics, 
                  where correlation is often mistaken for causation. 
                </p>

              <!-- Video from Crash Course Statistics -->
              <VideoBox videoTitle="Correlation Doesn't Equal Causation" videoUrl="https://www.youtube.com/embed/GtV-VYdNt_g" startAt="3"/>
              
              <h3 class="fc-stats">Calculating the Correlation Coefficient</h3>
              <p>
                Suppose I have two sets of data, <MathEquation :equation="'x'" /> and <MathEquation :equation="'y'" />, as shown in the table below. 
              </p>
              <div class="table-spreadsheet">
                <table>
                  <tr>
                    <th><MathEquation :equation="'x'" /></th>
                    <th><MathEquation :equation="'y'" /></th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>9</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>6</td>
                  </tr>
                </table>
              </div>
              <div class="accordion mt-3" id="distributionAccordion">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      <b>Step 1: Calculating the Sample Covariance</b>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#distributionAccordion">
                    <div class="accordion-body">
                      <p>
                        The sample covariance is a measure of how two variables change together. It is calculated by taking the sum of the product of the differences 
                        between each value and the mean of each set of data and then dividing by the degrees of freedom. The formula for covariance is:
                      </p>
                      <p style="margin-top:12px; margin-bottom:24px;" class="center">
                        <MathEquation :equation="'\\text{cov}(x, y) = \\frac{1}{n-1} \\sum_{i=1}^{n} (x_i - \\bar{x})(y_i - \\bar{y})'" />
                      </p>
                      <p>
                        First, we need to calculate the mean of each set of data. The mean of <MathEquation :equation="'x'" /> is 1 and the mean of <MathEquation :equation="'y'" /> is 4. Then, we can calculate the covariance by 
                        plugging in the values for each pair of data points, subtracting the mean, and multiplying the differences together. Then we sum all of these 
                        products and divide by n-1, where n is the number of data points. 
                      </p>
                      <p style="margin-top:12px; margin-bottom:24px;" class="center">
                        <MathEquation :equation="`\\text{cov}(x, y) = \\frac{(1-1)(2-4) + (1-1)(3-4) + (0-1)(0-4) + (2-1)(9-4) + (1-1)(6-4)}{5-1} = 4.5`" />
                      </p>
                      <p>
                        The covariance of <MathEquation :equation="'x'" /> and <MathEquation :equation="'y'" /> is <MathEquation :equation="'4.5'" />. This tells us that the two variables change together, but it doesn't tell us the strength or direction of the 
                        relationship. To do that, we need to calculate the correlation coefficient.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <b>Step 2: Calculating the Correlation Coefficient</b>
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#distributionAccordion">
                    <div class="accordion-body">
                      <p>
                        The correlation coefficient is a measure of the strength and direction of the relationship between two variables. It is calculated by dividing 
                        the covariance by the product of the standard deviations of each set of data. The formula for the correlation coefficient is:
                      </p>
                      <p style="margin-top:12px; margin-bottom:24px;" class="center">
                        <MathEquation :equation="'r = \\frac{\\text{cov}(x, y)}{s_x s_y}'" class="em1-50" />
                      </p>
                      <p>
                        The standard deviation of <MathEquation :equation="'x'" /> is <MathEquation :equation="'\\sqrt{0.5}'" /> and the standard deviation of 
                        <MathEquation :equation="'y'" /> is <MathEquation :equation="'\\sqrt{12.5}'" />. We can plug these values into the formula to calculate the 
                        correlation coefficient. 
                      </p>
                      <p style="margin-top:12px; margin-bottom:24px;" class="center">
                        <MathEquation :equation="`r = \\frac{4.5}{\\sqrt{0.5} \\times \\sqrt{12.5}} = 0.90`" class="em1-25" />
                      </p>
                      <p>
                        The correlation coefficient of <MathEquation :equation="'x'" /> and <MathEquation :equation="'y'" /> is <MathEquation :equation="'0.90'" />. 
                        This tells us that there is a strong positive relationship between the two variables. When <MathEquation :equation="'x'" /> increases, 
                        <MathEquation :equation="'y'" /> also tends to increase, and vice versa.
                      </p>
                    </div>
                  </div>
                </div>  
              </div>

              <div class="mt-3" v-if="userData">
                <h4 class="fc-prove">Prove Your Understanding</h4>
                <p>Use the data below to calculate the sample covariance and the correlation coefficient.</p>
                <div class="table-spreadsheet">
                  <table>
                    <tr>
                      <th><MathEquation :equation="'x'" /></th>
                      <th><MathEquation :equation="'y'" /></th>
                    </tr>
                    <tr>
                      <td>{{ userData.stats.problemSets.ch2.x[0] }}</td>
                      <td>{{ userData.stats.problemSets.ch2.y[0] }}</td>
                    </tr>
                    <tr>
                      <td>{{ userData.stats.problemSets.ch2.x[1] }}</td>
                      <td>{{ userData.stats.problemSets.ch2.y[1] }}</td>
                    </tr>
                    <tr>
                      <td>{{ userData.stats.problemSets.ch2.x[2] }}</td>
                      <td>{{ userData.stats.problemSets.ch2.y[2] }}</td>
                    </tr>
                    <tr>
                      <td>{{ userData.stats.problemSets.ch2.x[3] }}</td>
                      <td>{{ userData.stats.problemSets.ch2.y[3] }}</td>
                    </tr>
                    <tr>
                      <td>{{ userData.stats.problemSets.ch2.x[4] }}</td>
                      <td>{{ userData.stats.problemSets.ch2.y[4] }}</td>
                    </tr>
                  </table>
                </div>
                <div class="input-group mb-3">
                  <button ref="popoverCov" class="input-group-text" style="width: 120px;" id="cov-desc"><MathEquation :equation="'cov(x, y)'"/></button>
                  <input type="text" class="form-control" placeholder="Calculate the covariance of x" aria-label="cov" aria-describedby="basic-addon1" id="cov-ans" :value="userData.stats.textbookResponses.ch2.mod2.cov" :style="checkCov">
                  <button class="btn btn-outline-secondary" type="button" id="mean-check" @click="computeCov" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                </div>
                <div class="input-group mb-3">
                  <button ref="popoverCor" class="input-group-text" style="width: 120px;" id="cor-desc"><MathEquation :equation="'r(x, y)'"/></button>
                  <input type="text" class="form-control" placeholder="Calculate the correlation coefficient of x" aria-label="cor" aria-describedby="basic-addon1" id="cor-ans" :value="userData.stats.textbookResponses.ch2.mod2.cor" :style="checkcor">
                  <button class="btn btn-outline-secondary" type="button" id="cor-check" @click="computeCor" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                </div>
              </div>
              <CalculatorLinks :score="mod2Score" :scoreTotal="6"/>
            </div>
            <!-- #endregion -->

            <!-- #region MODULE 3 -->
            <div v-if="userData" class="intructions-module rounded container">
              <h3 class="fc-stats">Four Reasons X is Correlated with Y</h3>
              <p>
                There are four reasons why two variables might be correlated with each other. These are: 
              </p>
              <ol>
                <li><b>X causes Y</b> - A change in X does something to cause a change in Y.</li>
                <li><b>Y causes X</b> - The cause and effect are reversed.</li>
                <li><b>Z causes both X and Y</b> - Some other factor Z is causing changes in X and Y, which are otherwise unrelated.</li>
                <li><b>Random Chance</b> - The correlation is due to random chance.</li>
              </ol>
              <p>
                Here are examples of each of these scenarios:
              </p>
              <ol>
                <li><b>X causes Y</b> - The more you sleep, the better your grades. Therefore, sleeping causes good grades.</li>
                <li><b>Y causes X</b> - The better your grades, the more you can sleep. Therefore, good grades causes more sleep.</li>
                <li><b>Z causes both X and Y</b> - The less stress you have, the more you sleep and the better your grades are.</li>
                <li><b>Random Chance</b> - There is no real relationship between sleep and grades, the correlation we see is due to chance.</li>
              </ol>
              <h5 class="fc-stats">If we find that X and Y are correlated, which of these is the most likely explanation?</h5>
              <p>
                By far, the most common reason two variables would be correlated is due to <b>random chance</b>. It is incredibly easy to spin 
                a theory for why one variance might be causing another, but often there is no reason at all. 
              </p>
              <p>
                Visit the <a href="https://www.tylervigen.com/spurious-correlations" target="_blank">Spurious Correlations</a> website to see some interesting examples of correlations that are not causations.
              </p>
              <h3 class="fc-prove">Try it Yourself</h3>
              <p>Use these controls to change the scatterplot. Each button generates a new dataset of the size specified according to the causal relationship chosen. Notice how different the correlation can be in each circumstance!</p>
              <!-- Include Chart.js scatterplot of random data -->
              <div class="chart-container-div">
                <div class="chart-container">
                  <ScatterChart :scatterData="scatterData" />
                </div>
                <div class="chart-controls">
                  <div style="width: 180px">
                    <h5 class="fc-stats mb-4">Chart Controls</h5>
                    <div class="input-group flex-nowrap">
                        <input type="text" id="dataCount" v-model="dataCount" class="form-control text-center" placeholder="Username" aria-label="Username" aria-describedby="addon-wrapping">
                      <span class="input-group-text" id="addon-wrapping">observations</span>
                    </div>
                  </div>
                  <button @click="xCausesY">X causes Y</button>
                  <button @click="zCausesBoth">Z causes both X and Y</button>
                  <button @click="yCausesX">Y causes X</button>
                  <button @click="randomizeData" ref="randomHelp">Randomize Data</button>
                </div>
              </div>
              <div>
                The correlation coefficient of the data is <b>{{ correlation }}</b>. <br>
                Keep trying until you see strong correlations for each of the scenarios.
              </div>
              <div ref="gradePopover" class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">You have scored {{ mod3Score }} points out of 4 possible.</div>
            </div>
            <!-- #endregion -->

            <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/hyGrNXJ6t5s" startAt="0"/>
            
            <!-- #region DATA CHANGER -->
            <div v-if="userData" class="data-changer">
              <div class="em1-50 fw600">Questions Reset</div>
              <div>You can reset the questions with randomly generated numbers for more practice.</div>
              <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
            </div>
            <!-- #endregion -->

        </div>
        <!-- EXPLORATORY LESSON -->
        <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
            <div class="row mt-4">
            <div class="col-4">
                <img :src="ExploratoryLessonIcon" width="100%">
            </div>
            <div class="col-8">
                <h3 class="fc-stats">Exploratory Lesson 2</h3>
                <div style="text-align: justify;">
                  The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will learn how to use the =IF() and =IFS() functions in Google Sheets, 
                  and how to calculate the covariance and correlation coefficient for a dataset. We will also create some charts to visualize our correlation.
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
                You have scored {{ lssnScore }} out of 20 points on this lesson.
                </div>
            </div>
        
            </div>
            <div class="d-flex align-items-center justify-content-center mt-4">
            <router-link to="/stats/2/lesson" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
            </div>
        </div>
        <!-- SPREADSHEET ASSIGNMENT -->
        <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
            <div class="row mt-4">
            <div class="col-4">
                <img :src="SpreadsheetAssignmentIcon" width="100%">
            </div>
            <div class="col-8">
                <h3 class="fc-stats">Spreadsheet Assignment 2</h3>
                <div style="text-align: justify;">
                  The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will be given a link to download a .csv file. You will need 
                  to import the data from this file into Google Sheets. You will be asked to calculate several covariances and correlation coefficients for a dataset. 
                  You will also be asked to create a scatterplot of some data. Remember, a beautiful spreadsheet is it's own reward!
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
                You have scored {{ assnScore }} out of 30 points on this assignment.
                </div>
            </div>
        
            </div>
            <div class="d-flex align-items-center justify-content-center mt-4">
            <router-link to="/stats/2/assignment" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
            </div>
        </div>
        </div>
    
    
    
    </div>
    
    <!-- #region MODAL -->
    <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <img :src="probabilibot" width="20%">
            <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body col" v-html="modalMessage"></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->
    
</template>

<script>
import { doc, getDoc, setDoc } from "firebase/firestore"; 
import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
import MathEquation from '@/components/MathEquation.vue';
import CalculatorLinks from '@/components/CalculatorLinks.vue';
import PopOver from '@/components/PopOver.vue';
import { Popover } from 'bootstrap';
import VideoBox from '@/components/VideoBox.vue';

import { create, all } from 'mathjs'
const math = create(all);
import ScatterChart from "@/components/ScatterChart.vue";

import probabilibot from '@/assets/Probabilibot.png';
import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
import ifStatementImg from '@/assets/if_statement.png';

export default {
  name: "statsChapter2",
  components: {
    MathEquation,
    CalculatorLinks,
    PopOver,
    VideoBox,
    ScatterChart,
  },
  props: ['tab'],
  data() {
    return {
      userData: null,
      setUpStatsData,
      mod1Score: 0,
      mod2Score: 0,
      mod3Score: 0,
      mod4Score: 0,
      assnScore: 0,
      lssnScore: 0,
      modalTitle: 'Loading...',
      modalMessage: '',
      probabilibot,
      ExploratoryLessonIcon,
      SpreadsheetAssignmentIcon,
      ifStatementImg,
      renderEq,
      scatterData: [],
      correlation: 0,
      dataCount: 50,
      mod3GradePopoverContent: `
        <ul>
          <li><span class="fw600">X causes Y </span><br> Find r > 0.8 and r < -0.8</li>
          <li><span class="fw600">Y causes X</span><br> Find r > 0.8 and r < -0.8</li>
          <li><span class="fw600">Z causes both X and Y</span><br> Find r > 0.8 and r < -0.8</li>
          <li><span class="fw600">Random Chance</span><br> Find r > 0.8 and r < -0.8</li>
        </ul>
      `,
      corrType: 'random',
      keepTime: new Date(),
      minTime: 500,
      minCount: 5,
      maxCount: 100,
      randomCount: 0,
    }
  },
  created() {
    this.loadUserInformation();    
  },
  methods: {
    async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullProblemSet();
        await this.setPopovers();
        this.randomizeData();
    },
    async pullProblemSet() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      let docSnap = await getDoc(docRef);
      let data = docSnap.data();
      
      // ADD VALUES FOR THE CHAPTER PROBLEM SET
      if(Object.keys(data.stats.problemSets.ch2).length == 0){
        await setDoc(docRef, {
          stats: {
            problemSets: {
              ch2: {
                x: [math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9)],
                y: [math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9)],
              }
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
      if(Object.keys(data.stats.textbookResponses.ch2).length == 0){
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch2: {
                mod1: {
                  if1: null,
                  if2: null,
                  if3: null,
                  if4: null,
                  if5: null,
                },
                mod2: {
                  cov: null,
                  cor: null,
                },
              }
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      // ADD CHAPTER GRADEBOOK IF MISSING
      if(!data.stats.gradeBook.ch2.mod1){
        await setDoc(docRef, {
          stats: {
            gradeBook: {
              ch2: {
                mod1: [0,0,0,0,0],
                mod2: [0,0],
                mod3: [0,0,0,0,0,0,0,0],
              }
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      this.userData = data;
      this.mod1Score = math.sum(this.userData.stats.gradeBook.ch2.mod1);
      this.mod2Score = math.sum(this.userData.stats.gradeBook.ch2.mod2);
      this.mod3Score = math.sum(this.userData.stats.gradeBook.ch2.mod3);
      this.mod4Score = math.sum(this.userData.stats.gradeBook.ch2.mod4);
      this.assnScore = this.userData.stats.gradeBook.ch2.assignment ? this.userData.stats.gradeBook.ch2.assignment : 0;
      this.lssnScore = this.userData.stats.gradeBook.ch2.lesson ? this.userData.stats.gradeBook.ch2.lesson : 0;
    },
    async computeIF1() {
      if(!this.userData){
        return null;
      } else {
        const set = this.userData.stats.problemSets.ch2;
        const data = {
          a: [0, set.x[0], set.x[3], set.y[1]],
          b: [0, set.x[1], set.x[4], set.y[2]],
          c: [0, set.x[2], set.y[0], set.y[3]]
        }
        const trueValue = data.a[1] > data.c[2] ? "true" : "false";
        const studValue = document.getElementById('if1-ans').value;
        const result = compare(trueValue, studValue, "text");
        let feedback = `A1 = ${data.a[1]} and C2 = ${data.c[2]}. Therefore, the logical expression is ${data.a[1]} > ${data.c[2]}. `;
        if(result){
          feedback += `This statement is ${trueValue}.`;
        } else {
          feedback += `Is this true or false?`;
        }
        this.modalTitle = "Evaluating IF Statements";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">You got it!</span> ${feedback}
          ` 
          :
          `
          I'm sorry, that is not correct. ${feedback}
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch2.mod1;
        if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch2: {
                mod1: {
                  if1: studValue,
                },
              }
            },
            gradeBook: {
              ch2: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeIF2() {
      if(!this.userData){
        return null;
      } else {
        const set = this.userData.stats.problemSets.ch2;
        const data = {
          a: [0, set.x[0], set.x[3], set.y[1]],
          b: [0, set.x[1], set.x[4], set.y[2]],
          c: [0, set.x[2], set.y[0], set.y[3]]
        }
        const trueValue = data.b[2] == data.c[3] ? data.b[2] : 10;
        const studValue = document.getElementById('if2-ans').value ? document.getElementById('if2-ans').value : null;
        const result = compare(trueValue, studValue, 0);
        let feedback = `B2 = ${data.b[2]} and C3 = ${data.c[3]}. Therefore, the logical expression is ${data.b[2]} = ${data.c[3]}. `;

        if(data.b[2] == data.c[3] && result){
          feedback += `This statement is true, so the function returns the value of B2, which is ${data.b[2]}.`;
        } else if(data.b[2] != data.c[3] && result){
          feedback += `This statement is false, so the function returns the value of 10.`;
        } else {
          feedback += `Is this true or false?`;
        }

        this.modalTitle = "Evaluating IF Statements";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">You got it!</span> ${feedback}
          ` 
          :
          `
          I'm sorry, that is not correct. ${feedback}
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch2.mod1;
        if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch2: {
                mod1: {
                  if2: studValue,
                },
              }
            },
            gradeBook: {
              ch2: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeIF3() {
      if(!this.userData){
        return null;
      } else {
        const set = this.userData.stats.problemSets.ch2;
        const data = {
          a: [0, set.x[0], set.x[3], set.y[1]],
          b: [0, set.x[1], set.x[4], set.y[2]],
          c: [0, set.x[2], set.y[0], set.y[3]]
        }
        const trueValue = data.a[3] <= 4 ? data.b[3] : data.c[3];
        const studValue = document.getElementById('if3-ans').value;
        const result = compare(trueValue, studValue, 0);
        let feedback = `A3 = ${data.a[3]}. Therefore, the logical expression is ${data.a[3]} <= 4. `;
        if(data.a[3] <= 4 && result){
          feedback += `This statement is true, so the function returns the value of B3, which is ${data.b[3]}.`;
        } else if(data.a[3] > 4 && result){
          feedback += `This statement is false, so the function returns the value of C3, which is ${data.c[3]}.`;
        } else {
          feedback += `Is this true or false?`;
        }
        this.modalTitle = "Evaluating IF Statements";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">You got it!</span> ${feedback}
          ` 
          :
          `
          I'm sorry, that is not correct. ${feedback}
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch2.mod1;
        if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch2: {
                mod1: {
                  if3: studValue,
                },
              }
            },
            gradeBook: {
              ch2: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeIF4() {
      if(!this.userData){
        return null;
      } else {
        const set = this.userData.stats.problemSets.ch2;
        const data = {
          a: [0, set.x[0], set.x[3], set.y[1]],
          b: [0, set.x[1], set.x[4], set.y[2]],
          c: [0, set.x[2], set.y[0], set.y[3]]
        }
        const trueValue = data.a[3] + data.c[1] < 7 ? data.a[1] + data.a[2] : data.b[1] + data.b[2];
        const studValue = document.getElementById('if4-ans').value;
        const result = compare(trueValue, studValue, 0);
        let feedback = `A3 + C1 = ${data.a[3]} + ${data.c[1]}. Therefore, the logical expression is ${data.a[3]} + ${data.c[1]} < 7. `;
        if(data.a[3] + data.c[1] < 7 && result){
          feedback += `This statement is true, so the function returns the value of A1 + A2, which is ${data.a[1] + data.a[2]}.`;
        } else if(data.a[3] + data.c[1] >= 7 && result){
          feedback += `This statement is false, so the function returns the value of B1 + B2, which is ${ data.b[1] + data.b[2]}.`;
        } else {
          feedback += `Is this true or false?`;
        }
        this.modalTitle = "Evaluating IF Statements";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">You got it!</span> ${feedback}
          ` 
          :
          `
          I'm sorry, that is not correct. ${feedback}
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch2.mod1;
        if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch2: {
                mod1: {
                  if4: studValue,
                },
              }
            },
            gradeBook: {
              ch2: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeIF5() {
      if(!this.userData){
        return null;
      } else {
        const set = this.userData.stats.problemSets.ch2;
        const data = {
          a: [0, set.x[0], set.x[3], set.y[1]],
          b: [0, set.x[1], set.x[4], set.y[2]],
          c: [0, set.x[2], set.y[0], set.y[3]]
        }
        const trueValue = data.c[2] != data.b[1] ? math.max(data.a[1], data.a[2], data.a[3]) : math.min(data.b[1], data.b[2], data.b[3]);
        const studValue = document.getElementById('if5-ans').value;
        const result = compare(trueValue, studValue, 0);
        let feedback = `C2 = ${data.c[2]} and B1 = ${data.b[1]}. Therefore, the logical expression is ${data.c[2]} &lt;&gt; ${data.b[1]}. `;
        if(data.c[2] != data.b[1] && result){
          feedback += `This statement is true, so the function returns the maximum value of A1, A2, and A3, which is ${math.max(data.a[1], data.a[2], data.a[3])}.`;
        } else if(data.c[2] == data.a[1] && result){
          feedback += `This statement is false, so the function returns the minimum value of B1, B2, and B3, which is ${math.min(data.b[1], data.b[2], data.b[3])}.`;
        } else {
          feedback += `Is this true or false?`;
        }
        this.modalTitle = "Evaluating IF Statements";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">You got it!</span> ${feedback}
          ` 
          :
          `
          I'm sorry, that is not correct. ${feedback}
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch2.mod1;
        if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch2: {
                mod1: {
                  if5: studValue,
                },
              }
            },
            gradeBook: {
              ch2: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeCov() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch2;
        const xMean = math.mean(data.x);
        const yMean = math.mean(data.y);
        const cov = math.sum(data.x.map((x, i) => (x - xMean) * (data.y[i] - yMean))) / (data.x.length - 1);
        const studValue = document.getElementById('cov-ans').value;
        const result = compare(cov, studValue, 0.01);
        this.modalTitle = "Calculating Covariance";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">You got it!</span> The covariance of x and y is ${cov.toFixed(2)}.
          ` 
          :
          `
          I'm sorry, that is not correct. Check out <a href="https://www.youtube.com/watch?v=rb2nU7YZV_I" target="_blank">this video</a> for some more help on how to calculate covariance.
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch2.mod2;
        if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 3}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch2: {
                mod2: {
                  cov: studValue,
                },
              }
            },
            gradeBook: {
              ch2: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeCor() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch2;
        const xMean = math.mean(data.x);
        const yMean = math.mean(data.y);
        const cov = math.sum(data.x.map((x, i) => (x - xMean) * (data.y[i] - yMean))) / (data.x.length - 1);
        const xStd = math.std(data.x);
        const yStd = math.std(data.y);
        const cor = cov / (xStd * yStd);
        const studValue = document.getElementById('cor-ans').value;
        const result = compare(cor, studValue, 0.01);
        this.modalTitle = "Calculating Correlation Coefficient";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">You got it!</span> The correlation coefficient of x and y is ${cor.toFixed(2)}.
          ` 
          :
          `
          I'm sorry, that is not correct. Check out <a href="https://www.youtube.com/watch?v=11c9cs6WpJU" target="_blank">this video</a> for some more help on how to calculate the correlation coefficient.
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch2.mod2;
        if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 3}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch2: {
                mod2: {
                  cor: studValue,
                },
              }
            },
            gradeBook: {
              ch2: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async setPopovers() {
      await new Promise((resolve) => {
          const intervalId = setInterval(() => {
              if (this.userData !== null) {
                  clearInterval(intervalId);
                  resolve(this.userData);
              }
          }, 100); // Check every 100 milliseconds
      });
      const popoverLE1 = new Popover(this.$refs.le1, {
        container: 'body',
        title: `(1 = 2)`,
        content: `Use the = sign to compare two values. If the values are equal, the expression is true. If they are not equal, the expression is false. Since 1 is not equal to 2, this expression is false.`,
        trigger: 'hover focus',
        placement: 'bottom',
        html: true,
      });
      const popoverLE2 = new Popover(this.$refs.le2, {
        container: 'body',
        title: `(X &lt;&gt; 4)`,
        content: `Use the &lt;&gt; sign to compare two values. If the values are not equal, the expression is true. If they are equal, the expression is false. When X = 5, the expression is true because 5 is not equal to 4.`,
        trigger: 'hover focus',
        placement: 'bottom',
        html: true,
      }); 
      const popoverLE3 = new Popover(this.$refs.le3, {
        container: 'body',
        title: `(SUM(X) >= 100)`,
        content: `Use the >= sign to compare two values. If the first value is greater than or equal to the second value, the expression is true. If the first value is less than the second value, the expression is false. When X = {8, 50, 33, 7}, the sum of X is 98, which is less than 100. Therefore, the expression is false. <br><br> You can also use > (greater than), < (less than), and <= (less than or equal to) to compare values.`,
        trigger: 'hover focus',
        placement: 'bottom',
        html: true,
      });
      const popoverIF1 = new Popover(this.$refs.if1, {
        container: 'body',
        title: `=IF(1 = 2, "This is true", "This is false")`,
        content: `This if-statement evaluates the logical expression 1 = 2. Since 1 is not equal to 2, the logical expression is false. Therefore, the function returns the string "This is false". <br><br> A 'string' is a sequence of characters, like words or sentences.`,
        trigger: 'hover focus',
        placement: 'bottom',
        html: true,
      });
      const popoverIF2 = new Popover(this.$refs.if2, {
        container: 'body',
        title: `=IF(X &lt;&gt; 4, Y + 1, Y - 1)`,
        content: `This if-statement evaluates the logical expression X &lt;&gt; 4. When X = 5, the logical expression is true. Therefore, the function returns the value of Y + 1. If X were equal to 4, the logical expression would be false, and the function would return the value of Y - 1. Since Y = 0, the function returns 1.`,
        trigger: 'hover focus',
        placement: 'bottom',
        html: true,
      });
      const popoverIF3 = new Popover(this.$refs.if3, {
        container: 'body',
        title: `=IF(SUM(X) >= 100, 0, Y)`,
        content: `This if-statement evaluates the logical expression SUM(X) >= 100. When X = {8, 50, 33, 7}, the sum of X is 98, which is less than 100. Therefore, the logical expression is false. The function returns the value of Y, which is the string "Too Small!".`,
        trigger: 'hover focus',
        placement: 'bottom',
        html: true,
      });
      const popoverCov = new Popover(this.$refs.popoverCov, {
        container: 'body',
        title: `Covariance`,
        content: `Calculate the sample covariance of x and y. Do not round until your final answer, at which point you may round to two decimal places or more.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverCor = new Popover(this.$refs.popoverCor, {
        container: 'body',
        title: `Correlation Coefficient`,
        content: `Calculate the correlation coefficient of x and y. Do not round until your final answer, at which point you may round to two decimal places or more.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      this.mod3PopoverChecks(this.userData.stats.gradeBook.ch2.mod3);
      const gradePopover = new Popover(this.$refs.gradePopover, {
        container: 'body',
        title: `Module 3 Grade`,
        content: this.mod3GradePopoverContent,
        trigger: 'hover focus',
        placement: 'right',
        html: true,
      });
      popoverLE1; popoverLE2; popoverLE3; popoverIF1; popoverIF2; popoverIF3; popoverCov; popoverCor; gradePopover;
    },
    async resetAll() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      await setDoc(docRef, {
        stats: {
          problemSets: {
            ch2: {
                x: [math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9)],
                y: [math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9)],
            }
          }
        }
      }, {merge: true});
      await setDoc(docRef, {
        stats: {
          textbookResponses: {
            ch2: {
              mod1: {
                if1: null,
                if2: null,
                if3: null,
                if4: null,
                if5: null,
              },
              mod2: {
                cov: null,
                cor: null,
              },
            }
          }
        }
      }, {merge: true});
      const docSnap = await getDoc(docRef);
      this.userData = docSnap.data();
    },
    randomizeData() {
      this.randomCount++;
      if(this.randomCount === 50){this.randomHelpPopover();}
      this.corrType = 'random';
      const now = new Date();
      if(now - this.keepTime < this.minTime){
        return null;
      }
      this.keepTime = now;
      this.dataCount = Math.min(Math.max(this.dataCount, this.minCount), this.maxCount);
      let newData = [];
      for(let i = 0; i < this.dataCount; i++){
        newData.push({
          x: Math.random() * 10,
          y: Math.random() * 10,
        });
      }
      this.scatterData = newData;
      this.correlation = math.round(math.corr(newData.map(d => d.x), newData.map(d => d.y)), 2);
      this.gradeCorrelations();
    },
    xCausesY() {
      const now = new Date();
      if(now - this.keepTime < this.minTime){
        return null;
      }
      this.keepTime = now;
      this.corrType = 'xy';
      this.correlateData();
    },
    yCausesX() {
      const now = new Date();
      if(now - this.keepTime < this.minTime){
        return null;
      }
      this.keepTime = now;
      this.corrType = 'yx';
      this.correlateData();
    },
    zCausesBoth() {
      const now = new Date();
      if(now - this.keepTime < this.minTime){
        return null;
      }
      this.keepTime = now;
      this.corrType = 'z';
      this.correlateData();
    },
    correlateData() {
      this.dataCount = Math.min(Math.max(this.dataCount, this.minCount), this.maxCount);
      let newData = [];
      const factor = Math.random() * 9;
      const sign = Math.random() > 0.5 ? 1 : -1;
      for(let i = 0; i < this.dataCount; i++){
        const xval = Math.random() * 10;
        let yval = (Math.random()*15*factor - 5) + xval*(10-factor)*sign;
        newData.push({
          x: xval,
          y: yval,
        });
      }
      // scale all y values to be between 0 and 10
      const yValues = newData.map(d => d.y);
      const yMin = Math.min(...yValues);
      const yMax = Math.max(...yValues);
      newData = newData.map(d => {
        return {
          x: d.x,
          y: (d.y - yMin) / (yMax - yMin) * 10,
        }
      });
      this.scatterData = newData;
      this.correlation = math.round(math.corr(newData.map(d => d.x), newData.map(d => d.y)), 2);
      this.gradeCorrelations();
    },
    async gradeCorrelations() {
      if(!this.userData){
        return null;
      } else {
        let updateGrade = false;
        let gradeMatrix =  this.userData.stats.gradeBook.ch2.mod3;
        if(this.correlation >= 0.8){
          switch (this.corrType) {
            case 'xy':
              if(gradeMatrix[0] == 0){gradeMatrix[0] = 0.5; updateGrade = true;}
              break;
            case 'yx':
              if(gradeMatrix[1] == 0){gradeMatrix[1] = 0.5; updateGrade = true;}
              break;
            case 'z':
              if(gradeMatrix[2] == 0){gradeMatrix[2] = 0.5; updateGrade = true;}
              break;
            case 'random':
              if(gradeMatrix[3] == 0){gradeMatrix[3] = 0.5; updateGrade = true;}
              break;
            default:
              break;
          }
        } else if(this.correlation <= -0.8){
          switch (this.corrType) {
            case 'xy':
              if(gradeMatrix[4] == 0){gradeMatrix[4] = 0.5; updateGrade = true;}
              break;
            case 'yx':
              if(gradeMatrix[5] == 0){gradeMatrix[5] = 0.5; updateGrade = true;}
              break;
            case 'z':
              if(gradeMatrix[6] == 0){gradeMatrix[6] = 0.5; updateGrade = true;}
              break;
            case 'random':
              if(gradeMatrix[7] == 0){gradeMatrix[7] = 0.5; updateGrade = true;}
              break;
            default:
              break;
          }
        }
        if(updateGrade){
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                ch2: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod3Score = math.sum(gradeMatrix);
          this.mod3PopoverChecks(gradeMatrix);
        }
      }
    },
    mod3PopoverChecks(gradeMatrix) {
      let checkMarks = ['','','',''];
      if(gradeMatrix[0] > 0){checkMarks[0] += '➕';}
      if(gradeMatrix[4] > 0){checkMarks[0] += '➖';}
      if(gradeMatrix[1] > 0){checkMarks[1] += '➕';}
      if(gradeMatrix[5] > 0){checkMarks[1] += '➖';}
      if(gradeMatrix[2] > 0){checkMarks[2] += '➕';}
      if(gradeMatrix[6] > 0){checkMarks[2] += '➖';}
      if(gradeMatrix[3] > 0){checkMarks[3] += '➕';}
      if(gradeMatrix[7] > 0){checkMarks[3] += '➖';}
      this.mod3GradePopoverContent = `
            <ul>
              <li><span class="fw600">X causes Y ${checkMarks[0]}</span><br> Find r > 0.8 and r < -0.8</li>
              <li><span class="fw600">Y causes X ${checkMarks[1]}</span><br> Find r > 0.8 and r < -0.8</li>
              <li><span class="fw600">Z causes both X and Y ${checkMarks[2]}</span><br> Find r > 0.8 and r < -0.8</li>
              <li><span class="fw600">Random Chance ${checkMarks[3]}</span><br> Find r > 0.8 and r < -0.8</li>
            </ul>
            <small>➕ = Found r > 0.8, ➖ = Found r < -0.8</small>
          `;
      const existingPopover = Popover.getInstance(this.$refs.gradePopover);
      if(existingPopover){
        existingPopover.dispose();
        const gradePopover = new Popover(this.$refs.gradePopover, {
          container: 'body',
          title: `Module 3 Grade`,
          content: this.mod3GradePopoverContent,
          trigger: 'hover focus',
          placement: 'right',
          html: true,
        });
        gradePopover;
      }
    },
    randomHelpPopover() {
      const helpPopover = new Popover(this.$refs.randomHelp, {
        container: 'body',
        title: `Random Data`,
        content: `Try changing the number of observations and see if that increases or decreases the range of correlation coefficients you see.`,
        trigger: 'hover click',
        placement: 'right',
        html: true,
      });
      helpPopover;
    },
  }, 
  computed: {
    checkIF1() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch2;
        const trueValue = data.x[0] > data.y[2] ? "true" : "false";
        const studValue = this.userData.stats.textbookResponses.ch2.mod1.if1;
        const result = compare(trueValue, studValue, "text");
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkIF2() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch2;
        const trueValue = data.x[4] == data.y[3] ? data.x[1] : 10;
        const studValue = this.userData.stats.textbookResponses.ch2.mod1.if2;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkIF3() {
      if(!this.userData){
        return null;
      } else {
        const set = this.userData.stats.problemSets.ch2;
        const data = {
          a: [0, set.x[0], set.x[3], set.y[1]],
          b: [0, set.x[1], set.x[4], set.y[2]],
          c: [0, set.x[2], set.y[0], set.y[3]]
        }
        const trueValue = data.a[3] <= 4 ? data.b[3] : data.c[3];
        const studValue = this.userData.stats.textbookResponses.ch2.mod1.if3;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkIF4() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch2;
        const trueValue = data.y[1] + data.x[2] < 7 ? data.x[0] + data.x[3] : data.x[1] + data.x[4];
        const studValue = this.userData.stats.textbookResponses.ch2.mod1.if4;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkIF5() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch2;
        const trueValue = data.y[0] != data.x[1] ? math.max(data.x[0], data.x[3], data.y[1]) : math.min(data.x[1], data.x[4], data.y[2]);
        const studValue = this.userData.stats.textbookResponses.ch2.mod1.if5;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkCov() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch2;
        const xMean = math.mean(data.x);
        const yMean = math.mean(data.y);
        const cov = math.sum(data.x.map((x, i) => (x - xMean) * (data.y[i] - yMean))) / (data.x.length - 1);
        const studValue = this.userData.stats.textbookResponses.ch2.mod2.cov;
        const result = compare(cov, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkcor() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch2;
        const xMean = math.mean(data.x);
        const yMean = math.mean(data.y);
        const cov = math.sum(data.x.map((x, i) => (x - xMean) * (data.y[i] - yMean))) / (data.x.length - 1);
        const xStd = math.std(data.x);
        const yStd = math.std(data.y);
        const cor = cov / (xStd * yStd);
        const studValue = this.userData.stats.textbookResponses.ch2.mod2.cor;
        const result = compare(cor, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
  },
  mounted() {
    
  },
}
</script>

<style>
.accordion-button {
  color: var(--business_statistics) !important;
  background-color: var(--off_white) !important;
}

.accordion-button:not(.collapsed) {
  color: white !important;
  background-color: var(--business_statistics) !important;
}

.accordion-body {
  background-color: var(--background_color) !important;
}

.open-spreadsheet-button {
  background-color: var(--business_statistics); 
  border: none;
  color: white; 
  padding: 12px 18px; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block;
  font-size: 1.25em; 
  cursor: pointer;
  border-radius: 7px; 
  margin-top: 12px;
}
.open-spreadsheet-button:hover {
  background-color: #51859e;
}
.open-spreadsheet-button:active {
  background-color: var(--business_statistics); 
}

.table-spreadsheet {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 24px;
}
.table-spreadsheet table {
  border-collapse: collapse;
  width: auto;
}
.table-spreadsheet th {
  background-color: var(--off_white);
  color: black;
  font-weight: bold;
  padding: 4px 36px;
  border: 1px gray solid;
}
.table-spreadsheet td {
  padding: 4px;
  border: 1px gray solid;
}

/* set up chart-container-div chart-container and chart-controls so the chart and controls are side-by-side and the buttons look nice */
.chart-container-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid var(--border_color);
  border-radius: 4px;
  background-color: var(--off_white);
  padding: 8px;
}

.chart-container {
  width: 60%;
}

.chart-controls {
  width: 40%;
  margin-left: 4px;
}

.chart-controls button {
  background-color: var(--business_statistics);
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  cursor: pointer;
  border-radius: 4px;
  margin: 4px 4px 0 0;
}

.chart-controls button:hover {
  background-color: #51859e;
}

.chart-controls button:active {
  background-color: var(--business_statistics);
}

</style>