<template>
  <div class="container col-md-6 offset-md-3">
  
  <!-- #region CHAPTER AND TITLE -->
  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em1-50">Chapter Nine</span><br>
      <span class="fw800 em3 fc-stats">Sampling with Confidence</span>
    </div>
  </div>
  <!-- #endregion -->
  
  <!-- SUB NAVIGATION BAR -->
  <nav>
    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
      <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
      <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
    </div>
  </nav>
  
  <!-- SUB NAV BAR CONTENT -->
  <div class="tab-content" id="nav-tabContent">
    <!-- TEXTBOOK -->
    <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
      
      <VideoBox class="mt-4" videoTitle="Sampling with Confidence" videoUrl="https://www.youtube.com/embed/wg-JfBRo-6w" startAt="0"/>

      <!-- #region MODULE 1 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">The Brewer</h3>
        <p>
          And now the story of a brewer who reworked the Normal distribution so he could make better beer and ended up changing the world.
        </p>
        <VideoBox videoUrl="https://www.youtube.com/embed/Ea4_eX--mIY" videoTitle="The Brewer Who Secretly Revolutionized Statistics" startAt="11" />

        <h4 class="fc-stats">Hypothesis Testing</h4>
        <p>
          Suppose we have some hypothesis about the mean of a population. For example, we might believe that the average GPA among students at a college 
          is 2.9, but we suspect that students who major in statistics have a higher average GPA. We could collect data on the GPAs of statistics majors 
          and use a one-sample t-test to determine if the average GPA of statistics majors is significantly different from 2.9. 
        </p>
        <p>
          That's because we know the distribution of possible sample means from the central limit theorem. If we take a sample of statistics majors and 
          find that their average GPA is 3.1, we can determine how likelihood of seeing that sample mean if the true mean is 2.9. In other words, the 
          question we are trying to answer is, "If statistics majors have the same average GPA as the rest of the student body, how likely is it that we 
          would see a sample mean of 3.1 or higher due to chance?"
        </p>
        <p>
          This is what we call "Hypothsis Testing." We start with a null hypothesis, which is the hypothesis that the population mean is equal to some 
          value. In this case, the null hypothesis is that the average GPA of statistics majors is 2.9. We then collect data and calculate the sample 
          mean. If the sample mean is significantly different from the null hypothesis, we reject the null hypothesis in favor of an alternative hypothesis. 
          In this case, the alternative hypothesis is that the average GPA of statistics majors is greater than 2.9. 
        </p>
        <p>
          Thanks to William Sealy Gosset, we can do that test even if our sample isn't infinitely large. To help us understand hypothesis testing with the 
          t-distribution, watch this video.
        </p>
        <VideoBox videoUrl="https://www.youtube.com/embed/pXuFeRCMTAo" videoTitle="Explaining The One-Sample t-Test" startAt="49" />

        <h4>The One-Sample t-Test</h4>
        <p>
          Suppose we gathered a sample of 25 statistics majors and found that their average GPA was 3.1 while the sample standard deviation was 0.5. 
          We can use the one-sample t-test to determine if the average GPA of statistics majors is significantly different from 2.9. The formula 
          for the t-statistic is: 
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center">
          <MathEquation :equation="'t = \\frac{\\bar{x}-\\mu}{s_x / \\sqrt{n}}'"/>
        </p>
        <p>
          Where <MathEquation :equation="'\\bar{x}'"/> is the sample mean, <MathEquation :equation="'\\mu'"/> is the population mean, 
          <MathEquation :equation="'s_x'"/> is the sample standard deviation, and <MathEquation :equation="'n'"/> is the sample size. 
        </p>
        <p>
          The t-statistic tells us how many standard errors the sample mean is from the population mean. If the t-statistic is large, it means that the 
          sample mean is significantly different from the population mean. We can use the t-distribution to determine the probability of seeing a t-statistic 
          as large as the one we calculated due to chance. If the probability is less than 0.05, we reject the null hypothesis in favor of the alternative 
          hypothesis.
        </p>
        <p>
          Let's do the calculation of the t-statistic for our sample of 25 statistics majors. 
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center">
          <MathEquation :equation="'t = \\frac{\\bar{x}-\\mu}{s_x / \\sqrt{n}} = \\frac{3.1 - 2.9}{0.5/\\sqrt{25}} = 2.0'"/>
        </p>
        <p>
          A t-statistic of 2.0 means that the sample mean is 2 standard errors from the population mean. This is a measure of weirdness. If the average 
          GPA for statistics majors really was the same as the rest of the student body, this sample mean is two full standard deviations away from what 
          we would expect to see. Using the t-distribution, we can find out how likely it is to see a t-statistic of 2.0 or higher due to chance. 
        </p>
        <p>
          Plug in our information into the t-distribution calculator below to find the p-value for our t-statistic. For now, we are using a one-tailed test, 
          and our degrees of freedom are <MathEquation :equation="'n-1 = 25-1 = 24'"/>.
        </p>
        <TStatPValue />
        <p>
          The p-value is the probability of seeing a t-statistic of 2.0 or higher due to chance. If the p-value is less than 0.05, we reject the null 
          hypothesis in favor of the alternative hypothesis. In this case, the p-value is 0.0285, which is less than 0.05. Therefore, we reject the null 
          hypothesis that the average GPA of statistics majors is 2.9 in favor of the alternative hypothesis that the average GPA of statistics majors is 
          greater than 2.9.
        </p>
        <div class="info-box">
          <h5 class="fc-stats">It is important to get this right.</h5>
          <p>
            Like the video above says, there are a lot of underlying concepts at play here. I want to write all out again. 
          </p>
          <p>
            <i>
              We start with a hypothesis: statistics majors have a higher GPA than the rest of the student body. We then collect some data. 
              We find that the average GPA of the student body is 2.9. We then get a sample of 25 statistics majors and find that their average GPA is 3.1. 
              Case closed? Not yet. It could be that our sample is biased. Perhaps we got a group of unusually studious statistics majors. In order to rule 
              out randomness as a reason for this result, we do a <b>hypothesis test</b>. Our sample is small, only 25 people, and so we need to use the 
              <b>t-statistic</b> as our test statistic. We formulate a <b>null hypothesis</b>, which is the hypothesis we seek to rule out. In this case, 
              the null hypothesis is that the average GPA of <u>statistics majors</u> is 2.9 or less. We then calculate the t-statistic for our sample based on that 
              null hypothesis. We got a t-statistic of 2.0. This means that *if the null hypothesis were true*, there is only a 2.85% chance that we would see 
              a sample mean of 3.1 or higher. That's pretty unlikely, so we <b>reject the null hypothesis</b> as too unlikely to be true. 
            </i>
          </p>
          <p>
            It is important to acknowledge that there is a 2.85% chance that our sample <i>is</i> biased. We can never be 100% certain that our sample is 
            representative of the population. We can only say that it is unlikely that our sample is biased.
          </p>
        </div>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            A researcher believes their new flashcard system will improve student test scores. The statewide average test score is 75. The researcher 
            collects data from 20 students who used the flashcard system. Their results are as follows: 
          </p>
          <div class="d-flex align-items-center justify-content-center mb-3">
            {{ problemSets.mod1.join(', ') }}
          </div>
          <p>
            Use this data to conduct a one-sample one-tailed t-test. The researcher will reject the null hypothesis if the p-value is less than 0.05.
          </p>
          <!-- Question 1 -->
          <div class="input-group mb-3">
            <button ref="m1q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\bar{x}'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the mean of the sample" id="m1q1" :value="textbookResponses.mod1.q1" :style="checkM1Q1">
            <button class="btn btn-outline-secondary" type="button" @click="computeM1Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <div class="input-group mb-3">
            <button ref="m1q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'s_x'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the standard deviation of the sample" id="m1q2" :value="textbookResponses.mod1.q2" :style="checkM1Q2">
            <button class="btn btn-outline-secondary" type="button" @click="computeM1Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <div class="input-group mb-3">
            <button ref="m1q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'t'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the t-statistic" id="m1q3" :value="textbookResponses.mod1.q3" :style="checkM1Q3">
            <button class="btn btn-outline-secondary" type="button" @click="computeM1Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <div class="input-group mb-3">
            <button ref="m1q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'d.f.'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the degrees of freedom" id="m1q4" :value="textbookResponses.mod1.q4" :style="checkM1Q4">
            <button class="btn btn-outline-secondary" type="button" @click="computeM1Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 5 -->
          <div class="input-group mb-3">
            <button ref="m1q5" class="input-group-text" style="width: 110px;"><MathEquation :equation="'p'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the p-value" id="m1q5" :value="textbookResponses.mod1.q5" :style="checkM1Q5">
            <button class="btn btn-outline-secondary" type="button" @click="computeM1Q5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 6 -->
          <div class="input-group mb-3">
            <button ref="m1q6" class="input-group-text" style="width: 110px;">Result</button>
            <select class="form-select" id="m1q6" :value="textbookResponses.mod1.q6" :style="checkM1Q6">
              <option :selected="textbookResponses.mod1.q6 === 'reject'" value="reject">Reject the null hypothesis</option>
              <option :selected="textbookResponses.mod1.q6 === 'do_not_reject'" value="do_not_reject">Do not reject the null hypothesis</option>
            </select>
            <button class="btn btn-outline-secondary" type="button" @click="computeM1Q6" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <TStatPValue />
          <CalculatorLinks :score="mod1Score" :scoreTotal="6"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 2 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">One-Tailed or Two-Tailed?</h3>
        <p>
          From our example about the GPA of statistics majors, there were a number of null hypotheses we could have tested based on the 
          student body GPA of 2.9. 
        </p>
        <div class="d-flex align-items-center justify-content-center mb-3">
          <table>
            <tr class="one-tailed">
              <td class="left-cell"><MathEquation :equation="'H_0: \\mu \\le 2.9'"/><br><MathEquation :equation="'H_0: \\mu \\ge 2.9'"/></td>
              <td>One-Tailed Tests</td>
            </tr>
            <tr class="two-tailed">
              <td class="left-cell"><MathEquation :equation="'H_0: \\mu = 2.9'"/></td>
              <td>Two-Tailed Tests</td>
            </tr>
          </table>
        </div>
        <p>
          A one-tailed test is conducted for a hypothesis which includes the ≥ or ≤ signs. A two-tailed test is conducted for a 
          hypothesis which includes the = sign. Which  one you use depends on the question you are trying to answer. A one-tailed test 
          is used when you are only interested in one direction of the distribution. For example, if you are testing a new drug and you 
          only care if it is better than the current drug, you would use a one tailed test. A two-tailed test is used when you are interested 
          in both directions of the distribution. For example, if you are testing a new drug and you want to know if it is <i>different</i> from the 
          current drug, you would use a two-tailed test. A two-tailed test is open to the possibility that the new drug could be worse than the 
          current drug. Because it assumes less, a two-tailed test is more conservative and more common than a one-tailed test.
        </p>
        <img :src="TailsChart" width="100%" class="rounded">

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Customers at a retail store spend an average of $50 per visit. The store manager isn't sure if customers who use a new rewards program 
            will spend more or less than $50 per visit. The manager collects data from 20 customers who used the rewards program. Their results are as follows: 
          </p>
          <div class="d-flex align-items-center justify-content-center mb-3">
            {{ problemSets.mod2.join(', ') }}
          </div>
          <p>
            Use this data to conduct a one-sample two-tailed t-test. The manager will reject the null hypothesis if the p-value is less than 0.05.
          </p>
            <!-- Question 1 -->
            <div class="input-group mb-3">
            <button ref="m2q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\bar{x}'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the mean of the sample" id="m2q1" :value="textbookResponses.mod2.q1" :style="checkM2Q1">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
            </div>
            <!-- Question 2 -->
            <div class="input-group mb-3">
            <button ref="m2q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'s_x'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the standard deviation of the sample" id="m2q2" :value="textbookResponses.mod2.q2" :style="checkM2Q2">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
            </div>
            <!-- Question 3 -->
            <div class="input-group mb-3">
            <button ref="m2q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'t'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the t-statistic" id="m2q3" :value="textbookResponses.mod2.q3" :style="checkM2Q3">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
            </div>
            <!-- Question 4 -->
            <div class="input-group mb-3">
            <button ref="m2q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'d.f.'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the degrees of freedom" id="m2q4" :value="textbookResponses.mod2.q4" :style="checkM2Q4">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
            </div>
            <!-- Question 5 -->
            <div class="input-group mb-3">
            <button ref="m2q5" class="input-group-text" style="width: 110px;"><MathEquation :equation="'p'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the p-value" id="m2q5" :value="textbookResponses.mod2.q5" :style="checkM2Q5">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
            </div>
            <!-- Question 6 -->
            <div class="input-group mb-3">
            <button ref="m2q6" class="input-group-text" style="width: 110px;">Result</button>
            <select class="form-select" id="m2q6" :value="textbookResponses.mod2.q6" :style="checkM2Q6">
              <option value="reject">Reject the null hypothesis</option>
              <option value="do_not_reject">Do not reject the null hypothesis</option>
            </select>
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q6" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
            </div>
            <TStatPValue />
          <CalculatorLinks :score="mod2Score" :scoreTotal="6"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 3 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Confidence Intervals</h3>
        <p>
          A subcomponent of our t-test is the <b>standard error</b>, which is the standard deviation of the sample mean. For the one-sample t-test, 
          the standard error is calculated as the sample standard deviation divided by the square root of the sample size: <MathEquation :equation="'s.e. = s/\\sqrt{n}'"/>.
          The standard error tells us how much the sample mean is likely to vary from the population mean. 
        </p>
        <p>
          Using the p-value from our t-test to either reject or fail to reject the null hypothesis is a straitforward method of hypothesis testing, 
          it loses a lot of nuance. It is a binary decision about a probabilistic outcome. A more nuanced approach is to use a <b>confidence interval</b>.
        </p>
        <p>
          In the video below, they use the two-sample t-test as their example, which we will cover in the next chapter. Just ignore that part of it for 
          now. The important part is the explanation of confidence intervals.
        </p>
        <VideoBox videoUrl="https://www.youtube.com/embed/eaB6SmKJFrk" videoTitle="Explaining Confidence Intervals and The Critical Region" startAt="24" />
        <h4>Calculating a Confidence Interval</h4>
        <p>
          In our example of the GPA of statistics majors, we had a mean of 3.1 and a standard deviation of 0.5 for a sample of 25 students. From this, 
          we can calculate both the upper bound and lower bound of a 95% confidence interval. The formula for a confidence interval is:
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center">
          <MathEquation :equation="'\\bar{x} \\pm (t_{\\alpha,d.f.} \\times s.e.)'"/>
        </p>
        <p>
          Where <MathEquation :equation="'\\bar{x}'"/> is the sample mean, <MathEquation :equation="'t_{\\alpha,d.f.}'"/> is the critical value from the t-distribution 
          for a given confidence level and degrees of freedom, and <MathEquation :equation="'s.e.'"/> is the standard error. We can calculate this standard error 
          using the data we collected:
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center">
          <MathEquation :equation="'s.e. = \\frac{s}{\\sqrt{n}} = \\frac{0.5}{\\sqrt{25}} = 0.1'"/>
        </p>
        <p>
          And we can find the critical value for a 95% confidence interval with 24 degrees of freedom using the calculator below. 
        </p>
        <TStatCrit />
        <p>
          When you input 95% as the confidence level and 24 as the degrees of freedom, you should get a critical value of 2.0639. So, 
          <MathEquation :equation="'t_{\\alpha,d.f.} = 2.0639'"/> and <MathEquation :equation="'s.e. = 0.1'"/>. This means that the 
          95% confidence interval is:
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center">
          <MathEquation :equation="'3.1 \\pm (2.0639 \\times 0.1) = 3.1 \\pm 0.2064 = (2.8936, 3.3064)'"/>
        </p>
        <p>
          Since we are both adding and subtracting from the sample mean, we end up with two values. The Lower Bound is 2.8936 and the 
          Upper Bound is 3.3064. From this we can say that we are 95% confident that the true population mean is between 2.8936 and 3.3064.
        </p>
        <p>
          This is a more nuanced approach to hypothesis testing. Instead of a binary decision, we have a range of values that we are confident 
          the true population mean falls within. This is a more informative way to present the results of a hypothesis test.
        </p>
        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            A survey asks 20 people how many servings of vegetables they eat per day. Below are the results:
          </p>
          <div class="d-flex align-items-center justify-content-center mb-3">
            {{ problemSets.mod3.join(', ') }}
          </div>
          <p>
            Use this data to calculate the confidence intervals listed for the average number of servings of vegetables eaten per day.
          </p>
          <!-- Question 1&2 -->
          <b>Calculate the 95% Confidence Interval</b>
          <div class="input-group mb-3">
          <button ref="m3q1" class="input-group-text" style="width: 120px;">Lower Bound</button>
          <input type="text" class="form-control" placeholder="Calculate the lower bound" id="m3q1" :value="textbookResponses.mod3.q1" :style="checkM3Q1">
          <button ref="m3q2" class="input-group-text" style="width: 120px;">Upper Bound</button>
          <input type="text" class="form-control" placeholder="Calculate the upper bound" id="m3q2" :value="textbookResponses.mod3.q2" :style="checkM3Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3&4 -->
          <b>Calculate the 99% Confidence Interval</b>
          <div class="input-group mb-3">
          <button ref="m3q3" class="input-group-text" style="width: 120px;">Lower Bound</button>
          <input type="text" class="form-control" placeholder="Calculate the lower bound" id="m3q3" :value="textbookResponses.mod3.q3" :style="checkM3Q3">
          <button ref="m3q4" class="input-group-text" style="width: 120px;">Upper Bound</button>
          <input type="text" class="form-control" placeholder="Calculate the upper bound" id="m3q4" :value="textbookResponses.mod3.q4" :style="checkM3Q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>          
          <TStatCrit />
          <CalculatorLinks :score="mod3Score" :scoreTotal="8"/>
        </div>
        
      </div> 
      <!-- #endregion -->
      

      <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/Y9-EVCJ_cmg" startAt="0"/>

      <!-- #region DATA CHANGER -->
      <div v-if="userData" class="data-changer">
        <div class="em1-50 fw600">Questions Reset</div>
        <div>You can reset the questions with randomly generated numbers for more practice.</div>
        <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
      </div>
      <!-- #endregion -->

    </div>
    <!-- EXPLORATORY LESSON -->
    <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="ExploratoryLessonIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Exploratory Lesson {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your exploratory lesson. In this week's Exploratory Lesson, we conduct hypothesis tests using both the 
            z-statistic and the t-statistic. We will also expand our understanding to include hypothesis testing with proportions. 
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ lssnScore }} out of 20 points on this lesson.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/lesson'" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
      </div>
    </div>
    <!-- SPREADSHEET ASSIGNMENT -->
    <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="SpreadsheetAssignmentIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Spreadsheet Assignment {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will 
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ assnScore }} out of 30 points on this assignment.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/assignment'" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
      </div>
    </div>
  </div>
  
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
  import { Popover } from 'bootstrap';
  import { studentt } from 'jstat';

  import MathEquation from '@/components/MathEquation.vue';
  import CalculatorLinks from '@/components/CalculatorLinks.vue';
  import VideoBox from '@/components/VideoBox.vue';
  import TStatPValue from '@/components/TStatPValue.vue';
  import TStatCrit from '@/components/TStatCrit.vue';
  
  import { create, all } from 'mathjs'
  const math = create(all);
  
  import probabilibot from '@/assets/Probabilibot.png';
  import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
  import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
  import TailsChart from '@/assets/TailsChart.png';
  
  export default {
    name: "statsMainPage",
    components: {
      MathEquation,
      CalculatorLinks,
      VideoBox,
      TStatPValue,
      TStatCrit,
    },
    props: ['tab'],
    data() {
      return {
        ch: 'ch9',
        userData: null,
        problemSets: null,
        textbookResponses: null,
        setUpStatsData,
        mod1Score: 0,
        mod2Score: 0,
        mod3Score: 0,
        assnScore: 0,
        lssnScore: 0,
        modalTitle: 'Loading...',
        modalMessage: '',
        probabilibot,
        ExploratoryLessonIcon,
        SpreadsheetAssignmentIcon,
        TailsChart,
        renderEq,
      }
    },
    created() {
      this.loadUserInformation();
    },
    methods: {
      async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullProblemSet();
        await this.setPopovers();
      },
      async pullProblemSet() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        
        // ADD VALUES FOR THE CHAPTER PROBLEM SET
        if(data.stats.problemSets[this.ch].length == 0){
          await setDoc(docRef, {
            stats: {
              problemSets: {
                [this.ch]: this.problemSet(),
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
        if(Object.keys(data.stats.textbookResponses[this.ch]).length == 0){
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                    q5: null,
                    q6: null,
                  },
                  mod2: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                    q5: null,
                    q6: null,
                  },
                  mod3: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER GRADEBOOK IF MISSING
        if(!data.stats.gradeBook[this.ch].mod1){
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                [this.ch]: {
                  mod1: [0,0,0,0,0,0],
                  mod2: [0,0,0,0,0,0],
                  mod3: [0,0,0,0],
                  assignment: null,
                  lesson: null,
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        this.userData = data;
        this.problemSets = data.stats.problemSets[this.ch];
        this.textbookResponses = data.stats.textbookResponses[this.ch];
        this.mod1Score = math.sum(this.userData.stats.gradeBook[this.ch].mod1);
        this.mod2Score = math.sum(this.userData.stats.gradeBook[this.ch].mod2);
        this.mod3Score = math.sum(this.userData.stats.gradeBook[this.ch].mod3);
        this.assnScore = this.userData.stats.gradeBook[this.ch].assignment ? this.userData.stats.gradeBook[this.ch].assignment : 0;
        this.lssnScore = this.userData.stats.gradeBook[this.ch].lesson ? this.userData.stats.gradeBook[this.ch].lesson : 0;
      },
      async setPopovers() {
        await new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (this.userData !== null) {
                    clearInterval(intervalId);
                    resolve(this.userData);
                }
            }, 100); // Check every 100 milliseconds
        });
        const popoverM1Q1 = new Popover(this.$refs.m1q1, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the mean of the sample. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });   
        const popoverM1Q2 = new Popover(this.$refs.m1q2, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the standard deviation of the sample. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        }); 
        const popoverM1Q3 = new Popover(this.$refs.m1q3, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the t-statistic for the sample. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q4 = new Popover(this.$refs.m1q4, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the degrees of freedom for the sample. Your final answer should be an integer.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q5 = new Popover(this.$refs.m1q5, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the p-value for a one-tailed t-test. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q6 = new Popover(this.$refs.m1q6, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Based on the p-value, do you reject the null hypothesis or not?`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q1 = new Popover(this.$refs.m2q1, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the mean of the sample. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q2 = new Popover(this.$refs.m2q2, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the standard deviation of the sample. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q3 = new Popover(this.$refs.m2q3, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the t-statistic for the sample. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q4 = new Popover(this.$refs.m2q4, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the degrees of freedom for the sample. Your final answer should be an integer.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q5 = new Popover(this.$refs.m2q5, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the p-value for a two-tailed t-test. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q6 = new Popover(this.$refs.m2q6, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Based on the p-value, do you reject the null hypothesis or not?`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q1 = new Popover(this.$refs.m3q1, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the lower bound of the 95% confidence interval. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q2 = new Popover(this.$refs.m3q2, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the upper bound of the 95% confidence interval. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q3 = new Popover(this.$refs.m3q3, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the lower bound of the 99% confidence interval. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q4 = new Popover(this.$refs.m3q4, {
          container: 'body',
          title: 'Student\'s T-Test',
          content: `Calculate the upper bound of the 99% confidence interval. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        popoverM1Q1; popoverM1Q2; popoverM1Q3; popoverM1Q4; popoverM1Q5; popoverM1Q6; popoverM2Q1; popoverM2Q2; popoverM2Q3; popoverM2Q4; popoverM2Q5; popoverM2Q6; popoverM3Q1; popoverM3Q2; popoverM3Q3; popoverM3Q4;
      },
      async resetAll() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            problemSets: {
              [this.ch]: this.problemSet()
            }
          }
        }, {merge: true});
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod1: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                  q5: null,
                  q6: null,
                },
                mod2: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                  q5: null,
                  q6: null,
                },
                mod3: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
              }
            }
          }
        }, {merge: true});
        const docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.problemSets = this.userData.stats.problemSets[this.ch];
      },
      problemSet() {
        const mod1 = Array.from({length: 20}, () => Math.floor(Math.random() * 41) + 60);
        const mod2 = Array.from({length: 20}, () => Math.floor(Math.random() * 41) + 30);
        const mod3 = Array.from({length: 20}, () => Math.floor(Math.random() * 6));

        return {
          mod1: mod1,
          mod2: mod2,
          mod3: mod3,
        }
      },
      async computeM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const trueValue = math.mean(data);
          const studValue = document.getElementById('m1q1').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The mean of the sample is ${trueValue}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Just compute the mean of these 20 numbers with whatever tool you prefer.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const trueValue = math.std(data);
          const studValue = document.getElementById('m1q2').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The standard deviation of the sample is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the sample standard deviation for these 20 numbers. Remember, the sample standard deviation divides by n-1.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const trueValue = (math.mean(data) - 75) / (math.std(data) / math.sqrt(20));
          const studValue = document.getElementById('m1q3').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The t-statistic for this sample is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the t-statistic for this sample. Remember, the formula is (sample mean - population mean) / (sample standard deviation / sqrt(n)).`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const trueValue = 19;
          const studValue = document.getElementById('m1q4').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The degrees of freedom for this sample is ${trueValue}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the degrees of freedom for this sample. Remember, the formula is n-1.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const trueValue = 1 - studentt.cdf(math.abs(math.mean(data) - 75) / (math.std(data) / math.sqrt(20)), 19);
          const studValue = document.getElementById('m1q5').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The p-value for this sample is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the p-value for your t-statistic with the degrees of freedom using the calculator below.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q5: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const pValue = 1 - studentt.cdf(math.abs(math.mean(data) - 75) / (math.std(data) / math.sqrt(20)), 19);
          const trueValue = pValue <= 0.05 ? 'reject' : 'do_not_reject';
          const studValue = document.getElementById('m1q6').value;
          const result = trueValue == studValue;
          const feedbackString = trueValue == 'reject' ? 'Because this is less than or equal to 0.05, we reject the null hypothesis' : 'Because this is more than 0.05, we do not reject the null hypothesis';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The p-value for this sample is ${math.round(pValue,4)}. ${feedbackString}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The p-value for this sample is ${math.round(pValue,4)}. When the p-value is less than 0.05, we reject the null hypothesis. Otherwise, we do not reject the null hypothesis.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[5] == 0 && result){gradeMatrix[5] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q6: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = math.mean(data);
          const studValue = document.getElementById('m2q1').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The mean of the sample is ${trueValue}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Just compute the mean of these 20 numbers with whatever tool you prefer.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = math.std(data);
          const studValue = document.getElementById('m2q2').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The standard deviation of the sample is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the sample standard deviation for these 20 numbers. Remember, the sample standard deviation divides by n-1.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = (math.mean(data) - 50) / (math.std(data) / math.sqrt(20));
          const studValue = document.getElementById('m2q3').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The t-statistic for this sample is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the t-statistic for this sample. Remember, the formula is (sample mean - population mean) / (sample standard deviation / sqrt(n)).`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = 19;
          const studValue = document.getElementById('m2q4').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The degrees of freedom for this sample is ${trueValue}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the degrees of freedom for this sample. Remember, the formula is n-1.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = 2*(1 - studentt.cdf(math.abs(math.mean(data) - 50) / (math.std(data) / math.sqrt(20)), 19));
          const studValue = document.getElementById('m2q5').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The p-value for this sample is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the p-value for your t-statistic with the degrees of freedom using the calculator below.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q5: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const pValue = 2*(1 - studentt.cdf(math.abs(math.mean(data) - 50) / (math.std(data) / math.sqrt(20)), 19));
          const trueValue = pValue <= 0.05 ? 'reject' : 'do_not_reject';
          const studValue = document.getElementById('m2q6').value;
          const result = trueValue == studValue;
          const feedbackString = trueValue == 'reject' ? 'Because this is less than or equal to 0.05, we reject the null hypothesis' : 'Because this is more than 0.05, we do not reject the null hypothesis';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The p-value for this sample is ${math.round(pValue,4)}. ${feedbackString}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The p-value for this sample is ${math.round(pValue,4)}. When the p-value is less than 0.05, we reject the null hypothesis. Otherwise, we do not reject the null hypothesis.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[5] == 0 && result){gradeMatrix[5] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q6: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod3;
          const trueValueLower = math.mean(data) - ((math.std(data) / math.sqrt(20)) * studentt.inv(0.975, 19));
          const studValueLower = document.getElementById('m3q1').value;
          const resultLower = compare(trueValueLower, studValueLower, 0.0001);
          const trueValueUpper = math.mean(data) + ((math.std(data) / math.sqrt(20)) * studentt.inv(0.975, 19));
          const studValueUpper = document.getElementById('m3q2').value;
          const resultUpper = compare(trueValueUpper, studValueUpper, 0.0001);
          if(resultLower && resultUpper){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The lower bound of the 95% confidence interval for this sample is ${math.round(trueValueLower,4)} and the upper bound is ${math.round(trueValueUpper,4)}.`;
          } else if(resultLower) {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The lower bound is correct, but the upper bound is not.`;
          } else if(resultUpper) {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The upper bound is correct, but the lower bound is not.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the lower bound of the 95% confidence interval for this sample. Remember, the formula is sample mean - (sample standard deviation / sqrt(n)) * critical-value.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[0] == 0 && resultLower){gradeMatrix[0] = 2}
          if(gradeMatrix[1] == 0 && resultUpper){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q1: studValueLower,
                    q2: studValueUpper,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod3;
          const trueValueLower = math.mean(data) - ((math.std(data) / math.sqrt(20)) * studentt.inv(0.995, 19));
          const studValueLower = document.getElementById('m3q3').value;
          const resultLower = compare(trueValueLower, studValueLower, 0.0001);
          const trueValueUpper = math.mean(data) + ((math.std(data) / math.sqrt(20)) * studentt.inv(0.995, 19));
          const studValueUpper = document.getElementById('m3q4').value;
          const resultUpper = compare(trueValueUpper, studValueUpper, 0.0001);
          if(resultLower && resultUpper){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The lower bound of the 99% confidence interval for this sample is ${math.round(trueValueLower,4)} and the upper bound is ${math.round(trueValueUpper,4)}.`;
          } else if(resultLower) {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The lower bound is correct, but the upper bound is not.`;
          } else if(resultUpper) {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The upper bound is correct, but the lower bound is not.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the lower bound of the 99% confidence interval for this sample. Remember, the formula is sample mean - (sample standard deviation / sqrt(n)) * critical-value.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[2] == 0 && resultLower){gradeMatrix[2] = 2}
          if(gradeMatrix[3] == 0 && resultUpper){gradeMatrix[3] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q3: studValueLower,
                    q4: studValueUpper,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
    }, 
    computed: {
      checkM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const trueValue = math.mean(data);
          const studValue = this.textbookResponses.mod1.q1;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const trueValue = math.std(data);
          const studValue = this.textbookResponses.mod1.q2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const trueValue = (math.mean(data) - 75) / (math.std(data) / math.sqrt(20));
          const studValue = this.textbookResponses.mod1.q3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const trueValue = 19;
          const studValue = this.textbookResponses.mod1.q4;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const trueValue = 1 - studentt.cdf(math.abs(math.mean(data) - 75) / (math.std(data) / math.sqrt(20)), 19);
          const studValue = this.textbookResponses.mod1.q5;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod1;
          const pValue = 1 - studentt.cdf(math.abs(math.mean(data) - 75) / (math.std(data) / math.sqrt(20)), 19);
          const trueValue = pValue <= 0.05 ? 'reject' : 'do_not_reject';
          const studValue = this.textbookResponses.mod1.q6;
          const result = trueValue == studValue;
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = math.mean(data);
          const studValue = this.textbookResponses.mod2.q1;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = math.std(data);
          const studValue = this.textbookResponses.mod2.q2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = (math.mean(data) - 50) / (math.std(data) / math.sqrt(20));
          const studValue = this.textbookResponses.mod2.q3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = 19;
          const studValue = this.textbookResponses.mod2.q4;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = 2*(1 - studentt.cdf(math.abs(math.mean(data) - 50) / (math.std(data) / math.sqrt(20)), 19));
          const studValue = this.textbookResponses.mod2.q5;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const pValue = 2*(1 - studentt.cdf(math.abs(math.mean(data) - 50) / (math.std(data) / math.sqrt(20)), 19));
          const trueValue = pValue <= 0.05 ? 'reject' : 'do_not_reject';
          const studValue = this.textbookResponses.mod2.q6;
          const result = trueValue == studValue;
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod3;
          const trueValue = math.mean(data) - ((math.std(data) / math.sqrt(20)) * studentt.inv(0.975, 19));
          const studValue = this.textbookResponses.mod3.q1;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod3;
          const trueValue = math.mean(data) + ((math.std(data) / math.sqrt(20)) * studentt.inv(0.975, 19));
          const studValue = this.textbookResponses.mod3.q2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod3;
          const trueValue = math.mean(data) - ((math.std(data) / math.sqrt(20)) * studentt.inv(0.995, 19));
          const studValue = this.textbookResponses.mod3.q3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod3;
          const trueValue = math.mean(data) + ((math.std(data) / math.sqrt(20)) * studentt.inv(0.995, 19));
          const studValue = this.textbookResponses.mod3.q4;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
    }
  }
  </script>
  
<style scoped>
.info-box {
  background-color: var(--off_white);
  padding: 10px;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 1px solid var(--border_color);
}
table {
  width: 50%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
}
th, td {
  padding: 10px;
  text-align: center;
  border: 1px solid black;
}
.two-tailed {
  background-color: #87c6de;
  color: black;
}
.one-tailed {
  background-color: #6aad6d;
  color: black;
}
.left-cell {
  text-align: left;
  font-style: italic;
}
</style>