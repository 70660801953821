<template>
  <div class="shell">
    <div class="title">P(Z) Calculator</div>
    <div class="calculator">
      
      <input type="number" id="z-score" v-model="zScore" placeholder="Z-Score"/>
      <div class="prob">P(Z): {{ probability }}</div>
    </div>
  </div>
</template>

<script>
import { create, all } from 'mathjs'
const math = create(all);
export default {
  data() {
    return {
      zScore: null,
      probability: null,
    };
  },
  watch: {
    zScore: function(newVal) {
      if (newVal !== null) {
        this.probability = math.round((1.0 + math.erf(newVal / math.sqrt(2))) / 2.0,6);
      }
    },
  }
};
</script>

<style scoped>
.shell {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  width: 300px;
}
.title {
  font-size: 1.15rem;
  font-weight: bold;
}
.calculator {
  display: flex;
  width: auto;
  align-items: center;
}
.calculator input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100px;
}
.calculator .prob {
  margin-left: 12px;
}
</style>