<template>
  <div class="container mb-3">
    <div class="card p-2" style="border-radius: 15px;">
      <div class="text-center">T-Statistic P-Value Calculator</div>
      <div class="d-flex align-items-center justify-content-center">
        <div class="form-group mr-3">
          <label for="tStatistic">T-Statistic:</label>
          <input
            type="number"
            v-model.number="tStatistic"
            class="form-control"
            style="width: 100px; margin-right: 10px;"
            id="tStatistic"
          />
        </div>
        <div class="form-group mr-3">
          <label for="degreesOfFreedom"><i>d.f.</i>:</label>
          <input
            type="number"
            v-model.number="degreesOfFreedom"
            class="form-control"
            style="width: 100px; margin-right: 10px;"
            id="degreesOfFreedom"
          />
        </div>
        <div class="form-group mr-3">
          <label for="tailType">Tail Type:</label>
          <select
            v-model="tailType"
            class="form-control"
            style="width: 110px; margin-right: 10px;"
            id="tailType"
          >
            <option value="one-tailed">One-Tailed</option>
            <option value="two-tailed">Two-Tailed</option>
          </select>
        </div>
        <div class="form-group mr-3">
          <div v-if="pValue !== null">P-Value: {{ pValue.toFixed(4) }}</div>
          <div v-if="pValue == null">P-Value: </div>
          <button @click="calculatePValue" class="btn btn-primary">Calculate P-Value</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { studentt } from 'jstat';

export default {
  data() {
    return {
      tStatistic: null,
      degreesOfFreedom: null,
      tailType: 'one-tailed',
      pValue: null,
    };
  },
  methods: {
    calculatePValue() {
      if (this.tStatistic !== null && this.degreesOfFreedom !== null) {
        const t = Math.abs(this.tStatistic);
        const df = this.degreesOfFreedom;

        // Calculate the p-value using the cumulative distribution function
        const pValueOneTailed = 1 - studentt.cdf(t, df);

        this.pValue = this.tailType === 'one-tailed' ? pValueOneTailed : 2 * pValueOneTailed;
      }
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 15px;
}
.form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
