<template>
  <div class="container col-md-6 offset-md-3">
  
  <!-- #region CHAPTER AND TITLE -->
  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em1-50">Chapter Eight</span><br>
      <span class="fw800 em3 fc-stats">The Central Limit Theorem</span>
    </div>
  </div>
  <!-- #endregion -->
  
  <!-- SUB NAVIGATION BAR -->
  <nav>
    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
      <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
      <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
    </div>
  </nav>
  
  <!-- SUB NAV BAR CONTENT -->
  <div class="tab-content" id="nav-tabContent">
    <!-- TEXTBOOK -->
    <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
      
      <VideoBox class="mt-4" videoTitle="The Central Limit Theorem" videoUrl="https://www.youtube.com/embed/msU8qeafFY0" startAt="0"/>

      <!-- #region MODULE 1 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Sampling</h3>
        <p>
          Sampling is selecting a subset of individuals from a population to estimate characteristics of the whole. A representative sample ensures the sample's characteristics reflect those of the population. There are various sampling methods, each with pros and cons.
        </p>
        <p style="margin-left: 36px; margin-right: 36px;">
          <b>Simple Random Sampling</b> involves randomly selecting individuals from the population. While ideal, achieving true randomness is difficult. Participants' willingness to join can bias results, and random selection may still yield an unrepresentative sample.
        </p>
        <p style="margin-left: 36px; margin-right: 36px;">
          <b>Systematic Sampling</b> reduces the chances of an unrepresentative sample by selecting participants systematically, such as every 10th person in a list. This method can help align the sample more closely with the population if some information about the population is known.
        </p>
        <p style="margin-left: 36px; margin-right: 36px;">
          <b>Stratified Sampling</b> involves dividing the population into groups (e.g., by age, race, or income) and randomly sampling within each group. This method can produce a better sample but risks bias if the stratification method correlates with the experiment's outcome.
        </p>
        <p style="margin-left: 36px; margin-right: 36px;">
          <b>Cluster Sampling</b> selects random clusters from the population and includes all individuals within those clusters. For example, unemployment surveys might call random households and ask about each member's employment status. This practical method is often dictated by experimental design needs.
        </p>
        <p style="margin-left: 36px; margin-right: 36px;">
          <b>Convenience Sampling</b> uses easily accessible individuals, like friends or social media followers. Though often seen as "unscientific" due to likely bias, all samples have some level of convenience.
        </p>
        <p>
          While sampling is a widely accepted method for gathering information, it's essential to recognize its inherent limitations and potential biases.
        </p>

        <p>
          The polling firm Ipsos asked 1,013 Americans whether or not they believe in ghosts and 36% of them said that they do. But when 
          <a href="https://www.ipsos.com/en-us/news-polls/belief-in-ghosts-2021" target="_blank">Ipsos reported this</a>, 
          they said that 36% of Americans believe in ghosts. They did not say 36% of the 1,013 people we talked to believe in ghosts. How can they leap to 
          such a conclusion!?
        </p>
        <h3>The Law of Large Numbers</h3>
        <p>
          We care about two key numbers here: the true mean of the population, <MathEquation :equation="'\\mu'"/>, and the mean of our sample, <MathEquation :equation="'\\bar{x}'"/>. Our goal is to determine if <MathEquation :equation="'\\bar{x} = \\mu'"/> (our sample has the same mean as the population). Due to random sampling error, <MathEquation :equation="'\\bar{x} = \\mu'"/> is not guaranteed. However, the random error decreases as the sample size, <MathEquation :equation="'n'"/>, increases. The Law of Large Numbers states:
        </p>
        <p style="margin-top:12px; margin-bottom:12px;" class="d-flex justify-content-center">
          <MathEquation :equation="'\\lim_{n \\to \\infty} \\bar{x} = \\mu'"/>
        </p>
        <p>
          In simple terms, as our sample size increases, the sample mean approaches the true population mean.
        </p>


        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Answer each of the multiple choice questions below.
          </p>

          <!-- MULTIPLE CHOICE COMPONENT -->
          <div class="mc-container">
            <div v-for="(question, index) in problemSets.mod1" :key="question.id" class="mc-question" v-show="index === currentQuestionIndex">
              <b :style="checkM1Q1[index]">Question {{ index+1 }}</b>. {{ question.question }}
              <div v-for="answer in question.answers" :key="answer.id" class="mc-answer">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="question.id"
                    :id="'m1'+question.id+answer.id"
                    :value="answer.id"
                    :checked="textbookResponses.mod1[index] == answer.id"
                  />
                  <label class="form-check-label" :for="question.id+answer.id">
                    {{ answer.text }}
                  </label>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;">
                <button class="btn btn-light" type="button" @click="previousQuestion">Previous</button>
                <button class="btn btn-outline-secondary" type="button" @click="computeM1Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answers</button>
                <button class="btn btn-light" type="button" @click="nextQuestion">Next</button>
              </div>
            </div>  
          </div>
  
          <CalculatorLinks :score="mod1Score" :scoreTotal="6"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 2 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Taking a Lot of Samples</h3>
        <img src="https://media2.giphy.com/media/xUPJPpON8q52wq3M2Y/200w.gif" class="rounded float-start me-3 mt-1" alt="gobbling...">
        <p>
          A sample mean estimates a population mean, but it's not always perfect. Sometimes, our sample might not represent the population well. 
          The law of large numbers helps: bigger samples better reflect the population. But how can we tell if our sample is accurate? We need the 
          probability that our sample mean is close to the population mean. Just like using the Binomial Distribution figure out the chances of 
          getting a certain number of Heads in coin flips, we need the <b>probability distribution of the sample mean</b>.
        </p>
        <p>
          Enter our friend Monte Carlo! Imagine sampling 5 random people and recording their average age. This mean might be close to the population 
          mean, or it might not. So, we take another sample of 5 people and record their average age, repeating this many times. What does the distribution 
          of these sample means look like?
        </p>
        <div class="d-flex justify-content-center">
          <img :src="CentralLimitTheorem" class="rounded" alt="Central Limit Theorem" style="max-width: 600px;">
        </div>
        <p>
          If we know the probability distribution of sample means, we can calculate the probability that the mean of a random sample is close (or not so close) 
          to the population mean. This would go a long way to helping us determine the strength of our sample as evidence for some hypothesis!
        </p>


        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Here is a population of 100 people. for each person we have recorded their height in inches.
          </p>
          <div style="display: flex; flex-direction: column; align-items: center;">
            <MathEquation :equation="bigPopulation.slice(0,20).join(',')+','"/>
            <MathEquation :equation="bigPopulation.slice(20,40).join(',')+','"/>
            <MathEquation :equation="bigPopulation.slice(40,60).join(',')+','"/>
            <MathEquation :equation="bigPopulation.slice(60,80).join(',')+','"/>
            <MathEquation :equation="bigPopulation.slice(80,100).join(',')"/>
          </div>
          <p class="mt-3">
            Compute each of the statistics below. 
          </p>
          <!-- Question 1 -->
          <div class="input-group mb-3">
            <button ref="m2q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\mu'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the mean of the population" id="m2q1" :value="textbookResponses.mod2.q1" :style="checkM2Q1">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <div class="input-group mb-3">
            <button ref="m2q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\sigma'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the standard deviation of the population" id="m2q2" :value="textbookResponses.mod2.q2" :style="checkM2Q2">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>

          <p>Use the following sample to compute the statistics below.</p>
          <div style="display: flex; flex-direction: column; align-items: center;" class="mb-3">
            <MathEquation :equation="'x = \\{'+problemSets.mod2.sample1.join(',')+'\\}'"/>
          </div>
          <!-- Question 3 -->
          <div class="input-group mb-3">
            <button ref="m2q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\bar{x}'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the mean of the sample" id="m2q3" :value="textbookResponses.mod2.q3" :style="checkM2Q3">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <div class="input-group mb-3">
            <button ref="m2q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'s_x'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the standard deviation of the sample" id="m2q4" :value="textbookResponses.mod2.q4" :style="checkM2Q4">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>.
          </div>

          <p>Use the following sample to compute the statistics below.</p>
          <div style="display: flex; flex-direction: column; align-items: center;" class="mb-3">
            <MathEquation :equation="'y = \\{'+problemSets.mod2.sample2.join(',')+'\\}'"/>
          </div>
          <!-- Question 5 -->
          <div class="input-group mb-3">
            <button ref="m2q5" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\bar{y}'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the mean of the sample" id="m2q5" :value="textbookResponses.mod2.q5" :style="checkM2Q5">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 6 -->
          <div class="input-group mb-3">
            <button ref="m2q6" class="input-group-text" style="width: 110px;"><MathEquation :equation="'s_y'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the standard deviation of the sample" id="m2q6" :value="textbookResponses.mod2.q6" :style="checkM2Q6">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q6" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>.
          </div>

          <p>Use the following sample to compute the statistics below.</p>
          <div style="display: flex; flex-direction: column; align-items: center;" class="mb-3">
            <MathEquation :equation="'z = \\{'+problemSets.mod2.sample3.join(',')+'\\}'"/>
          </div>
          <!-- Question 7 -->
          <div class="input-group mb-3">
            <button ref="m2q7" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\bar{z}'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the mean of the sample" id="m2q7" :value="textbookResponses.mod2.q7" :style="checkM2Q7">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q7" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 8 -->
          <div class="input-group mb-3">
            <button ref="m2q8" class="input-group-text" style="width: 110px;"><MathEquation :equation="'s_z'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the standard deviation of the sample" id="m2q8" :value="textbookResponses.mod2.q8" :style="checkM2Q8">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q8" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>.
          </div>

          <p>
            Imagine doing this thousands or millions of times! You would have a distribution of sample means. What would the mean of that distribution be? 
            For now, let's just calculate the mean of our three samples, instead of the mean of a million samples. 
          </p>
          <!-- Question 9 -->
          <div class="input-group mb-3">
            <button ref="m2q9" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\hat{\\mu}'"/></button>
            <input type="text" class="form-control" placeholder="Calculate the mean of the sample means" id="m2q9" :value="textbookResponses.mod2.q9" :style="checkM2Q9">
            <button class="btn btn-outline-secondary" type="button" @click="computeM2Q9" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>.
          </div>

          <CalculatorLinks :score="mod2Score" :scoreTotal="9"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 3 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">The Central Limit Theorem</h3>
        <p>
          Let <MathEquation :equation="'\\bar{x}'"/> be the mean of a sample of size <MathEquation :equation="'n'"/>. The Central Limit Theorem 
          states that the distribution of <MathEquation :equation="'\\bar{x}'"/> is approximately normal, regardless of the population's distribution, 
          if the sample size is large enough. The mean of the distribution of <MathEquation :equation="'\\bar{x}'"/> is the population mean, 
          <MathEquation :equation="'\\mu'"/>, and the standard deviation is the population standard deviation, <MathEquation :equation="'\\sigma'"/>, 
          divided by the square root of the sample size.
        </p>
        <p class="d-flex justify-content-center">
          Stated mathematically, 
        </p>
        <p class="d-flex justify-content-center em1-50">
          <MathEquation :equation="'\\bar{x} \\sim N(\\mu, \\frac{\\sigma}{\\sqrt{n}})'"/>
        </p>
        <div class="d-flex justify-content-center">
          <img :src="MindBlown" class="rounded" alt="Mind Blown" style="max-width: 500px;">
        </div>
        <p class="mt-3">
          The universe is an opaque and indecipherable place. The night is dark and full of terrors. We have so few candles to light our way. 
          But in that darkness we find the Central Limit Theorem, a lighthouse in the storm. It tells us about the distribution of sample means, 
          which appears on the surface to be a small and insignificant thing. But it is not. It is our greatest ally in our quest to know the universe.
        </p>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            it is a simple task. Just watch this video. 
          </p>
          <VideoTracker videoId="zeJD6dqJ5lo" @update-view="handleUpdateView" />
          <small>You'll need to watch the entire video on this page so that it can give you credit for it. Your score will increase as you watch. Skipping ahead won't work.</small>
          <CalculatorLinks :score="mod3Score" :scoreTotal="5"/>
        </div>
        
      </div> 
      <!-- #endregion -->
      

      <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/FwLumeOMCw4" startAt="0"/>

      <!-- #region DATA CHANGER -->
      <div v-if="userData" class="data-changer">
        <div class="em1-50 fw600">Questions Reset</div>
        <div>You can reset the questions with randomly generated numbers for more practice.</div>
        <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
      </div>
      <!-- #endregion -->

    </div>
    <!-- EXPLORATORY LESSON -->
    <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="ExploratoryLessonIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Exploratory Lesson {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will draw 1,000 random samples from 
            a large population of GPA scores. We will calculate the mean of each sample and plot the distribution of the sample means. We will 
            also introduce an important limitation of the Central Limit Theorem: the sample size.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ lssnScore }} out of 20 points on this lesson.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/lesson'" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
      </div>
    </div>
    <!-- SPREADSHEET ASSIGNMENT -->
    <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="SpreadsheetAssignmentIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Spreadsheet Assignment {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will need to determine if a small dataset 
            is a good representation of a larger population or if it is biased. To do this, you will need to apply your knowledge of the Central 
            Limit Theorem and the Standard Normal Distribution.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ assnScore }} out of 30 points on this assignment.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/assignment'" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
      </div>
    </div>
  </div>
  
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
</template>

<script>
import { doc, getDoc, setDoc } from "firebase/firestore"; 
import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
import { Popover } from 'bootstrap';

import MathEquation from '@/components/MathEquation.vue';
import CalculatorLinks from '@/components/CalculatorLinks.vue';
import VideoTracker from '@/components/VideoTracker.vue';

import { create, all } from 'mathjs'
const math = create(all);

import probabilibot from '@/assets/Probabilibot.png';
import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
import CentralLimitTheorem from '@/assets/CentralLimitTheorem.gif';
import MindBlown from '@/assets/MindBlown.gif';

export default {
  name: "statsMainPage",
  components: {
    MathEquation,
    CalculatorLinks,
    VideoTracker,
  },
  props: ['tab'],
  data() {
    return {
      ch: 'ch8',
      userData: null,
      problemSets: null,
      textbookResponses: null,
      setUpStatsData,
      mod1Score: 0,
      mod2Score: 0,
      mod3Score: 0,
      assnScore: 0,
      lssnScore: 0,
      modalTitle: 'Loading...',
      modalMessage: '',
      probabilibot,
      ExploratoryLessonIcon,
      SpreadsheetAssignmentIcon,
      CentralLimitTheorem,
      MindBlown,
      renderEq,
      currentQuestionIndex: 0,
      bigPopulation: [69,66,67,64,69,68,70,63,72,70,70,66,72,74,67,74,65,66,67,63,70,72,60,66,71,64,69,61,74,69,73,68,71,67,74,62,62,67,72,68,71,68,64,71,68,72,68,68,72,62,72,64,69,69,71,64,69,66,70,72,72,71,77,66,68,69,71,66,65,65,59,64,65,68,67,68,64,69,71,73,72,63,62,69,68,63,69,73,66,71,69,68,62,74,64,69,66,67,66,69],
    }
  },
  created() {
    this.loadUserInformation();
  },
  methods: { 
    async loadUserInformation() {
      await this.setUpStatsData();
      await this.pullProblemSet();
      await this.setPopovers();
    }, 
    async pullProblemSet() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      let docSnap = await getDoc(docRef);
      let data = docSnap.data();
      
      // ADD VALUES FOR THE CHAPTER PROBLEM SET
      if(data.stats.problemSets[this.ch].length == 0){
        await setDoc(docRef, {
          stats: {
            problemSets: {
              [this.ch]: {
                mod1: this.problemSetMod1(),
                mod2: this.problemSetMod2(),
                mod3: null,
              },
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
      if(Object.keys(data.stats.textbookResponses[this.ch]).length == 0){
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod1: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                  q5: null,
                  q6: null,
                },
                mod2: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                  q5: null,
                  q6: null,
                  q7: null,
                  q8: null,
                  q9: null,
                },
                mod3: {
                  watched: [],
                  total: 1,
                },
              }
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      // ADD CHAPTER GRADEBOOK IF MISSING
      if(!data.stats.gradeBook[this.ch].mod1){
        await setDoc(docRef, {
          stats: {
            gradeBook: {
              [this.ch]: {
                mod1: [0,0,0,0,0,0],
                mod2: [0,0,0,0,0,0,0,0,0],
                mod3: [0],
                assignment: null,
                lesson: null,
              }
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      this.userData = data;
      this.problemSets = data.stats.problemSets[this.ch];
      this.textbookResponses = data.stats.textbookResponses[this.ch];
      this.mod1Score = math.sum(this.userData.stats.gradeBook[this.ch].mod1);
      this.mod2Score = math.sum(this.userData.stats.gradeBook[this.ch].mod2);
      this.mod3Score = math.sum(this.userData.stats.gradeBook[this.ch].mod3);
      this.assnScore = this.userData.stats.gradeBook[this.ch].assignment ? this.userData.stats.gradeBook[this.ch].assignment : 0;
      this.lssnScore = this.userData.stats.gradeBook[this.ch].lesson ? this.userData.stats.gradeBook[this.ch].lesson : 0;
    },
    async setPopovers() {
      await new Promise((resolve) => {
          const intervalId = setInterval(() => {
              if (this.userData !== null) {
                  clearInterval(intervalId);
                  resolve(this.userData);
              }
          }, 100); // Check every 100 milliseconds
      });
      const popoverM2Q1 = new Popover(this.$refs.m2q1, {
        container: 'body',
        title: 'Population Parameters',
        content: `Compute the mean of the population of numbers above. Your answer should be exact.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      }); 
      const popoverM2Q2 = new Popover(this.$refs.m2q2, {
        container: 'body',
        title: 'Population Parameters',
        content: `Compute the standard deviation of the population of numbers above. Be careful! This is a little different than computing the sample standard deviation. Your answer should be exact.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM2Q3 = new Popover(this.$refs.m2q3, {
        container: 'body',
        title: 'Sample Statistics',
        content: `Compute the mean of the sample of numbers above. Round your answer to two decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM2Q4 = new Popover(this.$refs.m2q4, {
        container: 'body',
        title: 'Sample Statistics',
        content: `Compute the standard deviation of the sample of numbers above. Round your answer to two decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM2Q5 = new Popover(this.$refs.m2q5, {
        container: 'body',
        title: 'Sample Statistics',
        content: `Compute the mean of the sample of numbers above. Round your answer to two decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM2Q6 = new Popover(this.$refs.m2q6, {
        container: 'body',
        title: 'Sample Statistics',
        content: `Compute the standard deviation of the sample of numbers above. Round your answer to two decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM2Q7 = new Popover(this.$refs.m2q7, {
        container: 'body',
        title: 'Sample Statistics',
        content: `Compute the mean of the sample of numbers above. Round your answer to two decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM2Q8 = new Popover(this.$refs.m2q8, {
        container: 'body',
        title: 'Sample Statistics',
        content: `Compute the standard deviation of the sample of numbers above. Round your answer to two decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverM2Q9 = new Popover(this.$refs.m2q9, {
        container: 'body',
        title: 'Sample Statistics',
        content: `Compute the mean of the sample means of the three samples above. Round your answer to two decimal places.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      popoverM2Q1; popoverM2Q2; popoverM2Q3; popoverM2Q4; popoverM2Q5; popoverM2Q6; popoverM2Q7; popoverM2Q8; popoverM2Q9;
    },
    async resetAll() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      await setDoc(docRef, {
        stats: {
          problemSets: {
            [this.ch]: {
              mod1: this.problemSetMod1(),
              mod2: this.problemSetMod2(),
              mod3: null,
            }
          }
        }
      }, {merge: true});
      await setDoc(docRef, {
        stats: {
          textbookResponses: {
            [this.ch]: {
              mod1: {
                q1: null,
                q2: null,
                q3: null,
                q4: null,
                q5: null,
                q6: null,
              },
              mod2: {
                q1: null,
                q2: null,
                q3: null,
                q4: null,
                q5: null,
                q6: null,
                q7: null,
                q8: null,
                q9: null,
              },
            }
          }
        }
      }, {merge: true});
      const docSnap = await getDoc(docRef);
      this.userData = docSnap.data();
      this.problemSets = this.userData.stats.problemSets[this.ch];
    },
    async handleUpdateView(data) {
      const watched = data.watched;
      const mergedSet = [...new Set([...watched, ...this.textbookResponses.mod3.watched])];
      data.watched = mergedSet; 
      const total = data.total;
      const percent = (mergedSet.length / total);
      let score = math.ceil(percent * 50)/10;
      if(percent < 0.01 || score < 0) score = 0;
      if(percent > 0.98 || score > 5) score = 5;
      if(!this.userData){
        return null;
      } else {
        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
        if(mergedSet.length !== this.textbookResponses.mod3.watched.length){
          gradeMatrix[0] = score > gradeMatrix[0] ? score : gradeMatrix[0];
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: data,
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      }
    },
    problemSetMod1() {
      let questionSet = [
        {
          id: 'q1',
          question: 'Airport security in the distant country of Saboga tried to deter smuggling by selecting every 10th passenger in line for a more thorough screening. What type of sampling is this?',
          answers: [
            { id: 'a1', text: 'Simple Random Sampling' },
            { id: 'a2', text: 'Systematic Sampling' },
            { id: 'a3', text: 'Stratified Sampling' },
            { id: 'a4', text: 'Cluster Sampling' },
            { id: 'a5', text: 'Convenience Sampling' },
          ],
          correct: 'a2',
        },
        {
          id: 'q2',
          question: 'A school newspaper wants to know how students feel about a new dress code. To make sure their sample is representative, they sample 10 random Freshman, 10 random Sophomores, 10 random Juniors, and 10 random Seniors. What type of sampling is this?',
          answers: [
            { id: 'a1', text: 'Simple Random Sampling' },
            { id: 'a2', text: 'Systematic Sampling' },
            { id: 'a3', text: 'Stratified Sampling' },
            { id: 'a4', text: 'Cluster Sampling' },
            { id: 'a5', text: 'Convenience Sampling' },
          ],
          correct: 'a3',
        },
        {
          id: 'q3',
          question: 'A school wants to test whether or not playing music during exams helps increase test scores. They randomly select 10 classrooms which will employ the new method, and then they look at individual student scores to see if it worked. What type of sampling is this?',
          answers: [
            { id: 'a1', text: 'Simple Random Sampling' },
            { id: 'a2', text: 'Systematic Sampling' },
            { id: 'a3', text: 'Stratified Sampling' },
            { id: 'a4', text: 'Cluster Sampling' },
            { id: 'a5', text: 'Convenience Sampling' },
          ],
          correct: 'a4',
        },
        {
          id: 'q4',
          question: 'A marketing firm stands in a busy shopping center offering passersby $5 to participate in a consumer survey. What kind of sampling is this?',
          answers: [
            { id: 'a1', text: 'Simple Random Sampling' },
            { id: 'a2', text: 'Systematic Sampling' },
            { id: 'a3', text: 'Stratified Sampling' },
            { id: 'a4', text: 'Cluster Sampling' },
            { id: 'a5', text: 'Convenience Sampling' },
          ],
          correct: 'a5',
        },
        {
          id: 'q5',
          question: 'A company wants to know how many of their employees are satisfied with their job. They send out a survey to randomly selected employees. What kind of sampling is this?',
          answers: [
            { id: 'a1', text: 'Simple Random Sampling' },
            { id: 'a2', text: 'Systematic Sampling' },
            { id: 'a3', text: 'Stratified Sampling' },
            { id: 'a4', text: 'Cluster Sampling' },
            { id: 'a5', text: 'Convenience Sampling' },
          ],
          correct: 'a1',
        },
        {
          id: 'q6',
          question: 'In the context of the Law of Large Numbers, what happens as the number of observations in a sample increases?',
          answers: [
            { id: 'a1', text: 'The standard deviation of the sample means increases, diverging from the population mean.' },
            { id: 'a2', text: 'The probability of observing extreme values in the sample decreases, leading to a narrower confidence interval.' },
            { id: 'a3', text: 'The sample mean converges towards the population mean, increasing the accuracy of the estimate.' },
            { id: 'a4', text: 'The variance between individual sample observations increases, reducing the overall reliability of the sample.' },
          ],
          correct: 'a3',
        },
      ];

      // randomize order of answer for each question
      for(let question of questionSet){
        question.answers.sort(() => Math.random() - 0.5);
      }

      return questionSet;
    },
    problemSetMod2() {
      // randomly select a sample of 10 from bigPopulation and return it as an array.
      let sample1 = [];
      let sample2 = [];
      let sample3 = [];
      for(let i = 0; i < 10; i++){
        sample1.push(this.bigPopulation[Math.floor(Math.random() * this.bigPopulation.length)]);
        sample2.push(this.bigPopulation[Math.floor(Math.random() * this.bigPopulation.length)]);
        sample3.push(this.bigPopulation[Math.floor(Math.random() * this.bigPopulation.length)]);
      }
      return {
        sample1: sample1,
        sample2: sample2,
        sample3: sample3,
      };
    },
    previousQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
      } else {
        this.currentQuestionIndex = this.problemSets.mod1.length - 1;
      }
    },
    nextQuestion() {
      if (this.currentQuestionIndex < this.problemSets.mod1.length - 1) {
        this.currentQuestionIndex++;
      } else {
        this.currentQuestionIndex = 0;
      }
    },
    async computeM1Q1() {
      if(!this.userData){
        return null;
      } else {
        const studentChecked = [];
        let gradedArray = [];
        for(let question of this.problemSets.mod1){
          const trueValue = question.correct;
          const selectedAnswer = document.querySelector(`input[name=${question.id}]:checked`);
          const studValue = selectedAnswer ? selectedAnswer.value : null;
          studentChecked.push(studValue);
          const result = trueValue == studValue;
          gradedArray.push(result);
        }
        let feedbackArray = [
          {
            a1: `Question 1: Simple random sampling involves randomly selecting individuals from the population. Picking every 10th person in line isn't random.`,
            a2: `Question 1 is correct!`,
            a3: `Question 1: Stratified sampling involves dividing the population into groups and randomly sampling within each group. There are no groups in this scenario.`,
            a4: `Question 1: Cluster sampling selects random clusters from the population and includes all individuals within those clusters. This is not cluster sampling.`,
            a5: `Question 1: Convenience sampling uses easily accessible individuals, like friends or social media followers. This is not convenience sampling.`,
          },
          {
            a1: `Question 2: Simple random sampling involves randomly selecting individuals from the population. Stratified sampling involves dividing the population into groups and randomly sampling within each group.`,
            a2: `Question 2: Systematic sampling reduces the chances of an unrepresentative sample by selecting participants systematically, such as every 10th person in a list.`,
            a3: `Question 2 is correct!`,
            a4: `Question 2: Cluster sampling selects random clusters from the population and includes all individuals within those clusters. This is not cluster sampling.`,
            a5: `Question 2: Convenience sampling uses easily accessible individuals, like friends or social media followers. This is not convenience sampling.`,
          },
          {
            a1: `Question 3: Simple random sampling involves randomly selecting individuals from the population. Cluster sampling selects random clusters from the population and includes all individuals within those clusters.`,
            a2: `Question 3: Systematic sampling reduces the chances of an unrepresentative sample by selecting participants systematically, such as every 10th person in a list.`,
            a3: `Question 3: Stratified sampling involves dividing the population into groups and randomly sampling within each group. This is not stratified sampling.`,
            a4: `Question 3 is correct!`,
            a5: `Question 3: Convenience sampling uses easily accessible individuals, like friends or social media followers. This is not convenience sampling.`,
          },
          {
            a1: `Question 4: Simple random sampling involves randomly selecting individuals from the population. Convenience sampling uses easily accessible individuals, like friends or social media followers.`,
            a2: `Question 4: Systematic sampling reduces the chances of an unrepresentative sample by selecting participants systematically, such as every 10th person in a list.`,
            a3: `Question 4: Stratified sampling involves dividing the population into groups and randomly sampling within each group. This is not stratified sampling.`,
            a4: `Question 4: Cluster sampling selects random clusters from the population and includes all individuals within those clusters. This is not cluster sampling.`,
            a5: `Question 4 is correct!`,
          },
          {
            a1: `Question 5 is correct!`,
            a2: `Question 5: Systematic sampling reduces the chances of an unrepresentative sample by selecting participants systematically, such as every 10th person in a list.`,
            a3: `Question 5: Stratified sampling involves dividing the population into groups and randomly sampling within each group. This is not stratified sampling.`,
            a4: `Question 5: Cluster sampling selects random clusters from the population and includes all individuals within those clusters. This is not cluster sampling.`,
            a5: `Question 5: Convenience sampling uses easily accessible individuals, like friends or social media followers. This is not convenience sampling.`,
          },
          {
            a1: `Question 6: The standard deviation of the sample means decreases as the number of observations in a sample increases, converging towards the population mean.`,
            a2: `Question 6: The probability of observing extreme values in the sample decreases, leading to a narrower confidence interval.`,
            a3: `Question 6 is correct!`,
            a4: `Question 6: The variance between individual sample observations decreases as the number of observations in a sample increases, increasing the overall reliability of the sample.`,
          },
        ];
        this.modalTitle = 'Multiple Choice Results';
        this.modalMessage = '';
        for(let i = 0; i < gradedArray.length; i++){
          if(studentChecked[i]){
            this.modalMessage += feedbackArray[i][studentChecked[i]] + '<br><br>';
          } else {
            this.modalMessage += 'You did not answer question ' + (i+1) + '<br><br>';
          }
        }

        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
        for(let i = 0; i < gradedArray.length; i++){
          if(gradeMatrix[i] == 0 && gradedArray[i]){
            gradeMatrix[i] = 1;
          } 
        }

        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod1: studentChecked,
              }
            },
            gradeBook: {
              [this.ch]: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q1() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.mean(this.bigPopulation);
        const studValue = document.getElementById('m2q1').value;
        const result = compare(trueValue, studValue, 0);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The mean of the population is ${trueValue}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The mean is found by adding all the values and dividing by the number of values.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
        if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod2: {
                  q1: studValue,
                },
              }
            },
            gradeBook: {
              [this.ch]: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q2() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.sqrt(math.variance(this.bigPopulation)*(99/100));
        const studValue = document.getElementById('m2q2').value;
        const result = compare(trueValue, studValue, 0);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The standard deviation of the population is ${trueValue}.`;
        } else if (compare(math.std(this.bigPopulation), studValue, 0.01)) {
          this.modalTitle = 'Close!';
          this.modalMessage = `In this rare circumstance, we need the <i>population</i> standard deviation, not the sample standard deviation. The population standard deviation is found by the equation: <br><br> <MathEquation :equation="'\\sigma = \\sqrt{\\frac{\\sum_{i=1}^{N}(x_i - \\mu)^2}{N}}'/>`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The <i>population</i> standard deviation is found by taking the square root of the <i>population</i> variance.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
        if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod2: {
                  q2: studValue,
                },
              }
            },
            gradeBook: {
              [this.ch]: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q3() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.mean(this.problemSets.mod2.sample1);
        const studValue = document.getElementById('m2q3').value;
        const result = compare(trueValue, studValue, 0.01);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The mean of the sample is ${trueValue}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The mean is found by adding all the values and dividing by the number of values.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
        if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod2: {
                  q3: studValue,
                },
              }
            },
            gradeBook: {
              [this.ch]: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q4() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.std(this.problemSets.mod2.sample1);
        const studValue = document.getElementById('m2q4').value;
        const result = compare(trueValue, studValue, 0.01);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The standard deviation of the sample is ${math.round(trueValue,2)}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The sample standard deviation is found by taking the square root of the sample variance. The sample variance is found by taking the sum of the squared differences from the mean and dividing by the number of values minus one.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
        if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod2: {
                  q4: studValue,
                },
              }
            },
            gradeBook: {
              [this.ch]: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q5() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.mean(this.problemSets.mod2.sample2);
        const studValue = document.getElementById('m2q5').value;
        const result = compare(trueValue, studValue, 0.01);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The mean of the sample is ${trueValue}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The mean is found by adding all the values and dividing by the number of values.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
        if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod2: {
                  q5: studValue,
                },
              }
            },
            gradeBook: {
              [this.ch]: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q6() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.std(this.problemSets.mod2.sample2);
        const studValue = document.getElementById('m2q6').value;
        const result = compare(trueValue, studValue, 0.01);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The standard deviation of the sample is ${math.round(trueValue,2)}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The sample standard deviation is found by taking the square root of the sample variance. The sample variance is found by taking the sum of the squared differences from the mean and dividing by the number of values minus one.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
        if(gradeMatrix[5] == 0 && result){gradeMatrix[5] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod2: {
                  q6: studValue,
                },
              }
            },
            gradeBook: {
              [this.ch]: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q7() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.mean(this.problemSets.mod2.sample3);
        const studValue = document.getElementById('m2q7').value;
        const result = compare(trueValue, studValue, 0.01);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The mean of the sample is ${trueValue}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The mean is found by adding all the values and dividing by the number of values.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
        if(gradeMatrix[6] == 0 && result){gradeMatrix[6] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod2: {
                  q7: studValue,
                },
              }
            },
            gradeBook: {
              [this.ch]: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q8() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.std(this.problemSets.mod2.sample3);
        const studValue = document.getElementById('m2q8').value;
        const result = compare(trueValue, studValue, 0.01);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The standard deviation of the sample is ${math.round(trueValue,2)}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The sample standard deviation is found by taking the square root of the sample variance. The sample variance is found by taking the sum of the squared differences from the mean and dividing by the number of values minus one.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
        if(gradeMatrix[7] == 0 && result){gradeMatrix[7] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod2: {
                  q8: studValue,
                },
              }
            },
            gradeBook: {
              [this.ch]: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeM2Q9() {
      if(!this.userData){
        return null;
      } else {
        const m1 = math.mean(this.problemSets.mod2.sample1);
        const m2 = math.mean(this.problemSets.mod2.sample2);
        const m3 = math.mean(this.problemSets.mod2.sample3);
        const trueValue = math.mean([m1, m2, m3]);
        const studValue = document.getElementById('m2q9').value;
        const result = compare(trueValue, studValue, 0.01);
        if(result){
          this.modalTitle = 'Correct!';
          this.modalMessage = `The mean of the means is ${trueValue}.`;
        } else {
          this.modalTitle = 'Incorrect';
          this.modalMessage = `The mean of the means is found by adding all the means and dividing by the number of means.`;
        }
        let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
        if(gradeMatrix[8] == 0 && result){gradeMatrix[8] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod2: {
                  q9: studValue,
                },
              }
            },
            gradeBook: {
              [this.ch]: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
  }, 
  computed: {
    checkM1Q1() {
      if(!this.userData){
        return null;
      } else {
        let styleArray = [];
        for(let [index, question] of this.problemSets.mod1.entries()) {
          const trueValue = question.correct; 
          const studValue = this.textbookResponses.mod1[index]; 
          const result = trueValue == studValue;
          if(studValue && result){
            styleArray.push({color: 'green'})
          } else if (studValue) {
            styleArray.push({color: 'red'})
          } else {
            styleArray.push(null);
          }
        }
        return styleArray;
      }
    },
    checkM2Q1() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.mean(this.bigPopulation);
        const studValue = this.textbookResponses.mod2.q1;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q2() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.sqrt(math.variance(this.bigPopulation)*(99/100));
        const studValue = this.textbookResponses.mod2.q2;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q3() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.mean(this.problemSets.mod2.sample1);
        const studValue = this.textbookResponses.mod2.q3;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q4() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.std(this.problemSets.mod2.sample1);
        const studValue = this.textbookResponses.mod2.q4;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q5() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.mean(this.problemSets.mod2.sample2);
        const studValue = this.textbookResponses.mod2.q5;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q6() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.std(this.problemSets.mod2.sample2);
        const studValue = this.textbookResponses.mod2.q6;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q7() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.mean(this.problemSets.mod2.sample3);
        const studValue = this.textbookResponses.mod2.q7;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q8() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.std(this.problemSets.mod2.sample3);
        const studValue = this.textbookResponses.mod2.q8;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkM2Q9() {
      if(!this.userData){
        return null;
      } else {
        const m1 = math.mean(this.problemSets.mod2.sample1);
        const m2 = math.mean(this.problemSets.mod2.sample2);
        const m3 = math.mean(this.problemSets.mod2.sample3);
        const trueValue = math.mean([m1, m2, m3]);
        const studValue = this.textbookResponses.mod2.q9;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
  }
}
</script>
  
<style scoped>

</style>