<template>
  <button class="popover-text" ref="popover">
    {{ buttonText }}
  </button>
</template>

<script>
import { Popover } from 'bootstrap';

export default {
  name: 'PopOver',
  props: {
    direction: {
      type: String,
      default: 'top'
    },
    title: {
      type: String,
      default: 'Popover Title'
    },
    content: {
      type: String,
      default: 'I am popover content!'
    },
    buttonText: {
      type: String,
      default: 'Hover Me'
    }
  },
  mounted() {
    // Initialize the popover
    new Popover(this.$refs.popover, {
      container: 'body',
      placement: this.direction,
      trigger: 'hover focus',
      title: this.title,
      content: this.content,
      html: true
    });
  }
};
</script>