<template>
  <div class="container password-reset-container">
    <div class="password-reset-div rounded shadow">
      <div class="logo-holder">
        <img src="../assets/ProGoLoGo.png" alt="ProGoLoGo" class="img-fluid" style="width: 300px; margin: 0 auto; display: block;">
      </div>
      
      <div class="fw700 em2">Reset Password</div>

      <div class="input-group">
        <label for="resetEmail">Enter Your Email:</label>
        <input type="email" id="resetEmail" name="resetEmail" autocomplete="email" required>
        <small id="resetEmailError">Please use your registered email address</small>
      </div>
      
      <button class="reset-button" @click="sendPasswordReset">Send Password Reset Email</button>
      <br><br>

    </div>
  </div>
</template>

<script>
// Import the necessary Firebase function for password reset
import { auth } from '../main';
import { sendPasswordResetEmail } from 'firebase/auth';

import ProGoLoGo from '../assets/ProGoLoGo.png';

export default {
  name: "passwordResetPage",
  data() {
    return {
      ProGoLoGo,
    }
  },
  methods: {
    async sendPasswordReset() {
      const resetEmail = document.getElementById('resetEmail').value;
      if (!resetEmail) {
        document.getElementById('resetEmailError').innerText = "Email address is required";
        document.getElementById('resetEmailError').classList.add('error');
        return;
      } else {
        document.getElementById('resetEmailError').innerText = "Please use your registered email address";
        document.getElementById('resetEmailError').classList.remove('error');
      }
      
      try {
        const actionCodeSettings = {
          url: 'https://learnprogo.com/',
          handleCodeInApp: true,
        };
        await sendPasswordResetEmail(auth, resetEmail, actionCodeSettings);
        alert('Password reset email sent successfully');
        // Optionally, redirect the user or show a success message
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Error:', errorMessage);
        document.getElementById('resetEmailError').innerText = "Error sending password reset email. Please check your email and try again.";
        document.getElementById('resetEmailError').classList.add('error');
      }
    },
  }
}
</script>

<style scoped>
.logo-holder {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background: linear-gradient(90deg in oklab, blue, red);
}
.password-reset-container { 
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-group {
  margin-bottom: 20px;
}
.input-group label {
  display: block;
  margin-bottom: 5px;
}
.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.reset-button {
  width: 100%;
  padding: 10px;
  background: linear-gradient(90deg in oklab, blue, red);
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.reset-button:hover {
  background: linear-gradient(270deg in oklab, blue, red);
}
.password-reset-div {
  left: auto;
  margin-top: 50px;
  padding: 24px;
  width: 500px;
  height: 400px;
  background-color: var(--off_white);
}
.error {
  color: red;
}
</style>
