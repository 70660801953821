<template>
  <div class="container col-md-6 offset-md-3">
  
  <!-- #region CHAPTER AND TITLE -->
  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em1-50">Chapter Eleven</span><br>
      <span class="fw800 em3 fc-stats">Ordinary Least Squares</span>
    </div>
  </div>
  <!-- #endregion -->
  
  <!-- SUB NAVIGATION BAR -->
  <nav>
    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
      <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
      <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
    </div>
  </nav>
  
  <!-- SUB NAV BAR CONTENT -->
  <div class="tab-content" id="nav-tabContent">
    <!-- TEXTBOOK -->
    <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
      
      <VideoBox class="mt-4" videoTitle="Ordinary Least Squares" videoUrl="https://www.youtube.com/embed/sPJg7C6OfT4" startAt="0"/>

      <!-- #region MODULE 1 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Linear Regression</h3>
        <p>
          Let's start by planting the seeds of understanding with this Crash Course video.
        </p>
        <VideoBox videoUrl="https://www.youtube.com/embed/WWqE7YHR4Jc" videoTitle="Regression" startAt="3" />
        
        <h4 class="fc-stats">The Basic Problem We are Trying to Solve</h4>
        <p>
          An Example: There is a strong an positive correlation between educational attainment and income. People who have more education tend to earn more money. 
          This is probably why you are sitting there reading this right now. You are getting more years of schooling so that you can earn more money 
          (and learn some cool stuff like regression!). At this point in your educational journey, however, you face higher costs. You have to pay for 
          tuition, books, and maybe even room and board. You are investing in your future, but you are also spending money now. You are hoping that 
          the investment will pay off in the future. Will it? 
        </p>
        <p>
          Linear regression is a tool we can use to answer questions like that. We can use data to estimate what an additional year of education is 
          "worth" in terms of future income. 
        </p>
        <div class="d-flex justify-content-center"><h5>Median Weekly Earnings by Highest Degree, 2022</h5></div> 
        <BarChart />
        <div class="d-flex justify-content-end"><small><a href="https://www.bls.gov/careeroutlook/2023/data-on-display/education-pays.htm" target="_blank">Source: bls.gov</a></small></div>
        

        <p>
          In the chart above, the Bureau of Labor Statistics has done something similar to linear regression. They have plotted the median weekly 
          earnings for people with different levels of education. You can find your education level on the chart and see what the median weekly 
          earnings are for people with that level of education. Linear Regression does almost the same thing, except it finds the <i>average</i> value 
          for <i>y</i> given any value of <i>x</i>.
        </p>
        <p>If we convert those degrees into the typical years of education it takes to get them and plot it on a scatterplot, you can see what I mean.</p>
        <RegressionChart :data="scatterIncomeData" :multiple="false"/>
        <p>
          The equation for the line of best fit is <MathEquation :equation="'y = 131.5x - 680.35'"/>, where y is the median weekly wage and x is a person's years 
          of schooling. People who have one more year of education also typically make an additional $131.50 per week. From this, we can predict someone's wage 
          using their years of schooling. The regression line predicts that someone with 12 years of education will earn $897.58. The data itself says the median 
          earnings for a high school graduate is $853.00 (plug in 12 for x). The line is close, but not perfect.
        </p>
        <p>
          The slope of the best fit line is often the statistic we need to understand the workings of the world. Knowing that one more year of education is 
          associated with a $131.50 per week pay increase allows us to determine the typical value a year of education has in terms of future earnings. 
          According to these data, the discounted present value of an extra $131.50 is well above $100,000, even with a high discount rate! 
        </p>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Use the best fit line to predict the median weekly wage for the different years of education below. 
          </p>
          <div class="d-flex justify-content-center mb-3"><MathEquation :equation="'y = 131.5x - 680.35'"/></div>
          <!-- Question 1 -->
          <div class="input-group mb-3">
          <button ref="m1q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'x = '+problemSets.mod1[0]"/></button>
          <input type="text" class="form-control" placeholder="Predict the value of y using x" id="m1q1" :value="textbookResponses.mod1.q1" :style="checkM1Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <div class="input-group mb-3">
          <button ref="m1q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'x = '+problemSets.mod1[1]"/></button>
          <input type="text" class="form-control" placeholder="Predict the value of y using x" id="m1q2" :value="textbookResponses.mod1.q2" :style="checkM1Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <div class="input-group mb-3">
          <button ref="m1q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'x = '+problemSets.mod1[2]"/></button>
          <input type="text" class="form-control" placeholder="Predict the value of y using x" id="m1q3" :value="textbookResponses.mod1.q3" :style="checkM1Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <div class="input-group mb-3">
          <button ref="m1q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'x = '+problemSets.mod1[3]"/></button>
          <input type="text" class="form-control" placeholder="Predict the value of y using x" id="m1q4" :value="textbookResponses.mod1.q4" :style="checkM1Q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <CalculatorLinks :score="mod1Score" :scoreTotal="4"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 2 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Ordinary Least Squares</h3>
        <div>
          To find the best fit line, we need to find values for the betas in the linear model below.
        </div>
        <div class="d-flex justify-content-center mt-2 mb-2 em1-25"><MathEquation :equation="'y_i = \\beta_0 + \\beta_1x_i + \\varepsilon_i'"/></div>
        <p>
          There are different methods for finding the line of best fit. The most common method is called Ordinary Least Squares. This method finds the values 
          for the beta coefficients that minimize the sum of the squared differences between the predicted values and the actual values (a.k.a. the error). 
          That let's us find the values for the betas that best fit the data.
        </p>
        <h5>Step One: Find <MathEquation :equation="'\\beta_1'"/></h5>
        <p>Let's apply this to the data from the scatterplot of education and earnings.</p>
        <div class="d-flex justify-content-center container">
          <table class="income-table">
              <tr>
                  <th>Education (in Years)</th>
                  <th>Weekly Earnings</th>
              </tr>
              <tr>
                  <td>21</td>
                  <td>$2,083</td>
              </tr>
              <tr>
                  <td>20</td>
                  <td>$2,080</td>
              </tr>
              <tr>
                  <td>18</td>
                  <td>$1,661</td>
              </tr>
              <tr>
                  <td>16</td>
                  <td>$1,432</td>
              </tr>
              <tr>
                  <td>14</td>
                  <td>$1,005</td>
              </tr>
              <tr>
                  <td>13</td>
                  <td>$935</td>
              </tr>
              <tr>
                  <td>12</td>
                  <td>$853</td>
              </tr>
              <tr>
                  <td>9</td>
                  <td>$682</td>
              </tr>
          </table>
          <div class="d-flex flex-column">
            
            <p class="mt-2">
              <MathEquation :equation="'\\beta_1'"/> is the coefficient for <MathEquation :equation="'x_i'"/> (a.k.a the slope of the line). The formula 
              is reminicient of the old "rise over run" formula from algebra.
            </p>
            <div class="d-flex justify-content-center em1-25">
              <MathEquation :equation="'\\beta_1 = \\frac{cov(x_i,y_i)}{var(x_i)}'"/>
            </div>
            <div class="mb-3 mt-3">
              In this case, <MathEquation :equation="'x_i'"/> is the years of education and <p>Let's apply this to the data from the scatterplot of education and earnings.</p> is the weekly earnings. 
              The sample covariance of <MathEquation :equation="'x_i'"/> and <MathEquation :equation="'y_i'"/> is 2,251.839 and the sample variance of 
              <MathEquation :equation="'x_i'"/> is 17.125. So, the coefficient is: 
            </div>
            <div class="d-flex justify-content-center em1-25 mb-3">
              <MathEquation :equation="'\\beta_1 = \\frac{2,251.839}{17.125} = 131.5'"/>
            </div>
            <div>
              In general, we interpret this coefficient as: "One more unit of x is associated with a <MathEquation :equation="'\\beta_1'"/> unit increase in y." 
              In this case, one more year of education is associated with a $131.50 increase in weekly earnings. 
            </div>
          </div>
        </div>
        <h5>Step Two: Find <MathEquation :equation="'\\beta_0'"/></h5>
        <p>
          Once we have the slope, we can calculate the y-intercept, which is represented by <MathEquation :equation="'\\beta_0'"/>. This is the value of y when 
          x is zero. In this case, it is the value of weekly earnings when a person has zero years of education.
        </p>
        <p>
          The regression we estimate will produce a line that is unlikely to pass through any of the points of data we have. The line of best fit 
          tries to get close to <i>all</i> of the data, not just one point. However, the regression line needs to be correct <i>on average</i>. If 
          our regression line consistently underestimates or overestimates the value of y, then we can get a better fit by adjusting the y-intercept 
          until that consistent bias is gone. So, the line of <i>best</i> fit will be unbiased, meaning it will pass through the average values of 
          x and y. We can use that fact to find the y-intercept.
        </p>
        <div class="d-flex justify-content-center em1-25">
          <MathEquation :equation="'\\beta_0 = \\bar{y} - \\beta_1\\bar{x}'"/>
        </div>
        <p>
          In the case of the education data, the average years of education is 15.375 and the average weekly earnings is $1,341.375. So, the y-intercept is: 
        </p>
        <div class="d-flex justify-content-center mb-3">
          <MathEquation :equation="'\\beta_0 = 1,341.375 - 131.5(15.375) = -680.35'"/>
        </div>
        <p>
          The line of best fit is <MathEquation :equation="'y = 131.5x - 680.35'"/>. This line is the best fit for the data we have. It is the line that 
          minimizes the sum of the squared differences between the predicted values and the actual values.
        </p>


        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>Use the data below to estimate the following regression model: </p>
          <div class="d-flex justify-content-center"><MathEquation :equation="'y_i = \\beta_0 + \\beta_1x_i + \\varepsilon'"/></div>
          <div class="d-flex justify-content-center">
            <table class="problem-set-table">
              <tr>
                <th>x</th>
                <th>y</th>
              </tr>
              <tr v-for="(x, index) in problemSets.mod2.x" :key="index">
                <td>{{ x }}</td>
                <td>{{ problemSets.mod2.y[index] }}</td>
              </tr>
            </table>
          </div>
          <!-- Question 1 -->
          <div class="input-group mb-3">
          <button ref="m2q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\beta_1'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the value of beta 1" id="m2q1" :value="textbookResponses.mod2.q1" :style="checkM2Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <div class="input-group mb-3">
          <button ref="m2q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\beta_0'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the value of beta 0" id="m2q2" :value="textbookResponses.mod2.q2" :style="checkM2Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <b>For each value of x, calculate the predicted value of y.</b>
          <div class="input-group mb-3">
          <button ref="m2q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'x = '+problemSets.mod2.x1"/></button>
          <input type="text" class="form-control" placeholder="Predict the value of y using x" id="m2q3" :value="textbookResponses.mod2.q3" :style="checkM2Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <div class="input-group mb-3">
          <button ref="m2q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'x = '+problemSets.mod2.x2"/></button>
          <input type="text" class="form-control" placeholder="Predict the value of y using x" id="m2q4" :value="textbookResponses.mod2.q4" :style="checkM2Q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
  
          <CalculatorLinks :score="mod2Score" :scoreTotal="8"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 3 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">The Errors of Regression</h3>
        <p>
          In the Crash Course video, they explain the different kinds of variance that make up a regression model: 
        </p>
        <p style="margin-top:12px; margin-bottom:6px;" class="d-flex justify-content-center">
          <MathEquation :equation="'\\text{SST} = \\displaystyle \\sum_{i=1}^{n} (y_i - \\bar{y})^2'"/>
        </p>
        <p style="margin-top:6px; margin-bottom:6px;" class="d-flex justify-content-center">
          <MathEquation :equation="'\\text{SSR} = \\displaystyle \\sum_{i=1}^{n} (\\hat{y}_i - \\bar{y})^2'"/>
        </p>
        <p style="margin-top:6px; margin-bottom:12px;" class="d-flex justify-content-center">
          <MathEquation :equation="'\\text{SSE} = \\displaystyle \\sum_{i=1}^{n} (y_i - \\hat{y}_i)^2'"/>
        </p>
        <p>
          <b>SST</b> is the sum of squares total, and you'll notice that it is the same as the numerator of the variance formula. <b>SSR</b> is the sum of squares 
          regression, and you can think of it as the numerator of a formula for the variance of the predicted values (<MathEquation :equation="'\\hat{y}'" />). 
          <b>SSE</b> is the sum of squares error, and you can think of it as the numerator of a formula for the variance of the residuals 
          (<MathEquation :equation="'y - \\hat{y}'" />, or <MathEquation :equation="'e'" />).
        </p>
        <p>
          The same Crash Course video highlighted an amazing fact: the sum of squares total is equal to the sum of squares regression plus the sum of squares 
          error!
        </p>
        <p style="margin-top:12px; margin-bottom:12px;" class="d-flex justify-content-center"><MathEquation :equation="'SST = SSR + SSE'"/></p>
        <p>
          I know it doesn't seem like much, but this relationships helps us understand what regression is doing, and it is how we test the staistical 
          significance of the coefficients we estimate. 
        </p>
        <p>
          People are different. For any variable <MathEquation :equation="'y'" /> we choose, different people will have different values. We could pick 
          age, income, height, location, happiness, health, or any other variable. No matter what, there will be <i>variance</i> in the values people 
          have for that variable. And the curious want to know why! Why do people differ in their values for <MathEquation :equation="'y'" />? Curious, 
          truth-seeking people want to try and <i>explain</i> the variance we see in <MathEquation :equation="'y'" />.
        </p>
        <p>
          Using another variable, <MathEquation :equation="'x'" />, we can try to explain the variance in <MathEquation :equation="'y'" />. People don't just 
          vary in their values for <MathEquation :equation="'y'" />, they also vary in their values for <MathEquation :equation="'x'" />. Maybe there is a 
          correlation between the two variables. Using Ordinary Least Squares, we can estimate the the coefficients for a linear equation like 
          <MathEquation :equation="'y = \\beta_0 + \\beta_1x + \\varepsilon'"/>. In effect, we are using the variance people have in <MathEquation :equation="'x'" /> 
          to explain some of their variance in <MathEquation :equation="'y'" />. The proportion of the variance in <MathEquation :equation="'y'" /> that is 
          explained by the variance in <MathEquation :equation="'x'" /> is called the <i>coefficient of determination</i>, or <MathEquation :equation="'R^2'" />.
        </p>
        <p style="margin-top:6px; margin-bottom:12px;" class="d-flex justify-content-center">
          <MathEquation :equation="'R^2 = \\frac{SSR}{SST}'"/>
        </p>
        <p>
          We also find that there is a part of the variance in <MathEquation :equation="'y'" /> that is not explained by the variance in <MathEquation :equation="'x'" />. 
          This is the sum of squares error (<MathEquation :equation="'\\text{SSE}'"/>). Unless we have a perfect correlation between <MathEquation :equation="'x'" /> and 
          <MathEquation :equation="'y'" />, we will always have some error. It is from the variance of this error that we can test the statistical significance 
          of our coefficients.
        </p>
        <h4 class="fc-stats">Hypothesis Testing for <MathEquation :equation="'\\beta_1'"/></h4>
        <p>The standard form of a test statistic used in hypothesis testing is:</p>
        <p style="margin-top:6px; margin-bottom:12px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="'t = \\frac{\\text{what we found} - \\text{what we think we should have found}}{\\text{the standard error of what we found}}'"/>
        </p>
        <p>
          When we estimate the regression model using a sample, we find <MathEquation :equation="'\\hat{\\beta_1}'"/>, which is our estimate for the population 
          parameter of <MathEquation :equation="'\\beta_1'"/>. Typically, we want to test it against the null hypothesis that <MathEquation :equation="'\\beta_1 = 0'"/>. 
          So, our t-statistic becomes:
        </p>
        <p style="margin-top:6px; margin-bottom:12px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="'t = \\frac{\\hat{\\beta_1} - 0}{\\text{standard error of }\\hat{\\beta_1}}'"/>
        </p>
        <p>
          Unfortunately, dear student, the standard error of <MathEquation :equation="'\\hat{\\beta_1}'"/> is a doozy.
        </p>
        <p style="margin-top:6px; margin-bottom:12px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="'SE_{\\beta_1} = \\sqrt{\\frac{SSE}{n-k-1}\\frac{1}{SSX}}'"/>
        </p>
        <p>
          Where SSE is the sum of squared error, SSX is the sum of squared for x, n is the number of observations, and k is the number of independent variables 
          in the model. The table below does these calculations. 
        </p>
        <table class="income-table mb-3">
            <tr>
                <th>Education (x)</th>
                <th>Earnings (y)</th>
                <th ref="yHat"><MathEquation :equation="'\\hat{y_i}'"/></th>
                <th ref="resid"><MathEquation :equation="'e_i = y_i - \\hat{y}_i'"/></th>
                <th ref="resid2"><MathEquation :equation="'e_i^2'"/></th>
                <th ref="ssx"><MathEquation :equation="'(x_i - \\bar{x})^2'"/></th>
            </tr>
            <tr>
                <td>21</td>
                <td>$2,083</td>
                <td>2081.03</td>
                <td>1.97</td>
                <td>3.88</td>
                <td>31.64</td>
            </tr>
            <tr>
                <td>20</td>
                <td>$2,080</td>
                <td>1949.54</td>
                <td>130.46</td>
                <td>17020.86</td>
                <td>21.39</td>
            </tr>
            <tr>
                <td>18</td>
                <td>$1,661</td>
                <td>1686.55</td>
                <td>-25.55</td>
                <td>652.67</td>
                <td>6.89</td>
            </tr>
            <tr>
                <td>16</td>
                <td>$1,432</td>
                <td>1423.56</td>
                <td>8.44</td>
                <td>71.25</td>
                <td>0.39</td>
            </tr>
            <tr>
                <td>14</td>
                <td>$1,005</td>
                <td>1160.57</td>
                <td>-155.57</td>
                <td>24202.14</td>
                <td>1.89</td>
            </tr>
            <tr>
                <td>13</td>
                <td>$935</td>
                <td>1029.08</td>
                <td>-94.08</td>
                <td>8850.32</td>
                <td>5.64</td>
            </tr>
            <tr>
                <td>12</td>
                <td>$853</td>
                <td>897.58</td>
                <td>-44.58</td>
                <td>1987.54</td>
                <td>11.39</td>
            </tr>
            <tr>
                <td>9</td>
                <td>$682</td>
                <td>503.10</td>
                <td>178.90</td>
                <td>32005.55</td>
                <td>40.64</td>
            </tr>
            <tr class="sums-row">
                <td><b>Sums: </b></td>
                <td>$10,731.00</td>
                <td>10,731.00</td>
                <td>0.00</td>
                <td>84,794.21</td>
                <td>119.88</td>
            </tr>
        </table>
        <p>
          The sum of the squared residuals is the sum of squares error, which is 84,794.21. The sum of squares for x is 119.88. Since we have only 
          1 independent variable (<MathEquation :equation="'x'"/>), the standard error of <MathEquation :equation="'\\hat{\\beta_1}'"/> is:
        </p>
        <div class="d-flex justify-content-center em1-25 mb-3">
          <MathEquation :equation="'SE_{\\beta_1} = \\sqrt{\\frac{84,794.21}{8-1-1}\\frac{1}{119.88}} = 10.858'"/>
        </div>
        <p>
          <MathEquation :equation="'n-k-1'"/> is also our degrees of freedom for the t-distribution. 
          With this, we can compute the t-statistic for the hypothesis test of <MathEquation :equation="'\\beta_1 = 0'"/>:
        </p>
        <div class="d-flex justify-content-center em1-25 mb-3">
          <MathEquation :equation="'t = \\frac{131.5 - 0}{10.858} = 12.11'"/>
        </div>
        <p>
          We don't need a p-value calculator to know that this coefficient is statistically significant. The t-statistic is well above the critical 
          value of 2.4469 for 95% confidence and 6 degrees of freedom. Even with so few degrees of freedom, it is highly unlikely that the linear 
          relationship between education and earnings is due to random chance. And at $131.50 per year, it is practically significant as well!
        </p>

        
        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>Use the same data below to estimate the following regression model: </p>
          <div class="d-flex justify-content-center"><MathEquation :equation="'y_i = \\beta_0 + \\beta_1x_i + \\varepsilon'"/></div>
          <div class="d-flex justify-content-center">
            <table class="problem-set-table">
              <tr>
                <th>x</th>
                <th>y</th>
              </tr>
              <tr v-for="(x, index) in problemSets.mod2.x" :key="index">
                <td>{{ x }}</td>
                <td>{{ problemSets.mod2.y[index] }}</td>
              </tr>
            </table>
          </div>
          <!-- Question 1 -->
          <b>Compute the Sum of Squared Error</b>
          <div class="input-group mb-3">
          <button ref="m3q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'SSE'"/></button>
          <input type="text" class="form-control" placeholder="Compute the Sum of Squared Error" id="m3q1" :value="textbookResponses.mod3.q1" :style="checkM3Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <b>Compute the Sum of Squared for X</b>
          <div class="input-group mb-3">
          <button ref="m3q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'SSX'"/></button>
          <input type="text" class="form-control" placeholder="Compute the Sum of Squared for X" id="m3q2" :value="textbookResponses.mod3.q2" :style="checkM3Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <b>Compute the Standard Error of Beta 1</b>
          <div class="input-group mb-3">
          <button ref="m3q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'SE_{\\beta_1}'"/></button>
          <input type="text" class="form-control" placeholder="Compute the Standard Error of Beta 1" id="m3q3" :value="textbookResponses.mod3.q3" :style="checkM3Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <b>Compute the T-Statistic</b>
          <div class="input-group mb-3">
          <button ref="m3q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'t'"/></button>
          <input type="text" class="form-control" placeholder="Compute the T-Statistic" id="m3q4" :value="textbookResponses.mod3.q4" :style="checkM3Q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
  
          <CalculatorLinks :score="mod3Score" :scoreTotal="8"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/kOJK_caJ2Yo" startAt="0"/>

      <!-- #region DATA CHANGER -->
      <div v-if="userData" class="data-changer">
        <div class="em1-50 fw600">Questions Reset</div>
        <div>You can reset the questions with randomly generated numbers for more practice.</div>
        <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
      </div>
      <!-- #endregion -->

    </div>
    <!-- EXPLORATORY LESSON -->
    <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="ExploratoryLessonIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Exploratory Lesson {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will use Ordinary Least Squares 
            to estimate a simple regression. We will also learn how to interpret the results of the regression and test the statistical 
            significance of the coefficients.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ lssnScore }} out of 20 points on this lesson.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/lesson'" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
      </div>
    </div>
    <!-- SPREADSHEET ASSIGNMENT -->
    <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="SpreadsheetAssignmentIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Spreadsheet Assignment {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will conduct several simple regressions and 
            run hypothesis tests on the coefficients.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ assnScore }} out of 30 points on this assignment.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/assignment'" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
      </div>
    </div>
  </div>
  
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, compare, renderEq, setUpStatsData, linearRegression } from '@/main';
  import { Popover } from 'bootstrap';
  import { normal, sumsqerr } from 'jstat';

  import MathEquation from '@/components/MathEquation.vue';
  import CalculatorLinks from '@/components/CalculatorLinks.vue';
  import VideoBox from '@/components/VideoBox.vue';
  import BarChart from '@/components/BarChart.vue';
  import RegressionChart from '@/components/RegressionChart.vue';
  
  import { create, all } from 'mathjs'
  const math = create(all);
  
  import probabilibot from '@/assets/Probabilibot.png';
  import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
  import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
  import RegressionErrors from '@/assets/RegressionErrors.jpg';
  
  export default {
    name: "statsMainPage",
    components: {
      MathEquation,
      CalculatorLinks,
      VideoBox,
      BarChart,
      RegressionChart,
    },
    props: ['tab'],
    data() {
      return {
        ch: 'ch11',
        userData: null,
        problemSets: null,
        textbookResponses: null,
        setUpStatsData,
        mod1Score: 0,
        mod2Score: 0,
        mod3Score: 0,
        assnScore: 0,
        lssnScore: 0,
        modalTitle: 'Loading...',
        modalMessage: '',
        probabilibot,
        ExploratoryLessonIcon,
        SpreadsheetAssignmentIcon,
        RegressionErrors,
        renderEq,
        scatterIncomeData: [
          { x: 21, y: 2083 },
          { x: 20, y: 2080 },
          { x: 18, y: 1661 },
          { x: 16, y: 1432 },
          { x: 14, y: 1005 },
          { x: 13, y: 935 },
          { x: 12, y: 853 },
          { x: 9, y: 682 },
          ],
      }
    },
    created() {
      this.loadUserInformation();
    },
    methods: {
      async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullProblemSet();
        await this.setPopovers();
      },
      async pullProblemSet() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        
        // ADD VALUES FOR THE CHAPTER PROBLEM SET
        if(data.stats.problemSets[this.ch].length == 0){
          await setDoc(docRef, {
            stats: {
              problemSets: {
                [this.ch]: this.problemSet(),
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
        if(Object.keys(data.stats.textbookResponses[this.ch]).length == 0){
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                  },
                  mod2: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                  },
                  mod3: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER GRADEBOOK IF MISSING
        if(!data.stats.gradeBook[this.ch].mod1){
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                [this.ch]: {
                  mod1: [0,0,0,0],
                  mod2: [0,0,0,0],
                  mod3: [0,0,0,0],
                  assignment: null,
                  lesson: null,
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        this.userData = data;
        this.problemSets = data.stats.problemSets[this.ch];
        this.textbookResponses = data.stats.textbookResponses[this.ch];
        this.mod1Score = math.sum(this.userData.stats.gradeBook[this.ch].mod1);
        this.mod2Score = math.sum(this.userData.stats.gradeBook[this.ch].mod2);
        this.mod3Score = math.sum(this.userData.stats.gradeBook[this.ch].mod3);
        this.assnScore = this.userData.stats.gradeBook[this.ch].assignment ? this.userData.stats.gradeBook[this.ch].assignment : 0;
        this.lssnScore = this.userData.stats.gradeBook[this.ch].lesson ? this.userData.stats.gradeBook[this.ch].lesson : 0;
      },
      async setPopovers() {
        await new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (this.userData !== null) {
                    clearInterval(intervalId);
                    resolve(this.userData);
                }
            }, 100); // Check every 100 milliseconds
        });
        const yHat = new Popover(this.$refs.yHat, {
          container: 'body',
          // title: 'Normal Distribution',
          content: `This is the predicted value of y based on the regression model we estimated: y = 131.5x - 680.35`,
          trigger: 'hover focus',
          placement: 'top',
          html: true,
        }); 
        const resid = new Popover(this.$refs.resid, {
          container: 'body',
          // title: 'Normal Distribution',
          content: `This is the difference between the actual value of y and the predicted value of y. We call it the 'residual', and it is our estimate of the error term (ε) in the model.`,
          trigger: 'hover focus',
          placement: 'top',
          html: true,
        });  
        const resid2 = new Popover(this.$refs.resid2, {
          container: 'body',
          // title: 'Normal Distribution',
          content: `This is the square of the residual.`,
          trigger: 'hover focus',
          placement: 'top',
          html: true,
        });
        const ssx = new Popover(this.$refs.ssx, {
          container: 'body',
          // title: 'Normal Distribution',
          content: `This is the squared difference between each value of x and the average value of x.`,
          trigger: 'hover focus',
          placement: 'top',
          html: true,
        });
        const m1q1 = new Popover(this.$refs.m1q1, {
          container: 'body',
          title: 'Predicted Values of Y',
          content: `Plug this value in for X and solve for Y.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m1q2 = new Popover(this.$refs.m1q2, {
          container: 'body',
          title: 'Predicted Values of Y',
          content: `Plug this value in for X and solve for Y.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m1q3 = new Popover(this.$refs.m1q3, {
          container: 'body',
          title: 'Predicted Values of Y',
          content: `Plug this value in for X and solve for Y.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m1q4 = new Popover(this.$refs.m1q4, {
          container: 'body',
          title: 'Predicted Values of Y',
          content: `Plug this value in for X and solve for Y.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q1 = new Popover(this.$refs.m2q1, {
          container: 'body',
          title: 'Ordinary Least Squares',
          content: `Estimate the coefficient by dividing the covariance of x and y by the variance of x. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q2 = new Popover(this.$refs.m2q2, {
          container: 'body',
          title: 'Ordinary Least Squares',
          content: `Estimate the y-intercept by subtracting the product of the coefficient and the mean of x from the mean of y. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q3 = new Popover(this.$refs.m2q3, {
          container: 'body',
          title: 'Ordinary Least Squares',
          content: `Estimate the predicted value of y by plugging in the value of x into the regression equation. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q4 = new Popover(this.$refs.m2q4, {
          container: 'body',
          title: 'Ordinary Least Squares',
          content: `Estimate the predicted value of y by plugging in the value of x into the regression equation. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m3q1 = new Popover(this.$refs.m3q1, {
          container: 'body',
          title: 'Sum of Squared Error',
          content: `This is the sum of the squared residuals. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m3q2 = new Popover(this.$refs.m3q2, {
          container: 'body',
          title: 'Sum of Squared for X',
          content: `This is the sum of the squared differences between each value of x and the average value of x. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m3q3 = new Popover(this.$refs.m3q3, {
          container: 'body',
          title: 'Standard Error of Beta 1',
          content: `This is the standard error of the coefficient for x. Divide the SSE by n-k-1 and multiply by 1/SSX. Then take the square root. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m3q4 = new Popover(this.$refs.m3q4, {
          container: 'body',
          title: 'T-Statistic',
          content: `This is the t-statistic for the hypothesis test of the coefficient for x. Divide the coefficient by the standard error of the coefficient. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        yHat; resid; resid2; ssx; m1q1; m1q2; m1q3; m1q4; m2q1; m2q2; m2q3; m2q4; m3q1; m3q2; m3q3; m3q4;
      },
      async resetAll() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            problemSets: {
              [this.ch]: this.problemSet()
            }
          }
        }, {merge: true});
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod1: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
                mod2: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
                mod3: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
              }
            }
          }
        }, {merge: true});
        const docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.problemSets = this.userData.stats.problemSets[this.ch];
      },
      problemSet() {
        const p1 = math.randomInt(1, 23);
        const p2 = math.randomInt(1, 23);
        while(p2 == p1){
          p2 = math.randomInt(1, 23);
        }
        const p3 = math.randomInt(1, 23);
        while(p3 == p1 || p3 == p2){
          p3 = math.randomInt(1, 23);
        }
        const p4 = math.randomInt(1, 23);
        while(p4 == p1 || p4 == p2 || p4 == p3){
          p4 = math.randomInt(1, 23);
        }
        const x = Array.from({length: 7}, () => Math.floor(Math.random() * 10) + 1);
        const x1 = math.randomInt(1, 10);
        let x2 = math.randomInt(1, 10);
        while(x2 == x1){
          x2 = math.randomInt(1, 10);
        }
        const b0 = math.randomInt(-10, 10);
        const b1 = math.randomInt(-10, 10);
        const y = math.round(x.map((val) => b0 + b1*val + normal.sample(0, 10)),0);

        return {
          mod1: [p1, p2, p3, p4],
          mod2: {
            x: x,
            y: y,
            x1: x1,
            x2: x2,
          },
        };
      },
      async computeM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = data.mod1[0]*131.5 - 680.35;
          const studValue = document.getElementById('m1q1').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `A person with ${data.mod1[0]} years of education can expect to earn $${trueValue.toFixed(2)} per week.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in ${data.mod1[0]} for x and solve for y.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = data.mod1[1]*131.5 - 680.35;
          const studValue = document.getElementById('m1q2').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `A person with ${data.mod1[1]} years of education can expect to earn $${trueValue.toFixed(2)} per week.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in ${data.mod1[1]} for x and solve for y.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = data.mod1[2]*131.5 - 680.35;
          const studValue = document.getElementById('m1q3').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `A person with ${data.mod1[2]} years of education can expect to earn $${trueValue.toFixed(2)} per week.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in ${data.mod1[2]} for x and solve for y.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = data.mod1[3]*131.5 - 680.35;
          const studValue = document.getElementById('m1q4').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `A person with ${data.mod1[3]} years of education can expect to earn $${trueValue.toFixed(2)} per week.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in ${data.mod1[3]} for x and solve for y.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = linearRegression(data.mod2.x, data.mod2.y)[1];
          const studValue = document.getElementById('m2q1').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The slope of the line is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Use the formula for beta 1 to calculate the slope of the line.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = linearRegression(data.mod2.x, data.mod2.y)[0];
          const studValue = document.getElementById('m2q2').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The y-intercept of the line is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Use the formula for beta 0 to calculate the y-intercept of the line.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const trueValue = math.add(beta[0],beta[1]*data.mod2.x1);
          const studValue = document.getElementById('m2q3').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `People with ${data.mod2.x2} for x tend to have around ${math.round(trueValue,2)} for y.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in ${data.mod2.x1} for x and solve for y.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const trueValue = math.add(beta[0],beta[1]*data.mod2.x2);
          const studValue = document.getElementById('m2q4').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `People with ${data.mod2.x2} for x tend to have around ${math.round(trueValue,2)} for y.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in ${data.mod2.x2} for x and solve for y.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y); 
          const yHat = math.add(beta[0], math.multiply(beta[1][0], data.mod2.x));
          const resid = math.subtract(data.mod2.y, yHat);
          const trueValue = sumsqerr(resid);
          const studValue = document.getElementById('m3q1').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The sum of squared errors is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the sum of squared errors by squaring each residual and summing them up.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = sumsqerr(data.mod2.x);
          const studValue = document.getElementById('m3q2').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The sum of squared x values is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the sum of squared x values by squaring each x value and summing them up.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const yHat = math.add(beta[0], math.multiply(beta[1][0], data.mod2.x));
          const resid = math.subtract(data.mod2.y, yHat);
          const sse =sumsqerr(resid);
          const ssx = sumsqerr(data.mod2.x);
          const dof = data.mod2.x.length - 2;
          const trueValue = math.sqrt((sse/dof)/ssx);
          const studValue = document.getElementById('m3q3').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The standard error of the coefficient is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the standard error of the coefficient by dividing the sum of squared errors by the degrees of freedom and the sum of squared x values. Then take the square root of that value.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const yHat = math.add(beta[0], math.multiply(beta[1][0], data.mod2.x));
          const resid = math.subtract(data.mod2.y, yHat);
          const sse =sumsqerr(resid);
          const ssx = sumsqerr(data.mod2.x);
          const dof = data.mod2.x.length - 2;
          const trueValue = beta[1][0] / math.sqrt((sse/dof)/ssx);
          const studValue = document.getElementById('m3q4').value;
          const result = compare(trueValue, studValue, 0.01) ? true : compare(-trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The t-statistic for the coefficient is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the t-statistic for the coefficient by dividing the coefficient by the standard error of the coefficient.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
    }, 
    computed: {
      checkM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = data.mod1[0]*131.5 - 680.35;
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q1;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = data.mod1[1]*131.5 - 680.35;
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q2;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = data.mod1[2]*131.5 - 680.35;
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q3;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = data.mod1[3]*131.5 - 680.35;
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q4;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = linearRegression(data.mod2.x, data.mod2.y)[1];
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q1;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = linearRegression(data.mod2.x, data.mod2.y)[0];
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q2;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const trueValue = math.add(beta[0],beta[1]*data.mod2.x1);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q3;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const trueValue = math.add(beta[0],beta[1]*data.mod2.x2);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q4;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const yHat = math.add(beta[0], math.multiply(beta[1][0], data.mod2.x));
          const resid = math.subtract(data.mod2.y, yHat);
          const trueValue = sumsqerr(resid);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q1;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = sumsqerr(data.mod2.x);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q2;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const yHat = math.add(beta[0], math.multiply(beta[1][0], data.mod2.x));
          const resid = math.subtract(data.mod2.y, yHat);
          const sse =sumsqerr(resid);
          const ssx = sumsqerr(data.mod2.x);
          const dof = data.mod2.x.length - 2;
          const trueValue = math.sqrt((sse/dof)/ssx);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q3;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const yHat = math.add(beta[0], math.multiply(beta[1][0], data.mod2.x));
          const resid = math.subtract(data.mod2.y, yHat);
          const sse =sumsqerr(resid);
          const ssx = sumsqerr(data.mod2.x);
          const dof = data.mod2.x.length - 2;
          const trueValue = beta[1][0] / math.sqrt((sse/dof)/ssx);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q4;
          const result = compare(trueValue, studValue, 0.01) ? true : compare(-trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
    }
  }
  </script>
  
<style scoped>
.income-table {
  width: 100%;
  border-collapse: collapse;
  margin: 6px 12px 6px 0px;
}
.income-table td, .income-table th{
  text-align: center;
  padding: 6px;
  border: 1px dashed var(--border_color);
}
.income-table tr:nth-child(even) {
  background-color: var(--off_white);
}

.problem-set-table {
  width: 300px;
  border-collapse: collapse;
  margin: 6px 12px 24px 0px;
}
.problem-set-table td, .problem-set-table th{
  text-align: center;
  padding: 6px;
  border: 1px dashed var(--border_color);
}
.problem-set-table tr:nth-child(even) {
  background-color: var(--off_white);
}
.sums-row td {
  border-top: 1px solid #000;
}

</style>