<template>

  <div class="container col-md-6 offset-md-3">
      
      <!-- #region CHAPTER AND TITLE -->
      <div class="container">
      <div class="book-title-div">
          <span class="fw600 em1-50">Chapter Five</span><br>
          <span class="fw800 em3 fc-stats">Please Be Discrete</span>
      </div>
      </div>
      <!-- #endregion -->
      
      <!-- SUB NAVIGATION BAR -->
      <nav>
      <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
          <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
          <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
          <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
      </div>
      </nav>
      
      <!-- SUB NAV BAR CONTENT -->
      <div class="tab-content" id="nav-tabContent">
      <!-- TEXTBOOK -->
      <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
  
        <VideoBox class="mt-4" videoTitle="Please Be Discrete" videoUrl="https://www.youtube.com/embed/q9I9gP90DBA" startAt="0"/>
  
          <!-- #region MODULE 1 -->
          <div v-if="userData" class="intructions-module rounded container">
              <h3 class="fc-stats">Binomial Distribution</h3>
              <p>
                When a sample consists of a fixed number of independent trials (<b>n</b>), and each trial results in one of two mutually exclusive and collectively 
                exhaustive outcomes, with each outcome having a consistent probability of occurrence (<b>p</b>) across all trials, then the probability of having 
                <b><i>x</i></b> successes out of <b><i>n</i></b> trials is described by the binomial distribution:
              </p>
              <p class="d-flex justify-content-center align-items-center" ref="bayesEquation">
                <span class="em1-25"><MathEquation :equation="'P(x|n,p) = '"/></span>
                <span class="em1-50"><MathEquation :equation="'\\frac{n!}{x!(n-x)!}'"/></span>
                <span class="em1-25"><MathEquation :equation="'p^x(1-p)^{n-x}'"/></span>
              </p>
              <p>
                As always, an example illuminates. Suppose we are going to flip a coin 10 times. Each flip is independent of the other, and we have a fixed number 
                of trials (10). Each trial can result in one of two outcomes: Heads <i>or</i> Tails. The probability of a flip resulting in Heads is the same across all 10 
                trials (50%).
              </p>
              <p>
                What is the probability we will see exactly 5 Heads and 5 Tails?
                <br><i>We will treat Heads as a 'success'.</i>
              </p>
              <p>
                <MathEquation :equation="'P(5|10,0.5) = \\frac{10!}{5!(10-5)!}0.5^5(1-0.5)^{10-5} = 0.2461 = 24.61\\%'"/>
              </p>
              <p>
                The probability of seeing exactly 5 Heads and 5 Tails is 24.61%.
              </p>
              <p>
                To get a deeper understanding of the binomial distribution, watch the video below. It is the first of a three part series. 
                You only need to watch this video, but if you're interested in the other two, they are linked in the description of the video.
              </p>

              <VideoBox videoUrl="https://www.youtube.com/embed/8idr1WZ1A7Q" videoTitle="Binomial Distributions" startAt="0" />

              <h4>The Mean and Variance</h4>
              <p>
                It has been a while since we have seen the mean and variance, but they are important to understand. The mean of a binomial distribution is 
                <MathEquation :equation="'\\mu = np'"/> and the variance is <MathEquation :equation="'\\sigma^2 = np(1-p)'"/>.
              </p>
              <p>
                For our coin flipping example, the mean is <MathEquation :equation="'\\mu = 10(0.5) = 5'"/> and the variance is 
                <MathEquation :equation="'\\sigma^2 = 10(0.5)(1-0.5) = 2.5'"/>. Of course, this means that the standard deviation of the distribution is 
                <MathEquation :equation="'\\sigma = \\sqrt{2.5} = 1.58'"/>. This helps us understand how spread out the distribution is. While we expect to see 
                5 Heads on average, a standard deviation of 1.58 means that we could see as few as 3 Heads or as many as 7 Heads and still be within approximately one standard 
                deviation of the mean.
              </p>

              <h4>Cumulative Distribution</h4>
              <p>
                The cumulative distribution is the probability of seeing a value less than or equal to a certain value. For example, the probability of seeing less than 
                5 Heads in our coin flipping example is the sum of the probabilities of seeing 0, 1, 2, 3, and 4 Heads. This is given by 
                <MathEquation :equation="'P(x \\le 4) = P(x=0) + P(x=1) + P(x=2) + P(x=3) + P(x=4)'"/>. This would be: 
              </p>
              <p>
                <MathEquation :equation="'P(x \\le 4) = 0.00098 + 0.00977 + 0.04395 + 0.11719 + 0.20508 = 0.37695 = 37.695\\%'"/>
              </p>
              <p>
                Conversely, the probability of seeing at least 5 Heads is the sum of the probabilities of seeing 5, 6, 7, 8, 9, and 10 Heads. Since we know the probabilities 
                from 0 to 10 must equal 1, we can find the probability of seeing at least 5 Heads by subtracting the probability of seeing less than 5 Heads from 1. This would be:
              </p>
              <p>
                <MathEquation :equation="'P(x \\ge 5) = 1 - P(x \\le 4) = 1 - 0.37695 = 0.62305 = 62.305\\%'"/>
              </p>
              <p>
                The cumulative distribution is an important tool, but it can be time consuming to calculate by hand. Fortunately, calculators and spreadsheets can do this 
                for us. For example, you can use WolframAlpha to find <MathEquation :equation="'P(x \\le 4)'"/> by entering 
                <a href="https://www.wolframalpha.com/input?i=P%5BX%3C%3D4%5D+for+X%7EB%2810%2C0.5%29" target="_blank">P[X&lt;=4] for X~B(10,0.5)</a> into their search bar. 
                Or, for <MathEquation :equation="'P(x \\ge 5)'"/>, you would enter 
                <a href="https://www.wolframalpha.com/input?i=P%5BX%3E%3D5%5D+for+X%7EB%2810%2C0.5%29" target="_blank">P[X>=5] for X~B(10,0.5)</a> into their search bar.
                
              </p>
  
              <div class="mt-3" v-if="userData">
                <h4 class="fc-prove">Prove Your Understanding</h4>
                <p>
                  A baker orders a dozen eggs. The probability that an egg is broken before it can be used is {{ userData.stats.problemSets.ch5.mod1.broken }}%. 
                  Let <MathEquation :equation="'x'"/> be the number of eggs that are <b>broken</b>. 
                  Using this information, compute each value below. 
                </p>
              </div>
              <!-- Question 1 -->
              <div class="input-group mb-3">
                <button ref="m1q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\mu'"/></button>
                <input type="text" class="form-control" placeholder="Calculate the expected value of the distribution" id="m1q1" :value="userData.stats.textbookResponses.ch5.mod1.q1" :style="checkM1Q1">
                <button class="btn btn-outline-secondary" type="button" @click="computeM1Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
              </div>
              <!-- Question 2 -->
              <div class="input-group mb-3">
                <button ref="m1q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\sigma^2'"/></button>
                <input type="text" class="form-control" placeholder="Calculate the variance of the distribution" id="m1q2" :value="userData.stats.textbookResponses.ch5.mod1.q2" :style="checkM1Q2">
                <button class="btn btn-outline-secondary" type="button" @click="computeM1Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
              </div>
              <!-- Question 3 -->
              <div class="input-group mb-3">
                <button ref="m1q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="`P(x=${userData.stats.problemSets.ch5.mod1.x1})`"/></button>
                <input type="text" class="form-control" :placeholder="'Calculate the probability of seeing exactly '+userData.stats.problemSets.ch5.mod1.x1+' broken eggs' " id="m1q3" :value="userData.stats.textbookResponses.ch5.mod1.q3" :style="checkM1Q3">
                <button class="btn btn-outline-secondary" type="button" @click="computeM1Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
              </div>
              <!-- Question 4 -->
              <div class="input-group mb-3">
                <button ref="m1q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="`P(x<${userData.stats.problemSets.ch5.mod1.x2})`"/></button>
                <input type="text" class="form-control" :placeholder="'Calculate the probability of seeing less than '+userData.stats.problemSets.ch5.mod1.x2+' broken eggs' " id="m1q4" :value="userData.stats.textbookResponses.ch5.mod1.q4" :style="checkM1Q4">
                <button class="btn btn-outline-secondary" type="button" @click="computeM1Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
              </div>
              <!-- Question 5 -->
              <div class="input-group mb-3">
                <button ref="m1q5" class="input-group-text" style="width: 110px;"><MathEquation :equation="`P(x \\ge ${userData.stats.problemSets.ch5.mod1.x3})`"/></button>
                <input type="text" class="form-control" :placeholder="'Calculate the probability of seeing at least '+userData.stats.problemSets.ch5.mod1.x3+' broken eggs' " id="m1q5" :value="userData.stats.textbookResponses.ch5.mod1.q5" :style="checkM1Q5">
                <button class="btn btn-outline-secondary" type="button" @click="computeM1Q5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
              </div>
  
              <CalculatorLinks :score="mod1Score" :scoreTotal="10"/>
          </div> 
          <!-- #endregion -->
          
          <!-- #region MODULE 2 -->
          <div v-if="userData" class="intructions-module rounded container">
              <h3 class="fc-stats">Poisson Distribution</h3>
              <p>
                What if we want to know the probability that we will have a certain number of customers come into our store over the next hour? 
                Or the probability that there will be a car accident today along a certain stretch of road? Thanks to Siméon Denis Poisson, those 
                problems are a lot more tractable than they seem.
              </p>
              <p>
                For Poisson's distribution to apply, we need an 'area of opportunity' such as a period of time, a length, and area of space, or something like that. 
                The probability of an event occurring needs to be uniform across that area (e.g. the probability of an accident is the same at every point along the 
                stretch of road, there are no spots more dangerous than others). And if an event happens in one area, it doesn't change the probability of it happening 
                elsewhere. When this is the case, the probability of seeing an event x times over a given area of opportunity, when we expect to see that event 
                <b>λ</b> times, is:
              </p>
              <p class="d-flex justify-content-center align-items-center" ref="bayesEquation">
                <span class="em1-25"><MathEquation :equation="'P(x|\\lambda) = '"/></span>
                <span class="em1-50"><MathEquation :equation="'\\frac{e^{-\\lambda}\\lambda^x}{x!}'"/></span>
              </p>
              <p>
                If we have a 5 mile stretch of highway which typically sees 73 accidents per year, what is the probability it will see an accident today?
              </p>
              <p>
                Our first step is to calculate 𝜆, which is the expected number of events in the space. If there are 73 accidents per year, then we would 
                expect to see 0.2 accidents per day (73/365 = 0.2). So, 𝜆 = 0.2. We want to know the probability of 1 accident, so 𝑥 = 1. And that is all 
                we need to calculate this probability:
              </p>
              <p>
                <MathEquation :equation="'P(1|0.2) = \\frac{e^{-0.2}0.2^1}{1!} \\approx 0.1637 \\approx 16.37\\%'"/>
              </p>
              <p>
                There is a 16.37% chance that there will be 1 accident along that stretch of freeway today.
              </p>
              <p>
                If you feel good about that, then you can probably skip the video below. But if you want someone to explain all of this, then watch the video below.
              </p>

              <VideoBox videoUrl="https://www.youtube.com/embed/cPOChr_kuQs" videoTitle="Poisson Distributions" startAt="0" />

              <h4>The Mean and Variance</h4>
              <p>
                The Poisson distribution is interesting because the mean, 𝜆, is part of the calculation. But even more interesting is that the variance is also 𝜆!
              </p>
              <p>
                For our accident example, the mean is <MathEquation :equation="'\\mu = 0.2'"/> and the variance is <MathEquation :equation="'\\sigma^2 = 0.2'"/>. This means that 
                the standard deviation of the distribution is <MathEquation :equation="'\\sigma = \\sqrt{0.2} = 0.45'"/>. This helps us understand how spread out the distribution is. 
                While we expect to see 0.2 accidents on average, a standard deviation of 0.45 means that we could see as few as 0 accidents or as many as 1 accident and still be 
                within approximately one standard deviation of the mean.
              </p>

              <h4>Cumulative Distribution</h4>
              <p>
                The cumulative distribution for the Poisson distribution is the probability of seeing a value less than or equal to a certain value. For example, the 
                probability of seeing less than 5 accidents in a day when we expect to see 0.2 accidents is:
              </p>
              <p>
                <MathEquation :equation="'P(x \\le 4) = P(x=0) + P(x=1) + P(x=2) + P(x=3) + P(x=4)'"/>
              </p>
              <p>
                This would be:
              </p>
              <p>
                <MathEquation :equation="'P(x \\le 4) = 0.8187 = 81.87\\%'"/>
              </p>
              <p>
                Conversely, the probability of seeing at least 5 accidents in a day when we expect to see 0.2 accidents is:
              </p>
              <p>
                <MathEquation :equation="'P(x \\ge 5) = 1 - P(x \\le 4) = 1 - 0.8187 = 0.1813 = 18.13\\%'"/>
              </p>
              <p>
                The cumulative distribution is an important tool, but it can be time consuming to calculate by hand. Fortunately, calculators and spreadsheets can do this 
                for us. For example, you can use WolframAlpha to find <MathEquation :equation="'P(x \\le 4)'"/> by entering 
                <a href="https://www.wolframalpha.com/input?i=P%5BX%3C%3D1%5D+for+X%7EPo%280.2%29" target="_blank">P[X&lt;=1] for X~Po(0.2)</a> into their search bar. 
                Or, for <MathEquation :equation="'P(x \\ge 5)'"/>, you would enter 
                <a href="https://www.wolframalpha.com/input?i=P%5BX%3E%3D2%5D+for+X%7EPo%280.2%29" target="_blank">P[X>=2] for X~Po(0.2)</a> into their search bar.
              </p>
  
              <div class="mt-3" v-if="userData">
                <h4 class="fc-prove">Prove Your Understanding</h4>
                <p>
                  A Parisian boulanger is well known to locals for their delicious <i>Pain au chocolat</i>. On average, they sell 
                  {{ userData.stats.problemSets.ch5.mod2.lambda }} of the pastries per hour.
                  Using this information, compute each value below. 
                </p>
              </div>
              <!-- Question 1 -->
              <div class="input-group mb-3">
                <button ref="m2q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\mu'"/></button>
                <input type="text" class="form-control" placeholder="Calculate the expected value of the distribution" id="m2q1" :value="userData.stats.textbookResponses.ch5.mod2.q1" :style="checkM2Q1">
                <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
              </div>
              <!-- Question 2 -->
              <div class="input-group mb-3">
                <button ref="m2q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\sigma^2'"/></button>
                <input type="text" class="form-control" placeholder="Calculate the variance of the distribution" id="m2q2" :value="userData.stats.textbookResponses.ch5.mod2.q2" :style="checkM2Q2">
                <button class="btn btn-outline-secondary" type="button" @click="computeM2Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
              </div>
              <!-- Question 3 -->
              <div class="input-group mb-3">
                <button ref="m2q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="`P(x=${userData.stats.problemSets.ch5.mod2.x1})`"/></button>
                <input type="text" class="form-control" :placeholder="'Calculate the probability of selling exactly '+userData.stats.problemSets.ch5.mod2.x1+' pastries' " id="m2q3" :value="userData.stats.textbookResponses.ch5.mod2.q3" :style="checkM2Q3">
                <button class="btn btn-outline-secondary" type="button" @click="computeM2Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
              </div>
              <!-- Question 4 -->
              <div class="input-group mb-3">
                <button ref="m2q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="`P(x<${userData.stats.problemSets.ch5.mod2.x2})`"/></button>
                <input type="text" class="form-control" :placeholder="'Calculate the probability of selling less than '+userData.stats.problemSets.ch5.mod2.x2+' pastries' " id="m2q4" :value="userData.stats.textbookResponses.ch5.mod2.q4" :style="checkM2Q4">
                <button class="btn btn-outline-secondary" type="button" @click="computeM2Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
              </div>
              <!-- Question 5 -->
              <div class="input-group mb-3">
                <button ref="m2q5" class="input-group-text" style="width: 110px;"><MathEquation :equation="`P(x \\ge ${userData.stats.problemSets.ch5.mod2.x3})`"/></button>
                <input type="text" class="form-control" :placeholder="'Calculate the probability of selling at least '+userData.stats.problemSets.ch5.mod2.x3+' pastries' " id="m2q5" :value="userData.stats.textbookResponses.ch5.mod2.q5" :style="checkM2Q5">
                <button class="btn btn-outline-secondary" type="button" @click="computeM2Q5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
              </div>
  
              <CalculatorLinks :score="mod2Score" :scoreTotal="10"/>
          </div> 
          <!-- #endregion -->
          
          <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/dv3qtu6WtL4" startAt="0"/>
          
          <!-- #region DATA CHANGER -->
          <div v-if="userData" class="data-changer">
            <div class="em1-50 fw600">Questions Reset</div>
            <div>You can reset the questions with randomly generated numbers for more practice.</div>
            <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
          </div>
          <!-- #endregion -->

      </div>
      <!-- EXPLORATORY LESSON -->
      <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
          <div class="row mt-4">
          <div class="col-4">
              <img :src="ExploratoryLessonIcon" width="100%">
          </div>
          <div class="col-8">
              <h3 class="fc-stats">Exploratory Lesson 5</h3>
              <div style="text-align: justify;">
                The button below will open your exploratory lesson. In this week's Exploratory Lesson, 
                we will calculate probabilities using the Binomial and Poisson distributions, and we will 
                create charts to visualize those distributions.
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
              You have scored {{ lssnScore }} out of 20 points on this lesson.
              </div>
          </div>
      
          </div>
          <div class="d-flex align-items-center justify-content-center mt-4">
          <router-link to="/stats/5/lesson" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
          </div>
      </div>
      <!-- SPREADSHEET ASSIGNMENT -->
      <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
          <div class="row mt-4">
          <div class="col-4">
              <img :src="SpreadsheetAssignmentIcon" width="100%">
          </div>
          <div class="col-8">
              <h3 class="fc-stats">Spreadsheet Assignment 5</h3>
              <div style="text-align: justify;">
                <p>
                  The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will apply the Binomial distribution and the 
                  Poisson distribution to real-world problems. 
                </p>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
              You have scored {{ assnScore }} out of 30 points on this assignment.
              </div>
          </div>
      
          </div>
          <div class="d-flex align-items-center justify-content-center mt-4">
          <router-link to="/stats/5/assignment" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
          </div>
      </div>

    </div>
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
  import { Popover } from 'bootstrap';

  import MathEquation from '@/components/MathEquation.vue';
  import CalculatorLinks from '@/components/CalculatorLinks.vue';
  import VideoBox from '@/components/VideoBox.vue';
  
  import { create, all } from 'mathjs'
  const math = create(all);
  
  import probabilibot from '@/assets/Probabilibot.png';
  import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
  import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
  
  export default {
    name: "statsMainPage",
    components: {
      MathEquation,
      CalculatorLinks,
      VideoBox,
    },
    props: ['tab'],
    data() {
      return {
        userData: null,
        setUpStatsData,
        mod1Score: 0,
        mod2Score: 0,
        mod3Score: 0,
        assnScore: 0,
        lssnScore: 0,
        modalTitle: 'Loading...',
        modalMessage: '',
        probabilibot,
        ExploratoryLessonIcon,
        SpreadsheetAssignmentIcon,
        renderEq,
        compare,
      }
    },
    created() {
      this.loadUserInformation();    
    },
    methods: {
      async loadUserInformation() {
          await this.setUpStatsData();
          await this.pullProblemSet();
          await this.setPopovers();
      },
      async pullProblemSet() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        
        // ADD VALUES FOR THE CHAPTER PROBLEM SET
        if(Object.keys(data.stats.problemSets.ch5).length == 0){
          await setDoc(docRef, {
            stats: {
              problemSets: {
                ch5: {
                  mod1: {
                    broken: math.randomInt(50, 100)/10,
                    x1: math.randomInt(0, 5),
                    x2: math.randomInt(2, 5),
                    x3: math.randomInt(3, 6),
                  },
                  mod2: {
                    lambda: math.randomInt(200, 500)/10,
                    x1: math.randomInt(20, 50),
                    x2: math.randomInt(20, 50),
                    x3: math.randomInt(20, 50),
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
        if(Object.keys(data.stats.textbookResponses.ch5).length == 0){
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod1: {
                      q1: null,
                      q2: null,
                      q3: null,
                      q4: null,
                      q5: null,
                  },
                  mod2: {
                      q1: null,
                      q2: null,
                      q3: null,
                      q4: null,
                      q5: null,
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER GRADEBOOK IF MISSING
        if(!data.stats.gradeBook.ch5.mod1){
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                ch5: {
                  mod1: [0,0,0,0,0],
                  mod2: [0,0,0,0,0],
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        this.userData = data;
        this.mod1Score = math.sum(this.userData.stats.gradeBook.ch5.mod1);
        this.mod2Score = math.sum(this.userData.stats.gradeBook.ch5.mod2);
        this.assnScore = this.userData.stats.gradeBook.ch5.assignment ? this.userData.stats.gradeBook.ch5.assignment : 0;
        this.lssnScore = this.userData.stats.gradeBook.ch5.lesson ? this.userData.stats.gradeBook.ch5.lesson : 0;
      },
      async setPopovers() {
        await new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (this.userData !== null) {
                    clearInterval(intervalId);
                    resolve(this.userData);
                }
            }, 100); // Check every 100 milliseconds
        });
        const m1q1 = new Popover(this.$refs.m1q1, {
          container: 'body',
          title: `Binomial Distribution`,
          content: `The mean of a binomial distribution is given by the formula ${renderEq('\\mu = np')}. This is the expected value of the distribution.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m1q2 = new Popover(this.$refs.m1q2, {
          container: 'body',
          title: `Binomial Distribution`,
          content: `The variance of a binomial distribution is given by the formula ${renderEq('\\sigma^2 = np(1-p)')}. <br><br><b>Your answer should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m1q3 = new Popover(this.$refs.m1q3, {
          container: 'body',
          title: `Binomial Distribution`,
          content: `The probability of seeing exactly ${this.userData.stats.problemSets.ch5.mod1.x1} broken eggs can be found using the binomial distribution, where ${renderEq(`n = 12`)}, ${renderEq(`x = ${this.userData.stats.problemSets.ch5.mod1.x1}`)}, and ${renderEq(`p = ${math.round(this.userData.stats.problemSets.ch5.mod1.broken/100,3)}`)}. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to SIX decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m1q4 = new Popover(this.$refs.m1q4, {
          container: 'body',
          title: `Binomial Distribution`,
          content: `The probability of seeing less than ${this.userData.stats.problemSets.ch5.mod1.x2} broken eggs can be found using the binomial distribution, where ${renderEq(`n = 12`)}, ${renderEq(`x < ${this.userData.stats.problemSets.ch5.mod1.x2}`)}, and ${renderEq(`p = ${math.round(this.userData.stats.problemSets.ch5.mod1.broken/100,3)}`)}. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to SIX decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m1q5 = new Popover(this.$refs.m1q5, {
          container: 'body',
          title: `Binomial Distribution`,
          content: `The probability of seeing at least ${this.userData.stats.problemSets.ch5.mod1.x3} broken eggs can be found using the binomial distribution, where ${renderEq(`n = 12`)}, ${renderEq(`x \\ge ${this.userData.stats.problemSets.ch5.mod1.x3}`)}, and ${renderEq(`p = ${math.round(this.userData.stats.problemSets.ch5.mod1.broken/100,3)}`)}. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to SIX decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q1 = new Popover(this.$refs.m2q1, {
          container: 'body',
          title: `Poisson Distribution`,
          content: `The mean of a Poisson distribution is given by the formula ${renderEq('\\mu = \\lambda')}. ${renderEq('\\lambda')} is the expected value of the distribution.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q2 = new Popover(this.$refs.m2q2, {
          container: 'body',
          title: `Poisson Distribution`,
          content: `The variance of a Poisson distribution is given by the formula ${renderEq('\\sigma^2 = \\lambda')}. ${renderEq('\\lambda')} is the expected value of the distribution.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q3 = new Popover(this.$refs.m2q3, {
          container: 'body',
          title: `Poisson Distribution`,
          content: `The probability of selling exactly ${this.userData.stats.problemSets.ch5.mod2.x1} pastries can be found using the Poisson distribution, where ${renderEq(`\\lambda = ${this.userData.stats.problemSets.ch5.mod2.lambda}`)} and ${renderEq(`x = ${this.userData.stats.problemSets.ch5.mod2.x1}`)}. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to SIX decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q4 = new Popover(this.$refs.m2q4, {
          container: 'body',
          title: `Poisson Distribution`,
          content: `The probability of selling less than ${this.userData.stats.problemSets.ch5.mod2.x2} pastries can be found using the Poisson distribution, where ${renderEq(`\\lambda = ${this.userData.stats.problemSets.ch5.mod2.lambda}`)} and ${renderEq(`x < ${this.userData.stats.problemSets.ch5.mod2.x2}`)}. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to SIX decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q5 = new Popover(this.$refs.m2q5, {
          container: 'body',
          title: `Poisson Distribution`,
          content: `The probability of selling at least ${this.userData.stats.problemSets.ch5.mod2.x3} pastries can be found using the Poisson distribution, where ${renderEq(`\\lambda = ${this.userData.stats.problemSets.ch5.mod2.lambda}`)} and ${renderEq(`x \\ge ${this.userData.stats.problemSets.ch5.mod2.x3}`)}. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to SIX decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });

        m1q1; m1q2; m1q3; m1q4; m1q5; m2q1; m2q2; m2q3; m2q4; m2q5;
      },
      async resetAll() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            problemSets: {
              ch5: {
                  mod1: {
                    broken: math.randomInt(50, 100)/10,
                    x1: math.randomInt(0, 5),
                    x2: math.randomInt(2, 5),
                    x3: math.randomInt(3, 6),
                  },
                  mod2: {
                    lambda: math.randomInt(200, 500)/10,
                    x1: math.randomInt(20, 50),
                    x2: math.randomInt(20, 50),
                    x3: math.randomInt(20, 50),
                  },
                }
            }
          }
        }, {merge: true});
        await setDoc(docRef, {
          stats: {
              textbookResponses: {
                ch5: {
                  mod1: {
                      q1: null,
                      q2: null,
                      q3: null,
                      q4: null,
                      q5: null,
                  },
                  mod2: {
                      q1: null,
                      q2: null,
                      q3: null,
                      q4: null,
                      q5: null,
                  },
                }
              }
          }
        }, {merge: true});
        const docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
      },
      binomial(n, x, p) {
        return math.combinations(n, x) * math.pow(p, x) * math.pow(1-p, n-x);
      },
      poisson(l, x) {
        return math.pow(l, x) * math.exp(-l) / math.factorial(x);
      },
      cumulativeBinomial(n, x, p) {
        let sum = 0;
        for(let i = 0; i <= x; i++){
          sum += this.binomial(n, i, p);
        }
        return sum;
      },
      cumulativePoission(l, x) {
        let sum = 0;
        for(let i = 0; i <= x; i++){
          sum += this.poisson(l, i);
        }
        return sum;
      },
      async computeM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const broken = data.mod1.broken/100;
          const trueValue = 12*broken;
          const studValue = document.getElementById('m1q1').value;
          let result = compare(trueValue, studValue, 0.0001);
          if(result && !studValue.toString().includes('%')){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The expected value of the distribution is given by ${renderEq(`\\mu = np = 12 \\times ${math.round(broken,3)} = ${math.round(trueValue,3)}`)}`;
          } else if(studValue.toString().includes('%')) {
            result = false;
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The expected value of the distribution is given by ${renderEq(`\\mu = np`)}. This is not a percentage.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The expected value of the distribution is given by ${renderEq(`\\mu = np`)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch5.mod1;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod1: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                ch5: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const broken = data.mod1.broken/100;
          const trueValue = 12*broken*(1-broken);
          const studValue = document.getElementById('m1q2').value;
          let result = compare(trueValue, studValue, 0.0001);
          if(result && !studValue.toString().includes('%')){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The variance of the distribution is given by ${renderEq(`\\sigma^2 = np(1-p) = 12 \\times ${math.round(broken,3)} \\times ${math.round(1-broken,3)} \\approx ${math.round(trueValue,4)}`)}`;
          } else if(studValue.toString().includes('%')){
            result = false;
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The variance of the distribution is given by ${renderEq(`\\sigma^2 = np(1-p)`)}. This is not a percentage.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The variance of the distribution is given by ${renderEq(`\\sigma^2 = np(1-p)`)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch5.mod1;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod1: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                ch5: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const broken = data.mod1.broken/100;
          const trueValue = this.binomial(12, data.mod1.x1, broken);
          const studValue = document.getElementById('m1q3').value;
          const result = compare(trueValue, studValue, 0.000001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability of seeing exactly ${data.mod1.x1} broken eggs is given by ${renderEq(`P(x=${data.mod1.x1}) = \\frac{12!}{${data.mod1.x1}!(12-${data.mod1.x1})!} \\times ${math.round(broken,3)}^{${data.mod1.x1}} \\times ${math.round(1-broken,3)}^{12-${data.mod1.x1}} = ${math.round(trueValue,6)} \\approx ${math.round(trueValue*100,4)}\\%`)}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The probability of seeing exactly ${data.mod1.x1} broken eggs can be found using the binomial distribution where ${renderEq(`n = 12`)}, ${renderEq(`x = ${data.mod1.x1}`)}, and ${renderEq(`p = ${math.round(broken,3)}`)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch5.mod1;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod1: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                ch5: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const broken = data.mod1.broken/100;
          const trueValue = this.cumulativeBinomial(12, data.mod1.x2-1, broken);
          const studValue = document.getElementById('m1q4').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability of seeing less than ${data.mod1.x2} broken eggs is given by ${renderEq(`P(x<${data.mod1.x2}) = \\sum_{i=0}^{${data.mod1.x2-1}} \\frac{12!}{i!(12-i)!} \\times ${math.round(broken,3)}^i \\times ${math.round(1-broken,3)}^{12-i} = ${math.round(trueValue,6)} \\approx ${math.round(trueValue*100,4)}\\%`)}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The probability of seeing less than ${data.mod1.x2} broken eggs can be found using the binomial distribution where ${renderEq(`n = 12`)}, ${renderEq(`x < ${data.mod1.x2}`)}, and ${renderEq(`p = ${math.round(broken,3)}`)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch5.mod1;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod1: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                ch5: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const broken = data.mod1.broken/100;
          const trueValue = 1 - this.cumulativeBinomial(12, data.mod1.x3-1, broken);
          const studValue = document.getElementById('m1q5').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability of seeing at least ${data.mod1.x3} broken eggs is given by ${renderEq(`P(x \\ge ${data.mod1.x3}) = 1 - \\sum_{i=0}^{${data.mod1.x3-1}} \\frac{12!}{i!(12-i)!} \\times ${math.round(broken,3)}^i \\times ${math.round(1-broken,3)}^{12-i} = ${math.round(trueValue,6)} \\approx ${math.round(trueValue*100,4)}\\%`)}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The probability of seeing at least ${data.mod1.x3} broken eggs can be found using the binomial distribution where ${renderEq(`n = 12`)}, ${renderEq(`x \\ge ${data.mod1.x3}`)}, and ${renderEq(`p = ${math.round(broken,3)}`)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch5.mod1;
          if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod1: {
                    q5: studValue,
                  },
                }
              },
              gradeBook: {
                ch5: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const lambda = data.mod2.lambda;
          const trueValue = lambda;
          const studValue = document.getElementById('m2q1').value;
          let result = compare(trueValue, studValue, 0.0001);
          if(result && !studValue.toString().includes('%')){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The expected value of the distribution is given by ${renderEq(`\\mu = \\lambda = ${lambda}`)}`;
          } else if(studValue.toString().includes('%')) {
            result = false;
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The expected value of the distribution is given by ${renderEq(`\\mu = \\lambda`)}. This is not a percentage.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The expected value of the distribution is given by ${renderEq(`\\mu = \\lambda`)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch5.mod2;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod2: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                ch5: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const lambda = data.mod2.lambda;
          const trueValue = lambda;
          const studValue = document.getElementById('m2q2').value;
          let result = compare(trueValue, studValue, 0.0001);
          if(result && !studValue.toString().includes('%')){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The variance of the distribution is given by ${renderEq(`\\sigma^2 = \\lambda = ${lambda}`)}`;
          } else if(studValue.toString().includes('%')){
            result = false;
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The variance of the distribution is given by ${renderEq(`\\sigma^2 = \\lambda`)}. This is not a percentage.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The variance of the distribution is given by ${renderEq(`\\sigma^2 = \\lambda`)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch5.mod2;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod2: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                ch5: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const lambda = data.mod2.lambda;
          const trueValue = this.poisson(lambda, data.mod2.x1);
          const studValue = document.getElementById('m2q3').value;
          const result = compare(trueValue, studValue, 0.000001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability of selling exactly ${data.mod2.x1} pastries is given by <br>${renderEq(`P(X=${data.mod2.x1}) = \\frac{${lambda}^{${data.mod2.x1}}e^{-${lambda}}}{${data.mod2.x1}!} = ${math.round(trueValue,6)} \\approx ${math.round(trueValue*100,4)}\\%`)}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The probability of selling exactly ${data.mod2.x1} pastries can be found using the Poisson distribution where ${renderEq(`\\lambda = ${lambda}`)} and ${renderEq(`x = ${data.mod2.x1}`)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch5.mod2;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod2: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                ch5: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const lambda = data.mod2.lambda;
          const trueValue = this.cumulativePoission(lambda, data.mod2.x2-1);
          const studValue = document.getElementById('m2q4').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability of selling less than ${data.mod2.x2} pastries is given by <br>${renderEq(`P(x<${data.mod2.x2}) = \\sum_{i=0}^{${data.mod2.x2-1}} \\frac{${lambda}^i e^{-${lambda}}}{i!} = ${math.round(trueValue,6)} \\approx ${math.round(trueValue*100,4)}\\%`)}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The probability of selling less than ${data.mod2.x2} pastries can be found using the Poisson distribution where ${renderEq(`\\lambda = ${lambda}`)} and ${renderEq(`x < ${data.mod2.x2}`)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch5.mod2;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod2: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                ch5: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const lambda = data.mod2.lambda;
          const trueValue = 1 - this.cumulativePoission(lambda, data.mod2.x3-1);
          const studValue = document.getElementById('m2q5').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability of selling at least ${data.mod2.x3} pastries is given by <br>${renderEq(`P(x \\ge ${data.mod2.x3}) = 1 - \\sum_{i=0}^{${data.mod2.x3-1}} \\frac{${lambda}^i e^{-${lambda}}}{i!} = ${math.round(trueValue,6)} \\approx ${math.round(trueValue*100,4)}\\%`)}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The probability of selling at least ${data.mod2.x3} pastries can be found using the Poisson distribution where ${renderEq(`\\lambda = ${lambda}`)} and ${renderEq(`x \\ge ${data.mod2.x3}`)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch5.mod2;
          if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch5: {
                  mod2: {
                    q5: studValue,
                  },
                }
              },
              gradeBook: {
                ch5: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
    }, 
    computed: {
      checkM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const broken = data.mod1.broken/100;
          const trueValue = 12*broken;
          const studValue = this.userData.stats.textbookResponses.ch5.mod1.q1;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result && !studValue.toString().includes('%')){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const broken = data.mod1.broken/100;
          const trueValue = 12*broken*(1-broken);
          const studValue = this.userData.stats.textbookResponses.ch5.mod1.q2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result && !studValue.toString().includes('%')){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const broken = data.mod1.broken/100;
          const trueValue = this.binomial(12, data.mod1.x1, broken);
          const studValue = this.userData.stats.textbookResponses.ch5.mod1.q3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const broken = data.mod1.broken/100;
          const trueValue = this.cumulativeBinomial(12, data.mod1.x2-1, broken);
          const studValue = this.userData.stats.textbookResponses.ch5.mod1.q4;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const broken = data.mod1.broken/100;
          const trueValue = 1 - this.cumulativeBinomial(12, data.mod1.x3-1, broken);
          const studValue = this.userData.stats.textbookResponses.ch5.mod1.q5;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const lambda = data.mod2.lambda;
          const trueValue = lambda;
          const studValue = this.userData.stats.textbookResponses.ch5.mod2.q1;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result && !studValue.toString().includes('%')){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const lambda = data.mod2.lambda;
          const trueValue = lambda;
          const studValue = this.userData.stats.textbookResponses.ch5.mod2.q2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result && !studValue.toString().includes('%')){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const lambda = data.mod2.lambda;
          const trueValue = this.poisson(lambda, data.mod2.x1);
          const studValue = this.userData.stats.textbookResponses.ch5.mod2.q3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const lambda = data.mod2.lambda;
          const trueValue = this.cumulativePoission(lambda, data.mod2.x2-1);
          const studValue = this.userData.stats.textbookResponses.ch5.mod2.q4;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch5;
          const lambda = data.mod2.lambda;
          const trueValue = 1 - this.cumulativePoission(lambda, data.mod2.x3-1);
          const studValue = this.userData.stats.textbookResponses.ch5.mod2.q5;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
    },
    mounted() {
      
    },
  }
  </script>
  
<style scoped>

</style>