<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th></th>
          <th v-for="col in columns" :key="col" :class="getCellClass(-1, columns.indexOf(col))">{{ col }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
          <th :class="getCellClass(rowIndex, -1)">{{ row.header }}</th>
          <td
            v-for="(cell, colIndex) in row.data"
            :key="colIndex"
            :class="getCellClass(rowIndex, colIndex)"
            @mouseover="hoverCell(rowIndex, colIndex)"
            @mouseleave="clearHover"
          >
            {{ cell.toFixed(4) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { create, all } from 'mathjs';

const math = create(all);

export default {
  data() {
    return {
      columns: this.generateColumns(),
      rows: this.generateRows(),
      hoverRow: null,
      hoverCol: null
    };
  },
  methods: {
    generateColumns() {
      const cols = [];
      for (let i = 0; i <= 9; i++) {
        let colVal = i/100;
        cols.push(colVal.toFixed(2));
      }
      return cols;
    },
    generateRows() {
      const rows = [];
      for (let i = -3; i <= 3.05; i+=0.1) {
        const row = {
          header: i.toFixed(1),
          data: this.calculateZValues(i.toFixed(1))
        };
        rows.push(row);
      }
      return rows;
    },
    calculateZValues(rowHeader) {
      const values = [];
      const baseValue = parseFloat(rowHeader);
      for (let i = 0; i <= 9; i++) {
        const zValue = baseValue < 0 ? baseValue - parseFloat(`0.0${i}`) : baseValue + parseFloat(`0.0${i}`);
        values.push(this.standardNormalCDF(zValue));
      }
      return values;
    },
    standardNormalCDF(z) {
      return (1.0 + math.erf(z / math.sqrt(2))) / 2.0;
    },
    hoverCell(rowIndex, colIndex) {
      this.hoverRow = rowIndex;
      this.hoverCol = colIndex;
    },
    clearHover() {
      this.hoverRow = null;
      this.hoverCol = null;
    },
    getCellClass(rowIndex, colIndex) {
      if (this.hoverRow === rowIndex && this.hoverCol === colIndex) {
        return 'highlight';
      } else if (this.hoverRow === rowIndex && this.hoverCol > colIndex) {
        return 'highlight-light';
      } else if (this.hoverCol === colIndex && this.hoverRow > rowIndex) {
        return 'highlight-light';
      }
      return '';
    }
  }
};
</script>

<style scoped>
.table-container {
  max-height: 600px;
  overflow-y: auto;
  border: 1px solid #ccc;
  position: relative;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th, td {
  border: 1px solid black;
  padding: 1px 8px;
  text-align: center;
}
thead th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}
.highlight {
  background-color: gray;
}
.highlight-light {
  background-color: lightgray;
}
</style>
