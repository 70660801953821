<template>
  <div class="container">
    <div class="header-image">
      <img :src="headerImage" class="rounded"/>
    </div>
    <div class="container main-page-container rounded">
      <p>
        <span class="fw600 em2">Welcome to Learn ProGo!</span><br>
        <span>
          Here you can access all of the materials you will need to <strong>Learn</strong> in <strong>Pro</strong>fessor Brian <strong>Go</strong>egan's courses.
          All of the learning resources for my courses are provided to students for free. If you are considering taking one of my courses, feel free to check them out by clicking 
          on them below. 
        </span>
      </p>
    </div>
    <div class="container courses-container">
      <div class="row">
        <div class="col course-container-left rounded">
          <span class="fw600 em2">ECN 211</span>
          <span class="em1-25 fw600"> Macroeconomic Principles</span>
          <div class="main-page-course-logo">
            <img :src="macroLogo" class="rounded"/>
          </div>
        </div>
        <div class="col course-container-mid rounded">
          <span class="fw600 em2">ECN 212</span>
          <span class="em1-25 fw600"> Microeconomic Principles</span>
          <div class="main-page-course-logo">
            <img :src="microLogo" class="rounded"/>
          </div>
        </div>
        <router-link to="/stats" class="col course-container-right rounded nolink fc-text">
          <!-- <div class="col course-container-right rounded"> -->
            <span class="fw600 em2">GBS 221</span>
            <span class="em1-25 fw600"> Business Statistics</span>
            <div class="main-page-course-logo">
              <img :src="statsLogo" class="rounded"/>
            </div>
          <!-- </div> -->
        </router-link>
      </div>
    </div>
  </div>
    
</template>

<script>
import headerImage from '@/assets/main-page-header-image.png';
import statsLogo from '@/assets/stats-logo.png';
import microLogo from '@/assets/micro-logo.png';
import macroLogo from '@/assets/macro-logo.png';

export default {
  name: "mainPage",
  data() {
    return {
      headerImage, statsLogo, microLogo, macroLogo,
    }
  },
  methods: {

  },
}
</script>