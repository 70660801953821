<template>
  <div class="container col-md-6 offset-md-3">
  
  <!-- #region CHAPTER AND TITLE -->
  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em1-50">Chapter Six</span><br>
      <span class="fw800 em3 fc-stats">It's Perfectly Normal</span>
    </div>
  </div>
  <!-- #endregion -->
  
  <!-- SUB NAVIGATION BAR -->
  <nav>
    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
      <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
      <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
    </div>
  </nav>
  
  <!-- SUB NAV BAR CONTENT -->
  <div class="tab-content" id="nav-tabContent">
    <!-- TEXTBOOK -->
    <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
      
      <VideoBox class="mt-4" videoTitle="It's Perfectly Normal" videoUrl="https://www.youtube.com/embed/z3rPUq710so" startAt="0"/>

      <!-- #region MODULE 1 -->
      <div v-if="userData" class="intructions-module rounded container">
        <h3 class="fc-stats">The Normal Distribution</h3>
        <p>
          <a href="https://en.wikipedia.org/wiki/Carl_Friedrich_Gauss" target="_blank">Carl Friedrich Gauss</a> is generally credited with discovering the normal 
          distribution as we know it. While the normal distribution is often a good approximation for real-world observations like height, its real importance will 
          become clear in Chapter 8. For now, we will simply greet it as a new friend:
          
        </p>
        <p class="d-flex justify-content-center align-items-center">
          <span class="em1-25"><MathEquation :equation="`f(x) = `"/></span>
          <span class="em1-50"><MathEquation :equation="` \\frac{1}{\\sigma\\sqrt{2\\pi}}e^{\\frac{-(x-\\mu)^2}{2\\sigma^2}}`"/></span>
        </p>
        <p>
          <span class="fw600">The Normal Distribution</span> is a continuous probability distribution that is symmetric about the mean, median, and mode. 
          The mean, median, and mode are all equal in a normal distribution. The normal distribution is defined by two parameters: the mean <MathEquation :equation="'\\mu'"/> 
          and the standard deviation <MathEquation :equation="'\\sigma'"/>. The normal distribution is often referred to as the bell curve because of its shape.
        </p>

        <p class="d-flex justify-content-center align-items-center">
          <a href="https://www.desmos.com/calculator/py45f6tyzi" target="_blank" class="btn btn-primary">
            Open the Normal Distribution in Desmos
          </a>
        </p>
        <div class="d-flex justify-content-center align-items-center">
          <img :src="BellCurve" width="80%" class="rounded">
        </div>

        <p>
          It is from the normal distribution that we get the 68-95-99.7 rule. This rule states that in a normal distribution, 68% of the data falls within one standard 
          deviation of the mean, 95% falls within two standard deviations of the mean, and 99.7% falls within three standard deviations of the mean. For more about this, 
          watch the video below. There are <b>practice questions</b> which will help with the questions below the video.
        </p>

        <VideoBox videoUrl="https://www.youtube.com/embed/mtbJbDwqWLE" videoTitle="The Normal Distribution and the 68-95-99.7 Rule" startAt="5" />

        <div class="mt-3" v-if="userData">
          <h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            A normal distribution has a mean (<MathEquation :equation="'\\mu'"/>) of 
            {{ userData.stats.problemSets.ch6.mod1.mean }} 
            and a standard deviation (<MathEquation :equation="'\\sigma'"/>) of 
            {{ userData.stats.problemSets.ch6.mod1.sd }}. 
            Use the 68-95-99.7 rule to find the area between the following values of <MathEquation :equation="'x'"/>.
          </p>
          <div style="display: flex; justify-content: right; align-items: center; margin-bottom: 6px; margin-top: -24px;">
            <div style="width: 67%; "><NormalDistribution :mean="userData.stats.problemSets.ch6.mod1.mean" :standardDeviation="userData.stats.problemSets.ch6.mod1.sd"/></div>
          </div>
        </div>
        <!-- Question 1 -->
        <div class="input-group mb-3">
          <button ref="m1q1" class="input-group-text" style="width: 140px;"><MathEquation :equation="`${userData.stats.problemSets.ch6.mod1.x1l} \\le x \\le ${userData.stats.problemSets.ch6.mod1.x1u}`"/></button>  
          <input type="text" class="form-control" placeholder="Calculate the area between these two values" id="m1q1" :value="userData.stats.textbookResponses.ch6.mod1.q1" :style="checkM1Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <!-- Question 2 -->
        <div class="input-group mb-3">
          <button ref="m1q2" class="input-group-text" style="width: 140px;"><MathEquation :equation="`${userData.stats.problemSets.ch6.mod1.x2l} \\le x \\le ${userData.stats.problemSets.ch6.mod1.x2u}`"/></button>
          <input type="text" class="form-control" placeholder="Calculate the area between these two values" id="m1q2" :value="userData.stats.textbookResponses.ch6.mod1.q2" :style="checkM1Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <!-- Question 3 -->
        <div class="input-group mb-3">
          <button ref="m1q3" class="input-group-text" style="width: 140px;"><MathEquation :equation="`${userData.stats.problemSets.ch6.mod1.x3l} \\le x \\le ${userData.stats.problemSets.ch6.mod1.x3u}`"/></button>
          <input type="text" class="form-control" :placeholder="'Calculate the area between these two values' " id="m1q3" :value="userData.stats.textbookResponses.ch6.mod1.q3" :style="checkM1Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>

        <CalculatorLinks :score="mod1Score" :scoreTotal="6"/>
    </div> 
    <!-- #endregion -->
        
      <!-- #region MODULE 2 -->
      <div v-if="userData" class="intructions-module rounded container">
        <h3 class="fc-stats">The <b>Standard</b> Normal Distribution</h3>
        <p>
          Phoenix and Los Angeles are 15,700 football fields apart. Is that a lot? ¯\_(ツ)_/¯ But if I convert that to miles, I get 356.83 miles. 
          I have a pretty good idea of how far that is. The standard normal distribution is like converting football fields to miles. It's a way of 
          standardizing data so that we can compare apples to apples. In the video below, you will learn how to convert any normal distribution to 
          the standard normal distribution using Z-scores. You will also learn how to use Z-Scores to find the area under the standard normal distribution curve.
        </p>

        <VideoBox videoUrl="https://www.youtube.com/embed/2tuBREK_mgE" videoTitle="Z-Scores, Standardization, and the Standard Normal Distribution" startAt="5" />

        <div class="mt-3" v-if="userData">
          <h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Each question below gives a normal distribution (e.g. N(80, 10) has a mean of 80 and a standard deviation of 10). 
            It also gives you a value for x. In each case, calculate the Z-score for the value of <MathEquation :equation="'x'"/>. 
          </p>
        </div>
        <!-- Question 1 -->
        <div class="input-group mb-3">
          <button ref="m2q1" class="input-group-text" style="width: 125px;"><MathEquation :equation="`N(${userData.stats.problemSets.ch6.mod2.m1},${userData.stats.problemSets.ch6.mod2.s1})`"/></button>  
          <button class="input-group-text" style="width: 125px;"><MathEquation :equation="`x = ${userData.stats.problemSets.ch6.mod2.x1}`"/></button> 
          <input type="text" class="form-control" placeholder="Calculate the z-score for x" id="m2q1" :value="userData.stats.textbookResponses.ch6.mod2.q1" :style="checkM2Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <!-- Question 2 -->
        <div class="input-group mb-3">
          <button ref="m2q2" class="input-group-text" style="width: 125px;"><MathEquation :equation="`N(${userData.stats.problemSets.ch6.mod2.m2},${userData.stats.problemSets.ch6.mod2.s2})`"/></button>
          <button class="input-group-text" style="width: 125px;"><MathEquation :equation="`x = ${userData.stats.problemSets.ch6.mod2.x2}`"/></button>
          <input type="text" class="form-control" placeholder="Calculate the z-score for x" id="m2q2" :value="userData.stats.textbookResponses.ch6.mod2.q2" :style="checkM2Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <!-- Question 3 -->
        <div class="input-group mb-3">
          <button ref="m2q3" class="input-group-text" style="width: 125px;"><MathEquation :equation="`N(${userData.stats.problemSets.ch6.mod2.m3},${userData.stats.problemSets.ch6.mod2.s3})`"/></button>
          <button class="input-group-text" style="width: 125px;"><MathEquation :equation="`x = ${userData.stats.problemSets.ch6.mod2.x3}`"/></button>
          <input type="text" class="form-control" :placeholder="'Calculate the z-score for x' " id="m2q3" :value="userData.stats.textbookResponses.ch6.mod2.q3" :style="checkM2Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>


        <CalculatorLinks :score="mod2Score" :scoreTotal="6"/>
      </div> 
      <!-- #endregion -->
      
      <!-- #region MODULE 3 -->
      <div v-if="userData" class="intructions-module rounded container">
        <h3 class="fc-stats">Z-Table</h3>
        <p>
          The Z-Table below can be used to find the area under the standard normal distribution curve up to the given value for Z. For example, 
          a Z-score of 1.96 has an area of 0.9750 under the curve. This means that 97.5% of the data falls below a Z-score of 1.96. Conversely, 
          a Z-score of -1.96 has an area of 0.0250 under the curve. This means that 2.5% of the data falls below a Z-score of -1.96. That means that 
          95% of the data falls between -1.96 and 1.96. The Z-Table is a useful tool for finding the area under the standard normal distribution curve. 
        </p>

        <ZTable />

        <div class="mt-3" v-if="userData">
          <h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Use the Z-table above to find the area under the standard normal distribution curve up to the given value for Z, or between the two given values for Z.
          </p>
        </div>
        <!-- Question 1 -->
        <div class="input-group mb-3">
          <button ref="m3q1" class="input-group-text" style="width: 220px;"><MathEquation :equation="`P(Z \\le ${userData.stats.problemSets.ch6.mod3.z1})`"/></button>  
          <input type="text" class="form-control" placeholder="Use the Z-table to find the area" id="m3q1" :value="userData.stats.textbookResponses.ch6.mod3.q1" :style="checkM3Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <!-- Question 2 -->
        <div class="input-group mb-3">
          <button ref="m3q2" class="input-group-text" style="width: 220px;"><MathEquation :equation="`P(Z \\ge ${userData.stats.problemSets.ch6.mod3.z2})`"/></button>
          <input type="text" class="form-control" placeholder="Use the Z-table to find the area" id="m3q2" :value="userData.stats.textbookResponses.ch6.mod3.q2" :style="checkM3Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <!-- Question 3 -->
        <div class="input-group mb-3">
          <button ref="m3q3" class="input-group-text" style="width: 220px;"><MathEquation :equation="`P(${userData.stats.problemSets.ch6.mod3.z3l} \\le Z \\le ${userData.stats.problemSets.ch6.mod3.z3u})`"/></button>
          <input type="text" class="form-control" :placeholder="'Use the Z-table to find the area' " id="m3q3" :value="userData.stats.textbookResponses.ch6.mod3.q3" :style="checkM3Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <!-- Question 4 -->
        <div class="input-group mb-3">
          <button ref="m3q4" class="input-group-text" style="width: 220px;"><MathEquation :equation="`P(${userData.stats.problemSets.ch6.mod3.z4l} \\le Z \\le ${userData.stats.problemSets.ch6.mod3.z4u})`"/></button>
          <input type="text" class="form-control" :placeholder="'Use the Z-table to find the area' " id="m3q4" :value="userData.stats.textbookResponses.ch6.mod3.q4" :style="checkM3Q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>

        <CalculatorLinks :score="mod3Score" :scoreTotal="8"/>
      </div> 
      <!-- #endregion -->
          
      <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/1btH5ghTwfA" startAt="0"/>

      <!-- #region DATA CHANGER -->
      <div v-if="userData" class="data-changer">
        <div class="em1-50 fw600">Questions Reset</div>
        <div>You can reset the questions with randomly generated numbers for more practice.</div>
        <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
      </div>
      <!-- #endregion -->

    </div>
    <!-- EXPLORATORY LESSON -->
    <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="ExploratoryLessonIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Exploratory Lesson 6</h3>
          <div style="text-align: justify;">
            The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will compute Z-scores, find the area below 
            the normal distribution curve, and use a Monte Carlo simulation to find the odds one team will beat the other in the sport of Big.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ lssnScore }} out of 20 points on this lesson.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link to="/stats/6/lesson" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
      </div>
    </div>
    <!-- SPREADSHEET ASSIGNMENT -->
    <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="SpreadsheetAssignmentIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Spreadsheet Assignment 6</h3>
          <div style="text-align: justify;">
            The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will act as Bank Manager, using the normal 
            distribution to help determine how much cash to keep on hand to meet customer demand.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ assnScore }} out of 30 points on this assignment.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link to="/stats/6/assignment" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
      </div>
    </div>
  </div>
  
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
  import { Popover } from 'bootstrap';

  import MathEquation from '@/components/MathEquation.vue';
  import CalculatorLinks from '@/components/CalculatorLinks.vue';
  import VideoBox from '@/components/VideoBox.vue';
  import NormalDistribution from '@/components/NormalDistribution.vue';
  import ZTable from '@/components/ZTable.vue';
  
  import { create, all } from 'mathjs'
  const math = create(all);
  
  import probabilibot from '@/assets/Probabilibot.png';
  import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
  import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
  import BellCurve from '@/assets/BellCurve.png';
  
  export default {
    name: "statsMainPage",
    components: {
      MathEquation,
      CalculatorLinks,
      VideoBox,
      NormalDistribution,
      ZTable,
    },
    props: ['tab'],
    data() {
      return {
        userData: null,
        setUpStatsData,
        mod1Score: 0,
        mod2Score: 0,
        mod3Score: 0,
        assnScore: 0,
        lssnScore: 0,
        modalTitle: 'Loading...',
        modalMessage: '',
        probabilibot,
        ExploratoryLessonIcon,
        SpreadsheetAssignmentIcon,
        BellCurve,
        renderEq,
      }
    },
    created() {
      this.loadUserInformation();
    },
    methods: {
      async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullProblemSet();
        await this.setPopovers();
      },
      async pullProblemSet() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        
        // ADD VALUES FOR THE CHAPTER PROBLEM SET
        if(data.stats.problemSets.ch6.length == 0){
          await setDoc(docRef, {
            stats: {
              problemSets: {
                ch6: {
                  mod1: this.problemSetsMod1(),
                  mod2: this.problemSetsMod2(),
                  mod3: this.problemSetsMod3(),
                },
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
        if(Object.keys(data.stats.textbookResponses.ch6).length == 0){
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod1: {
                    q1: null,
                    q2: null,
                    q3: null,
                  },
                  mod2: {
                    q1: null,
                    q2: null,
                    q3: null,
                  },
                  mod3: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER GRADEBOOK IF MISSING
        if(!data.stats.gradeBook.ch6.mod1){
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                ch6: {
                  mod1: [0,0,0],
                  mod2: [0,0,0],
                  mod3: [0,0,0,0],
                  assignment: null,
                  lesson: null,
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        this.userData = data;
        this.mod1Score = math.sum(this.userData.stats.gradeBook.ch6.mod1);
        this.mod2Score = math.sum(this.userData.stats.gradeBook.ch6.mod2);
        this.mod3Score = math.sum(this.userData.stats.gradeBook.ch6.mod3);
        this.assnScore = this.userData.stats.gradeBook.ch6.assignment ? this.userData.stats.gradeBook.ch6.assignment : 0;
        this.lssnScore = this.userData.stats.gradeBook.ch6.lesson ? this.userData.stats.gradeBook.ch6.lesson : 0;
      },
      async setPopovers() {
        await new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (this.userData !== null) {
                    clearInterval(intervalId);
                    resolve(this.userData);
                }
            }, 100); // Check every 100 milliseconds
        });
        const popoverM1Q1 = new Popover(this.$refs.m1q1, {
          container: 'body',
          title: 'Normal Distribution',
          content: `The video above has examples of problems like this. <br><br> You may enter your answer as a decimal or a percentage, but it should be exactly as accurate as the 68-95-99.7 rule.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });   
        const popoverM1Q2 = new Popover(this.$refs.m1q2, {
          container: 'body',
          title: 'Normal Distribution',
          content: `The video above has examples of problems like this. <br><br> You may enter your answer as a decimal or a percentage, but it should be exactly as accurate as the 68-95-99.7 rule.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        }); 
        const popoverM1Q3 = new Popover(this.$refs.m1q3, {
          container: 'body',
          title: 'Normal Distribution',
          content: `The video above has examples of problems like this. <br><br> You may enter your answer as a decimal or a percentage, but it should be exactly as accurate as the 68-95-99.7 rule.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q1 = new Popover(this.$refs.m2q1, {
          container: 'body',
          title: 'Z-Scores',
          content: `Z = (x - μ)/σ <br> The video above has examples of problems like this. <br><br> <b>Your answer should be accurate to at least 2 decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q2 = new Popover(this.$refs.m2q2, {
          container: 'body',
          title: 'Z-Scores',
          content: `Z = (x - μ)/σ <br> The video above has examples of problems like this. <br><br> <b>Your answer should be accurate to at least 2 decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q3 = new Popover(this.$refs.m2q3, {
          container: 'body',
          title: 'Z-Scores',
          content: `Z = (x - μ)/σ <br> The video above has examples of problems like this. <br><br> <b>Your answer should be accurate to at least 2 decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q1 = new Popover(this.$refs.m3q1, {
          container: 'body',
          title: 'P(Z)',
          content: `Find this value in the Z-table above. <br><br> <b>Your answer should be accurate to at least 4 decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q2 = new Popover(this.$refs.m3q2, {
          container: 'body',
          title: 'P(Z)',
          content: `Find this value in the Z-table above. Remember, the table tells you the area to the left of this value, and this question is asking for the area to the right. <br><br> <b>Your answer should be accurate to at least 4 decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q3 = new Popover(this.$refs.m3q3, {
          container: 'body',
          title: 'P(Z)',
          content: `Find each value in the Z-table above and take the difference. <br><br> <b>Your answer should be accurate to at least 4 decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q4 = new Popover(this.$refs.m3q4, {
          container: 'body',
          title: 'P(Z)',
          content: `Find each value in the Z-table above and take the difference. <br><br> <b>Your answer should be accurate to at least 4 decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        popoverM1Q1; popoverM1Q2; popoverM1Q3; popoverM2Q1; popoverM2Q2; popoverM2Q3; popoverM3Q1; popoverM3Q2; popoverM3Q3; popoverM3Q4;
      },
      async resetAll() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            problemSets: {
              ch6: {
                mod1: this.problemSetsMod1(),
                mod2: this.problemSetsMod2(),
                mod3: this.problemSetsMod3(),
              }
            }
          }
        }, {merge: true});
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch6: {
                mod1: {
                  q1: null,
                  q2: null,
                  q3: null,
                },
                mod2: {
                  q1: null,
                  q2: null,
                  q3: null,
                },
                mod3: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
              }
            }
          }
        }, {merge: true});
        const docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
      },
      problemSetsMod1() {
        const mean = math.randomInt(4, 8)*10;
        const sd = math.randomInt(5, 10);
        const x1l = mean - math.randomInt(1, 3)*sd;
        const x1u = mean;
        const x2l = mean;
        const x2u = mean + math.randomInt(1, 3)*sd;
        const lower = math.randomInt(1, 3);
        let upper = math.randomInt(1, 3);
        while(upper == lower){
          upper = math.randomInt(1, 3);
        }
        const x3l = mean - lower*sd;
        const x3u = mean + upper*sd;
        return {
          mean: mean,
          sd: sd,
          x1l: x1l,
          x1u: x1u,
          x2l: x2l,
          x2u: x2u,
          x3l: x3l,
          x3u: x3u,
        }
      },
      problemSetsMod2() {
        const m1 = math.randomInt(40, 80);
        const s1 = math.randomInt(50, 100)/10;
        const x1 = math.round(m1 - math.randomInt(1, 300)*s1/100,3);
        const m2 = math.randomInt(-40, -80);
        const s2 = math.randomInt(50, 100)/10;
        const x2 = math.round(m2 + math.randomInt(1, 300)*s2/100,3);
        const m3 = math.randomInt(40, 80)/10;
        const s3 = math.randomInt(5, 10)/10;
        let x3 = math.round(m3 + math.randomInt(-300, 300)*s3/100,3);
        while (x3 == m3){
          x3 = math.round(m3 + math.randomInt(-300, 300)*s3/100,3);
        }
        return {
          m1: m1,
          s1: s1,
          x1: x1,
          m2: m2,
          s2: s2,
          x2: x2,
          m3: m3,
          s3: s3,
          x3: x3,
        }
      },
      problemSetsMod3() {
        const z1 = math.round(math.randomInt(20, 300)/100,2);
        const z2 = math.round(math.randomInt(200, 300)/100,2);
        const z3l = math.round(math.randomInt(-300, -20)/100,2);
        let z3u = -z3l;
        const z4l = math.round(math.randomInt(-300, 100)/100,2);
        let z4u =  math.round(z4l + math.randomInt(20, 200)/100,2);
        while(z4u == -z4l){
          z4u =  math.round(z4l + math.randomInt(20, 200)/100,2);
        }
        return {
          z1: z1,
          z2: z2,
          z3l: z3l,
          z3u: z3u,
          z4l: z4l,
          z4u: z4u,
        }
      },
      standardNormalCDF(z) {
        return (1.0 + math.erf(z / math.sqrt(2))) / 2.0;
      },
      async computeM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const empiricalRule = [0, 0.34, 0.475, 0.499];
          const lower = empiricalRule[math.round((data.mod1.mean - data.mod1.x1l)/data.mod1.sd,0)];
          const upper = empiricalRule[math.round((data.mod1.x1u - data.mod1.mean)/data.mod1.sd,0)];
          const trueValue = lower + upper;
          const studValue = document.getElementById('m1q1').value;
          const result = compare(trueValue, studValue, 0.001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `${data.mod1.x1l} is ${math.round((data.mod1.mean - data.mod1.x1l)/data.mod1.sd,0)} standard deviations below the mean, and ${data.mod1.x1u} is ${math.round((data.mod1.x1u - data.mod1.mean)/data.mod1.sd,0)} standard deviations above the mean. Using the 68-95-99.7 rule, we find that ${math.round(trueValue,3)} is the area between these two values.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `${data.mod1.x1l} is ${math.round((data.mod1.mean - data.mod1.x1l)/data.mod1.sd,0)} standard deviations below the mean, and ${data.mod1.x1u} is ${math.round((data.mod1.x1u - data.mod1.mean)/data.mod1.sd,0)} standard deviations above the mean. Use the 68-95-99.7 rule to find the area between these two values.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch6.mod1;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod1: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                ch6: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const empiricalRule = [0, 0.34, 0.475, 0.499];
          const lower = empiricalRule[math.round((data.mod1.mean - data.mod1.x2l)/data.mod1.sd,0)];
          const upper = empiricalRule[math.round((data.mod1.x2u - data.mod1.mean)/data.mod1.sd,0)];
          const trueValue = lower + upper;
          const studValue = document.getElementById('m1q2').value;
          const result = compare(trueValue, studValue, 0.001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `${data.mod1.x2l} is ${math.round((data.mod1.mean - data.mod1.x2l)/data.mod1.sd,0)} standard deviations below the mean, and ${data.mod1.x2u} is ${math.round((data.mod1.x2u - data.mod1.mean)/data.mod1.sd,0)} standard deviations above the mean. Using the 68-95-99.7 rule, we find that ${math.round(trueValue,3)} is the area between these two values.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `${data.mod1.x2l} is ${math.round((data.mod1.mean - data.mod1.x2l)/data.mod1.sd,0)} standard deviations below the mean, and ${data.mod1.x2u} is ${math.round((data.mod1.x2u - data.mod1.mean)/data.mod1.sd,0)} standard deviations above the mean. Use the 68-95-99.7 rule to find the area between these two values.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch6.mod1;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod1: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                ch6: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const empiricalRule = [0, 0.34, 0.475, 0.499];
          const lower = empiricalRule[math.round((data.mod1.mean - data.mod1.x3l)/data.mod1.sd,0)];
          const upper = empiricalRule[math.round((data.mod1.x3u - data.mod1.mean)/data.mod1.sd,0)];
          const trueValue = lower + upper;
          const studValue = document.getElementById('m1q3').value;
          const result = compare(trueValue, studValue, 0.001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `${data.mod1.x3l} is ${math.round((data.mod1.mean - data.mod1.x3l)/data.mod1.sd,0)} standard deviations below the mean, and ${data.mod1.x3u} is ${math.round((data.mod1.x3u - data.mod1.mean)/data.mod1.sd,0)} standard deviations above the mean. Using the 68-95-99.7 rule, we find that ${math.round(trueValue,3)} is the area between these two values.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `${data.mod1.x3l} is ${math.round((data.mod1.mean - data.mod1.x3l)/data.mod1.sd,0)} standard deviations below the mean, and ${data.mod1.x3u} is ${math.round((data.mod1.x3u - data.mod1.mean)/data.mod1.sd,0)} standard deviations above the mean. Use the 68-95-99.7 rule to find the area between these two values.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch6.mod1;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod1: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                ch6: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = (data.mod2.x1 - data.mod2.m1)/data.mod2.s1;
          const studValue = document.getElementById('m2q1').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The Z-score is calculated as <br> ${renderEq('Z = \\frac{x - \\mu}{\\sigma}')} <br> The Z-score for ${data.mod2.x1} in a normal distribution with a mean of ${data.mod2.m1} and a standard deviation of ${data.mod2.s1} is ${math.round(trueValue,3)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The Z-score is calculated as <br> ${renderEq('Z = \\frac{x - \\mu}{\\sigma}')} <br> The normal distribution described for this question has a mean (${renderEq('\\mu')}) of ${data.mod2.m1} and a standard deviation (${renderEq('\\sigma')}) of ${data.mod2.s1}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch6.mod2;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod2: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                ch6: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = (data.mod2.x2 - data.mod2.m2)/data.mod2.s2;
          const studValue = document.getElementById('m2q2').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The Z-score is calculated as <br> ${renderEq('Z = \\frac{x - \\mu}{\\sigma}')} <br> The Z-score for ${data.mod2.x2} in a normal distribution with a mean of ${data.mod2.m2} and a standard deviation of ${data.mod2.s2} is ${math.round(trueValue,3)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The Z-score is calculated as <br> ${renderEq('Z = \\frac{x - \\mu}{\\sigma}')} <br> The normal distribution described for this question has a mean (${renderEq('\\mu')}) of ${data.mod2.m2} and a standard deviation (${renderEq('\\sigma')}) of ${data.mod2.s2}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch6.mod2;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod2: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                ch6: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = (data.mod2.x3 - data.mod2.m3)/data.mod2.s3;
          const studValue = document.getElementById('m2q3').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The Z-score is calculated as <br> ${renderEq('Z = \\frac{x - \\mu}{\\sigma}')} <br> The Z-score for ${data.mod2.x3} in a normal distribution with a mean of ${data.mod2.m3} and a standard deviation of ${data.mod2.s3} is ${math.round(trueValue,3)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The Z-score is calculated as <br> ${renderEq('Z = \\frac{x - \\mu}{\\sigma}')} <br> The normal distribution described for this question has a mean (${renderEq('\\mu')}) of ${data.mod2.m3} and a standard deviation (${renderEq('\\sigma')}) of ${data.mod2.s3}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch6.mod2;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod2: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                ch6: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = this.standardNormalCDF(data.mod3.z1);
          const studValue = document.getElementById('m3q1').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The Z-table above shows that the area to the left of ${data.mod3.z1} is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The Z-table above shows that the area to the left of ${data.mod3.z1} in the row marked ${math.round(math.fix(data.mod3.z1*10)/10,1)} and the column marked ${math.round(math.abs(data.mod3.z1) - math.abs(math.fix(data.mod3.z1*10)/10),2)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch6.mod3;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod3: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                ch6: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = 1 - this.standardNormalCDF(data.mod3.z2);
          const studValue = document.getElementById('m3q2').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The Z-table above shows that the area to the left of ${data.mod3.z2} is ${math.round(this.standardNormalCDF(data.mod3.z2),4)}, which means the area to the right is 1 - ${math.round(this.standardNormalCDF(data.mod3.z2),4)} = ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The Z-table above shows that the area to the left of ${data.mod3.z2} in the row marked ${math.round(math.fix(data.mod3.z2*10)/10,1)} and the column marked ${math.round(math.abs(data.mod3.z2) - math.abs(math.fix(data.mod3.z2*10)/10),2)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch6.mod3;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod3: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                ch6: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = this.standardNormalCDF(data.mod3.z3u) - this.standardNormalCDF(data.mod3.z3l);
          const studValue = document.getElementById('m3q3').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The Z-table above shows that the area to the left of ${data.mod3.z3u} is ${math.round(this.standardNormalCDF(data.mod3.z3u),4)}, and the area to the left of ${data.mod3.z3l} is ${math.round(this.standardNormalCDF(data.mod3.z3l),4)}. The difference between these two values is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The Z-table above shows that the area to the left of ${data.mod3.z3u} in the row marked ${math.round(math.fix(data.mod3.z3u*10)/10,1)} and the column marked ${math.round(math.abs(data.mod3.z3u) - math.abs(math.fix(data.mod3.z3u*10)/10),2)}. The area to the left of ${data.mod3.z3l} is found in the row marked ${math.round(math.fix(data.mod3.z3l*10)/10,1)} and the column marked ${math.round(math.abs(data.mod3.z3l) - math.abs(math.fix(data.mod3.z3l*10)/10),2)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch6.mod3;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod3: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                ch6: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = this.standardNormalCDF(data.mod3.z4u) - this.standardNormalCDF(data.mod3.z4l);
          const studValue = document.getElementById('m3q4').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The Z-table above shows that the area to the left of ${data.mod3.z4u} is ${math.round(this.standardNormalCDF(data.mod3.z4u),4)}, and the area to the left of ${data.mod3.z4l} is ${math.round(this.standardNormalCDF(data.mod3.z4l),4)}. The difference between these two values is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The Z-table above shows that the area to the left of ${data.mod3.z4u} in the row marked ${math.round(math.fix(data.mod3.z4u*10)/10,1)} and the column marked ${math.round(math.abs(data.mod3.z4u) - math.abs(math.fix(data.mod3.z4u*10)/10),2)}. The area to the left of ${data.mod3.z4l} is found in the row marked ${math.round(math.fix(data.mod3.z4l*10)/10,1)} and the column marked ${math.round(math.abs(data.mod3.z4l) - math.abs(math.fix(data.mod3.z4l*10)/10),2)}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch6.mod3;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch6: {
                  mod3: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                ch6: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
    }, 
    computed: {
      checkM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const empiricalRule = [0, 0.34, 0.475, 0.499];
          const lower = empiricalRule[math.round((data.mod1.mean - data.mod1.x1l)/data.mod1.sd,0)];
          const upper = empiricalRule[math.round((data.mod1.x1u - data.mod1.mean)/data.mod1.sd,0)];
          const trueValue = lower + upper;
          const studValue = this.userData.stats.textbookResponses.ch6.mod1.q1;
          const result = compare(trueValue, studValue, 0.001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const empiricalRule = [0, 0.34, 0.475, 0.499];
          const lower = empiricalRule[math.round((data.mod1.mean - data.mod1.x2l)/data.mod1.sd,0)];
          const upper = empiricalRule[math.round((data.mod1.x2u - data.mod1.mean)/data.mod1.sd,0)];
          const trueValue = lower + upper;
          const studValue = this.userData.stats.textbookResponses.ch6.mod1.q2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const empiricalRule = [0, 0.34, 0.475, 0.499];
          const lower = empiricalRule[math.round((data.mod1.mean - data.mod1.x3l)/data.mod1.sd,0)];
          const upper = empiricalRule[math.round((data.mod1.x3u - data.mod1.mean)/data.mod1.sd,0)];
          const trueValue = lower + upper;
          const studValue = this.userData.stats.textbookResponses.ch6.mod1.q3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = (data.mod2.x1 - data.mod2.m1)/data.mod2.s1;
          const studValue = this.userData.stats.textbookResponses.ch6.mod2.q1;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = (data.mod2.x2 - data.mod2.m2)/data.mod2.s2;
          const studValue = this.userData.stats.textbookResponses.ch6.mod2.q2;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = (data.mod2.x3 - data.mod2.m3)/data.mod2.s3;
          const studValue = this.userData.stats.textbookResponses.ch6.mod2.q3;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = this.standardNormalCDF(data.mod3.z1);
          const studValue = this.userData.stats.textbookResponses.ch6.mod3.q1;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = 1 - this.standardNormalCDF(data.mod3.z2);
          const studValue = this.userData.stats.textbookResponses.ch6.mod3.q2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = this.standardNormalCDF(data.mod3.z3u) - this.standardNormalCDF(data.mod3.z3l);
          const studValue = this.userData.stats.textbookResponses.ch6.mod3.q3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch6;
          const trueValue = this.standardNormalCDF(data.mod3.z4u) - this.standardNormalCDF(data.mod3.z4l);
          const studValue = this.userData.stats.textbookResponses.ch6.mod3.q4;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
    }
  }
  </script>
  
  <style scoped>

  </style>