<template>
  <div class="container col-md-6 offset-md-3">
  
  <!-- #region CHAPTER AND TITLE -->
  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em1-50">Chapter Fourteen</span><br>
      <span class="fw800 em3 fc-stats">Good for Business</span>
    </div>
  </div>
  <!-- #endregion -->
  
  <!-- SUB NAVIGATION BAR -->
  <nav>
    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
      <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
      <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
    </div>
  </nav>
  
  <!-- SUB NAV BAR CONTENT -->
  <div class="tab-content" id="nav-tabContent">
    <!-- TEXTBOOK -->
    <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
      
      <VideoBox class="mt-4" videoTitle="Good for Business" videoUrl="https://www.youtube.com/embed/aLq4awksKgA" startAt="0"/>

      <!-- #region MODULE 0 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">One Final Time</h3>
        <p>
          Here we are at the end of our course on Business Statistics. In this second half of the course, we have covered 
          a lot of ground. We have learned about the normal distribution, the central limit theorem, and hypothesis testing. 
          We have also explored the basics of regression analysis and research design. 
        </p>
        <p>
          In this final chapter, we will be putting those things together in a kind of quasi-final exam. But first, check out this 
          short video with some advice on what to do next. 
        </p>
        <VideoBox videoUrl="https://www.youtube.com/embed/vZvwE5lk-7I" videoTitle="Don't Follow Your Passion" startAt="0" />
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 1 -->
      <div class="intructions-module rounded container">
        <div v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            A brewery is expanding and needs to increase its production of beer. In order to do this, they need to use a different 
            kind of hops in their beer. To test whether or not this new kind of hops will improve the taste of their beer, they 
            conduct a taste test. Participants are randomly split into two groups. One group tastes the beer made with the new hops 
            and the other group tastes the beer made with the old hops. The ratings provided by each group are below:
          </p>
          <p class="d-flex justify-content-center">
            <MathEquation :equation="`x_{new} = \\{${problemSets.mod1.treatment.join(',')}\\}`"/>
          </p><p class="d-flex justify-content-center">
            <MathEquation :equation="`x_{old} = \\{${problemSets.mod1.control.join(',')}\\}`"/>
          </p>
          <p>
            Use this information to conduct a two-tailed hypothesis test to determine if the new hops changes the quality of the beer.
          </p>
          <!-- Question 1 -->
          <div class="input-group mb-3">
          <button ref="m1q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\bar{x}_1 - \\bar{x}_2'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the difference in the sample means" id="m1q1" :value="textbookResponses.mod1.q1" :style="checkM1Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <div class="input-group mb-3">
          <button ref="m1q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'S.E.'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the standard error of the sample" id="m1q2" :value="textbookResponses.mod1.q2" :style="checkM1Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <div class="input-group mb-3">
          <button ref="m1q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'t'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the t-statistic" id="m1q3" :value="textbookResponses.mod1.q3" :style="checkM1Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <div class="input-group mb-3">
          <button ref="m1q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'d.f.'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the degrees of freedom" id="m1q4" :value="textbookResponses.mod1.q4" :style="checkM1Q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 5 -->
          <div class="input-group mb-3">
          <button ref="m1q5" class="input-group-text" style="width: 110px;"><MathEquation :equation="'p'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the p-value" id="m1q5" :value="textbookResponses.mod1.q5" :style="checkM1Q5">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 6 -->
          <div class="input-group mb-3">
          <button ref="m1q6" class="input-group-text" style="width: 110px;">Result</button>
          <select class="form-select" id="m1q6" :value="textbookResponses.mod1.q6" :style="checkM1Q6">
            <option :selected="textbookResponses.mod1.q6 === 'reject'" value="reject">Reject the null hypothesis</option>
            <option :selected="textbookResponses.mod1.q6 === 'do_not_reject'" value="do_not_reject">Do not reject the null hypothesis</option>
          </select>
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q6" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 7&8 -->
          <b>Calculate the 95% Confidence Interval for the difference in the means.</b>
          <div class="input-group mb-3">
          <button ref="m1q7" class="input-group-text" style="width: 120px;">Lower Bound</button>
          <input type="text" class="form-control" placeholder="Calculate the lower bound" id="m1q7" :value="textbookResponses.mod1.q7" :style="checkM1Q7">
          <button ref="m1q8" class="input-group-text" style="width: 120px;">Upper Bound</button>
          <input type="text" class="form-control" placeholder="Calculate the upper bound" id="m1q8" :value="textbookResponses.mod1.q8" :style="checkM1Q8">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q7" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div> 
          <TStatPValue />
          <TStatCrit />
  
          <CalculatorLinks :score="mod1Score" :scoreTotal="8"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 2 -->
      <div class="intructions-module rounded container">
        <div v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>Use the data below to estimate the following regression model: </p>
          <div class="d-flex justify-content-center"><MathEquation :equation="'y_i = \\beta_0 + \\beta_1x_i + \\varepsilon'"/></div>
          <div class="d-flex justify-content-center">
            <table class="problem-set-table">
              <tr>
                <th>x</th>
                <th>y</th>
              </tr>
              <tr v-for="(x, index) in problemSets.mod2.x" :key="index">
                <td>{{ x }}</td>
                <td>{{ problemSets.mod2.y[index] }}</td>
              </tr>
            </table>
          </div>
          <!-- Question 1 -->
          <div class="input-group mb-3">
          <button ref="m2q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\beta_1'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the value of beta 1" id="m2q1" :value="textbookResponses.mod2.q1" :style="checkM2Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <div class="input-group mb-3">
          <button ref="m2q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\beta_0'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the value of beta 0" id="m2q2" :value="textbookResponses.mod2.q2" :style="checkM2Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <div class="input-group mb-3">
          <button ref="m2q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'SE_{\\beta_1}'"/></button>
          <input type="text" class="form-control" placeholder="Compute the Standard Error of Beta 1" id="m2q3" :value="textbookResponses.mod2.q3" :style="checkM2Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <div class="input-group mb-3">
          <button ref="m2q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'t'"/></button>
          <input type="text" class="form-control" placeholder="Compute the T-Statistic" id="m2q4" :value="textbookResponses.mod2.q4" :style="checkM2Q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
  
          <CalculatorLinks :score="mod2Score" :scoreTotal="8"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 3 -->
      <div class="intructions-module rounded container">
        <div v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Answer each of the questions below.
          </p>
          <!-- MULTIPLE CHOICE COMPONENT -->
          <div class="mc-container">
            <div v-for="(question, index) in problemSets.mod3" :key="question.id" class="mc-question" v-show="index === currentQuestionIndex">
              <b :style="checkM3Q1[index]">Question {{ index+1 }}</b>. {{ question.question }}
              <div v-for="answer in question.answers" :key="answer.id" class="mc-answer">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="question.id"
                    :id="'m3'+question.id+answer.id"
                    :value="answer.id"
                    :checked="textbookResponses.mod3[index] == answer.id"
                  />
                  <label class="form-check-label" :for="question.id+answer.id">
                    {{ answer.text }}
                  </label>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;">
                <button class="btn btn-light" type="button" @click="previousQuestion">Previous</button>
                <button class="btn btn-outline-secondary" type="button" @click="computeM3Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answers</button>
                <button class="btn btn-light" type="button" @click="nextQuestion">Next</button>
              </div>
            </div>  
          </div>
  
          <CalculatorLinks :score="mod3Score" :scoreTotal="4"/>
        </div>
        
      </div> 
      <!-- #endregion -->
      

      <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/VIPcC97YEaw" startAt="0"/>

      <!-- #region DATA CHANGER -->
      <div v-if="userData" class="data-changer">
        <div class="em1-50 fw600">Questions Reset</div>
        <div>You can reset the questions with randomly generated numbers for more practice.</div>
        <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
      </div>
      <!-- #endregion -->

    </div>
    <!-- EXPLORATORY LESSON -->
    <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="ExploratoryLessonIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Exploratory Lesson {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will take another look at the climate 
            data from Chapter 2: Correlation is Not Causation. Now that we are more familiar with hypothesis testing and regression analysis, 
            we can take a deeper dive into the data and see if greenhouse gases really do explain the increase in global temperatures.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ lssnScore }} out of 20 points on this lesson.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/lesson'" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
      </div>
    </div>
    <!-- SPREADSHEET ASSIGNMENT -->
    <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="SpreadsheetAssignmentIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Spreadsheet Assignment {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will conduct an A/B test on some data 
            for two different ads. 
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ assnScore }} out of 30 points on this assignment.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/assignment'" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
      </div>
    </div>
  </div>
  
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, compare, renderEq, setUpStatsData, linearRegression } from '@/main';
  import { Popover } from 'bootstrap';
  import { studentt } from 'jstat';

  import MathEquation from '@/components/MathEquation.vue';
  import CalculatorLinks from '@/components/CalculatorLinks.vue';
  import VideoBox from '@/components/VideoBox.vue';
  import TStatCrit from "@/components/TStatCrit.vue";
  import TStatPValue from '@/components/TStatPValue.vue';
  import { normal, sumsqerr } from 'jstat';
  
  import { create, all } from 'mathjs'
  const math = create(all);
  
  import probabilibot from '@/assets/Probabilibot.png';
  import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
  import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
  
  export default {
    name: "statsMainPage",
    components: {
      MathEquation,
      CalculatorLinks,
      VideoBox,
      TStatCrit,
      TStatPValue,
    },
    props: ['tab'],
    data() {
      return {
        ch: 'ch14',
        userData: null,
        problemSets: null,
        textbookResponses: null,
        setUpStatsData,
        mod1Score: 0,
        mod2Score: 0,
        mod3Score: 0,
        assnScore: 0,
        lssnScore: 0,
        modalTitle: 'Loading...',
        modalMessage: '',
        probabilibot,
        ExploratoryLessonIcon,
        SpreadsheetAssignmentIcon,
        renderEq,
        currentQuestionIndex: 0,
      }
    },
    created() {
      this.loadUserInformation();
    },
    methods: {
      async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullProblemSet();
        await this.setPopovers();
      },
      async pullProblemSet() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        
        // ADD VALUES FOR THE CHAPTER PROBLEM SET
        if(data.stats.problemSets[this.ch].length == 0){
          await setDoc(docRef, {
            stats: {
              problemSets: {
                [this.ch]: this.problemSet(),
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
        if(Object.keys(data.stats.textbookResponses[this.ch]).length == 0){
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                    q5: null,
                    q6: null,
                    q7: null,
                    q8: null,
                  },
                  mod2: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                  },
                  mod3: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER GRADEBOOK IF MISSING
        if(!data.stats.gradeBook[this.ch].mod1){
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                [this.ch]: {
                  mod1: [0,0,0,0,0,0,0,0],
                  mod2: [0,0,0,0],
                  mod3: [0,0,0,0],
                  assignment: null,
                  lesson: null,
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        this.userData = data;
        this.problemSets = data.stats.problemSets[this.ch];
        this.textbookResponses = data.stats.textbookResponses[this.ch];
        this.mod1Score = math.sum(this.userData.stats.gradeBook[this.ch].mod1);
        this.mod2Score = math.sum(this.userData.stats.gradeBook[this.ch].mod2);
        this.mod3Score = math.sum(this.userData.stats.gradeBook[this.ch].mod3);
        this.assnScore = this.userData.stats.gradeBook[this.ch].assignment ? this.userData.stats.gradeBook[this.ch].assignment : 0;
        this.lssnScore = this.userData.stats.gradeBook[this.ch].lesson ? this.userData.stats.gradeBook[this.ch].lesson : 0;
      },
      async setPopovers() {
        await new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (this.userData !== null) {
                    clearInterval(intervalId);
                    resolve(this.userData);
                }
            }, 100); // Check every 100 milliseconds
        });
        const popoverM1Q1 = new Popover(this.$refs.m1q1, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Take the difference in the sample means. You can do it either way, but typically you take the treatment group minus the control group. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });   
        const popoverM1Q2 = new Popover(this.$refs.m1q2, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Find the standard error of the difference in sample means. This is the denominator of the t-statistic equation. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q3 = new Popover(this.$refs.m1q3, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Compute the t-statistic. This is the difference in the sample means divided by the standard error. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q4 = new Popover(this.$refs.m1q4, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the degrees of freedom when assuming equal variances. Your final answer should be an integer.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q5 = new Popover(this.$refs.m1q5, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the p-value using the calculator below. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q6 = new Popover(this.$refs.m1q6, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Decide whether to reject the null hypothesis. If the p-value is less than 0.05, you should reject the null hypothesis. Otherwise, you should not reject the null hypothesis.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q7 = new Popover(this.$refs.m1q7, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the lower bound of the 95% confidence interval for the difference in the means. Take the difference and subtract the standard error times the critical value for t. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q8 = new Popover(this.$refs.m1q8, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the upper bound of the 95% confidence interval for the difference in the means. Take the difference and add the standard error times the critical value for t. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q1 = new Popover(this.$refs.m2q1, {
          container: 'body',
          title: 'Ordinary Least Squares',
          content: `Estimate the coefficient by dividing the covariance of x and y by the variance of x. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q2 = new Popover(this.$refs.m2q2, {
          container: 'body',
          title: 'Ordinary Least Squares',
          content: `Estimate the y-intercept by subtracting the product of the coefficient and the mean of x from the mean of y. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q3 = new Popover(this.$refs.m2q3, {
          container: 'body',
          title: 'Standard Error of Beta 1',
          content: `This is the standard error of the coefficient for x. Divide the SSE by n-k-1 and multiply by 1/SSX. Then take the square root. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const m2q4 = new Popover(this.$refs.m2q4, {
          container: 'body',
          title: 'T-Statistic',
          content: `This is the t-statistic for the hypothesis test of the coefficient for x. Divide the coefficient by the standard error of the coefficient. <br><br> Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
      },
      async resetAll() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            problemSets: {
              [this.ch]: this.problemSet()
            }
          }
        }, {merge: true});
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod1: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                  q5: null,
                  q6: null,
                  q7: null,
                  q8: null,
                },
                mod2: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
                mod3: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
              }
            }
          }
        }, {merge: true});
        const docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.problemSets = this.userData.stats.problemSets[this.ch];
      },
      problemSet() {
        let n1 = Math.floor(Math.random() * 5) + 8;
        let n2 = Math.floor(Math.random() * 5) + 8;
        const treatment1 = Array.from({length: n1}, () => Math.floor(Math.random() * 9) + 1);
        const control1 = Array.from({length: n2}, () => Math.floor(Math.random() * 6) + 1);

        const x = Array.from({length: 7}, () => Math.floor(Math.random() * 10) + 1);
        const b0 = math.randomInt(-10, 10);
        const b1 = math.randomInt(-10, 10);
        const y = math.round(x.map((val) => b0 + b1*val + normal.sample(0, 10)),0);

        let questionSet = [
          {
            id: 'q1',
            question: 'A company wants to test the effectiveness of a new online advertising campaign on sales. They randomly assign half of their customers to see the new ads while the other half sees the old ads. After one month, they compare the average sales between the two groups to determine the impact of the new advertising campaign. Which research design is this company using?',
            answers: [
              { id: 'a1', text: 'Randomized Controlled Trial' },
              { id: 'a2', text: 'Difference-in-Differences' },
              { id: 'a3', text: 'Instrumental Variables' },
              { id: 'a4', text: 'Regression Discontinuity' },
            ],
            correct: 'a1',
          },
          {
            id: 'q2',
            question: 'A city implemented a minimum wage increase in January 2020. Researchers compared employment levels before and after the policy change in this city with employment levels in a nearby city that did not change its minimum wage. They aim to estimate the impact of the minimum wage increase on employment levels. Which research design is being used?',
            answers: [
              { id: 'a1', text: 'Randomized Controlled Trial' },
              { id: 'a2', text: 'Difference-in-Differences' },
              { id: 'a3', text: 'Instrumental Variables' },
              { id: 'a4', text: 'Regression Discontinuity' },
            ],
            correct: 'a2',
          },
          {
            id: 'q3',
            question: 'To evaluate the effect of education on earnings, a researcher uses the distance to the nearest college as an instrument for years of education. They argue that distance affects earnings only through its effect on education. Which research design is the researcher using?',
            answers: [
              { id: 'a1', text: 'Randomized Controlled Trial' },
              { id: 'a2', text: 'Difference-in-Differences' },
              { id: 'a3', text: 'Instrumental Variables' },
              { id: 'a4', text: 'Regression Discontinuity' },
            ],
            correct: 'a3',
          },
          {
            id: 'q4',
            question: 'A scholarship program awards financial aid to students whose test scores exceed a certain threshold. Researchers compare students who just met the threshold with those who just missed it to estimate the effect of the scholarship on academic performance. Which research design are the researchers employing?',
            answers: [
              { id: 'a1', text: 'Randomized Controlled Trial' },
              { id: 'a2', text: 'Difference-in-Differences' },
              { id: 'a3', text: 'Instrumental Variables' },
              { id: 'a4', text: 'Regression Discontinuity' },
            ],
            correct: 'a4',
          }
        ];
        // randomize order of answer for each question
        for(let question of questionSet){
          question.answers.sort(() => Math.random() - 0.5);
        }

        return {
          mod1: {
            treatment: treatment1,
            control: control1,
          },
          mod2: {
            x: x,
            y: y,
          },
          mod3: questionSet,
        };
      },
      previousQuestion() {
        if (this.currentQuestionIndex > 0) {
          this.currentQuestionIndex--;
        } else {
          this.currentQuestionIndex = this.problemSets.mod3.length - 1;
        }
      },
      nextQuestion() {
        if (this.currentQuestionIndex < this.problemSets.mod3.length - 1) {
          this.currentQuestionIndex++;
        } else {
          this.currentQuestionIndex = 0;
        }
      },
      async computeM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const trueValue = math.abs(x1 - x2);
          const studRaw = document.getElementById('m1q1').value;
          const studValue = studRaw ? math.abs(studRaw) : null;
          const result = compare(trueValue, studValue, 0.0001);
          const addendum = x1 - x2 < 0 ? 'Note that the average for the new group is lower than the old group, which means this beer is worse!' : '';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The difference in the sample means is ${math.round(x1 - x2,4)}. ${addendum}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Take the average of each group and subtract them from each other.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q1: studRaw,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const trueValue = math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const studValue = document.getElementById('m1q2').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The standard error of the sample is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The standard error is the denominator of the t-statistic equation. It is the square root of the pooled variance times the sum of the reciprocals of the sample sizes.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const trueValue = math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const studRaw = document.getElementById('m1q3').value;
          const studValue = studRaw ? math.abs(studRaw) : null;
          const result = compare(trueValue, studValue, 0.0001);
          const neg = x1 - x2 < 0 ? '-' : '';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The t-statistic is ${neg+math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The t-statistic is the difference in the sample means divided by the standard error of the sample.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q3: studRaw,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const trueValue = n1 + n2 - 2;
          const studValue = document.getElementById('m1q4').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The degrees of freedom are ${trueValue}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The degrees of freedom are the sum of the sample sizes minus 2.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const trueValue = 2 * (1 - (studentt.cdf(math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2)), n1 + n2 - 2)));
          const studValue = document.getElementById('m1q5').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The p-value is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The p-value is the probability of seeing a difference this large or larger if the null hypothesis is true.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q5: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const t = math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const p = 2 * (1 - studentt.cdf(t, n1 + n2 - 2));
          const trueValue = p < 0.05 ? 'reject' : 'do_not_reject';
          const studValue = document.getElementById('m1q6').value;
          const result = trueValue == studValue;
          const feedbackString = trueValue == 'reject' ? 'Because this is less than or equal to 0.05, we reject the null hypothesis' : 'Because this is more than 0.05, we do not reject the null hypothesis';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The p-value for this sample is ${math.round(p,4)}. ${feedbackString}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The p-value for this sample is ${math.round(p,4)}. When the p-value is less than 0.05, we reject the null hypothesis. Otherwise, we do not reject the null hypothesis.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[5] == 0 && result){gradeMatrix[5] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q6: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q7() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const diff = x1 - x2;
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const se = math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const dof = n1 + n2 - 2;
          const trueValueLower = diff - (se * studentt.inv(0.975, dof));
          const trueValueUpper = diff + (se * studentt.inv(0.975, dof));
          const studRawLower = document.getElementById('m1q7').value;
          const studRawUpper = document.getElementById('m1q8').value;
          const resultLower = compare(trueValueLower, studRawLower, 0.0001) || compare(trueValueLower, -studRawUpper, 0.0001) ? true : false;
          const resultUpper = compare(trueValueUpper, studRawUpper, 0.0001) || compare(trueValueUpper, -studRawLower, 0.0001) ? true : false;
          if(resultLower && resultUpper){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The lower bound of the 95% confidence interval for this sample is ${math.round(trueValueLower,4)} and the upper bound is ${math.round(trueValueUpper,4)}.`;
          } else if(resultLower) {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The lower bound is correct, but the upper bound is not.`;
          } else if(resultUpper) {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The upper bound is correct, but the lower bound is not.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the lower bound of the 95% confidence interval for this sample. For a two-sample test, take the difference in the means and add and subtract the product of the standard error and the t-value for a 95% confidence interval.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[6] == 0 && resultLower){gradeMatrix[6] = 1}
          if(gradeMatrix[7] == 0 && resultUpper){gradeMatrix[7] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q7: studRawLower,
                    q8: studRawUpper,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = linearRegression(data.mod2.x, data.mod2.y)[1];
          const studValue = document.getElementById('m2q1').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The slope of the line is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Use the formula for beta 1 to calculate the slope of the line.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = linearRegression(data.mod2.x, data.mod2.y)[0];
          const studValue = document.getElementById('m2q2').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The y-intercept of the line is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Use the formula for beta 0 to calculate the y-intercept of the line.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const yHat = math.add(beta[0], math.multiply(beta[1][0], data.mod2.x));
          const resid = math.subtract(data.mod2.y, yHat);
          const sse =sumsqerr(resid);
          const ssx = sumsqerr(data.mod2.x);
          const dof = data.mod2.x.length - 2;
          const trueValue = math.sqrt((sse/dof)/ssx);
          const studValue = document.getElementById('m2q3').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The standard error of the coefficient is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the standard error of the coefficient by dividing the sum of squared errors by the degrees of freedom and the sum of squared x values. Then take the square root of that value.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const yHat = math.add(beta[0], math.multiply(beta[1][0], data.mod2.x));
          const resid = math.subtract(data.mod2.y, yHat);
          const sse =sumsqerr(resid);
          const ssx = sumsqerr(data.mod2.x);
          const dof = data.mod2.x.length - 2;
          const trueValue = beta[1][0] / math.sqrt((sse/dof)/ssx);
          const studValue = document.getElementById('m2q4').value;
          const result = compare(trueValue, studValue, 0.01) ? true : compare(-trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The t-statistic for the coefficient is ${math.round(trueValue,2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the t-statistic for the coefficient by dividing the coefficient by the standard error of the coefficient.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const studentChecked = [];
          let gradedArray = [];
          for(let question of this.problemSets.mod3){
            const trueValue = question.correct;
            const selectedAnswer = document.querySelector(`input[name=${question.id}]:checked`);
            const studValue = selectedAnswer ? selectedAnswer.value : null;
            studentChecked.push(studValue);
            const result = trueValue == studValue;
            gradedArray.push(result);
          }
          let feedbackArray = [
            {
              a1: `Question 1 is correct!`,
              a2: `Question 1: Think about whether this method compares outcomes over time or across groups. This might help you determine if it's the right answer.`,
              a3: `Question 1: An instrumental variable typically involves an external factor influencing the treatment. Reflect on whether that's happening in this scenario.`,
              a4: `Question 1: Regression discontinuity involves a cutoff point for assigning treatment. Consider whether such a cutoff exists in this case.`,
            },
            {
              a1: `Question 2: Random assignment of subjects to different conditions is central to one specific research design. Is that happening here?`,
              a2: `Question 2 is correct!`,
              a3: `Question 2: An instrumental variable analysis relies on an external variable. Think about whether such a variable is used here.`,
              a4: `Question 2: Regression discontinuity involves a cutoff point for treatment assignment. Does this example fit that description?`,
            },
            {
              a1: `Question 3: Random assignment of subjects to different conditions is not the focus here. Consider another design that uses external factors as instruments.`,
              a2: `Question 3: Think about whether this method involves comparing differences over time or between groups. This might help you rule out this option.`,
              a3: `Question 3 is correct!`,
              a4: `Question 3: Regression discontinuity involves a cutoff point for assigning treatment. Consider whether such a cutoff is relevant here.`,
            },
            {
              a1: `Question 4: Random assignment of subjects to different conditions is not the focus here. Reflect on whether there's a cutoff point for treatment.`,
              a2: `Question 4: This method might compare differences over time or between groups, but think about whether a cutoff point is crucial.`,
              a3: `Question 4: An instrumental variable relies on an external variable. Consider whether such a variable is used here.`,
              a4: `Question 4 is correct!`,
            }
          ];

          this.modalTitle = 'Multiple Choice Results';
          this.modalMessage = '';
          for(let i = 0; i < gradedArray.length; i++){
            if(studentChecked[i]){
              this.modalMessage += feedbackArray[i][studentChecked[i]] + '<br><br>';
            } else {
              this.modalMessage += 'You did not answer question ' + (i+1) + '<br><br>';
            }
          }

          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          for(let i = 0; i < gradedArray.length; i++){
            if(gradeMatrix[i] == 0 && gradedArray[i]){
              gradeMatrix[i] = 1;
            } 
          }

          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: studentChecked,
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
    }, 
    computed: {
      checkM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const trueValue = math.abs(x1 - x2);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q1;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const trueValue = math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const trueValue = math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q3;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const trueValue = n1 + n2 - 2;
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q4;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const trueValue = 2 * (1 - (studentt.cdf(math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2)), n1 + n2 - 2)));
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q5;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const t = math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const p = 2 * (1 - studentt.cdf(t, n1 + n2 - 2));
          const trueValue = p < 0.05 ? 'reject' : 'do_not_reject';
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q6;
          const result = trueValue == studValue;
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q7() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const diff = x1 - x2;
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const se = math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const dof = n1 + n2 - 2;
          const trueValueLower = diff - (se * studentt.inv(0.975, dof));
          const studRawLower = this.textbookResponses.mod1.q7;
          const studRawUpper = this.textbookResponses.mod1.q8;
          const resultLower = compare(trueValueLower, studRawLower, 0.0001) || compare(trueValueLower, -studRawUpper, 0.0001) ? true : false;
          if(studRawLower && resultLower){
            return {
              borderColor: 'green'
            }
          } else if (studRawLower) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q8() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod1.treatment);
          const x2 = math.mean(data.mod1.control);
          const diff = x1 - x2;
          const v1 = math.variance(data.mod1.treatment);
          const v2 = math.variance(data.mod1.control);
          const n1 = data.mod1.treatment.length;
          const n2 = data.mod1.control.length;
          const se = math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const dof = n1 + n2 - 2;
          const trueValueUpper = diff + (se * studentt.inv(0.975, dof));
          const studRawLower = this.textbookResponses.mod1.q7;
          const studRawUpper = this.textbookResponses.mod1.q8;
          const resultUpper = compare(trueValueUpper, studRawUpper, 0.0001) || compare(trueValueUpper, -studRawLower, 0.0001) ? true : false;
          if(studRawUpper && resultUpper){
            return {
              borderColor: 'green'
            }
          } else if (studRawUpper) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = linearRegression(data.mod2.x, data.mod2.y)[1];
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q1;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const trueValue = linearRegression(data.mod2.x, data.mod2.y)[0];
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q2;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const yHat = math.add(beta[0], math.multiply(beta[1][0], data.mod2.x));
          const resid = math.subtract(data.mod2.y, yHat);
          const sse =sumsqerr(resid);
          const ssx = sumsqerr(data.mod2.x);
          const dof = data.mod2.x.length - 2;
          const trueValue = math.sqrt((sse/dof)/ssx);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q3;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const beta = linearRegression(data.mod2.x, data.mod2.y);
          const yHat = math.add(beta[0], math.multiply(beta[1][0], data.mod2.x));
          const resid = math.subtract(data.mod2.y, yHat);
          const sse =sumsqerr(resid);
          const ssx = sumsqerr(data.mod2.x);
          const dof = data.mod2.x.length - 2;
          const trueValue = beta[1][0] / math.sqrt((sse/dof)/ssx);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q4;
          const result = compare(trueValue, studValue, 0.01) ? true : compare(-trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q1() {
        if(!this.userData){
          return null;
        } else {
          let styleArray = [];
          for(let [index, question] of this.problemSets.mod3.entries()) {
            const trueValue = question.correct; 
            const studValue = this.textbookResponses.mod3[index]; 
            const result = trueValue == studValue;
            if(studValue && result){
              styleArray.push({color: 'green'})
            } else if (studValue) {
              styleArray.push({color: 'red'})
            } else {
              styleArray.push(null);
            }
          }
          return styleArray;
        }
      },
    }
  }
  </script>
  
<style scoped>
.problem-set-table {
  width: 300px;
  border-collapse: collapse;
  margin: 6px 12px 24px 0px;
}
.problem-set-table td, .problem-set-table th{
  text-align: center;
  padding: 6px;
  border: 1px dashed var(--border_color);
}
.problem-set-table tr:nth-child(even) {
  background-color: var(--off_white);
}
</style>