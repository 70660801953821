<template>
  <div class="scatter-chart">
    <Line
      id="chart"
      :options="chartOptions"
      :data="chartData"
      class="scatter-chart"
    />
  </div>
</template>

<script>

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

import { Line } from 'vue-chartjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
  name: 'NormalDistribution',
  components: { Line },
  props: {
    mean: {
      type: Number,
      required: true
    },
    standardDeviation: {
      type: Number,
      required: true
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.generateLabels(),
        datasets: [
            {
              label: 'Dataset 1',
              borderColor: '#024c6f',
              backgroundColor: '#024c6f',
              data: this.generateData(),
              pointRadius: 0,
            },
          ]
      }
    },
    chartOptions() { return {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            min: 0,
            max: 0.08,
          },
        },
      }
    }
  },
  methods: {
    generateLabels() {
      // Generate labels for x-axis
      let labels = [];
      for(let i = 0; i <= 100; i += 1) {
        labels.push(i);
      }
      return labels;
    },
    generateData() {
      // Generate data for y-axis
      let data = [];
      for(let i = 0; i <= 100; i += 1) {
        let y = (1 / (this.standardDeviation * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * Math.pow((i - this.mean) / this.standardDeviation, 2));
        data.push(y);
      }
      return data;
    }
  }
}
</script>

<style scoped>
.scatter-chart {
  width: 100%;
  height: 100%;
}
</style>