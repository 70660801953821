<template>
  <div>
    <Scatter
      :data="chartData"
      :options="chartOptions"
      class="chart"
    />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LinearScale
} from 'chart.js'
import { Scatter } from 'vue-chartjs'

ChartJS.register(LinearScale, PointElement, Title, Tooltip, Legend);

import { linearRegression } from '@/main';
import { create, all } from 'mathjs'
const math = create(all);

export default {
  name: 'RegressionChart',
  components: { Scatter },
  props: {
    data: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chartData() {
      const { slope, intercept } = this.calculateBestFitLine(this.data);
      let fitLineData = this.data.map(point => ({
        x: point.x,
        y: slope * point.x + intercept
      }));

      if(this.multiple) {
        const yArray = this.data.map(point => point.y);
        const xArray = this.data.map(point => [point.x, point.x**2]);
        const betas = linearRegression(xArray, yArray); 
        fitLineData = this.data.map(point => ({
          x: point.x,
          y: math.add(betas[0], math.multiply(betas[1],point.x), math.multiply(betas[2],point.x**2))
        }));
      }

      return {
        datasets: [
          {
            label: 'Weekly Wage',
            fill: false,
            borderColor: '#000',
            backgroundColor: '#000',
            data: this.data,
          },
          {
            label: 'Regression Line',
            fill: false,
            borderColor: '#5c5c5c',
            borderDash: [5, 5],
            data: fitLineData,
            showLine: true,
            pointRadius: 0,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      };
    },
  },
  methods: {
    calculateBestFitLine(data) {
      const n = data.length;
      const sumX = data.reduce((sum, point) => sum + point.x, 0);
      const sumY = data.reduce((sum, point) => sum + point.y, 0);
      const sumXY = data.reduce((sum, point) => sum + point.x * point.y, 0);
      const sumX2 = data.reduce((sum, point) => sum + point.x * point.x, 0);

      const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
      const intercept = (sumY - slope * sumX) / n;

      return { slope, intercept };
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 100%;
}
</style>
