<template>
  <div class="scatter-chart">
    <Scatter
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
      class="scatter-chart"
    />
  </div>
</template>

<script>
import { Scatter } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement)

export default {
  name: 'ScatterChart',
  components: { Scatter },
  props: {
    scatterData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    chartData() { return {
        datasets: [
            {
              label: 'Scatter Dataset 1',
              fill: false,
              borderColor: '#024c6f',
              backgroundColor: '#024c6f',
              data: this.scatterData,
            },
          ]
      }
    },
    chartOptions() { return {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            type: 'linear',
            min: 0,
            max: 10,
            position: 'bottom',
          },
          y: {
            type: 'linear',
            min: 0,
            max: 10,
            position: 'left',
          },
        },
      }
    }
  },
}
</script>

<style>
.scatter-chart {
  width: 100%;
  height: 100%;
}
</style>