import { create, all } from 'mathjs'
const math = create(all);

export function binomial(n, x, p) {
  return math.combinations(n, x) * math.pow(p, x) * math.pow(1-p, n-x);
}
export function poisson(l, x) {
  return math.pow(l, x) * math.exp(-l) / math.factorial(x);
}
export function cumulativeBinomial(n, x, p) {
  let sum = 0;
  for(let i = 0; i <= x; i++){
    sum += binomial(n, i, p);
  }
  return sum;
}
export function cumulativePoisson(l, x) {
  let sum = 0;
  for(let i = 0; i <= x; i++){
    sum += poisson(l, i);
  }
  return sum;
}
export function standardNormalCDF(z) {
  return (1.0 + math.erf(z / math.sqrt(2))) / 2.0;
}
export function getZScore(p) {
  if (p <= 0 || p >= 1) {
      throw new Error("Probability must be between 0 and 1 (exclusive).");
  }

  // Coefficients for approximation
  const a1 = -39.69683028665376;
  const a2 = 220.9460984245205;
  const a3 = -275.9285104469687;
  const a4 = 138.3577518672690;
  const a5 = -30.66479806614716;
  const a6 = 2.506628277459239;

  const b1 = -54.47609879822406;
  const b2 = 161.5858368580409;
  const b3 = -155.6989798598866;
  const b4 = 66.80131188771972;
  const b5 = -13.28068155288572;

  const c1 = -7.784894002430293e-03;
  const c2 = -0.3223964580411365;
  const c3 = -2.400758277161838;
  const c4 = -2.549732539343734;
  const c5 = 4.374664141464968;
  const c6 = 2.938163982698783;

  const d1 = 7.784695709041462e-03;
  const d2 = 0.3224671290700398;
  const d3 = 2.445134137142996;
  const d4 = 3.754408661907416;

  // Define break-points
  const pLow = 0.02425;
  const pHigh = 1 - pLow;

  let q, r, z;

  // Rational approximation for lower region
  if (p < pLow) {
      q = Math.sqrt(-2 * Math.log(p));
      z = (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
          ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
  }
  // Rational approximation for upper region
  else if (pHigh < p) {
      q = Math.sqrt(-2 * Math.log(1 - p));
      z = -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
           ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
  }
  // Rational approximation for central region
  else {
      q = p - 0.5;
      r = q * q;
      z = (((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q /
          (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1);
  }

  return z;
}