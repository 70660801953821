<template>
  <div id="app" class="deck-draw">
    <div>Draw Cards <small>(click to draw)</small></div>
    <div class="deck" @click="drawHand">
      <div class="playing-card" v-for="card in hand" :key="card" :class="cardClass(card)">
        {{ card }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hand: [],
      deck: [
        '🂡', '🂢', '🂣', '🂤', '🂥', '🂦', '🂧', '🂨', '🂩', '🂪', '🂫', '🂭', '🂮', // Spades
        '🂱', '🂲', '🂳', '🂴', '🂵', '🂶', '🂷', '🂸', '🂹', '🂺', '🂻', '🂽', '🂾', // Hearts
        '🃁', '🃂', '🃃', '🃄', '🃅', '🃆', '🃇', '🃈', '🃉', '🃊', '🃋', '🃍', '🃎', // Diamonds
        '🃑', '🃒', '🃓', '🃔', '🃕', '🃖', '🃗', '🃘', '🃙', '🃚', '🃛', '🃝', '🃞'  // Clubs
      ]
    };
  },
  methods: {
    drawHand() {
      this.hand = [];
      let shuffledDeck = [...this.deck];
      for (let i = shuffledDeck.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledDeck[i], shuffledDeck[j]] = [shuffledDeck[j], shuffledDeck[i]];
      }
      this.hand = shuffledDeck.slice(0, 5);
    },
    cardClass(card) {
      return `animated flipInY ${this.getCardColor(card)}`;
    },
    getCardColor(card) {
      const blackCards = '🂡🂢🂣🂤🂥🂦🂧🂨🂩🂪🂫🂭🂮🃑🃒🃓🃔🃕🃖🃗🃘🃙🃚🃛🃝🃞';
      return blackCards.includes(card) ? 'black-card' : 'red-card';
    }
  },
  mounted() {
    this.drawHand();
  }
};
</script>

<style scoped>
.deck-draw {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 150px;
  width: 280px;
  border-radius: 8px;
  border: 1px solid var(--border_color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  padding: 0px 6px 6px 6px;
  background-color: var(--off_white);
  margin-bottom: 8px;
}

.deck {
  width: 288px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.playing-card {
  margin: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5em;
}

.black-card {
  color: black;
}

.red-card {
  color: red;
}
</style>

<style>

</style>
