<template>
  <div class="container mb-3">
    <div class="card p-2" style="border-radius: 15px;">
      <div class="text-center">Critical Value Calculator</div>
      <div class="d-flex align-items-center justify-content-center">
        <div class="form-group mr-3">
          <label for="confidenceLevel">Confidence Level:</label>
          <select
            v-model="confidenceLevel"
            class="form-control"
            style="width: 150px; margin-right: 10px;"
            id="confidenceLevel"
          >
            <option value="0.90">90%</option>
            <option value="0.95">95%</option>
            <option value="0.99">99%</option>
          </select>
        </div>
        <div class="form-group mr-3">
          <label for="degreesOfFreedom"><i>d.f.</i>:</label>
          <input
            type="number"
            v-model.number="degreesOfFreedom"
            class="form-control"
            style="width: 100px; margin-right: 10px;"
            id="degreesOfFreedom"
          />
        </div>
        <div class="form-group mr-3">
          <div v-if="criticalValue !== null">Critical Value: {{ criticalValue.toFixed(4) }}</div>
          <div v-if="criticalValue == null">Critical Value: </div>
          <button @click="calculateCriticalValue" class="btn btn-primary">Calculate Critical Value</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { studentt } from 'jstat';

export default {
  data() {
    return {
      confidenceLevel: 0.95,
      degreesOfFreedom: null,
      criticalValue: null,
    };
  },
  methods: {
    calculateCriticalValue() {
      if (this.degreesOfFreedom !== null) {
        const alpha = 1 - this.confidenceLevel;
        const tailProbability = alpha / 2;
        const df = this.degreesOfFreedom;

        // Calculate the critical value using the custom tinv function
        this.criticalValue = studentt.inv(1 - tailProbability, df);
      }
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 15px;
}
.form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
