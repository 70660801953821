<template>
  <div class="container col-md-6 offset-md-3">
    <div v-if="userData" class="settings-wrapper">
      <div class="d-flex justify-content-center">
        <h2>Settings</h2>
      </div>

      <div class="alt-email">
        <h4>Alternate Email</h4>
        <p>
          All Google Sheets will be shared with <span class="fw600">{{ userData.userInfo.email }}</span>. If you would like to share spreadsheets with an additional email 
          address, please enter it below.
        </p>
        <div class="alt-email-input">
          <input type="email" id="altEmail" name="altEmail" placeholder="Enter an alternate email address" :value="userData.userInfo.altEmail">
          <button @click="updateAltEmail">Update</button>
        </div>
      </div>
      
    </div>
  
  </div>  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, setUpStatsData } from '@/main';

  export default {
    name: "userInfoPage",
    props: ['tab'],
    data() {
      return {
        userData: null,
        setUpStatsData,
      }
    },
    created() {
      this.loadUserInformation();
    },
    methods: {
      async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullUserData();
      },
      async pullUserData() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        this.userData = data;
      },
      async updateAltEmail() {
        // replace update text in update button with a spinning wheel
        document.querySelector('.alt-email-input button').innerHTML = '<i class="fas fa-spinner fa-spin"></i>';
        const altEmail = document.getElementById('altEmail').value;
        if (!altEmail) {
          alert('Please enter an email address');
          return;
        }
        await setDoc(doc(database, 'users', auth.currentUser.uid), {
          userInfo: {
            altEmail: altEmail,
          }
        }, { merge: true });
        this.pullUserData();
        // set update button back to saying update and let the user know the email was updated
        document.querySelector('.alt-email-input button').innerHTML = 'Update';
        alert('Alternate email address updated');
      }
    }, 
    computed: {
    }
  }
  </script>
  
<style scoped>
.settings-wrapper {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
  background: #f9f9f9;
}
.alt-email {
  
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fafafa;
}
.alt-email-input {
  display: flex;
  align-items: center;
  width: 400px;
}
.alt-email-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.alt-email-input button {
  padding: 9px;
  background: var(--business_statistics);
  color: white;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  width: 100px;
}
.alt-email-input button:hover {
  background: var(--microeconomics);
}
</style>