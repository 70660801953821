<template>
<div>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a href="https://www.desmos.com/scientific" target="_blank"><button type="button" class="btn btn-outline-secondary">Open Desmos</button></a>
        <a href="https://www.wolframalpha.com/" target="_blank"><button type="button" class="btn btn-outline-secondary">Open WolframAlpha</button></a>
        <a href="https://chat.openai.com/" target="_blank"><button type="button" class="btn btn-outline-secondary">Open ChatGPT</button></a>
    </div>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">You have scored {{ score }} points out of {{ scoreTotal }} possible.</div>
</div>
</template>

<script>
export default {
    name: "CalculatorLinks",
    props: {
     score: {
      type: Number,
      required: true,
    },
     scoreTotal: {
      type: Number,
      required: true,
    },
  },
}
</script>