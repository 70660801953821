<template>
  <div>
    <Bar
      :data="chartData"
      :options="chartOptions"
      class="bar-chart"
    />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default {
  name: 'BarChart',
  components: { Bar },
  computed: {
    chartData() {
      return {
        labels: ['Doctoral degree', 'Professional degree', "Master's degree", "Bachelor's degree", "Associate's degree", 'Some college, no degree', 'High school diploma', 'Less than high school diploma'],
        datasets: [
          {
            label: 'Weekly Wage',
            data: [2083, 2080, 1661, 1432, 1005, 935, 853, 682],
            backgroundColor: [
              'rgba(8,79,9, 0.75)',
              'rgba(3,103,4, 0.75)',
              'rgba(21,120,17, 0.75)',
              'rgba(22,129,24, 0.75)',
              'rgba(62,156,53, 0.75)',
              'rgba(102,183,82, 0.75)',
              'rgba(142,210,111, 0.75)',
              'rgba(182,237,140, 0.75)',
            ],
            borderColor: [
              'rgb(13,55,14)',
              'rgb(13,55,14)',
              'rgb(13,55,14)',
              'rgb(13,55,14)',
              'rgb(13,55,14)',
              'rgb(13,55,14)',
              'rgb(13,55,14)',
              'rgb(13,55,14)',
            ],
            borderWidth: 1,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false,
          },
          scales: {
            x: {
              ticks: {
                // Format ticks to show as currency
                callback: function(value) {
                  return '$' + value;
                }
              }
            }
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += '$' + context.parsed.x;
                }
                return label;
              }
            }
          },
        },
      };
    },
  }
};
</script>

<style scoped>
.bar-chart{
  width: 100%;
  height: 100%;
  }
</style>