<template>
  <div class="container d-flex align-items-center justify-content-center">
    <div class="progress-bar-container">
      <div class="progress-bar-full">
        <div class="segment left grade-all"><div class="filled" :style="{ width: calculateGrade().f + '%' }" style="border-top-left-radius: 12px; border-bottom-left-radius: 12px;"></div><div class="grade-letter">F</div><div class="detail">You need at least 0 points for an F</div></div>
        <div class="segment middle grade-all"><div class="filled" :style="{ width: calculateGrade().d + '%' }"></div><div class="grade-letter">D</div><div class="detail">&gt;600 points</div></div>
        <div class="segment middle grade-all"><div class="filled" :style="{ width: calculateGrade().c + '%' }"></div><div class="grade-letter">C</div><div class="detail">&gt;700 points</div></div>
        <div class="segment middle grade-all"><div class="filled" :style="{ width: calculateGrade().b + '%' }"></div><div class="grade-letter">B</div><div class="detail">&gt;800 points</div></div>
        <div class="segment right grade-all"><div class="filled" :style="{ width: calculateGrade().a + '%' }" style="border-top-right-radius: 12px; border-bottom-right-radius: 12px;"></div><div class="grade-letter">A</div><div class="detail">&gt;900 points</div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { create, all } from 'mathjs'
const math = create(all);

export default {
  name: 'GradeProgressBar',
  props: {
    gradeBook: {
      type: Array,
      required: true
    },
    extraCredit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      grade: 0
    }
  },
  mounted() {
    
  },
  methods: {
    calculateGrade() {
      let grade = 0;
      grade += this.extraCredit.lesson;
      grade += this.extraCredit.ec;
      for (let i = 0; i < this.gradeBook.length; i++) {
        const chapter = this.gradeBook[i];
        const modules = Object.keys(chapter).filter(key => key.includes('mod'));
        for (let j = 0; j < modules.length; j++) {
          grade += math.sum(chapter[modules[j]]);
        }
        grade += chapter.lesson;
        grade += chapter.assignment;
      }
      return {
        a: grade >= 900 ? math.min((grade - 900),100) : 0,
        b: grade >= 800 ? math.min(grade - 800,100) : 0,
        c: grade >= 700 ? math.min(grade - 700,100) : 0,
        d: grade >= 600 ? math.min(grade - 600,100) : 0,
        f: math.round(math.min(grade, 600)/6,0)
      }

    },
  },
}
</script>

<style scoped>
.progress-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 828px;
  margin-top: 12px;
  margin-bottom: 0px;
  border-radius: 15px;
  background-color: white;
}

.progress-bar-full {
  display: flex;
}

.segment {
  height: 50px;
  background-color: white;
  position: relative;
  margin: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 2px;
}

.left {
  width: 400px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-left: 1px solid #ccc;
}

.middle {
  width: 125px;
}

.right {
  width: 125px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.filled {
  background-color: var(--business_statistics);
  height: 100%;
}

.grade-all {
  font-size: 1.75em;
  font-weight: 700;
  color: #ccc;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.grade-letter {
  margin-top: -50px;
}
.detail {
  font-size: 0.5em;
  font-weight: 400;
  margin-top: -12px;
}


</style>