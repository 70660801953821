<template>
<div class="container col-md-6 offset-md-3">

  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em1-25">Brian Goegan Presents</span><br>
      <span class="fw600 em3 fc-stats">Business Statistics</span>
    </div>
  </div>

  <!-- #region OPENING QUOTATION -->
  <div class="container" style="margin-top: 24px;">
    <div class="row justify-content-center">
      <div class="col-4">
        <img class="rounded" width="100%" :src="liesDamnedLies" />
      </div>
      <div class="col-8" style="font-size: 1.42em; font-weight: 300;">
        <p>
          Figures often beguile me, particularly when I have the arranging of them myself; 
          in which case the remark attributed to Disraeli would often apply with justice and force: 
          'There are three kinds of lies: <span style="font-weight:700; color: var(--business_statistics);">lies, damned lies, and statistics</span>.'
        </p>
        <p class="text-end"> - Mark Twain</p>
      </div>
    </div>
  </div>
  <!-- #endregion -->

  <VideoBox class="mt-4" videoTitle="Course Introduction" videoUrl="https://www.youtube.com/embed/jsHvus9-DLs" startAt="0"/>
  
  <!-- #region CAST OF CHARACTERS -->
  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em2 fc-stats">Meet Your New Friends!</span>
    </div>
  </div>
  <div class="card-group mt-4">
    <div class="card">
      <div class="card-img-top center" style="padding: 43px 28% 43px 28%"><img :src="Cell9000" width="100%" id="cell9000img"></div>
      <div class="card-body">
        <h5 class="card-title text-center">Cell9000</h5>
        <p class="card-text text-center">Your bespoke AI assistant,<br> helping you complete your spreadsheet assignments.</p>
      </div>
    </div>
    <div class="card">
      <div class="card-img-top center" style="padding: 12px 2% 6px 2%"><img :src="GradE"  width="100%" id="gradeimg"></div>
      <div class="card-body">
        <h5 class="card-title text-center">Grad±E</h5>
        <p class="card-text text-center">He zips around, grading everything you do. He's not disorganized, he left your grade there on purpose!</p>
      </div>
    </div>
    <div class="card">
      <div class="card-img-top center" style="padding: 12px 2% 6px 2%"><img :src="Probabilibot"  width="100%" id="probabilibotimg"></div>
      <div class="card-body">
        <h5 class="card-title text-center">Probabilibot</h5>
        <p class="card-text text-center">He can decipher Grad±E's hieroglyphic-like writing system and give feedback.</p>
      </div>
    </div>
  </div>
  <!-- #endregion -->


  <!-- #region SPREADSHEET MODULE -->
  <div v-if="userData" class="intructions-module rounded container">
    <VideoBox class="mt-3" videoTitle="Intro to Spreadsheets" videoUrl="https://www.youtube.com/embed/HMLyjME3PYU" startAt="0"/>
      <h3 class="fc-stats">Working in Google Sheets</h3>
      <p>
        Let's jump right into our main tool for learning statistics: Google Sheets! Click the button below to open up your own copy of the spreadsheet 
        we will be working in. Our goal is to create a spreadsheet you can use to help you budget your money based on your income. 
      </p>
      <div class="center">
        <router-link to="/stats/0/lesson" target="_blank" class="btn btn-primary open-spreadsheet-button">Open Practice Spreadsheet</router-link>
      </div>
      <p class="mt-3">Once you have opened your spreadsheet, play the video below and follow along.</p>
      <VideoBox class="mt-3" videoTitle="Example Lesson Walkthrough" videoUrl="https://www.youtube.com/embed/7aSbr10Jjng" startAt="0"/>
  </div>
  <!-- #endregion -->


  <!-- #region TOC MODULE -->
  <div class="intructions-module rounded container">
    <h3 class="fc-stats">What We Will Cover</h3>
    This course is split into two parts each with seven chapters. Each chapter consists of three elements:
    <ul>
      <li><span class="fw600">Learn:</span> Work through the chapter material, answering questions along the way. <span class="fc-prove em0-75">(20 points)</span></li>
      <li><span class="fw600">Explore:</span> Together we will work through a spreadsheet to apply the concepts learned. <span class="fc-prove em0-75">(20 points)</span></li>
      <li><span class="fw600">Complete:</span> A spreadsheet assignment where you will prove your mastery. <span class="fc-prove em0-75">(30 points)</span></li>
    </ul>
    <h5 class="fc-stats">Table of Contents</h5>
    <div class="card-group">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Part 1: Descriptive Statistics</h5>
          <div class="card-text">
            <table class="toc-table">
              <tr><th>1.</th><th><routerLink to="/stats/1" class="nolink">Basic Statistics</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">Central Tendency, Dispersion, and Shape</div></td></tr> 

              <tr><th>2.</th><th><routerLink to="/stats/2" class="nolink">Correlation is Not Causation</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">Why things may not be as they seem</div></td></tr>           
              
              <tr><th>3.</th><th><routerLink to="/stats/3" class="nolink">Never Tell Me the Odds!</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">An Introduction to Probability Theory</div></td></tr>   

              <tr><th>4.</th><th><routerLink to="/stats/4" class="nolink">The Improbable Legacy of Thomas Bayes</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">How to Evaluate New Evidence</div></td></tr>   

              <tr><th>5.</th><th><routerLink to="/stats/5" class="nolink">Please Be Discrete</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">Discrete Probability Distributions</div></td></tr>   

              <tr><th>6.</th><th><routerLink to="/stats/6" class="nolink">It's Perfectly Normal</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">Continuous Probability Distributions</div></td></tr>   

              <tr><th>7.</th><th><routerLink to="/stats/7" class="nolink">What are the Chances?</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">Slightly More Advanced Probability Theory</div></td></tr>   
            </table>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Part 2: Inferential Statistics</h5>
          <div class="card-text">
            <table class="toc-table">
              <tr><th>8.</th><th><routerLink to="/stats/8" class="nolink">The Central Limit Theorem</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">The Distribution of Sample Means</div></td></tr>  

              <tr><th>9.</th><th><routerLink to="/stats/9" class="nolink">Sampling with Confidence</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">Hypothesis Testing and Confidence Intervals</div></td></tr>  

              <tr><th>10.</th><th><routerLink to="/stats/10" class="nolink">Randomized Controlled Trials</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">Two-Sample Hypothesis Testing</div></td></tr>  

              <tr><th>11.</th><th><routerLink to="/stats/11" class="nolink">Ordinary Least Squares</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">Simple Linear Regression</div></td></tr>  

              <tr><th>12.</th><th><routerLink to="/stats/12" class="nolink">The Magic Elixer</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">Multiple Linear Regression</div></td></tr>  

              <tr><th>13.</th><th><routerLink to="/stats/13" class="nolink">The Keys to the Universe</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">The Path from Cause to Effect</div></td></tr>  

              <tr><th>14.</th><th><routerLink to="/stats/14" class="nolink">Good for Business</routerLink></th></tr>
              <tr><td></td><td><div class="toc-desc">A Few More Applications</div></td></tr>  
            </table>
          </div>
        </div>  
      </div>  
    </div>  
    <!-- <h3 class="fc-stats mt-3">Video Syllabus</h3>
    This video will walk you through how the course works and what you need to do to complete it. 
    <div class="big-video-box container rounded d-flex align-items-center justify-content-center">SPACE FOR SYLLABUS VIDEO</div> -->
  </div>
  <!-- #endregion -->
</div>
<br><br><br>
</template>

<script>
import { auth, database, setUpStatsData } from '@/main';
import { doc, getDoc } from "firebase/firestore"; 

import VideoBox from '@/components/VideoBox.vue';

import liesDamnedLies from '@/assets/lies-damed-lies-and-statistics.jpg';
import Probabilibot from '@/assets/Probabilibot.png';
import GradE from '@/assets/Grad-e.png';
import Cell9000 from '@/assets/CELL9000.png';

export default {
  name: "statsMainPage",
  components: {
    VideoBox,
  },
  data() {
    return {
      liesDamnedLies,
      Probabilibot,
      GradE,
      Cell9000,
      userData: null,
      setUpStatsData,
    }
  },
  created() {
    this.loadUserInformation();
  },
  mounted() {

  },
  methods: {
    async loadUserInformation() {
      await this.setUpStatsData();
      await this.pullProblemSet();
      this.setUpEventListeners();
    },
    async pullProblemSet() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      let docSnap = await getDoc(docRef);
      let data = docSnap.data();
      this.userData = data;
    },
    setUpEventListeners() {
      document.getElementById('cell9000img').addEventListener('click', function() {
        this.classList.add('jiggling');
        this.addEventListener('animationend', () => {this.classList.remove('jiggling');});
      });
      document.getElementById('probabilibotimg').addEventListener('click', function() {
        this.classList.add('jiggling');
        this.addEventListener('animationend', () => {this.classList.remove('jiggling');});
      });
      document.getElementById('gradeimg').addEventListener('click', function() {
        this.classList.add('jiggling');
        this.addEventListener('animationend', () => {this.classList.remove('jiggling');});
      });
    }
  },
}
</script>

<style scoped>
.open-spreadsheet-button {
  background-color: var(--business_statistics); 
  border: none;
  color: white; 
  padding: 8px 12px; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block;
  font-size: 1em; 
  cursor: pointer;
  border-radius: 7px; 
  margin-top: 6px;
}
.open-spreadsheet-button:hover {
  background-color: #51859e;
}
.open-spreadsheet-button:active {
    background-color: var(--business_statistics); 
}
@keyframes jiggle {
  0%, 100% { transform: rotate(-3deg); }
  50% { transform: rotate(3deg); }
}
.jiggling {
  animation: jiggle 0.2s ease-in-out 2;
}
.toc-table {
  /* no space between rows */
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}
.toc-table th {
  padding: 0px;
}
.toc-table tr {
  padding: 0px;
}
.toc-table td {
  padding: 0px;
}
.toc-desc {
  font-size: 0.8em;
  color: gray;
}
</style>