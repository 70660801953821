import { createRouter, createWebHistory } from 'vue-router';
import { auth } from '../main';
import { onAuthStateChanged } from 'firebase/auth';

import mainPage from './mainPage.vue';
import loginPage from './login.vue';
import registerPage from './register.vue';
import passwordReset from './passwordReset.vue';
import settingsPage from './settings.vue';
import statsGradeBook from './stats/gradeBook.vue';
import statsMainPage from './stats/statsMainPage.vue';
import AssignmentViewer from './stats/AssignmentViewer.vue';
import ControlPanel from './ControlPanel.vue';
// Import chapters 1 through 14 for stats
import statsCh1 from './stats/Chapter1.vue';
import statsCh2 from './stats/Chapter2.vue';
import statsCh3 from './stats/Chapter3.vue';
import statsCh4 from './stats/Chapter4.vue';
import statsCh5 from './stats/Chapter5.vue';
import statsCh6 from './stats/Chapter6.vue';
import statsCh7 from './stats/Chapter7.vue';
import statsCh8 from './stats/Chapter8.vue';
import statsCh9 from './stats/Chapter9.vue';
import statsCh10 from './stats/Chapter10.vue';
import statsCh11 from './stats/Chapter11.vue';
import statsCh12 from './stats/Chapter12.vue';
import statsCh13 from './stats/Chapter13.vue';
import statsCh14 from './stats/Chapter14.vue';


const routesArray = [
    {
        // redirect to /stats by default
        path: '/',
        redirect: '/stats',
        meta: { requiresAuth: true }
    },
    {
        path: '/information',
        component: mainPage,  // Putting main page here for now
        meta: { requiresAuth: false }
    },
    {
        path: '/controlpanel',
        component: ControlPanel,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        component: loginPage,
        meta: { requiresAuth: false }
    },
    {
        path: '/register',
        component: registerPage,
        meta: { requiresAuth: false }
    },
    {
        path: '/password-reset',
        component: passwordReset,
        meta: { requiresAuth: false }
    },
    {
        path: '/settings',
        component: settingsPage,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/gradebook',
        component: statsGradeBook,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats',
        component: statsMainPage,
        meta: { requiresAuth: true }
    }, 
    {
        path: '/stats/1',
        component: statsCh1,
        meta: { requiresAuth: true }
    }, 
    {
        path: '/stats/2',
        component: statsCh2,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/3',
        component: statsCh3,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/4',
        component: statsCh4,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/5',
        component: statsCh5,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/6',
        component: statsCh6,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/7',
        component: statsCh7,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/8',
        component: statsCh8,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/9',
        component: statsCh9,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/10',
        component: statsCh10,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/11',
        component: statsCh11,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/12',
        component: statsCh12,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/13',
        component: statsCh13,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/14',
        component: statsCh14,
        meta: { requiresAuth: true }
    },
    {
        path: '/stats/:ch/:task',
        component: AssignmentViewer,
        meta: { requiresAuth: true }
    },
];

// Create router instance
export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routesArray,
});

let isAuthReady = false; // Flag to check if initial auth status is resolved

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    // Ensure the authentication state is ready before proceeding
    if (!isAuthReady) {
        onAuthStateChanged(auth, (user) => {
            isAuthReady = true;
            if(to.path === '/controlpanel' && user.email !== 'brian.goegan@paradisevalley.edu') {
                next({
                    path: '/',
                });
            }
            else if (requiresAuth && !user) {
                next({
                    path: '/login',
                    query: { redirect: to.fullPath } // Store the full path they were trying to access
                });
            } else {
                next();
            }
        });
    } else {
        if (requiresAuth && !auth.currentUser) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        } else {
            next();
        }
    }
});

