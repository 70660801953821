<template>
<div class="d-flex align-items-center justify-content-center">
  <div style="width: 1200px;">
    <!-- #region CHAPTER AND TITLE -->
    <div>
      <div class="book-title-div">
          <div class="fw600 em3" style="margin-bottom: -18px;">Gradebook</div>
          <div class="fw400 em1-50">-<span class="fc-prove">★★★★★</span>-</div>
          <div style="font-style: italic;">"My recommendation is to get an A." - Dr. Brian Goegan, PhD</div>
      </div>
    </div>
    <!-- #endregion -->

    <div class="d-flex align-items-center justify-content-center">
      <img :src="GradE" alt="Grad-e" style="width: 300px;">
    </div>

    <div v-if="grades.stats" class="code-style" style="text-align: center; line-height: 120%;">
      Grade±E <br>
      I have computed your grade below. You currently have {{ gradeStatsModulesTotal('top') + gradeStatsLessonTotal('top') + gradeStatsAssignmentTotal('top') + grades.statsEC.lesson + grades.statsEC.ec }} total points. <br>
      The progress bar below shows your progress towards a final grade. <br>
      If you stopped now, you would receive a{{ finalGradeCalculator(gradeStatsModulesTotal('top') + gradeStatsLessonTotal('top') + gradeStatsAssignmentTotal('top') + grades.statsEC.lesson + grades.statsEC.ec) }} for the course. <br>
      You can review each individual grade below the progress bar. 
    </div>

    <div v-if="grades.stats && grades.statsEC">
      <GradeProgressBar :gradeBook="grades.stats" :extraCredit="grades.statsEC" />
    </div>

    <!-- #region STATISTICS GRADES FOR MODULES -->
    <div v-if="grades.stats" class="gradebook-chapter-module container">
      <div style="display: flex; border-bottom: 1px solid #C0C0C0; margin-bottom: 12px;">
        <div class="gradebook-grade-container-source"><b>Chapter</b></div>
        <div class="gradebook-grade-container-item"><b>Assignment Type</b></div>
        <div class="gradebook-grade-container-grade"><b class="fc-stats">Grade</b></div>
        <div class="gradebook-grade-container-percent"><b>Percentage</b></div>
      </div>
      <!-- Chapter Modules -->
      <div v-for="(chapter, index) of grades.stats" :key="index">
        <div class="gradebook-grade-container">
          <div class="gradebook-grade-container-source">
            <router-link class="nolink" style="cursor: pointer !important;" :to="'/stats/'+(index+1)">Ch. {{ index+1 }}: {{ statsChapterTitles[index] }}</router-link>
          </div>
          <div class="gradebook-grade-container-item">Modules</div>
          <div class="gradebook-grade-container-grade"><b class="fc-stats">{{ gradeStatsModules(chapter, 'score') }}</b>&nbsp;/ 20</div>
          <div class="gradebook-grade-container-percent">{{ gradeStatsModules(chapter, 'percentage') }}%</div>
        </div>
        <div class="my-progress-bar">
          <div class="my-progress-bar-fill" :style="{ width: gradeStatsModules(chapter, 'width') + '%' }"></div>
        </div>  
      </div>
      <!-- Totals -->
      <div style="display: flex; border-top: 1px solid #C0C0C0; margin-bottom: 12px;">
        <div class="gradebook-grade-container-source">&nbsp;</div>
        <div class="gradebook-grade-container-item"><b>Total</b></div>
        <div class="gradebook-grade-container-grade"><b class="fc-stats">{{ gradeStatsModulesTotal('top') }}</b>&nbsp;/ {{ gradeStatsModulesTotal('bottom') }}</div>
        <div class="gradebook-grade-container-percent"><b>{{ gradeStatsModulesTotal('percent') }}%</b></div>
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region STATISTICS GRADES FOR LESSONS -->
    <div v-if="grades.stats" class="gradebook-chapter-module container">
      <!-- Header Row -->
      <div style="display: flex; border-bottom: 1px solid #C0C0C0; margin-bottom: 12px;">
        <div class="gradebook-grade-container-source"><b>Chapter</b></div>
        <div class="gradebook-grade-container-item"><b>Assignment Type</b></div>
        <div class="gradebook-grade-container-grade"><b class="fc-stats">Grade</b></div>
        <div class="gradebook-grade-container-percent"><b>Percentage</b></div>
      </div>
      <!-- Exploratory Lessons -->
      <div v-for="(chapter, index) of grades.stats" :key="index">
        <div class="gradebook-grade-container">
          <div class="gradebook-grade-container-source">
            <router-link class="nolink" :to="'/stats/'+(index+1)">Ch. {{ index+1 }}: {{ statsChapterTitles[index] }}</router-link>
          </div>
          <div class="gradebook-grade-container-item">Exploratory Lesson</div>
          <div class="gradebook-grade-container-grade"><b class="fc-stats">{{ gradeStatsAssignments(chapter, 'lesson', 'score') }}</b>&nbsp;/ 20</div>
          <div class="gradebook-grade-container-percent">{{ gradeStatsAssignments(chapter, 'lesson', 'percentage') }}%</div>
        </div>
        <div class="my-progress-bar">
          <div class="my-progress-bar-fill" :style="{ width: (chapter.lesson / 20 * 100).toFixed(0) + '%' }"></div>
        </div>  
      </div>
      <!-- Totals -->
      <div style="display: flex; border-top: 1px solid #C0C0C0; margin-bottom: 12px;">
        <div class="gradebook-grade-container-source">&nbsp;</div>
        <div class="gradebook-grade-container-item"><b>Total</b></div>
        <div class="gradebook-grade-container-grade"><b class="fc-stats">{{ gradeStatsLessonTotal('top') }}</b>&nbsp;/ {{ gradeStatsLessonTotal('bottom') }}</div>
        <div class="gradebook-grade-container-percent"><b>{{ gradeStatsLessonTotal('percent') }}%</b></div>
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region STATISTICS GRADES FOR ASSIGNMENTS -->
    <div v-if="grades.stats" class="gradebook-chapter-module container">
      <div style="display: flex; border-bottom: 1px solid #C0C0C0; margin-bottom: 12px;">
        <div class="gradebook-grade-container-source"><b>Chapter</b></div>
        <div class="gradebook-grade-container-item"><b>Assignment Type</b></div>
        <div class="gradebook-grade-container-grade"><b class="fc-stats">Grade</b></div>
        <div class="gradebook-grade-container-grade"><b>Percentage</b></div>
      </div>
      <div v-for="(chapter, index) of grades.stats" :key="index">
        <!-- Chapter Modules -->
        <div class="gradebook-grade-container">
          <div class="gradebook-grade-container-source">
            <router-link class="nolink" :to="'/stats/'+(index+1)">Ch. {{ index+1 }}: {{ statsChapterTitles[index] }}</router-link>
          </div>
          <div class="gradebook-grade-container-item">Spreadsheet Assignment</div>
          <div class="gradebook-grade-container-grade"><b class="fc-stats">{{ gradeStatsAssignments(chapter, 'assignment', 'score') }}</b> / 30</div>
          <div class="gradebook-grade-container-grade">{{ gradeStatsAssignments(chapter, 'assignment', 'percentage') }}%</div>
        </div>
        <div class="my-progress-bar">
          <div class="my-progress-bar-fill" :style="{ width: (chapter.assignment / 30 * 100).toFixed(0) + '%' }"></div>
        </div> 
      </div>
      <!-- Totals -->
      <div style="display: flex; border-top: 1px solid #C0C0C0; margin-bottom: 12px;">
        <div class="gradebook-grade-container-source">&nbsp;</div>
        <div class="gradebook-grade-container-item"><b>Total</b></div>
        <div class="gradebook-grade-container-grade"><b class="fc-stats">{{ gradeStatsAssignmentTotal('top') }}</b>&nbsp;/ {{ gradeStatsAssignmentTotal('bottom') }}</div>
        <div class="gradebook-grade-container-grade"><b>{{ gradeStatsAssignmentTotal('percent') }}%</b></div>
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region STATISTICS GRADES FOR EXTRA CREDIT -->
    <div v-if="grades.statsEC" class="gradebook-chapter-module container">
      <div style="display: flex; border-bottom: 1px solid #C0C0C0; margin-bottom: 12px;">
        <div class="gradebook-grade-container-source"><b>Chapter</b></div>
        <div class="gradebook-grade-container-item"><b>Assignment Type</b></div>
        <div class="gradebook-grade-container-grade"><b class="fc-stats">Grade</b></div>
        <div class="gradebook-grade-container-percent"><b>Percentage</b></div>
      </div>
      <div>
        <!-- Example Lesson-->
        <div class="gradebook-grade-container">
          <div class="gradebook-grade-container-source">
            <router-link class="nolink" :to="'/stats'">Introduction</router-link>
          </div>
          <div class="gradebook-grade-container-item">Example Lesson</div>
          <div class="gradebook-grade-container-grade"><b class="fc-stats">{{ grades.statsEC.lesson }}</b>&nbsp;/ 20</div>
          <div class="gradebook-grade-container-percent">{{ (100*grades.statsEC.lesson/20).toFixed(0) }}%</div>
        </div>
        <div class="my-progress-bar">
          <div class="my-progress-bar-fill" :style="{ width: (100*grades.statsEC.lesson/20).toFixed(0) + '%' }"></div>
        </div> 
      </div>
      <div>
        <!-- Extra Credit -->
        <div class="gradebook-grade-container">
          <div class="gradebook-grade-container-source">
            <router-link class="nolink" :to="'/stats'">-</router-link>
          </div>
          <div class="gradebook-grade-container-item">Extra Credit</div>
          <div class="gradebook-grade-container-grade"><b class="fc-stats">{{ grades.statsEC.ec }}</b>&nbsp;/ 0</div>
          <div class="gradebook-grade-container-percent">-%</div>
        </div>
        <div class="my-progress-bar">
          <div class="my-progress-bar-fill" :style="{ width: 100 + '%' }"></div>
        </div> 
      </div>
      <!-- Totals -->
      <div style="display: flex; border-top: 1px solid #C0C0C0; margin-bottom: 12px;">
        <div class="gradebook-grade-container-source">&nbsp;</div>
        <div class="gradebook-grade-container-item"><b>Total</b></div>
        <div class="gradebook-grade-container-grade"><b class="fc-stats">{{ grades.statsEC.lesson+grades.statsEC.ec }}</b>&nbsp;/ 20</div>
        <div class="gradebook-grade-container-percent"><b>{{ (100*(grades.statsEC.lesson+grades.statsEC.ec)/20).toFixed(0) }}%</b></div>
      </div>
    </div>
    <!-- #endregion -->
  </div>
</div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore"; 
import { auth, database } from '@/main';

import { create, all } from 'mathjs'
const math = create(all);

import GradeProgressBar from '@/components/GradeProgressBar.vue';

import Probabilibot from '@/assets/Probabilibot.png';
import GradE from '@/assets/Grad-e.png';

export default {
  name: 'gradeBook',
  components: {
    GradeProgressBar
  },
  data() {
    return {
      Probabilibot,
      GradE,
      grades: {
        stats: null,
        statsEC: null,
        micro: null,
        macro: null,
      },
      statsChapterTitles: [
        "Basic Statistics",
        "Correlation is Not Causation",
        "Never Tell Me the Odds!",
        "The Improbable Legacy of Thomas Bayes",
        "Please Be Discrete",
        "It's Perfectly Normal",
        "What are the Chances?",
        "The Central Limit Theorem",
        "Sampling with Confidence",
        "Randomized Controlled Trials",
        "Ordinary Least Squares",
        "The Magic Elixer",
        "The Keys to the Universe",
        "Good for Business",
      ],
    };
  },
  created() {
    this.getStudentGradebook();
  },
  methods: {
    async getStudentGradebook() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      let docSnap = await getDoc(docRef);
      let data = docSnap.data();
      console.log(data)
      if (data.stats) {
        this.grades.stats = [
          data.stats.gradeBook.ch1,
          data.stats.gradeBook.ch2,
          data.stats.gradeBook.ch3,
          data.stats.gradeBook.ch4,
          data.stats.gradeBook.ch5,
          data.stats.gradeBook.ch6,
          data.stats.gradeBook.ch7,
          data.stats.gradeBook.ch8,
          data.stats.gradeBook.ch9,
          data.stats.gradeBook.ch10,
          data.stats.gradeBook.ch11,
          data.stats.gradeBook.ch12,
          data.stats.gradeBook.ch13,
          data.stats.gradeBook.ch14,
        ];
        this.grades.statsEC = data.stats.gradeBook.ch0;
      }
    },
    gradeStatsModules(chapter, output) {
      const modules = Object.keys(chapter).filter(key => key.includes('mod'));
      let grade = 0;
      for (let i = 0; i < modules.length; i++) {
        grade += math.sum(chapter[modules[i]]);
      }
      if(output === 'width') return (grade / 20 * 100).toFixed(0);
      if(grade === 0) return '-';
      if(output === 'score') return grade;
      if(output === 'percentage') {
        const percentage = (grade / 20 * 100).toFixed(2);
        return percentage.endsWith('.00') ? parseInt(percentage) : parseFloat(percentage);
      }
      return grade;
    },
    gradeStatsAssignments(chapter, type, output) {
      const grade = chapter[type] ? chapter[type] : null;
      const divisor = type === 'lesson' ? 20 : 30;
      if(grade === 0 || grade === null) return '-';
      if(output === 'score') return grade;
      if(output === 'percentage') {
        const percentage = (grade / divisor * 100).toFixed(2);
        return percentage.endsWith('.00') ? parseInt(percentage) : parseFloat(percentage);
      }
      return grade;
    },
    gradeStatsModulesTotal(style) {
      if(this.grades.stats === null) return '-';
      let total = 0;
      let attempted = 0;
      for (let i = 0; i < this.grades.stats.length; i++) {
        const thisGrade = this.gradeStatsModules(this.grades.stats[i], 'score');
        if(thisGrade !== '-') {
          total += thisGrade;
          attempted += 20;
        }
      }
      if(style === 'top') return total;
      if(style === 'bottom') return attempted;
      if(style === 'percent') {
        const percentage = (total / attempted * 100).toFixed(2);
        return percentage.endsWith('.00') ? parseInt(percentage) : parseFloat(percentage);
      }
    },
    gradeStatsLessonTotal(style) {
      const lessons = this.grades.stats.map(item => item.lesson).filter(item => item !== null);
      if(style === 'top') return math.sum(lessons);
      if(style === 'bottom') return lessons.length * 20;
      if(style === 'percent') {
        const percentage = (math.sum(lessons) / (lessons.length * 20) * 100).toFixed(2);
        return percentage.endsWith('.00') ? parseInt(percentage) : parseFloat(percentage);
      }
    },
    gradeStatsAssignmentTotal(style) {
      const assignments = this.grades.stats.map(item => item.assignment).filter(item => item !== null);
      if(style === 'top') return math.sum(assignments);
      if(style === 'bottom') return assignments.length * 30;
      if(style === 'percent') {
        const percentage = (math.sum(assignments) / (assignments.length * 30) * 100).toFixed(2);
        return percentage.endsWith('.00') ? parseInt(percentage) : parseFloat(percentage);
      }
    },
    finalGradeCalculator(score) {
      if (score >= 900) {
        return 'n A';
      } else if (score >= 800) {
        return ' B';
      } else if (score >= 700) {
        return ' C';
      } else if (score >= 600) {
        return ' D';
      } else {
        return 'n F';
      }
    },
  },
};
</script>

<style>
.gradebook-chapter-module{
  padding: 6px 12px;
  margin-top: 12px;
  border: 1px solid var(--border_color);
  border-radius: 15px;
  width: 800px;
}
.gradebook-chapter-title {
  font-size: 1.25em;
  font-weight: 500;
  color: var(--business_statistics);
}
.gradebook-grade-container {
  z-index: 1;
  display: flex;
  border: 1px dashed var(--border_color);
  border-radius: 15px;
  width: auto;
  margin-bottom: -1.5em;
}
.gradebook-grade-container:hover {
  background-color: var(--off_white);
  z-index: -2;
}
.gradebook-grade-container-source {
  margin-left: 12px;
  padding: 3px;
  width: 390px;
  font-weight: 500;
  color: var(--business_statistics);
}
.gradebook-grade-container-item {
  padding: 3px;
  width: 220px;
  font-weight: 500;
}
.gradebook-grade-container-grade {
  padding: 3px;
  width: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.gradebook-grade-container-percent {
  padding: 3px;
  width: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.my-progress-bar {
  position: relative;
  top: -0.5em;
  width: 100%;
  height: 2em;
  border-radius: 15px;
  z-index: -1;
}
.my-progress-bar-fill {
  height: 100%;
  background-color: rgba(66, 135, 245, 0.25);
  border-radius: 15px;
}
</style>