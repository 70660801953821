<template>
    <div class="video-container">
        <div v-if="showThumbnail" class="video-thumbnail" @click="playVideo">
            <div class="video-marker">Video:</div>
            <div class="video-title" :style="{ fontSize: fontSize }">{{ videoTitle }}</div>
            <img :src="CharacterGroup" alt="Character Group">
            <div class="play-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
                </svg>
            </div>
        </div>
        <iframe v-if="!showThumbnail" 
                :src="videoFullUrl"
                title="YouTube video player" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
        </iframe>
    </div>
</template>

<script>
import CharacterGroup from '@/assets/CharacterGroup.png';

export default {
    props: {
        videoUrl: {
            type: String,
            required: true
        },
        videoTitle: {
            type: String,
            required: true
        },
        startAt: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            showThumbnail: true,
            videoFullUrl: this.getEmbedUrl(this.videoUrl),
            CharacterGroup,
        }
    },
    methods: {
        playVideo() {
            this.showThumbnail = false;
        },
        getEmbedUrl(url) {
            const videoId = url.split('v=')[1] || url.split('/').pop(); // Extract the video ID
            const ampersandPosition = videoId.indexOf('&');
            if (ampersandPosition !== -1) {
                return `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}?autoplay=1&rel=0&start=${this.startAt}`;
            }
            return `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&start=${this.startAt}`;
        }
    },
    computed: {
        fontSize() {
        if (this.videoTitle.length < 20) {
            return '2.25rem'; // Large font size for short titles
        } else if (this.videoTitle.length < 30) {
            return '2rem'; // Medium font size for medium titles
        } else {
            return '1.75rem'; // Small font size for long titles
        }
        },
    },
}
</script>

<style>
.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    margin-bottom: 12px;
}

.video-thumbnail, .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px double var(--border_color);
    border-radius: 20px;
}

.video-thumbnail {
    cursor: pointer;
    background-color: var(--business_statistics);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--text_color);
    background: repeating-linear-gradient(
                    45deg,
                    #1a6b91,
                    #1a6b91 1px,
                    var(--business_statistics) 1px,
                    var(--business_statistics) 2px
                );
}

.video-thumbnail img {
    width: 75%;
    height: auto;
}

.play-button {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.play-button svg {
    width: 15vw;
    height: 15vh;
}
.video-marker {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
}
.video-title {
    font-weight: bold;
    margin: 0 0 0 0;
    padding: 0;
    text-align: center;
    color: white;
}
</style>