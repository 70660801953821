<template>
  <div class="container col-md-6 offset-md-3">
  
  <!-- #region CHAPTER AND TITLE -->
  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em1-50">Chapter Seven</span><br>
      <span class="fw800 em3 fc-stats">What are the Chances?</span>
    </div>
  </div>
  <!-- #endregion -->
  
  <!-- SUB NAVIGATION BAR -->
  <nav>
    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
      <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
      <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
    </div>
  </nav>
  
  <!-- SUB NAV BAR CONTENT -->
  <div class="tab-content" id="nav-tabContent">
    <!-- TEXTBOOK -->
    <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
      
      <VideoBox class="mt-4" videoTitle="What are the Chances?" videoUrl="https://www.youtube.com/embed/E8Tbi9VPv_Y" startAt="0"/>

      <!-- #region MODULE 1 -->
      <div v-if="userData" class="intructions-module rounded container">
        <h3 class="fc-stats">Probability Puzzles</h3>
        <p>
          Here we are at midpoint of our course on Business Statistics. So far, we have covered basic statistics, correlation, probability, 
          and probability distributions. Now we are going to put everything together in a kind of quasi-midterm. Here is your cheat sheet.
        </p>

        <div class="cheat-sheet-container">
          <table class="cheat-sheet">
            <tr>
              <td>
                <h5>Counting Rule #1</h5>
                <p>If any one of <MathEquation :equation="'k'"/> different mutually exclusive and collectively exhaustive events can occur on each of <MathEquation :equation="'n'"/> trials, the number of possible outcomes is equal to:</p>
                <p><MathEquation :equation="'k^n'"/></p>
              </td>
              <td>
                <h5>Counting Rule #2</h5>
                <p>If there are <MathEquation :equation="'k_1'"/> events on the first trial, <MathEquation :equation="'k_2'"/> events on the second trial, ..., and <MathEquation :equation="'k_n'"/> events on the <MathEquation :equation="'n'"/>th trial, then the number of possible outcomes is:</p>
                <p><MathEquation :equation="'k_1 \\times k_2 \\times ... \\times k_n'"/></p>
              </td>
              <td>
                <h5>Counting Rule #3</h5>
                <p>The number of ways that <MathEquation :equation="'n'"/> items can be arranged in order is:</p>
                <p><MathEquation :equation="'n! = n \\times (n - 1) \\times (n - 2) \\times ... \\times 1'"/></p>
              </td>
            </tr>
          </table>
  
          <table class="cheat-sheet">
            <tr>
              <td>
                <h5>Counting Rule #4</h5>
                <p>Select <MathEquation :equation="'k'"/> items from <MathEquation :equation="'n'"/> items. Permutations (order matters):</p>
                <p><MathEquation :equation="'P = \\frac{n!}{(n - k)!}'"/></p>
              </td>
              <td>
                <h5>Counting Rule #5</h5>
                <p>Select <MathEquation :equation="'k'"/> items from <MathEquation :equation="'n'"/> items. Combinations (order does not matter):</p>
                <p><MathEquation :equation="'C = \\frac{n!}{k!(n - k)!}'"/></p>
              </td>
            </tr>
          </table>

          <table class="cheat-sheet">
            <tr>
              <td>
                <h5>Probability</h5>
                <p><MathEquation :equation="'P(A) = \\frac{\\text{The number of ways in which Event A occurs}}{\\text{The total number of possible outcomes in the sample space}}'"/></p>
              </td>
            </tr>
          </table>
  
          <table class="cheat-sheet">
            <tr>
              <td>
                <h5>Addition Rule</h5>
                <p><MathEquation :equation="'P(\\text{A and B}) = P(A) + P(B) - P(\\text{A or B})'"/></p>
              </td>
              <td>
                <h5>Bayes Theorem</h5>
                <p><MathEquation :equation="'P(A|B) = \\frac{P(B|A) \\times P(A)}{P(B|A) \\times P(A) + P(B|\\neg A) \\times P(\\neg A)}'"/></p>
              </td>
            </tr>
          </table>
  
          <table class="cheat-sheet">
            <tr>
              <td>
                <h5>Binomial Distribution</h5>
                <p><MathEquation :equation="'p(x|n, p) = \\frac{n!}{x!(n - x)!} p^x (1 - p)^{n - x}'"/></p>
              </td>
              <td>
                <h5>Poisson Distribution</h5>
                <p><MathEquation :equation="'p(x|\\lambda) = \\frac{e^{-\\lambda} \\lambda^x}{x!}'"/></p>
              </td>
            </tr>
          </table>
          
          <table class="cheat-sheet">
            <tr>
              <td>
                <h5>Normal Distribution</h5>
                <p><MathEquation :equation="'p(x|\\mu, \\sigma) = \\frac{1}{\\sigma \\sqrt{2\\pi}} e^{\\frac{-(x - \\mu)^2}{2\\sigma^2}}'"/></p>
              </td>
              <td>
                <h5>Standard Normal Distribution</h5>
                <p><MathEquation :equation="'Z \\sim N(0,1)'"/></p>
              </td>
            </tr>
          </table>
        </div>

        <p style="font-size: 1.25rem; font-weight: 600; margin: 12px 0 0 12px;">
          Before you head into the questions below, check out this very short video.
        </p>
        <VideoBox videoUrl="https://www.youtube.com/embed/zvrleanEYOw" videoTitle="Best Way to Learn Math" startAt="0" />

        <div class="mt-3" v-if="userData">
          <h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Answer each of the questions below using all you know about statistics.
          </p>

        </div>
        
        <div class="d-flex justify-content-center align-items-center">
          <div id="moduleOneCarousel" class="carousel carousel-dark slide carousel-wrapper">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#moduleOneCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#moduleOneCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#moduleOneCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
              <button type="button" data-bs-target="#moduleOneCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
              <button type="button" data-bs-target="#moduleOneCarousel" data-bs-slide-to="4" aria-label="Slide 5"></button>
              <button type="button" data-bs-target="#moduleOneCarousel" data-bs-slide-to="5" aria-label="Slide 6"></button>
              <button type="button" data-bs-target="#moduleOneCarousel" data-bs-slide-to="6" aria-label="Slide 7"></button>
            </div>
            <div class="carousel-inner">

              <!-- QUESTION 1 -->
              <div class="carousel-item active">
                <div class="d-block w-100">
                  <div class="carousel-title">Question 1</div>
                  <div class="carousel-question">
                    Tickets to a 50/50 Raffles are $1 and each person can only buy one ticket. If your ticket is drawn, 
                    you will win half of the total ticket revenue. There is a new raffle every day, and each day 
                    {{ userData.stats.problemSets.ch7.mod1.people }} people buy a ticket. <br>
                    What is the probability you will win this raffle at least 
                    {{ userData.stats.problemSets.ch7.mod1.wins }} times over the course of a year (365 days)? 
                    <div class="input-group mb-3 mt-3">
                      <span class="input-group-text" ref="m1q1">?</span>
                      <input type="text" class="form-control" id="m1q1" :value="userData.stats.textbookResponses.ch7.mod1.q1" :style="checkM1Q1">
                      <button class="btn btn-outline-secondary" type="button" @click="computeM1Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>

              <!-- QUESTION 2 -->
              <div class="carousel-item">
                <div class="d-block w-100">
                  <div class="carousel-title">Question 2</div>
                  <div class="carousel-question">
                    Assume that {{ userData.stats.problemSets.ch7.mod1.canceledClasses }}% of all classes are cancelled. 
                    When class is not cancelled, you expect there to be {{ userData.stats.problemSets.ch7.mod1.students }} students in class by 12:55 PM. 
                    When class is cancelled, you expect there to be 0 students in class at that time. You can assume that, where relevant, 
                    the assumptions required to use the statistical tools from this chapter have been met. Class is supposed to begin at 
                    1:00 PM. You arrive at 12:55 PM and no one is there. What is the probability that class has been cancelled that day?
                    <div class="input-group mb-3 mt-3">
                      <span class="input-group-text" ref="m1q2">?</span>
                      <input type="text" class="form-control" id="m1q2" :value="userData.stats.textbookResponses.ch7.mod1.q2" :style="checkM1Q2">
                      <button class="btn btn-outline-secondary" type="button" @click="computeM1Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>

              <!-- QUESTION 3 -->
              <div class="carousel-item">
                <div class="d-block w-100">
                  <div class="carousel-title">Question 3</div>
                  <div class="carousel-question">
                    About {{ userData.stats.problemSets.ch7.mod1.cheatPerc }}% of students cheat on Professor McQuillen's exam, 
                    and students who do cheat are {{ userData.stats.problemSets.ch7.mod1.cheatBonus }} percentage points more likely to 
                    select the correct answer on any given question. The exam consists of {{ userData.stats.problemSets.ch7.mod1.questions }} 
                    multiple choice questions each with 4 possible answers. One particular student got {{ userData.stats.problemSets.ch7.mod1.correctResponses }} 
                    questions correct on the exam. However, when evaluated under more strict conditions, this student is only able to select the 
                    correct answer {{ userData.stats.problemSets.ch7.mod1.trueAbility }}% of the time. What is the probability that this student 
                    cheated on the exam?
                    <div class="input-group mb-3 mt-3">
                      <span class="input-group-text" ref="m1q3">?</span>
                      <input type="text" class="form-control" id="m1q3" :value="userData.stats.textbookResponses.ch7.mod1.q3" :style="checkM1Q3">
                      <button class="btn btn-outline-secondary" type="button" @click="computeM1Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>

              <!-- QUESTION 4 -->
              <div class="carousel-item">
                <div class="d-block w-100">
                  <div class="carousel-title">Question 4</div>
                  <div class="carousel-question">
                    On any given day we expect there to be {{ userData.stats.problemSets.ch7.mod1.accidents }} car accidents in a small town. 
                    What is the probability that over the course of one year (365 days) that there will be at least one stretch of 
                    {{ userData.stats.problemSets.ch7.mod1.noAccidentDays }} or more days without any car accidents? 
                    <div class="input-group mb-3 mt-3">
                      <span class="input-group-text" ref="m1q4">?</span>
                      <input type="text" class="form-control" id="m1q4" :value="userData.stats.textbookResponses.ch7.mod1.q4" :style="checkM1Q4">
                      <button class="btn btn-outline-secondary" type="button" @click="computeM1Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>

              <!-- QUESTION 5 -->
              <div class="carousel-item">
                <div class="d-block w-100">
                  <div class="carousel-title">Question 5</div>
                  <div class="carousel-question">
                    Jonathan likes a movie {{ userData.stats.problemSets.ch7.mod1.likeMove }}% of the time. About 
                    {{ userData.stats.problemSets.ch7.mod1.sciFi }}% of movies are Science Fiction movies, and 
                    {{ userData.stats.problemSets.ch7.mod1.likeOrSciFi }}% of movies are ones that Jonathan likes or 
                    they are Science Fiction movies or both. If you screen a Science Fiction movie for Jonathan, 
                    what is the probability he will like it? 
                    <div class="input-group mb-3 mt-3">
                      <span class="input-group-text" ref="m1q5">?</span>
                      <input type="text" class="form-control" id="m1q5" :value="userData.stats.textbookResponses.ch7.mod1.q5" :style="checkM1Q5">
                      <button class="btn btn-outline-secondary" type="button" @click="computeM1Q5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>

              <!-- QUESTION 6 -->
              <div class="carousel-item">
                <div class="d-block w-100">
                  <div class="carousel-title">Question 6</div>
                  <div class="carousel-question">
                    Teams in the Big Leagues play the game of Big, which is similar in many ways to basketball except that it is more strictly 
                    defined by common statistical procedures. The points any given team scores are normally distributed. Two teams, the 
                    <b>Arizona Haboobs</b> and the <b>DC Justice</b>, are playing each other at the DC Justice Arena known as the Supreme Court. 
                    <br><br>
                    Let H be the scoring distribution for the Haboobs and J be the scoring distribution for the Justice. <br>
                    <br>H~N({{ userData.stats.problemSets.ch7.mod1.azMean }}, {{ userData.stats.problemSets.ch7.mod1.azSD }}) 
                    <br>J~N({{ userData.stats.problemSets.ch7.mod1.dcMean }}, {{ userData.stats.problemSets.ch7.mod1.dcSD }}) 
                    <br><br>What is the probability that the Arizona Haboobs will win the game? 
                    <div class="zscorecalculator"><ZScoreCalculator /></div>
                    <div class="input-group mb-3 mt-3">
                      <span class="input-group-text" ref="m1q6">?</span>
                      <input type="text" class="form-control" id="m1q6" :value="userData.stats.textbookResponses.ch7.mod1.q6" :style="checkM1Q6">
                      <button class="btn btn-outline-secondary" type="button" @click="computeM1Q6" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>

              <!-- QUESTION 7 -->
              <div class="carousel-item">
                <div class="d-block w-100">
                  <div class="carousel-title">Question 7</div>
                  <div class="carousel-question">
                    IQ is a normally distributed variable with a mean of 100 and a standard deviation of 15 (this is true!). 
                    A focus group has randomly selected {{ userData.stats.problemSets.ch7.mod1.studySize }} people to participate in a study. 
                    What is the probability that at least {{ userData.stats.problemSets.ch7.mod1.smartSize }} people will have have an IQ over 
                    {{ userData.stats.problemSets.ch7.mod1.iQ }}?
                    <div class="zscorecalculator"><ZScoreCalculator /></div>
                    <div class="input-group mb-3 mt-3">
                      <span class="input-group-text" ref="m1q7">?</span>
                      <input type="text" class="form-control" id="m1q7" :value="userData.stats.textbookResponses.ch7.mod1.q7" :style="checkM1Q7">
                      <button class="btn btn-outline-secondary" type="button" @click="computeM1Q7" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>

              
              
            </div>

            <button class="carousel-control-prev" type="button" data-bs-target="#moduleOneCarousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">P</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#moduleOneCarousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">N</span>
            </button>
          </div>
        </div>

        <CalculatorLinks :score="mod1Score" :scoreTotal="20"/>
      </div> 
      <!-- #endregion -->

      <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/D4jEovyIGyI" startAt="0"/>

      <!-- #region DATA CHANGER -->
      <div v-if="userData" class="data-changer">
        <div class="em1-50 fw600">Questions Reset</div>
        <div>You can reset the questions with randomly generated numbers for more practice.</div>
        <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
      </div>
      <!-- #endregion -->
       
    </div>
    <!-- EXPLORATORY LESSON -->
    <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="ExploratoryLessonIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Exploratory Lesson 7</h3>
          <div style="text-align: justify;">
            The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will test our innate sense of probability 
            and calculate a Brier score to see how well we can make predictions.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ lssnScore }} out of 20 points on this lesson.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link to="/stats/7/lesson" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
      </div>
    </div>
    <!-- SPREADSHEET ASSIGNMENT -->
    <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="SpreadsheetAssignmentIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Spreadsheet Assignment 7</h3>
          <div style="text-align: justify;">
            The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will run another Monte Carlo simulation. 
            This time, you will be using the Poisson distribution to model scoring in an inscrutable game.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ assnScore }} out of 30 points on this assignment.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link to="/stats/7/assignment" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
      </div>
    </div>
  </div>
  
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
  import { Popover } from 'bootstrap';
  // binomial, poisson, cumulativeBinomial, cumulativePoisson, standardNormalCDF, getZScore
  import { binomial, poisson, cumulativeBinomial, cumulativePoisson, standardNormalCDF } from './statFunctions.js'

  import MathEquation from '@/components/MathEquation.vue';
  import CalculatorLinks from '@/components/CalculatorLinks.vue';
  import ZScoreCalculator from '@/components/ZScoreCalculator.vue';
  import VideoBox from '@/components/VideoBox.vue';
  
  import { create, all } from 'mathjs'
  const math = create(all);
  
  import probabilibot from '@/assets/Probabilibot.png';
  import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
  import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
  import BellCurve from '@/assets/BellCurve.png';
  
  export default {
    name: "statsMainPage",
    components: {
      MathEquation,
      CalculatorLinks,
      ZScoreCalculator,
      VideoBox,
    },
    props: ['tab'],
    data() {
      return {
        userData: null,
        setUpStatsData,
        mod1Score: 0,
        assnScore: 0,
        lssnScore: 0,
        modalTitle: 'Loading...',
        modalMessage: '',
        probabilibot,
        ExploratoryLessonIcon,
        SpreadsheetAssignmentIcon,
        BellCurve,
        renderEq,
      }
    },
    created() {
      this.loadUserInformation();
    },
    methods: {
      async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullProblemSet();
        await this.setPopovers();
      },
      async pullProblemSet() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        
        // ADD VALUES FOR THE CHAPTER PROBLEM SET
        if(data.stats.problemSets.ch7.length == 0){
          await setDoc(docRef, {
            stats: {
              problemSets: {
                ch7: {
                  mod1: this.problemSetsMod1(),
                },
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
        if(Object.keys(data.stats.textbookResponses.ch7).length == 0){
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch7: {
                  mod1: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                    q5: null,
                    q6: null,
                    q7: null,
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER GRADEBOOK IF MISSING
        if(!data.stats.gradeBook.ch7.mod1){
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                ch7: {
                  mod1: [0,0,0,0,0,0,0],
                  assignment: null,
                  lesson: null,
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        this.userData = data;
        this.mod1Score = math.sum(this.userData.stats.gradeBook.ch7.mod1);
        this.assnScore = this.userData.stats.gradeBook.ch7.assignment ? this.userData.stats.gradeBook.ch7.assignment : 0;
        this.lssnScore = this.userData.stats.gradeBook.ch7.lesson ? this.userData.stats.gradeBook.ch7.lesson : 0;
      },
      async setPopovers() {
        await new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (this.userData !== null) {
                    clearInterval(intervalId);
                    resolve(this.userData);
                }
            }, 100); // Check every 100 milliseconds
        });
        const popoverM1Q1 = new Popover(this.$refs.m1q1, {
          container: 'body',
          title: 'Probability',
          content: `The probability of winning at least ${this.userData.stats.problemSets.ch7.mod1.wins} times in a year uses the cumulative probability distribution. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });  
        const popoverM1Q2 = new Popover(this.$refs.m1q2, {
          container: 'body',
          title: 'Probability',
          content: `You will need both the Poisson distribution and Bayes' Theorem for this question. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });  
        const popoverM1Q3 = new Popover(this.$refs.m1q3, {
          container: 'body',
          title: 'Probability',
          content: `This question requires you to use the Binomial distribution and Bayes' Theorem. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q4 = new Popover(this.$refs.m1q4, {
          container: 'body',
          title: 'Probability',
          content: `This question requires you to use both the Poisson and Binomial distributions. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q5 = new Popover(this.$refs.m1q5, {
          container: 'body',
          title: 'Probability',
          content: `This question requires you to use the Addition Rule. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q6 = new Popover(this.$refs.m1q6, {
          container: 'body',
          title: 'Probability',
          content: `This question requires you to use the Normal distribution. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q7 = new Popover(this.$refs.m1q7, {
          container: 'body',
          title: 'Probability',
          content: `This question requires you to use the Normal distribution and the Binomial distribution. <br><br><b>You may enter your answer in percentage or decimal form, but it should be accurate up to four decimal places.</b>`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        popoverM1Q1; popoverM1Q2; popoverM1Q3; popoverM1Q4; popoverM1Q5; popoverM1Q6; popoverM1Q7;
      },
      async resetAll() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            problemSets: {
              ch7: {
                mod1: this.problemSetsMod1(),
              }
            }
          }
        }, {merge: true});
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch7: {
                mod1: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                  q5: null,
                  q6: null,
                  q7: null,
                },
              }
            }
          }
        }, {merge: true});
        const docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
      },
      problemSetsMod1() {
        const people = math.randomInt(1000, 10000);
        const wins = math.randomInt(1, 3);
        const canceledClasses = math.randomInt(10, 50)/10;
        const students = math.randomInt(3, 9);
        const cheatPerc = math.randomInt(2, 10);
        const cheatBonus = math.randomInt(8, 15);
        const questions = math.randomInt(30, 40);
        const correctResponses = math.round((math.randomInt(70, 90)/100)*questions,0);
        const trueAbility = math.randomInt(40, 60);
        const accidents = math.round(math.randomInt(100, 150)/100,2);
        const noAccidentDays = math.randomInt(5, 8);
        const likeMove = math.randomInt(600, 750)/10;
        const sciFi = math.randomInt(150, 200)/10;
        const likeOrSciFi = math.round(likeMove + math.randomInt(100, 900 - 10*likeMove)/10,1);
        const azMean = math.randomInt(90, 110);
        const azSD = math.randomInt(10, 20);
        let dcMean = math.randomInt(90, 110);
        while(dcMean == azMean){
          dcMean = math.randomInt(90, 110);
        }
        let dcSD = math.randomInt(10, 20);
        while(dcSD == azSD){
          dcSD = math.randomInt(10, 20);
        }
        const studySize = math.randomInt(15, 30);
        const iQ = math.randomInt(85, 115);
        const smartSize = math.round(studySize/2 + math.randomInt(-math.round(studySize/4,0), math.round(studySize/4,0)),0);
        
        return {
          people: people,
          wins: wins,
          canceledClasses: canceledClasses,
          students: students,
          cheatPerc: cheatPerc,
          cheatBonus: cheatBonus,
          questions: questions,
          correctResponses: correctResponses,
          trueAbility: trueAbility,
          accidents: accidents,
          noAccidentDays: noAccidentDays,
          likeMove: likeMove,
          sciFi: sciFi,
          likeOrSciFi: likeOrSciFi,
          azMean: azMean,
          azSD: azSD,
          dcMean: dcMean,
          dcSD: dcSD,
          studySize: studySize,
          smartSize: smartSize,
          iQ: iQ,
        }
      },
      async computeM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const prob = 1/data.mod1.people;
          const trueValue = 1 - cumulativeBinomial(365, data.mod1.wins - 1, prob);
          const studValue = document.getElementById('m1q1').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability of winning at least ${data.mod1.wins} times in a year is ${math.round(trueValue,4)} or ${math.round(trueValue*100,2)}%.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `What is the probability of winning <i>less</i> than ${data.mod1.wins} times in a year? The cumulative probability distribution can answer that question, and subtracting that value from 1 will give you the probability of winning at least ${data.mod1.wins} times in a year.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch7.mod1;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 3}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch7: {
                  mod1: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                ch7: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const canceled = data.mod1.canceledClasses/100;
          const students = data.mod1.students;
          const trueValue = canceled/(canceled + (1 - canceled)*poisson(students,0));
          const studValue = document.getElementById('m1q2').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability of class being cancelled is ${math.round(trueValue,4)} or ${math.round(trueValue*100,2)}%.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Try using Bayes' Theorem to construct the problem. You will need the Poisson distribution to find the probability of the number of students in class given that class is not cancelled.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch7.mod1;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 3}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch7: {
                  mod1: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                ch7: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const cheatPerc = data.mod1.cheatPerc/100;
          const cheatBonus = data.mod1.cheatBonus/100;
          const questions = data.mod1.questions;
          const correctResponses = data.mod1.correctResponses;
          const trueAbility = data.mod1.trueAbility/100;
          const trueValue = binomial(questions, correctResponses, trueAbility + cheatBonus)*cheatPerc/(binomial(questions, correctResponses, trueAbility + cheatBonus)*cheatPerc + binomial(questions, correctResponses, trueAbility)*(1 - cheatPerc));
          const studValue = document.getElementById('m1q3').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability that the student cheated is ${math.round(trueValue,4)} or ${math.round(trueValue*100,2)}%.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `You will need to use Bayes' Theorem and the Binomial Distribution to solve this problem. Note that the student has a ${data.mod1.trueAbility}% of getting a question right on their own, but a ${data.mod1.trueAbility + data.mod1.cheatBonus}% chance of getting a question right if they cheat. The binomial distribution can help you find the probability of getting ${data.mod1.correctResponses} questions right out of ${data.mod1.questions} given each of those probabilities.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch7.mod1;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 3}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch7: {
                  mod1: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                ch7: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const accidents = data.mod1.accidents;
          const noAccidentDays = data.mod1.noAccidentDays;
          const probNoAccidents = cumulativePoisson(accidents, 0);
          const trueValue = 1 - cumulativeBinomial(365 - noAccidentDays + 1, 0, math.pow(probNoAccidents,noAccidentDays));
          const studValue = document.getElementById('m1q4').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability of having at least one stretch of ${noAccidentDays} or more days without an accident is ${math.round(trueValue,4)} or ${math.round(trueValue*100,2)}%.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `You will need to use the Poisson distribution to find the probability of having 0 accidents on any given day. Then use that value to determine the probability of having a streak of ${noAccidentDays} days without an accident. Then you can use the binomial distribution to find the probability of having at least one such streak in a year. Note that this problem is very similar to the binomial problem you solved in Spreadsheet Assignment 5.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch7.mod1;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 3}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch7: {
                  mod1: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                ch7: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const likeMove = data.mod1.likeMove/100;
          const sciFi = data.mod1.sciFi/100;
          const likeOrSciFi = data.mod1.likeOrSciFi/100;
          const trueValue = (likeMove + sciFi - likeOrSciFi)/(sciFi);
          const studValue = document.getElementById('m1q5').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability that Jonathan will like a Science Fiction movie is ${math.round(trueValue,4)} or ${math.round(trueValue*100,2)}%.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Set this problem up with Bayes' Theorem, but remember that P(B|A)*P(A) = P(A and B), and you can use the General Addition Rule to find that entire numerator, while P(B) is found in the problem itself.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch7.mod1;
          if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch7: {
                  mod1: {
                    q5: studValue,
                  },
                }
              },
              gradeBook: {
                ch7: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const azMean = data.mod1.azMean;
          const azSD = data.mod1.azSD;
          const dcMean = data.mod1.dcMean;
          const dcSD = data.mod1.dcSD;
          const zScore = (dcMean - azMean)/math.sqrt(math.pow(azSD,2) +  math.pow(dcSD,2));
          const trueValue = 1 - standardNormalCDF(zScore);
          const studValue = document.getElementById('m1q6').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability that the Arizona Haboobs will win the game is ${math.round(trueValue,4)} or ${math.round(trueValue*100,2)}%.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The variables H and J represent the scoring distributions for the Arizona Haboobs and the DC Justice, respectively. You want to find the mean and standard deviation of the difference between the two distributions (i.e. H-J). The mean of (H-J) is the difference between the means of H and J, and the standard deviation of (H-J) is the square root of the sum of the squares of the standard deviations of H and J. Then you can use the Z-score for a difference of 0 points to find the probability that the Haboobs <i>lose</i> the game.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch7.mod1;
          if(gradeMatrix[5] == 0 && result){gradeMatrix[5] = 3}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch7: {
                  mod1: {
                    q6: studValue,
                  },
                }
              },
              gradeBook: {
                ch7: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q7() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const m = 100;
          const s = 15;
          const studySize = data.mod1.studySize;
          const iQ = data.mod1.iQ;
          const smartSize = data.mod1.smartSize;
          const probSmart = 1 - standardNormalCDF((iQ - m)/s);
          const trueValue = 1 - cumulativeBinomial(studySize, smartSize - 1, probSmart);
          const studValue = document.getElementById('m1q7').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The probability that at least ${smartSize} people will have an IQ over ${iQ} is ${math.round(trueValue,4)} or ${math.round(trueValue*100,2)}%.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `You will need to use the Z-score formula to find the Z-score for an IQ of ${iQ} given a mean of ${m} and a standard deviation of ${s}. Then you can use the standard normal distribution to find the probability of having an IQ over ${iQ}. Finally, you can use the cumulative binomial distribution to find the probability of having at least ${smartSize} people with an IQ over ${iQ}.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook.ch7.mod1;
          if(gradeMatrix[6] == 0 && result){gradeMatrix[6] = 3}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                ch7: {
                  mod1: {
                    q7: studValue,
                  },
                }
              },
              gradeBook: {
                ch7: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
    }, 
    computed: {
      checkM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const prob = 1/data.mod1.people;
          const trueValue = 1 - cumulativeBinomial(365, data.mod1.wins - 1, prob);
          const studValue = this.userData.stats.textbookResponses.ch7.mod1.q1;
          const result = compare(trueValue, studValue, 0.001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const canceled = data.mod1.canceledClasses/100;
          const students = data.mod1.students;
          const trueValue = canceled/(canceled + (1 - canceled)*poisson(students,0));
          const studValue = this.userData.stats.textbookResponses.ch7.mod1.q2;
          const result = compare(trueValue, studValue, 0.001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const cheatPerc = data.mod1.cheatPerc/100;
          const cheatBonus = data.mod1.cheatBonus/100;
          const questions = data.mod1.questions;
          const correctResponses = data.mod1.correctResponses;
          const trueAbility = data.mod1.trueAbility/100;
          const trueValue = binomial(questions, correctResponses, trueAbility + cheatBonus)*cheatPerc/(binomial(questions, correctResponses, trueAbility + cheatBonus)*cheatPerc + binomial(questions, correctResponses, trueAbility)*(1 - cheatPerc));
          const studValue = this.userData.stats.textbookResponses.ch7.mod1.q3;
          const result = compare(trueValue, studValue, 0.001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const accidents = data.mod1.accidents;
          const noAccidentDays = data.mod1.noAccidentDays;
          const probNoAccidents = cumulativePoisson(accidents, 0);
          const trueValue = 1 - cumulativeBinomial(365 - noAccidentDays + 1, 0, math.pow(probNoAccidents,noAccidentDays));
          const studValue = this.userData.stats.textbookResponses.ch7.mod1.q4;
          const result = compare(trueValue, studValue, 0.001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const likeMove = data.mod1.likeMove/100;
          const sciFi = data.mod1.sciFi/100;
          const likeOrSciFi = data.mod1.likeOrSciFi/100;
          const trueValue = (likeMove + sciFi - likeOrSciFi)/(sciFi);
          const studValue = this.userData.stats.textbookResponses.ch7.mod1.q5;
          const result = compare(trueValue, studValue, 0.001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const azMean = data.mod1.azMean;
          const azSD = data.mod1.azSD;
          const dcMean = data.mod1.dcMean;
          const dcSD = data.mod1.dcSD;
          const zScore = (dcMean - azMean)/math.sqrt(math.pow(azSD,2) +  math.pow(dcSD,2));
          const trueValue = 1 - standardNormalCDF(zScore);
          const studValue = this.userData.stats.textbookResponses.ch7.mod1.q6;
          const result = compare(trueValue, studValue, 0.001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q7() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets.ch7;
          const m = 100;
          const s = 15;
          const studySize = data.mod1.studySize;
          const iQ = data.mod1.iQ;
          const smartSize = data.mod1.smartSize;
          const probSmart = 1 - standardNormalCDF((iQ - m)/s);
          const trueValue = 1 - cumulativeBinomial(studySize, smartSize - 1, probSmart);
          const studValue = this.userData.stats.textbookResponses.ch7.mod1.q7;
          const result = compare(trueValue, studValue, 0.001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
    }
  }
  </script>
  
<style scoped>
.cheat-sheet-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
.cheat-sheet {
  width: 100%;
  margin: 6px auto;
  border: 1px dashed #ccc;
}
.cheat-sheet td {
  width: 33%;
  text-align: center;
  vertical-align: top;
  padding: 6px;
}
.cheat-sheet h5 {
  font-size: 1.2em;
  font-weight: 600;
  color: #333;
}
.cheat-sheet p {
  font-size: 1em;
  color: #666;
}
.zscorecalculator {
  display: flex;
  justify-content: right;
  align-items: center;
}
</style>