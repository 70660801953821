<template>
<div class="container col-md-6 offset-md-3">

<!-- #region CHAPTER AND TITLE -->
<div class="container">
  <div class="book-title-div">
    <span class="fw600 em1-50">Chapter One</span><br>
    <span class="fw800 em3 fc-stats">Basic Statistics</span>
  </div>
</div>
<!-- #endregion -->

<!-- SUB NAVIGATION BAR -->
<nav>
  <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
    <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
    <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
    <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
  </div>
</nav>

<!-- SUB NAV BAR CONTENT -->
<div class="tab-content" id="nav-tabContent">
  <!-- TEXTBOOK -->
  <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">

    <VideoBox class="mt-4" videoTitle="Basic Statistics" videoUrl="https://www.youtube.com/embed/PDjjrYB_Enc" startAt="0"/>

    <!-- #region MODULE 1 LEARNING BOX START -->
    <div class="learn-box-accordion container rounded">
      <h3 class="fc-stats">Measures of Central Tendency</h3>
      <p>
        The first step on the way to understanding something is to establish the <i>typical case</i> for that thing.
        <b>Measures of Central Tendancy</b> aim to identify the 'typical case', and each of these three methods
        has its strengths and weaknesses.
      </p>

      <div class="accordion" id="centralTendancyAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              Measure #1:&nbsp;<b>The Mean</b>
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#centralTendancyAccordion">
            <div class="accordion-body">
              The <strong>arithmetic mean</strong>  (or <i>average</i>) is the most common measure of central tendancy. 
              If we had a collection of numbers, and I drew a random number from that collection and asked you to guess
              what it was, the best unbiased guess you could make would be the arithmetic mean. <br>
              <p style="margin-top:12px; margin-bottom:24px;">The equation to calculate the arithmetic mean is:</p>
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center">
                <button ref="popoverMeanEq" class="popover-text">
                  <MathEquation :equation="'\\overline{x} = \\frac{1}{n} \\sum_{i=1}^{n} x_i'" class="em1-25"/>
                </button>
              </p> 
              Suppose that we had the following set of numbers: <MathEquation :equation="'x = \\{4, 6, 7, 9\\}'"/>, then the mean would be: 
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'\\overline{x} = \\frac{1}{4}*(4 + 6 + 7 + 9) = 6.5'"/></p> 
              Why is 6.5 an unbiased estimate for <MathEquation :equation="'x'"/>? After all, if I <i>did</i> pick a number out of <MathEquation :equation="'x'"/> 
              at random, there is no way I would pick 6.5. The reason 6.5 is an unbiased guess is because it is correct <i>on average</i>, meaning that the error 
              in each guess adds up to zero. 
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'(4-6.5) + (6-6.5) + (7-6.5) + (9-6.5) = 0'"/></p> 
              There is no number other than 6.5 which satisfies this condition, and that is what makes it the mean. 
              <br><br>
              The downside of using the arithmetic mean is your measure of central tendency is that it is prone to outliers. If the value of 500 is added to <MathEquation :equation="'x'"/>, 
              the mean would jump to <MathEquation :equation="'\\bar{x}=\\frac{1}{5}*(4+6+7+9+500)=105.2'"/>. While this is still the unbiased estimate of <MathEquation :equation="'x'"/>,
              it no longer represents the 'typical' value of <MathEquation :equation="'x'"/>. 
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Measure #2:&nbsp;<b>The Median</b>
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#centralTendancyAccordion">
            <div class="accordion-body">
              The <strong>median</strong> is the value which seperates the upper half of a sample from the lower half. When a set of numbers has an odd number of values, the median
              is the value in the middle of the ranked list of numbers:
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'x = \\{7, 2, 4, 5, 7, 8, 2\\} => sort(x) = \\{2, 2, 4, 5, 7, 7, 8\\}'"/></p> 
              Since <MathEquation :equation="'n'"/> is odd, find the value in rank <MathEquation :equation="'\\frac{n+1}{2}'"/>. If <MathEquation :equation="'n=7'"/> (there are 7 numbers in the dataset)
              then <MathEquation :equation="'\\frac{n+1}{2} = \\frac{7+1}{2} = 4'"/>. The 4th number in the sorted dataset is 5, so the median is <strong>5</strong>. 
              <br><br>
      
              For a dataset with an even number of values, the median is the average of the two values in the middle: 
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'y = \\{8, 5, 2, 6, 6, 1\\} => sort(y) = \\{1, 2, 5, 6, 6, 8\\}'"/></p> 
              Since <MathEquation :equation="'n'"/> is even (<MathEquation :equation="'n=6'"/>), find the value in rank <MathEquation :equation="'\\frac{n}{2}'"/> and <MathEquation :equation="'(\\frac{n}{2} + 1)'"/>.
              In the sorted dataset, these would be the values of 5 and 6. The median is the average of these two values:
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'\\frac{5+6}{2}=5.5'"/></p> 
              So, the median in this case is <strong>5.5</strong>. 
              <br><br>
              The median's strength is that is not as vulnerable to outliers as the mean. If we added the value of 500 to <MathEquation :equation="'y'"/>, the median would change from 
              5.5 to 6, which is still a good representation of the typical value. The median's weakness is that it can be biased. The error in using the median doesn't add up to zero
              unless the distribution of the value is symmetric: 
              <p style="margin-top:12px; margin-bottom:24px;">
                <span class="d-flex justify-content-center"><MathEquation :equation="'x'"/>&nbsp;has a symmetric distribution (the mean is equal to the median), so the median is unbiased:</span><br>
                <span class="d-flex justify-content-center" style="margin-top:-18px;"><MathEquation :equation="'\\sum_{i=1}^{n} (x_i - 5) = (2-5) + (2-5) + (4-5) + (5-5) + (7-5) + (7-5) + (8-5) = 0'"/></span>
              </p>
              <p style="margin-top:12px; margin-bottom:24px;">
                <span class="d-flex justify-content-center"><MathEquation :equation="'y'"/>&nbsp;does not have a symmetric distribution (the mean is not equal to the median), so the median is biased:</span><br>
                <span class="d-flex justify-content-center" style="margin-top:-18px;"><MathEquation :equation="'\\sum_{i=1}^{n} (y_i - 5.5) = (1-5.5)+(2-5.5)+(5-5.5)+(6-5.5)+(6-5.5)+(8-5.5)=-5'"/></span>
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Measure #3:&nbsp;<b>The Mode</b>
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#centralTendancyAccordion">
            <div class="accordion-body">
              <p>
                The <strong>mode</strong> is the most common value in a dataset. If <MathEquation :equation="'x = \\{7, 2, 4, 5, 7, 8, 2\\}'"/>, there are two values for the mode of <MathEquation :equation="'x.'"/>
                Both <strong>2</strong> and <strong>7</strong> appear in the dataset twice, and no number appears more than twice. If <MathEquation :equation="'y = \\{8, 5, 2, 6, 6, 1\\}'"/>, the mode would be 
                <strong>6</strong>, as it is the only number to appear twice and no number appears more than twice. We could look at a third dataset like <MathEquation :equation="'z = \\{3, 7, 1, 8, 4, 2\\}'"/> 
                and find that no value appears more than once. In this case, there is no mode. 
              </p>
              <p>
                It makes sense to use the most common value in a dataset to represent the 'typical' value, and that is the strength of the mode. It is literally the most common value you 
                get when picking out of that dataset. But its weakness is that sometimes it doesn't exist, such as in a dataset of entirely unique values. It is also possible that the 
                mode can be an outlier in the dataset, such as in the case of <MathEquation :equation="'\\theta = \\{1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1000, 1000\\}'"/>. 
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3" v-if="userData">
        <h4 class="fc-prove">Prove Your Understanding</h4>
        <p>Let <MathEquation :equation="'x = \\{'+userData.stats.problemSets.ch1.sort().join(',')+'\\}'"/>. Compute all three measures of central tendancy below.</p>
        <div class="input-group mb-3">
          <button ref="popoverMean" class="input-group-text" style="width: 20%;" id="mean-desc">Mean of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the arithmetic mean of x" aria-label="mean" aria-describedby="basic-addon1" id="mean-ans" :value="userData.stats.textbookResponses.ch1.mod1.mean" :style="checkMean">
          <button class="btn btn-outline-secondary" type="button" id="mean-check" @click="computeMean" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <div class="input-group mb-3">
          <button ref="popoverMedian" class="input-group-text" style="width: 20%;" id="median-desc">Median of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the median of x" aria-label="median" aria-describedby="basic-addon1" id="median-ans" :value="userData.stats.textbookResponses.ch1.mod1.median" :style="checkMedian">
          <button class="btn btn-outline-secondary" type="button" id="median-check" @click="computeMedian" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <div class="input-group mb-3">
          <button ref="popoverMode" class="input-group-text" style="width: 20%;" id="mean-desc">Mode of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Separate multiple values with a comma, enter 'none' if no mode" aria-label="mode" aria-describedby="basic-addon1" id="mode-ans" :value="userData.stats.textbookResponses.ch1.mod1.mode" :style="checkMode">
          <button class="btn btn-outline-secondary" type="button" id="mean-check" @click="computeMode" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
      </div>
      <CalculatorLinks :score="mod1Score" :scoreTotal="6"/>
    </div>
    <!-- #endregion -->

    <!-- #region MODULE 2 LEARNING BOX START -->
    <div class="learn-box-accordion container rounded">
      <h3 class="fc-stats">Measures of Dispersion</h3>
      <p>
        The second step on the way to understanding something is to establish the <i>variability</i> of that thing. 
        <b>Measures of Dispersion</b> aim to identify how different values in a dataset are from the mean and from each other.
      </p>

      <div class="accordion" id="dispersionAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              Measure #1:&nbsp;<b>The Range</b>
            </button>
          </h2>
          <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#dispersionAccordion">
            <div class="accordion-body">
              The <strong>range</strong> is the difference between the largest and smallest values in a dataset. If <MathEquation :equation="'x = \\{4, 6, 7, 9\\}'"/>, then the range would be: 
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'max(x) - min(x) = 9 - 4 = 5'"/></p>
              The range is a simple measure of dispersion, but it is also a very crude one. It only takes into account the two most extreme values in the dataset, and it doesn't 
              tell you anything about the values in between. The range is also very sensitive to outliers. If we added the value of 500 to <MathEquation :equation="'x'"/>, the range would 
              jump from 5 to 496. This is a huge change, and it doesn't really capture what we want out of a measure of dispersion, which is a sense of how different values in <MathEquation :equation="'x'"/> 
              are from each other. What the range does give you is, at the very least, a sense of the spread of the values in the dataset.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              Measure #2:&nbsp;<b>The Variance</b>
            </button>
          </h2>
          <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#dispersionAccordion">
            <div class="accordion-body">
              The <strong>sample variance</strong> is the average of the squared differences between each value in a dataset and the mean of that dataset. The equation to calculate the variance is:
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center">
                <button ref="popoverVarianceEq" class="popover-text">
                  <MathEquation :equation="'s^2 = \\frac{1}{n-1} \\sum_{i=1}^{n} (x_i - \\overline{x})^2'" class="em1-25"/>
                </button>
              </p>
              If <MathEquation :equation="'x = \\{4, 6, 7, 9\\}'"/>, 
              then the variance would be: 
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'s^2 = \\frac{1}{4-1}((4-6.5)^2 + (6-6.5)^2 + (7-6.5)^2 + (9-6.5)^2) = 4.\\bar{3}'"/></p>
              The variance is a more sophisticated measure of dispersion than the range. It takes into account all the values in the dataset, not just the two most extreme ones. It also 
              gives you a sense of how different each value in the dataset is from the mean. The downside of the variance is that it is not very intuitive. It is hard to interpret what a variance 
              of 4.33 means in the context of the dataset. It is also sensitive to outliers. If we added the value of 500 to <MathEquation :equation="'x'"/>, the variance would jump from 4.33 to 48,711.7. 
              This is a huge change, and it doesn't really capture what we want out of a measure of dispersion, which is a sense of how different values in <MathEquation :equation="'x'"/> are from each other.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              Measure #3:&nbsp;<b>The Standard Deviation</b>
            </button>
          </h2>
          <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#dispersionAccordion">
            <div class="accordion-body">
              The <strong>sample standard deviation</strong> is the square root of the variance. The equation to calculate the standard deviation is:
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center">
                <button ref="popoverStDevEq" class="popover-text">
                  <MathEquation :equation="'s = \\sqrt{s^2}'" class="em1-25"/>
                </button>
              </p>
              If <MathEquation :equation="'x = \\{4, 6, 7, 9\\}'"/>, 
              then the standard deviation would be: 
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'s = \\sqrt{4.\\bar{3}} = 2.08'"/></p>
              The standard deviation is a more intuitive measure of dispersion than the variance. It is in the same units as the values in the dataset, so it is easier to interpret. It also 
              gives you a sense of how different each value in the dataset is from the mean, and from each other. While the standard deviation is not the average difference between each value, 
              it is a good representation of what would be considered 'far' from the mean or any other value in the dataset. It is the standard amount of deviation in the data! 
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3" v-if="userData">
        <h4 class="fc-prove">Prove Your Understanding</h4>
        <p>Let <MathEquation :equation="'x = \\{'+userData.stats.problemSets.ch1.sort().join(',')+'\\}'"/>. Compute all three measures of dispersion below.</p>
        <div class="input-group mb-3">
          <button ref="popoverRange" class="input-group-text" style="width: 20%;">Range of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the range of x" aria-label="range" aria-describedby="basic-addon1" id="range-ans" :value="userData.stats.textbookResponses.ch1.mod2.range" :style="checkRange">
          <button class="btn btn-outline-secondary" type="button" @click="computeRange" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <div class="input-group mb-3">
          <button ref="popoverVariance" class="input-group-text" style="width: 20%;">Variance of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the sample variance of x" aria-label="var" aria-describedby="basic-addon1" id="var-ans" :value="userData.stats.textbookResponses.ch1.mod2.variance" :style="checkVar">
          <button class="btn btn-outline-secondary" type="button" @click="computeVar" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <div class="input-group mb-3">
          <button ref="popoverStDev" class="input-group-text" style="width: 20%;">St. Dev. of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the sample standard deviation of x" aria-label="stdev" aria-describedby="basic-addon1" id="stdev-ans" :value="userData.stats.textbookResponses.ch1.mod2.stdev" :style="checkStDev">
          <button class="btn btn-outline-secondary" type="button" @click="computeStDev" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
      </div>
      <CalculatorLinks :score="mod2Score" :scoreTotal="6"/>
    </div>
    <!-- #endregion -->

    <!-- #region MODULE 3 LEARNING BOX START -->
    <div class="learn-box-accordion container rounded">
      <h3 class="fc-stats">Quantifying Distributions</h3>
      <p>
        The third step on the way to understanding something is to establish the <i>shape</i> of that thing. 
        Countless measures aim to identify the shape of a dataset and how it compares to other datasets. We will focus on measures which report 
        values which sit at specific points in the dataset, allowing us to compare the distribution of values in different datasets. The most 
        common of these measures are quartiles, which can also be reported together as the five-number-summary. We will also look at percentiles, 
        which is a more granular version of quartiles.
      </p>

      <div class="accordion" id="distributionAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
              <b>Quartiles</b>
            </button>
          </h2>
          <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#distributionAccordion">
            <div class="accordion-body">
              <p>
                <strong>Quartiles</strong> divide a dataset into four equal parts, or <i>quarters</i>. The first quartile (<MathEquation :equation="'Q_1'"/>) is the value 
                which is greater than or equal to 25% of the values in the dataset. The second quartile (<MathEquation :equation="'Q_2'"/>) is the median of the dataset, which means
                it is greater than or equal to 50% of the values in the dataset. The third quartile (<MathEquation :equation="'Q_3'"/>) is the value which is greater than or equal to 
                75% of the values in the dataset. We can also interpellate <MathEquation :equation="'Q_0'"/> and <MathEquation :equation="'Q_4'"/>, which would be the values greater than 
                or equal to 0% and 100% of the values in the dataset, respectively. That would make them equal to the minimum and maximum values in the dataset.
              </p>
              <p>
                There are different ways to calculate the quartiles, but we want our way to match the 
                <PopOver direction="bottom" title="<b>Excel vs. Google Sheets</b>" content="In Excel, they offer =QUARTILE.EXC() and =QUARTILE.INC(), which are also available in Google Sheets. Our method is the same as =QUARTILE.INC()." buttonText="=QUARTILE()" /> 
                  function in Google Sheets and Excel. That function follows this process: 
              </p>
              <h4>Step 1: Get the Index Number</h4>
                The index number is the rank of the value in the dataset which would be the quartile. The index number is calculated as: 
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'i = \\frac{(n-1) \\times \\text{Quartile Number}}{4} + 1'"/></p>
                If <MathEquation :equation="'x = \\{1, 4, 6, 7, 9, 12\\}'"/>, then the index number for the first quartile would be: 
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'i = \\frac{(6-1) \\times 1}{4} + 1 = 2.25'"/></p>
                The index number for the second quartile would be:
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'i = \\frac{(6-1) \\times 2}{4} + 1 = 3.5'"/></p>
                The index number for the third quartile would be:
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'i = \\frac{(6-1) \\times 3}{4} + 1 = 4.75'"/></p>
              <h4>Step 2: Interpolate the Value</h4>
                The index number can be broken down into the whole number and the remainder. To find the quartile, find the value in the dataset at the whole number index number, 
                and then add to it the remainder multiplied by the difference between the value at the whole number index number and the value at the next index number. For 
                example, the first quartile had an index number of 2.25. The whole number is 2, and the remainder is 0.25. The value at index 2 is 4, and the value at index 3 is 6. 
                So, the first quartile would be:
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'Q1 = 4 + 0.25*(6-4) = 4.5'"/></p>
                The second quartile had an index number of 3.5. The whole number is 3, and the remainder is 0.5. The value at index 3 is 6, and the value at index 4 is 7.
                So, the second quartile would be:
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'Q2 = 6 + 0.5*(7-6) = 6.5'"/></p>
                The third quartile had an index number of 4.75. The whole number is 4, and the remainder is 0.75. The value at index 4 is 7, and the value at index 5 is 9.
                So, the third quartile would be:
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'Q3 = 7 + 0.75*(9-7) = 8.5'"/></p>
                <p>
                  The quartiles give you a sense of how the values in the dataset are distributed. The first quartile tells you where the lower 25% of the values are, the second quartile 
                  tells you where the middle 50% of the values are, and the third quartile tells you where the upper 25% of the values are. Just by looking at the quartiles here,
                  we can conclude a lot about the distribution of <MathEquation :equation="'x'"/>. It looks symmetric, since <MathEquation :equation="'Q1 = 4.5'"/> and <MathEquation :equation="'Q3 = 8.5'"/> are
                  equidistant from the median of <MathEquation :equation="'x'"/>, which is <MathEquation :equation="'Q2 = 6.5'"/>. They are also close together, suggesting a small spread in the values of <MathEquation :equation="'x'"/>. 
                  Quartiles are a good summary statistic, especially when you have a large dataset. They synthesize a lot of information into just a few numbers. 
                </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
              <b>The Five-Number-Summary</b>
            </button>
          </h2>
          <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#distributionAccordion">
            <div class="accordion-body">
              The <strong>five-number-summary</strong> is a set of five values which describe the distribution of a dataset. The five values are the minimum, the first quartile, the median, 
              the third quartile, and the maximum. Using our method for computing the quartiles, this also corresponds to the values at the 0th, 1st, 2nd, 3rd, and 4th quartiles. 
              If <MathEquation :equation="'x = \\{1, 4, 6, 7, 9, 12\\}'"/>, then the five-number-summary would be:
              <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'\\{1, 4.5, 6.5, 8.5, 12\\}'"/></p>
              Commonly, these values will be used to construct a Box-and-Whiskers Plot, which is a visual representation of the five-number-summary. The Box-and-Whiskers Plot is a 
              good way to visualize the distribution of a dataset. It shows you where the values are concentrated, where they are spread out, and where the outliers are.
              <img :src="boxAndWhisker" class="img-fluid" alt="Box and Whisker Plot" style="margin-top:12px; margin-bottom:24px;"/>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
            <b>Percentiles</b> 
            </button>
          </h2>
          <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#distributionAccordion">
            <div class="accordion-body">
              <p>
                The <strong>percentiles</strong> are the values which divide a dataset into one hundred equal parts. This gives you more granular control about where 
                in the distribution you want to look. The first percentile, for example, is the value which is greater than or equal to 1% of the values in the dataset. 
                That would tell you where the very lowest values are. The 50th percentile is the median of the dataset, which means it is greater than or equal to 50% of 
                the values in the dataset. The 90th percentile is the value which is greater than or equal to 90% of the values in the dataset. Percentiles are calculated 
                in pretty much the same way quartile are, except you are dividing the dataset into 100 parts instead of 4. 
              </p>
              <h4>Step One: Get the Index Number</h4>
                The index number is the rank of the value in the dataset which would be the percentile. The index number is calculated as: 
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'i = \\frac{(n-1) \\times \\text{Percentile Number}}{100} + 1'"/></p>
                If <MathEquation :equation="'x = \\{1, 4, 6, 7, 9, 12\\}'"/>, then the index number for the first percentile would be: 
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'i = \\frac{(6-1) \\times 1}{100} + 1 = 1.05'"/></p>
                The index number for the 50th percentile would be:
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'i = \\frac{(6-1) \\times 50}{100} + 1 = 3.5'"/></p>
                The index number for the 90th percentile would be:
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'i = \\frac{(6-1) \\times 90}{100} + 1 = 5.5'"/></p>
              <h4>Step Two: Interpolate the Value</h4>
                The index number can be broken down into the whole number and the remainder. To find the percentile, find the value in the dataset at the whole number index number, 
                and then add to it the remainder multiplied by the difference between the value at the whole number index number and the value at the next index number. For 
                example, the first percentile had an index number of 1.05. The whole number is 1, and the remainder is 0.05. The value at index 1 is 1, and the value at index 2 is 4. 
                So, the first percentile would be:
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'P_1 = 1 + 0.05*(4-1) = 1.15'"/></p>
                The 50th percentile had an index number of 3.5. The whole number is 3, and the remainder is 0.5. The value at index 3 is 6, and the value at index 4 is 7.
                So, the 50th percentile would be:
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'P_50 = 6 + 0.5*(7-6) = 6.5'"/></p>
                The 90th percentile had an index number of 5.5. The whole number is 5, and the remainder is 0.5. The value at index 5 is 9, and the value at index 6 is 12.
                So, the 90th percentile would be:
                <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center"><MathEquation :equation="'P_90 = 9 + 0.5*(12-9) = 10.5'"/></p>
                <p>
                  Percentiles are part of our everyday language. When you hear that someone is in the 90th percentile for height, that means they are taller than 90% of the population. 
                  When you hear that someone is in the 10th percentile for income, that means they make less than 90% of the population. Percentiles are a good way to compare yourself 
                  to a large group of people, and they are a good way to compare datasets to each other.
                </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3" v-if="userData">
        <h4 class="fc-prove">Prove Your Understanding</h4>
        <p>Let <MathEquation :equation="'x = \\{'+userData.stats.problemSets.ch1.sort().join(',')+'\\}'"/>. Compute the five-number-summary below.</p>
        <div class="input-group mb-3">
          <button ref="popoverQ0" class="input-group-text" style="width: 20%;">Min of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the minimum value of x" aria-label="q0" aria-describedby="basic-addon1" id="q0-ans" :value="userData.stats.textbookResponses.ch1.mod3.q0" :style="checkQ0">
          <button class="btn btn-outline-secondary" type="button" @click="computeQ0" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <div class="input-group mb-3">
          <button ref="popoverQ1" class="input-group-text" style="width: 20%;">Q1 of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the first quartile of x" aria-label="q1" aria-describedby="basic-addon1" id="q1-ans" :value="userData.stats.textbookResponses.ch1.mod3.q1" :style="checkQ1">
          <button class="btn btn-outline-secondary" type="button" @click="computeQ1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <div class="input-group mb-3">
          <button ref="popoverQ2" class="input-group-text" style="width: 20%;">Q2 of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the second quartile of x" aria-label="q2" aria-describedby="basic-addon1" id="q2-ans" :value="userData.stats.textbookResponses.ch1.mod3.q2" :style="checkQ2">
          <button class="btn btn-outline-secondary" type="button" @click="computeQ2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <div class="input-group mb-3">
          <button ref="popoverQ3" class="input-group-text" style="width: 20%;">Q3 of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the third quartile of x" aria-label="q3" aria-describedby="basic-addon1" id="q3-ans" :value="userData.stats.textbookResponses.ch1.mod3.q3" :style="checkQ3">
          <button class="btn btn-outline-secondary" type="button" @click="computeQ3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div> 
        <div class="input-group mb-3">
          <button ref="popoverQ4" class="input-group-text" style="width: 20%;">Max of &nbsp;<MathEquation :equation="'x'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the maximum value of x" aria-label="q4" aria-describedby="basic-addon1" id="q4-ans" :value="userData.stats.textbookResponses.ch1.mod3.q4" :style="checkQ4">
          <button class="btn btn-outline-secondary" type="button" @click="computeQ4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
        </div>
        <CalculatorLinks :score="mod3Score" :scoreTotal="8"/>
      </div>  

    </div>
    <!-- #endregion -->

    <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/ux2KQoIYf8M" startAt="0"/>
    
    <!-- #region DATA CHANGER -->
    <div v-if="userData" class="data-changer">
      <div class="em1-50 fw600">Questions Reset</div>
      <div>You can reset the questions with randomly generated numbers for more practice.</div>
      <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
    </div>
    <!-- #endregion -->

  </div>
  <!-- EXPLORATORY LESSON -->
  <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
    <div class="row mt-4">
      <div class="col-4">
        <img :src="ExploratoryLessonIcon" width="100%">
      </div>
      <div class="col-8">
        <h3 class="fc-stats">Exploratory Lesson 1</h3>
        <div style="text-align: justify;">
          The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will learn the basics of working with spreadsheets 
          and how to use them to calculate measures of central tendency, measures of dispersion, and the five-number-summary.
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
          You have scored {{ lssnScore }} out of 20 points on this lesson.
        </div>
      </div>

    </div>
    <div class="d-flex align-items-center justify-content-center mt-4">
      <router-link to="/stats/1/lesson" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
    </div>
  </div>
  <!-- SPREADSHEET ASSIGNMENT -->
  <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
    <div class="row mt-4">
      <div class="col-4">
        <img :src="SpreadsheetAssignmentIcon" width="100%">
      </div>
      <div class="col-8">
        <h3 class="fc-stats">Spreadsheet Assignment 1</h3>
        <div style="text-align: justify;">
          The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will be asked to calculate the measures of central tendency, 
          measures of dispersion, and the five-number-summary for a dataset. You will also be asked to 
          <a href="https://support.google.com/docs/answer/63824?sjid=17524156092687252052-NC" target="_blank">create</a> a 
          <a href="https://support.google.com/docs/answer/9143294?hl=en&sjid=17524156092687252052-NC" target="_blank">Scatterplot</a> for the dataset.
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
          You have scored {{ assnScore }} out of 30 points on this assignment.
        </div>
      </div>

    </div>
    <div class="d-flex align-items-center justify-content-center mt-4">
      <router-link to="/stats/1/assignment" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
    </div>
  </div>
</div>



</div>

<!-- #region MODAL -->
<div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <img :src="probabilibot" width="20%">
        <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body col" v-html="modalMessage"></div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

</template>

<script>
import { doc, getDoc, setDoc } from "firebase/firestore"; 
import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
import MathEquation from '@/components/MathEquation.vue';
import CalculatorLinks from '@/components/CalculatorLinks.vue';
import PopOver from '@/components/PopOver.vue';
import { Popover } from 'bootstrap';

import { create, all } from 'mathjs'
const math = create(all);

import probabilibot from '@/assets/Probabilibot.png';
import boxAndWhisker from '@/assets/box_and_whisker.png';
import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
import VideoBox from "@/components/VideoBox.vue";

export default {
  name: "statsChapter1",
  components: {
    MathEquation,
    CalculatorLinks,
    PopOver,
    VideoBox,
  },
  props: ['tab'],
  data() {
    return {
      userData: null,
      mod1Score: 0,
      mod2Score: 0,
      mod3Score: 0,
      assnScore: 0,
      lssnScore: 0,
      modalTitle: 'Loading...',
      modalMessage: '',
      probabilibot,
      boxAndWhisker,
      ExploratoryLessonIcon,
      SpreadsheetAssignmentIcon,
      setUpStatsData,
    }
  },
  created() {
    this.loadUserInformation();
  },
  methods: {
    async loadUserInformation() {
      await this.setUpStatsData();
      this.pullProblemSet();
    },
    async pullProblemSet() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      let docSnap = await getDoc(docRef);
      let data = docSnap.data();
      
      // ADD VALUES FOR THE CH1 PROBLEM SET
      if(data.stats.problemSets.ch1.length == 0){
        await setDoc(docRef, {
          stats: {
            problemSets: {
              ch1: [math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9)],
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      // ADD CH1 TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
      if(Object.keys(data.stats.textbookResponses.ch1).length == 0){
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod1: {
                  mean: null,
                  median: null,
                  mode: null,
                },
                mod2: {
                  range: null,
                  variance: null,
                  stdev: null,
                },
                mod3: {
                  q0: null,
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
              }
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      // ADD CH1 GRADEBOOK IF MISSING
      if(!data.stats.gradeBook.ch1.mod1){
        await setDoc(docRef, {
          stats: {
            gradeBook: {
              ch1: {
                mod1: [0,0,0],
                mod2: [0,0,0],
                mod3: [0,0,0,0,0],
              }
            }
          }
        }, {merge: true});
        docSnap = await getDoc(docRef);
        data = docSnap.data();
      }

      this.userData = data;
      this.mod1Score = math.sum(this.userData.stats.gradeBook.ch1.mod1);
      this.mod2Score = math.sum(this.userData.stats.gradeBook.ch1.mod2);
      this.mod3Score = math.sum(this.userData.stats.gradeBook.ch1.mod3);
      this.assnScore = this.userData.stats.gradeBook.ch1.assignment ? this.userData.stats.gradeBook.ch1.assignment : 0;
      this.lssnScore = this.userData.stats.gradeBook.ch1.lesson ? this.userData.stats.gradeBook.ch1.lesson : 0;
      this.setPopovers();
    },
    async computeMean() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.mean(data);
        const studValue = document.getElementById('mean-ans').value;
        const result = compare(trueValue, studValue, 0.01);
        this.modalTitle = "Calculating the Mean";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> The mean of ${renderEq('x')} is ${math.round(trueValue*100)/100}.
          ` 
          :
          `
          I'm sorry, that is not correct. In order to compute the mean of ${renderEq('x')} you must 
          take each value of x and add them together: 
          <span class="centered-eq">${renderEq(data.join('+'))}</span>
          Then divide that sum by the number of values in ${renderEq('x')}. There are ${data.length} values in ${renderEq('x')}, so you divide the sum by ${data.length}.
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod1;
        if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod1: {
                  mean: studValue,
                },
              }
            },
            gradeBook: {
              ch1: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeMedian() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.median(data);
        const studValue = document.getElementById('median-ans').value;
        const result = compare(trueValue, studValue, 0);
        this.modalTitle = "Calculating the Median";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> The median of ${renderEq('x')} is ${trueValue}.
          ` 
          :
          `
          <p>I'm sorry, that is not correct. In order to compute the median of ${renderEq('x')} you must determine the middle values of the dataset. </p>
          <p>Since there are ${data.length} values in ${renderEq('x')}, which is an even number, you must find rank ${renderEq('\\frac{n}{2}')} and ${renderEq('(\\frac{n}{2} + 1)')}. </p>
          <p>When ${renderEq('n=10')} as it does here, those are the values in rank ${renderEq('\\frac{10}{2} = 5')} and ${renderEq('(\\frac{10}{2} + 1) = 6')}. </p>
          Then you take the average of those two values. 
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod1;
        if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
              textbookResponses: {
              ch1: {
                mod1: {
                  median: studValue,
                },
              }
            },
            gradeBook: {
              ch1: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);
      }
    },
    async computeMode() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1; 
        const trueValue = math.mode(data); console.log('trueValue: ',trueValue);
        const studEntry = document.getElementById('mode-ans').value;
        let studValue = studEntry == '' ? [] : studEntry.split(',').map(item => item.trim()).filter(item => item !== '').map(item => parseFloat(item)).sort((a, b) => a - b);
        if(studEntry == 'none' || studEntry == 'None'){
          studValue = data;
        }
        let result = false;
        let check = 0;
        for(let i = 0; i < trueValue.length; i++){
          if(studValue[i] == trueValue[i]){
            check++;
          }
        }
        if(check == trueValue.length){result = true;}
        let multiModal = 'No value repeats itself in this dataset, so there is no mode.';
        if(trueValue.length == 1){multiModal = `The mode of ${renderEq('x')} is ${trueValue[0]}.`;}
        if(trueValue.length > 1){multiModal = `In this dataset, ${trueValue.length} different values are repeated ${data.filter(item => item == trueValue[0]).length} times: ${trueValue.join(', ')}.`;}
        this.modalTitle = "Calculating the Mode";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> ${multiModal}
          ` 
          :
          `
          <p>I'm sorry, that is not correct. In order to compute the mode of ${renderEq('x')} you must determine if any values appear more than once in the dataset. </p>
          <p>If ${renderEq('x = \\{1,2,3,4,5\\}')}, than there is no mode, because no value appears more than once, and you should enter 'none' as your answer.</p>
          <p>If ${renderEq('x = \\{1,2,3,4,4\\}')}, than the mode is 4, as it is the only number to appear more than once, and you should enter '4' as your answer.</p>
          <p>If ${renderEq('x = \\{1,1,2,3,3\\}')}, than there are two modes: 1 and 3. Both values appear twice, and no value appears more than twice. In this case you should enter '1, 3' as your answer.</p>
          <p>If ${renderEq('x = \\{1,1,2,2,2\\}')}, than the mode is 2, as it appears 3 times, and no other number appears that often. In this case you should enter '2' as your answer.</p>
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod1;
        if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod1: {
                  mode: studValue.join(', '),
                },
              }
            },
            gradeBook: {
              ch1: {
                mod1: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod1Score = math.sum(gradeMatrix);

      }
    },
    async computeRange() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.max(data) - math.min(data);
        const studValue = document.getElementById('range-ans').value;
        const result = compare(trueValue, studValue, 0);
        this.modalTitle = "Calculating the Range";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> The range of ${renderEq('x')} is ${trueValue}.
          ` 
          :
          `
          <p>I'm sorry, that is not correct. In order to compute the range of ${renderEq('x')} you must subtract the smallest value from the largest value in the dataset. </p>
          <p>If ${renderEq('x = \\{1,2,3,4,5\\}')}, than the range is ${renderEq('5 - 1 = 4')}. </p>
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod2;
        if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod2: {
                  range: studValue,
                },
              }
            },
            gradeBook: {
              ch1: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeVar() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.variance(data);
        const studValue = document.getElementById('var-ans').value;
        const result = compare(trueValue, studValue, 0.01);
        this.modalTitle = "Calculating the Variance";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> The variance of ${renderEq('x')} is ${math.round(trueValue*100)/100}.
          ` 
          :
          `
          <p>I'm sorry, that is not correct. In order to compute the variance of ${renderEq('x')} you must subtract the mean from each value in the dataset, square the result, and then divide those squared differences by the sample size minus 1. </p>
          <p>If ${renderEq('x = \\{1,2,3,4,5\\}')}, than the variance is 2.5. </p>
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod2;
        if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod2: {
                  variance: studValue,
                },
              }
            },
            gradeBook: {
              ch1: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeStDev() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.std(data);
        const studValue = document.getElementById('stdev-ans').value;
        const result = compare(trueValue, studValue, 0.01);
        this.modalTitle = "Calculating the Standard Deviation";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> The standard deviation of ${renderEq('x')} is ${math.round(trueValue*100)/100}.
          ` 
          :
          `
          <p>I'm sorry, that is not correct. In order to compute the standard deviation of ${renderEq('x')} you must take the square root of the variance. </p>
          <p>If ${renderEq('x = \\{1,2,3,4,5\\}')}, than the standard deviation is 1.58. </p>
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod2;
        if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod2: {
                  stdev: studValue,
                },
              }
            },
            gradeBook: {
              ch1: {
                mod2: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod2Score = math.sum(gradeMatrix);
      }
    },
    async computeQ0() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.min(data);
        const studValue = document.getElementById('q0-ans').value;
        const result = compare(trueValue, studValue, 0);
        this.modalTitle = "Calculating the Minimum";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> The minimum value of ${renderEq('x')} is ${trueValue}.
          ` 
          :
          `
          <p>I'm sorry, that is not correct. In order to compute the minimum value of ${renderEq('x')} you must find the smallest value in the dataset. </p>
          <p>If ${renderEq('x = \\{1,2,3,4,5\\}')}, than the minimum value is 1. </p> ${trueValue}
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod3;
        if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod3: {
                  q0: studValue,
                },
              }
            },
            gradeBook: {
              ch1: {
                mod3: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod3Score = math.sum(gradeMatrix);
      }
    },
    async computeQ1() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.quantileSeq(data, 0.25);
        const studValue = document.getElementById('q1-ans').value;
        const result = compare(trueValue, studValue, 0);
        this.modalTitle = "Calculating the First Quartile";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> The first quartile of ${renderEq('x')} is ${trueValue}.
          ` 
          :
          `
          <p>I'm sorry, that is not correct. In order to compute the first quartile of ${renderEq('x')} you must find the value which is greater than or equal to 25% of the values in the dataset. </p>
          <p>If ${renderEq('x = \\{1,2,3,4,5\\}')}, than the first quartile is 1.5. </p>
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod3;
        if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod3: {
                  q1: studValue,
                },
              }
            },
            gradeBook: {
              ch1: {
                mod3: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod3Score = math.sum(gradeMatrix);
      }
    },
    async computeQ2() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.quantileSeq(data, 0.5);
        const studValue = document.getElementById('q2-ans').value;
        const result = compare(trueValue, studValue, 0);
        this.modalTitle = "Calculating the Second Quartile";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> The second quartile of ${renderEq('x')} is ${trueValue}.
          ` 
          :
          `
          <p>I'm sorry, that is not correct. In order to compute the second quartile of ${renderEq('x')} you must find the value which is greater than or equal to 50% of the values in the dataset. </p>
          <p>If ${renderEq('x = \\{1,2,3,4,5\\}')}, than the second quartile is 3. </p>
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod3;
        if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod3: {
                  q2: studValue,
                },
              }
            },
            gradeBook: {
              ch1: {
                mod3: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod3Score = math.sum(gradeMatrix);
      }
    },
    async computeQ3() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.quantileSeq(data, 0.75);
        const studValue = document.getElementById('q3-ans').value;
        const result = compare(trueValue, studValue, 0);
        this.modalTitle = "Calculating the Third Quartile";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> The third quartile of ${renderEq('x')} is ${trueValue}.
          ` 
          :
          `
          <p>I'm sorry, that is not correct. In order to compute the third quartile of ${renderEq('x')} you must find the value which is greater than or equal to 75% of the values in the dataset. </p>
          <p>If ${renderEq('x = \\{1,2,3,4,5\\}')}, than the third quartile is 4.5. </p>
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod3;
        if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 2}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod3: {
                  q3: studValue,
                },
              }
            },
            gradeBook: {
              ch1: {
                mod3: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod3Score = math.sum(gradeMatrix);
      }
    },
    async computeQ4() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.max(data);
        const studValue = document.getElementById('q4-ans').value;
        const result = compare(trueValue, studValue, 0);
        this.modalTitle = "Calculating the Maximum";
        this.modalMessage = result ?
          `
          <span class="fw600 fc-stats">Correct!</span> The maximum value of ${renderEq('x')} is ${trueValue}.
          ` 
          :
          `
          <p>I'm sorry, that is not correct. In order to compute the maximum value of ${renderEq('x')} you must find the largest value in the dataset. </p>
          <p>If ${renderEq('x = \\{1,2,3,4,5\\}')}, than the maximum value is 5. </p>
          ` 
          ;
        
        let gradeMatrix =  this.userData.stats.gradeBook.ch1.mod3;
        if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 1}
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              ch1: {
                mod3: {
                  q4: studValue,
                },
              }
            },
            gradeBook: {
              ch1: {
                mod3: gradeMatrix,
              }
            }
          }
        }, {merge: true});
        let docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.mod3Score = math.sum(gradeMatrix);
      }
    },
    async setPopovers() {
      await new Promise((resolve) => {
          const intervalId = setInterval(() => {
              if (this.userData !== null) {
                  clearInterval(intervalId);
                  resolve(this.userData);
              }
          }, 100); // Check every 100 milliseconds
      });
      const popoverMean = new Popover(this.$refs.popoverMean, {
        container: 'body',
        title: `Mean of ${renderEq('x')}`,
        content: `Calculate the arithmetic mean of ${renderEq('x')}. Do not round your answer.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverMedian = new Popover(this.$refs.popoverMedian, {
        container: 'body',
        title: `Median of ${renderEq('x')}`,
        content: `Calculate the median of ${renderEq('x')}. Do not round your answer.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverMode = new Popover(this.$refs.popoverMode, {
        container: 'body',
        title: `Mode of ${renderEq('x')}`,
        content: `Calculate the mode of ${renderEq('x')}. Separate multiple values with a comma. If no mode exists, enter 'none' as your answer.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverRange = new Popover(this.$refs.popoverRange, {
        container: 'body',
        title: `Range of ${renderEq('x')}`,
        content: `Calculate the range of ${renderEq('x')}. Do not round your answer.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverVariance = new Popover(this.$refs.popoverVariance, {
        container: 'body',
        title: `Variance of ${renderEq('x')}`,
        content: `Calculate the sample variance of ${renderEq('x')}. Round your final answer to two decimal places or more.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverStDev = new Popover(this.$refs.popoverStDev, {
        container: 'body',
        title: `Standard Deviation of ${renderEq('x')}`,
        content: `Calculate the sample standard deviation of ${renderEq('x')}. Round your final answer to two decimal places or more.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverQ0 = new Popover(this.$refs.popoverQ0, {
        container: 'body',
        title: `Minimum Value of ${renderEq('x')}`,
        content: `Calculate the minimum value of ${renderEq('x')}. Do not round your answer.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverQ1 = new Popover(this.$refs.popoverQ1, {
        container: 'body',
        title: `First Quartile of ${renderEq('x')}`,
        content: `Calculate the first quartile of ${renderEq('x')}. Do not round your answer.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverQ2 = new Popover(this.$refs.popoverQ2, {
        container: 'body',
        title: `Second Quartile of ${renderEq('x')}`,
        content: `Calculate the second quartile of ${renderEq('x')}. Do not round your answer.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverQ3 = new Popover(this.$refs.popoverQ3, {
        container: 'body',
        title: `Third Quartile of ${renderEq('x')}`,
        content: `Calculate the third quartile of ${renderEq('x')}. Do not round your answer.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverQ4 = new Popover(this.$refs.popoverQ4, {
        container: 'body',
        title: `Maximum Value of ${renderEq('x')}`,
        content: `Calculate the maximum value of ${renderEq('x')}. Do not round your answer.`,
        trigger: 'hover focus',
        placement: 'left',
        html: true,
      });
      const popoverMeanEq = new Popover(this.$refs.popoverMeanEq, {
        container: 'body',
        content: `x-bar (the mean) is equal to 1 over n (the sample size) times the sum of all values of x`,
        trigger: 'hover focus',
        placement: 'right',
        html: true,
      });
      const popoverVarianceEq = new Popover(this.$refs.popoverVarianceEq, {
        container: 'body',
        content: `The sample variance (written as ${renderEq('s^{2}')}) is equal to 1 over n minus 1 times the sum of all values of x minus the mean of x squared. Note that we divide by n minus 1 instead of n to correct for bias in the sample variance.`,
        trigger: 'hover focus',
        placement: 'right',
        html: true,
      });
      const popoverStDevEq = new Popover(this.$refs.popoverStDevEq, {
        container: 'body',
        content: `The letter  ${renderEq('s')} is used to represent the sample standard deviation, while the letter ${renderEq('\\sigma')} 
                  is used to represent the population standard deviation. Roman letters are used to represent the sample, while Greek letters 
                  are used to represent the population. <br><br>
                  Make sure to pour one out for the variance, which doesn't get a letter of its own. Instead, we represent the variance as the 
                  square of the standard deviation. Poor variance.`,
        trigger: 'hover focus',
        placement: 'right',
        html: true,
      });

      // popoverMean; popoverMedian; popoverMode; popoverMeanEq; popoverVarianceEq; popoverStDevEq; popoverRange; popoverVariance; popoverStDev; popoverQ0; popoverQ1; popoverQ2; popoverQ3; popoverQ4;
    },
    async resetAll() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      await setDoc(docRef, {
        stats: {
          problemSets: {
            ch1: [math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9),math.randomInt(0,9)],
          }
        }
      }, {merge: true});
      await setDoc(docRef, {
        stats: {
          textbookResponses: {
            ch1: {
              mod1: {
                mean: null,
                median: null,
                mode: null,
              },
              mod2: {
                range: null,
                variance: null,
                stdev: null,
              },
              mod3: {
                q0: null,
                q1: null,
                q2: null,
                q3: null,
                q4: null,
              },
            }
          }
        }
      }, {merge: true});
      const docSnap = await getDoc(docRef);
      this.userData = docSnap.data();
    }
  }, 
  computed: {
    checkMean() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.mean(this.userData.stats.problemSets.ch1);
        const studValue = this.userData.stats.textbookResponses.ch1.mod1.mean;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkMedian() {
      if(!this.userData){
        return null;
      } else {
        const trueValue = math.median(this.userData.stats.problemSets.ch1);
        const studValue = this.userData.stats.textbookResponses.ch1.mod1.median;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkMode() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.mode(data);
        const studEntry = this.userData.stats.textbookResponses.ch1.mod1.mode;
        let studValue = studEntry == '' || studEntry == null ? [] : studEntry.split(',').map(item => item.trim()).filter(item => item !== '').map(item => parseFloat(item)).sort((a, b) => a - b);
        if(studEntry == 'none' || studEntry == 'None'){
          studValue = data;
        }
        let result = false;
        let check = 0;
        for(let i = 0; i < trueValue.length; i++){
          if(studValue[i] == trueValue[i]){
            check++;
          }
        }
        if(check == trueValue.length){result = true;}
        if(studEntry && result){
          return {
            borderColor: 'green'
          }
        } else if (studEntry) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkRange() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.max(data) - math.min(data);
        const studValue = this.userData.stats.textbookResponses.ch1.mod2.range;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkVar() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.variance(data);
        const studValue = this.userData.stats.textbookResponses.ch1.mod2.variance;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkStDev() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.std(data);
        const studValue = this.userData.stats.textbookResponses.ch1.mod2.stdev;
        const result = compare(trueValue, studValue, 0.01);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkQ0() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.min(data);
        const studValue = this.userData.stats.textbookResponses.ch1.mod3.q0;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkQ1() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.quantileSeq(data, 0.25);
        const studValue = this.userData.stats.textbookResponses.ch1.mod3.q1;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkQ2() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.quantileSeq(data, 0.5);
        const studValue = this.userData.stats.textbookResponses.ch1.mod3.q2;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkQ3() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.quantileSeq(data, 0.75);
        const studValue = this.userData.stats.textbookResponses.ch1.mod3.q3;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
    checkQ4() {
      if(!this.userData){
        return null;
      } else {
        const data = this.userData.stats.problemSets.ch1;
        const trueValue = math.max(data);
        const studValue = this.userData.stats.textbookResponses.ch1.mod3.q4;
        const result = compare(trueValue, studValue, 0);
        if(studValue && result){
          return {
            borderColor: 'green'
          }
        } else if (studValue) {
          return {
            borderColor: 'red'
          }
        } else {
          return null;
        }
      }
    },
  }
}
</script>

<style>
.accordion-button {
  color: var(--business_statistics) !important;
  background-color: var(--off_white) !important;
}

.accordion-button:not(.collapsed) {
  color: white !important;
  background-color: var(--business_statistics) !important;
}

.accordion-body {
  background-color: var(--background_color) !important;
}

.open-spreadsheet-button {
  background-color: var(--business_statistics); 
  border: none;
  color: white; 
  padding: 12px 18px; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block;
  font-size: 1.25em; 
  cursor: pointer;
  border-radius: 7px; 
  margin-top: 12px;
}
.open-spreadsheet-button:hover {
  background-color: #51859e;
}
.open-spreadsheet-button:active {
    background-color: var(--business_statistics); 
}
</style>