<template>
  <div class="container col-md-6 offset-md-3">
  
  <!-- #region CHAPTER AND TITLE -->
  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em1-50">Chapter Twelve</span><br>
      <span class="fw800 em3 fc-stats">The Magic Elixer</span>
    </div>
  </div>
  <!-- #endregion -->
  
  <!-- SUB NAVIGATION BAR -->
  <nav>
    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
      <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
      <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
    </div>
  </nav>
  
  <!-- SUB NAV BAR CONTENT -->
  <div class="tab-content" id="nav-tabContent">
    <!-- TEXTBOOK -->
    <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
      
      <VideoBox class="mt-4" videoTitle="The Magic Elixer" videoUrl="https://www.youtube.com/embed/IYDdgz7vEHs" startAt="0"/>

      <!-- #region MODULE 1 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Multiple Regression</h3>
        <p>
          Suppose I have a scatterplot of data which looks like this:
        </p>
        <RegressionChart :data="nonlinearScatterData" :multiple="false"/>
        <p>
          We've seen this chart before. It is the global average temperature anomaly from 1850 to 2021. I've found the best fit line for this data, but 
          we can see that it isn't very good. The data is clearly not linear.
        </p>
        <p>Instead of estimating the coefficients for an equation like this: </p>
        <p style="margin-top:12px; margin-bottom:12px;" class="d-flex justify-content-center">
          <MathEquation :equation="'y_i = \\beta_0 + \\beta_1x_i + \\varepsilon_i'"/>
        </p>
        <p>What we really want is to fit an equation like this: </p>
        <p style="margin-top:12px; margin-bottom:12px;" class="d-flex justify-content-center">
          <MathEquation :equation="'y_i = \\beta_0 + \\beta_1x_i + \\beta_2x_i^2 + \\varepsilon_i'"/>
        </p>
        <p>
          We need an additional parameter in our model (<MathEquation :equation="'x_i^2'"/>) in order to capture the non-linear relationship between 
          the variables. This is something we can do with <b>multiple regression</b>, which allows us to fit a model with multiple parameters.
        </p>
        <p>
          How hard could this be? Is it just a matter of taking the covariance and variance for the second term as well? No. In fact, getting a value 
          for <MathEquation :equation="'\\beta_1'"/> is made quite a bit more complicated: 
        </p>
        <p style="margin-top:12px; margin-bottom:12px;" class="d-flex justify-content-center">
          <MathEquation :equation="'\\hat{\\beta_1} = \\frac{\\left( \\sum_{i=1}^n (y_i - \\bar{y})(x_{i1} - \\bar{x_1}) \\sum_{i=1}^n (x_{i2} - \\bar{x_2})^2 \\right) - \\left( \\sum_{i=1}^n (y_i - \\bar{y})(x_{i2} - \\bar{x_2}) \\sum_{i=1}^n (x_{i1} - \\bar{x_1})(x_{i2} - \\bar{x_2}) \\right)}{\\sum_{i=1}^n (x_{i1} - \\bar{x_1})^2 \\sum_{i=1}^n (x_{i2} - \\bar{x_2})^2 - \\left( \\sum_{i=1}^n (x_{i1} - \\bar{x_1})(x_{i2} - \\bar{x_2}) \\right)^2}'"/>
        </p>
        <p>
          I think I speak for us all when I say, "Yeah, no."
        </p>
        <p>
          Computers are very happy to do this for us. By adding this additional term, we can fit a model to the data that is much more accurate.
        </p>
        <RegressionChart :data="nonlinearScatterData" :multiple="true"/>
        <p>
          Multiple regression allows us to handle non-linearity in our data, which is very important when we're trying to model real-world phenomena. 
        </p>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Each question below will present an estimated non-linear model for x and y. Compute the predicted value of y for the given x value.
          </p>
          <!-- Question 1 -->
          <MathEquation :equation="'\\hat{y_i} = 0.5 + 0.3x_i + 0.1x_i^2'"/>
          <div class="input-group mb-3">
          <button ref="m1q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'x = '+problemSets.mod1[0]"/></button>
          <input type="text" class="form-control" placeholder="Predict the value of y using x" id="m1q1" :value="textbookResponses.mod1.q1" :style="checkM1Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <MathEquation :equation="'\\hat{y_i} = 10 + 2*ln(x_i)'"/>
          <div class="input-group mb-3">
          <button ref="m1q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'x = '+problemSets.mod1[1]"/></button>
          <input type="text" class="form-control" placeholder="Predict the value of y using x" id="m1q2" :value="textbookResponses.mod1.q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <MathEquation :equation="'\\hat{y_i} = -4 + 6\\sqrt{x_i} - 2.4x_i^3'"/>
          <div class="input-group mb-3">
          <button ref="m1q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'x = '+problemSets.mod1[2]"/></button>
          <input type="text" class="form-control" placeholder="Predict the value of y using x" id="m1q3" :value="textbookResponses.mod1.q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <MathEquation :equation="'\\hat{y_i} = 4.5 + 2.1x_i - 3.7x_i^2 + 0.25x_i^3'"/>
          <div class="input-group mb-3">
          <button ref="m1q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'x = '+problemSets.mod1[3]"/></button>
          <input type="text" class="form-control" placeholder="Predict the value of y using x" id="m1q4" :value="textbookResponses.mod1.q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM1Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
  
          <CalculatorLinks :score="mod1Score" :scoreTotal="4"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 2 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Control</h3>
        <p>
          There are four reasons why two variables might be correlated with each other. These are: 
        </p>
        <ol>
          <li><b>X causes Y</b> - A change in X does something to cause a change in Y.</li>
          <li><b>Y causes X</b> - The cause and effect are reversed.</li>
          <li><b>Z causes both X and Y</b> - Some other factor Z is causing changes in X and Y, which are otherwise unrelated.</li>
          <li><b>Random Chance</b> - The correlation is due to random chance.</li>
        </ol>
        <p>
          That dang pesky <b>Z</b>! It is always confounding our analysis. Maybe smoking doesn't cause cancer. Maybe stress causes people to smoke 
          and also causes cancer. Maybe our CO2 emissions aren't causing the planet to warm. Maybe the sun is getting hotter and we're just along for the ride. 
          Maybe more schooling doesn't lead to higher incomes. Maybe its just that smart people go to school and smart people make more money. 
          How can we <i>ever</i> know what is real and what is just a coincidence?
        </p>
        <p>
          Well folks, step right up because I have a tonic that cures all your ills. I have a magic elixer that will solve all your problems. I have a 
          statistical method that allows you to check every confounding factor you can think of and see if your correlation still stands. I have 
          <b>multiple regression</b>.
        </p>
        <p>
          We can use multiple regression to control for the effects of Z. This is called "controlling" for Z, or "holding Z constant". By controlling for Z, 
          we can see if the relationship between X and Y is still significant. If it is, then we can be more confident that the relationship is causal.
        </p>
        <p>
          If we are worried that there are other factors that cause both x and y, we can add them to our regression model and account for them. For example, 
          if I want to see whether more schooling really does lead to higher wages, I can add IQ to my model. The processes of estimating the model with 
          ordinary least squares will account for the effects of IQ on wages, and the coefficient for schooling will be the effect of schooling on wages 
          <i>holding IQ constant</i>. And we don't have to stop there. We can add as many variables as we have the data for! 
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center">
          <MathEquation :equation="'Wage_i = \\beta_0 + \\beta_1Schooling_i + \\beta_2IQ + \\beta_3FamilyIncome + \\beta_6Experience + \\varepsilon_i'"/>
        </p>
        <p>
          Here is a video to help explain the power of multiple regression. 
        </p>
        <VideoBox videoUrl="https://www.youtube.com/embed/lPSLdVPJhaU" videoTitle="Ceteris Paribus" startAt="0" />

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Answer each of the questions below.
          </p>
          <!-- MULTIPLE CHOICE COMPONENT -->
          <div class="mc-container">
            <div v-for="(question, index) in problemSets.mod2" :key="question.id" class="mc-question" v-show="index === currentQuestionIndex">
              <b :style="checkM2Q1[index]">Question {{ index+1 }}</b>. {{ question.question }}
              <div v-for="answer in question.answers" :key="answer.id" class="mc-answer">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="question.id"
                    :id="'m2'+question.id+answer.id"
                    :value="answer.id"
                    :checked="textbookResponses.mod2[index] == answer.id"
                  />
                  <label class="form-check-label" :for="question.id+answer.id">
                    {{ answer.text }}
                  </label>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;">
                <button class="btn btn-light" type="button" @click="previousQuestion">Previous</button>
                <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answers</button>
                <button class="btn btn-light" type="button" @click="nextQuestion">Next</button>
              </div>
            </div>  
          </div>
  
          <CalculatorLinks :score="mod2Score" :scoreTotal="8"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 3 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Forecasting</h3>
        <p>
          While researchers and scientists are concerned with causal identification, businesses are often more interested in forecasting. They want to be 
          able to predict the future. They want to know what will happen if they change their prices, or if they open a new store, or if they launch a new 
          product. They want to know what will happen to their sales if they increase their advertising budget. They want to know what will happen to their 
          profits if they cut costs. They want to know what will happen to their stock price if they miss their earnings target. The uses of regression in 
          business are endless. 
        </p>
        <p>
          Lately, there has been a boom in "Artificial Intelligence". New consulting firms have been popping up promising to use A.I. to improve business 
          outcomes. While some of these firms are using cutting-edge machine learning techniques, many are still using the same old regression models.
        </p>
        <p>
          Walmart is renowned for its efficient supply chain and inventory management, largely attributed to its use of regression analysis. By analyzing 
          historical sales data and external factors such as weather patterns and local events, Walmart can predict product demand with remarkable accuracy. 
          They aren't using some fancy new machine learning model. They're using good old-fashioned regression.
        </p>
        <p>
          Before an approaching hurricane, Walmart's regression analysis revealed a spike in the sales of Pop-Tarts and beer. By recognizing this pattern, 
          Walmart ensures these items are well-stocked, maximizing sales and customer satisfaction during critical times.
        </p>
        <h5>Simplified Example of the Regression Model</h5>
        <p>We can see how this might work by creating a simplified version of their model:</p>
        <p style="margin-top:12px; margin-bottom:12px;" class="d-flex justify-content-center">
          <MathEquation :equation="'\\text{Sales}_{PopTarts} = \\beta_0 + \\beta_1Hurricane + \\beta_2Temperature + \\beta_3Weekend + \\varepsilon'"/>
        </p>
        <ul>
          <li><b>Sales<sub>PopTarts</sub></b>: Number of Pop-Tarts sold</li>
          <li><b>Hurricane</b>: 1 if a hurricane is approaching, 0 otherwise</li>
          <li><b>Temperature</b>: Average temperature in the area</li>
          <li><b>Weekend</b>: 1 if it is a weekend, 0 otherwise</li>
        </ul>
        <p>
          By analyzing historical data, Walmart can estimate the coefficients for each of these variables. They can then use these coefficients to predict 
          the number of Pop-Tarts they will sell in the future. Suppose they estimates the following values for the coefficients:
        </p>
        <ul>
          <li><b>β<sub>0</sub></b>: 100</li>
          <li><b>β<sub>1</sub></b>: 50</li>
          <li><b>β<sub>2</sub></b>: -1</li>
          <li><b>β<sub>3</sub></b>: 20</li>
        </ul>
        <p>
          If a hurricane is approaching, the temperature is 80 degrees, and it is a weekend, Walmart can predict that they will sell: 
          <MathEquation :equation="'100 + 50(1) - 1(80) + 20(1) = 90'"/> Pop-Tarts. This is 50 more than they would sell if there was no hurricane, 
          the temperature was 80 degrees, and it was a weekend.
        </p>
        <p>
          Wal-Mart does this for virtually every product in every store in every region. They use regression to predict sales, profits, and stock 
          prices. And they aren't the only one. Regression is used in virtually every industry to predict the future.
        </p>
        <h4 class="fc-stats">Accurate Forecasting</h4>
        <p>
          There is an old saying: "Garbage in, garbage out." This is especially true in regression analysis. If you put bad data into your model, you will 
          get bad results. And there is another old saying: "All models are wrong, but some are useful." Adjusting your model for non-linearity, controlling 
          for confounding factors, and using the right data are all ways to make your model more useful, but it is hard to know when you have it right.
        </p>
        <p>
          In the Wal-Mart example, the model used the average temperature to predict Pop-Tart sales. If you gather data on historical sales and use the 
          <i>actual</i> temperature in the area at the time, you might be introducing a source of error in your model. The actual temperature might be 
          different from the forecasted temperature, and when you go to predict the future, you will have to use weather forecasts, not actual temperatures. 
          The same is true for whether or not there is a hurricane approaching.
        </p>
        <p>
          As with everything in this course, it is hard to do statistics correctly, and easy to do them poorly. So don't let this be your last course in 
          regression analysis. 
        </p>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            A large national retailer is scouting locations for a new store. Using their other locations, they have estimated the following model for 
            predicting sales at a new location:
          </p>
          <p style="margin-top:12px; margin-bottom:12px;" class="d-flex justify-content-center">
            <MathEquation :equation="'\\text{Sales} = 15,000 + 50(\\text{Population}) + 20(\\text{Income}) - 10,000(\\text{Competition}) + \\varepsilon'"/>
          </p>
          <p>
            "Sales" is the estimated sales at the new location, "Population" is the population of the area, "Income" is the average income of the area, and 
            "Competition" is the number of other similar stores in the area. 
          </p>
          <p>
            Using this information, forecast sales for each of the locations below. 
          </p>
          <!-- Question 1 -->
          <b>Location A has a population of {{ problemSets.mod3[0].population }}, an average income of {{ problemSets.mod3[0].income }}, 
           and {{ problemSets.mod3[0].competition }} other similar stores in the area.</b>
          <div class="input-group mb-3">
          <button ref="m3q1" class="input-group-text" style="width: 40px;">?</button>
          <input type="text" class="form-control" placeholder="Estimate sales at this location" id="m3q1" :value="textbookResponses.mod3.q1" :style="checkM3Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <b>Location B has a population of {{ problemSets.mod3[1].population }}, an average income of {{ problemSets.mod3[1].income }}, 
          and {{ problemSets.mod3[1].competition }} other similar stores in the area.</b>
          <div class="input-group mb-3">
          <button ref="m3q2" class="input-group-text" style="width: 40px;">?</button>
          <input type="text" class="form-control" placeholder="Estimate sales at this location" id="m3q2" :value="textbookResponses.mod3.q2" :style="checkM3Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <b>Location C has a population of {{ problemSets.mod3[2].population }}, an average income of {{ problemSets.mod3[2].income }}, 
          and {{ problemSets.mod3[2].competition }} other similar stores in the area.</b>
          <div class="input-group mb-3">
          <button ref="m3q3" class="input-group-text" style="width: 40px;">?</button>
          <input type="text" class="form-control" placeholder="Estimate sales at this location" id="m3q3" :value="textbookResponses.mod3.q3" :style="checkM3Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <b>Location D has a population of {{ problemSets.mod3[3].population }}, an average income of {{ problemSets.mod3[3].income }}, 
          and {{ problemSets.mod3[3].competition }} other similar stores in the area.</b>
          <div class="input-group mb-3">
          <button ref="m3q4" class="input-group-text" style="width: 40px;">?</button>
          <input type="text" class="form-control" placeholder="Estimate sales at this location" id="m3q4" :value="textbookResponses.mod3.q4" :style="checkM3Q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
  
          <CalculatorLinks :score="mod3Score" :scoreTotal="8"/>
        </div>
        
      </div> 
      <!-- #endregion -->
      

      <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/h1YzrsyizLQ" startAt="0"/>

      <!-- #region DATA CHANGER -->
      <div v-if="userData" class="data-changer">
        <div class="em1-50 fw600">Questions Reset</div>
        <div>You can reset the questions with randomly generated numbers for more practice.</div>
        <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
      </div>
      <!-- #endregion -->

    </div>
    <!-- EXPLORATORY LESSON -->
    <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="ExploratoryLessonIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Exploratory Lesson {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will dig into the idea omitted variable bias 
            and see how it can affect our regression models.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ lssnScore }} out of 20 points on this lesson.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/lesson'" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
      </div>
    </div>
    <!-- SPREADSHEET ASSIGNMENT -->
    <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="SpreadsheetAssignmentIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Spreadsheet Assignment {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will conduct three multiple 
            regressions and evaluate them for accuracy.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ assnScore }} out of 30 points on this assignment.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/assignment'" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
      </div>
    </div>
  </div>
  
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
  import { Popover } from 'bootstrap';

  import MathEquation from '@/components/MathEquation.vue';
  import CalculatorLinks from '@/components/CalculatorLinks.vue';
  import VideoBox from '@/components/VideoBox.vue';
  import RegressionChart from '@/components/RegressionChart.vue';
  
  import { create, all } from 'mathjs'
  const math = create(all);
  
  import probabilibot from '@/assets/Probabilibot.png';
  import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
  import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
  
  export default {
    name: "statsMainPage",
    components: {
      MathEquation,
      CalculatorLinks,
      VideoBox,
      RegressionChart,
    },
    props: ['tab'],
    data() {
      return {
        ch: 'ch12',
        userData: null,
        problemSets: null,
        textbookResponses: null,
        setUpStatsData,
        mod1Score: 0,
        mod2Score: 0,
        mod3Score: 0,
        assnScore: 0,
        lssnScore: 0,
        modalTitle: 'Loading...',
        modalMessage: '',
        probabilibot,
        ExploratoryLessonIcon,
        SpreadsheetAssignmentIcon,
        renderEq,
        currentQuestionIndex: 0,
        nonlinearScatterData: [
          {x: 1850, y: -0.42},
          {x: 1855, y: -0.3},
          {x: 1860, y: -0.39},
          {x: 1865, y: -0.33},
          {x: 1870, y: -0.33},
          {x: 1875, y: -0.38},
          {x: 1880, y: -0.32},
          {x: 1885, y: -0.47},
          {x: 1890, y: -0.51},
          {x: 1895, y: -0.45},
          {x: 1900, y: -0.23},
          {x: 1905, y: -0.41},
          {x: 1910, y: -0.53},
          {x: 1915, y: -0.19},
          {x: 1920, y: -0.3},
          {x: 1925, y: -0.28},
          {x: 1930, y: -0.18},
          {x: 1935, y: -0.21},
          {x: 1940, y: 0.08},
          {x: 1945, y: 0.04},
          {x: 1950, y: -0.23},
          {x: 1955, y: -0.2},
          {x: 1960, y: -0.12},
          {x: 1965, y: -0.2},
          {x: 1970, y: -0.09},
          {x: 1975, y: -0.11},
          {x: 1978, y: 0.01},
          {x: 1979, y: 0.09},
          {x: 1980, y: 0.2},
          {x: 1981, y: 0.25},
          {x: 1982, y: 0.03},
          {x: 1983, y: 0.22},
          {x: 1984, y: 0.05},
          {x: 1985, y: 0.05},
          {x: 1986, y: 0.1},
          {x: 1987, y: 0.24},
          {x: 1988, y: 0.28},
          {x: 1989, y: 0.18},
          {x: 1990, y: 0.36},
          {x: 1991, y: 0.34},
          {x: 1992, y: 0.12},
          {x: 1993, y: 0.17},
          {x: 1994, y: 0.23},
          {x: 1995, y: 0.38},
          {x: 1996, y: 0.28},
          {x: 1997, y: 0.42},
          {x: 1998, y: 0.58},
          {x: 1999, y: 0.32},
          {x: 2000, y: 0.33},
          {x: 2001, y: 0.49},
          {x: 2002, y: 0.54},
          {x: 2003, y: 0.54},
          {x: 2004, y: 0.47},
          {x: 2005, y: 0.61},
          {x: 2006, y: 0.57},
          {x: 2007, y: 0.59},
          {x: 2008, y: 0.47},
          {x: 2009, y: 0.6},
          {x: 2010, y: 0.68},
          {x: 2011, y: 0.54},
          {x: 2012, y: 0.58},
          {x: 2013, y: 0.62},
          {x: 2014, y: 0.67},
          {x: 2015, y: 0.83},
          {x: 2016, y: 0.93},
          {x: 2017, y: 0.85},
          {x: 2018, y: 0.76},
          {x: 2019, y: 0.89},
          {x: 2020, y: 0.92},
          {x: 2021, y: 0.76},
        ],
      }
    },
    created() {
      this.loadUserInformation();
    },
    methods: {
      async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullProblemSet();
        await this.setPopovers();
      },
      async pullProblemSet() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        
        // ADD VALUES FOR THE CHAPTER PROBLEM SET
        if(data.stats.problemSets[this.ch].length == 0){
          await setDoc(docRef, {
            stats: {
              problemSets: {
                [this.ch]: this.problemSet(),
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
        if(Object.keys(data.stats.textbookResponses[this.ch]).length == 0){
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                  },
                  mod2: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                  },
                  mod3: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER GRADEBOOK IF MISSING
        if(!data.stats.gradeBook[this.ch].mod1){
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                [this.ch]: {
                  mod1: [0,0,0,0],
                  mod2: [0,0,0,0],
                  mod3: [0,0,0,0],
                  assignment: null,
                  lesson: null,
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        this.userData = data;
        this.problemSets = data.stats.problemSets[this.ch];
        this.textbookResponses = data.stats.textbookResponses[this.ch];
        this.mod1Score = math.sum(this.userData.stats.gradeBook[this.ch].mod1);
        this.mod2Score = math.sum(this.userData.stats.gradeBook[this.ch].mod2);
        this.mod3Score = math.sum(this.userData.stats.gradeBook[this.ch].mod3);
        this.assnScore = this.userData.stats.gradeBook[this.ch].assignment ? this.userData.stats.gradeBook[this.ch].assignment : 0;
        this.lssnScore = this.userData.stats.gradeBook[this.ch].lesson ? this.userData.stats.gradeBook[this.ch].lesson : 0;
      },
      async setPopovers() {
        await new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (this.userData !== null) {
                    clearInterval(intervalId);
                    resolve(this.userData);
                }
            }, 100); // Check every 100 milliseconds
        });
        const popoverM1Q1 = new Popover(this.$refs.m1q1, {
          container: 'body',
          title: 'Nonlinearity',
          content: `Plug in this value for x and see what you get for y. Your answer should be accurate up to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });  
        const popoverM1Q2 = new Popover(this.$refs.m1q2, {
          container: 'body',
          title: 'Nonlinearity',
          content: `Plug in this value for x and see what you get for y. Your answer should be accurate up to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        }); 
        const popoverM1Q3 = new Popover(this.$refs.m1q3, {
          container: 'body',
          title: 'Nonlinearity',
          content: `Plug in this value for x and see what you get for y. Your answer should be accurate up to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM1Q4 = new Popover(this.$refs.m1q4, {
          container: 'body',
          title: 'Nonlinearity',
          content: `Plug in this value for x and see what you get for y. Your answer should be accurate up to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q1 = new Popover(this.$refs.m3q1, {
          container: 'body',
          title: 'Forecasting',
          content: `Use the data provided and the estimated regression model to predict sales for this location. Your answer should be a whole number.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q2 = new Popover(this.$refs.m3q2, {
          container: 'body',
          title: 'Forecasting',
          content: `Use the data provided and the estimated regression model to predict sales for this location. Your answer should be a whole number.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q3 = new Popover(this.$refs.m3q3, {
          container: 'body',
          title: 'Forecasting',
          content: `Use the data provided and the estimated regression model to predict sales for this location. Your answer should be a whole number.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q4 = new Popover(this.$refs.m3q4, {
          container: 'body',
          title: 'Forecasting',
          content: `Use the data provided and the estimated regression model to predict sales for this location. Your answer should be a whole number.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        popoverM1Q1; popoverM1Q2; popoverM1Q3; popoverM1Q4; popoverM3Q1; popoverM3Q2; popoverM3Q3; popoverM3Q4;
      },
      async resetAll() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            problemSets: {
              [this.ch]: this.problemSet()
            }
          }
        }, {merge: true});
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod1: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
                mod2: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
                mod3: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                },
              }
            }
          }
        }, {merge: true});
        const docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.problemSets = this.userData.stats.problemSets[this.ch];
      },
      problemSet() {
        const xVals = Array.from({length: 4}, () => Math.floor(Math.random() * 100) + 1);
        let questionSet = [
          {
            id: 'q1',
            question: 'Which scenario is an example of "controlling for a variable" in a multiple regression model?',
            answers: [
              { id: 'a1', text: 'Adding more data points to improve accuracy.' },
              { id: 'a2', text: 'Including IQ in the model to isolate the effect of schooling on wages.' },
              { id: 'a3', text: 'Using a larger sample size to reduce variability.' },
              { id: 'a4', text: 'Applying a logarithmic transformation to the dependent variable.' },
            ],
            correct: 'a2',
          },
          {
            id: 'q2',
            question: 'What is "omitted variable bias" and how can it impact the results of a regression analysis?',
            answers: [
              { id: 'a1', text: 'It is the bias introduced by including too many variables in the model.' },
              { id: 'a2', text: 'It occurs when a key variable is left out, leading to incorrect estimates of the treatment effect.' },
              { id: 'a3', text: 'It results from random chance and does not affect the regression outcomes.' },
              { id: 'a4', text: 'It is the error term that accounts for unobserved factors in the model.' },
            ],
            correct: 'a2',
          },
          {
            id: 'q3',
            question: 'In the nightlight and myopia example, what was the critical omitted variable that led to an incorrect conclusion?',
            answers: [
              { id: 'a1', text: 'The brightness of the nightlight' },
              { id: 'a2', text: 'The age of the infants' },
              { id: 'a3', text: 'The sleeping patterns of the infants' },
              { id: 'a4', text: 'The genetics of the parents' },
            ],
            correct: 'a4',
          },
          {
            id: 'q4',
            question: 'How does multiple regression help in distinguishing between correlation and causation?',
            answers: [
              { id: 'a1', text: 'By increasing the number of dependent variables.' },
              { id: 'a2', text: 'By holding other variables constant to isolate the effect of the primary variable.' },
              { id: 'a3', text: 'By simplifying the data collection process.' },
              { id: 'a4', text: 'By eliminating all random chance from the data.' },
            ],
            correct: 'a2',
          },
        ];
        // randomize order of answer for each question
        for(let question of questionSet){
          question.answers.sort(() => Math.random() - 0.5);
        }

        // Mod 3 questions
        const mod3Questions = [
          {
            population: math.randomInt(10, 50)*1000,
            income: math.randomInt(40, 80)*1000,
            competition: math.randomInt(0, 5),
          },
          {
            population: math.randomInt(50, 100)*1000,
            income: math.randomInt(60, 100)*1000,
            competition: math.randomInt(0, 5),
          },
          {
            population: math.randomInt(100, 200)*1000,
            income: math.randomInt(80, 120)*1000,
            competition: math.randomInt(2, 7),
          },
          {
            population: math.randomInt(200, 300)*1000,
            income: math.randomInt(100, 150)*1000,
            competition: math.randomInt(5, 10),
          },
        ];

        return {
          mod1: xVals,
          mod2: questionSet,
          mod3: mod3Questions,
        };
      },
      previousQuestion() {
        if (this.currentQuestionIndex > 0) {
          this.currentQuestionIndex--;
        } else {
          this.currentQuestionIndex = this.problemSets.mod2.length - 1;
        }
      },
      nextQuestion() {
        if (this.currentQuestionIndex < this.problemSets.mod2.length - 1) {
          this.currentQuestionIndex++;
        } else {
          this.currentQuestionIndex = 0;
        }
      },
      async computeM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod1;
          const trueValue = 0.5 + 0.3*data[0] + 0.1*Math.pow(data[0], 2);
          const studValue = document.getElementById('m1q1').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `When x = ${data[0]}, y = ${trueValue.toFixed(2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in the value of x into the equation and solve for y.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod1;
          const trueValue = 10 + 2*Math.log(data[1]);
          const studValue = document.getElementById('m1q2').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `When x = ${data[1]}, y = ${trueValue.toFixed(2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in the value of x into the equation and solve for y.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod1;
          const trueValue = -4 + 6*Math.sqrt(data[2]) - 2.4*Math.pow(data[2], 3);
          const studValue = document.getElementById('m1q3').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `When x = ${data[2]}, y = ${trueValue.toFixed(2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in the value of x into the equation and solve for y.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod1;
          const trueValue = 4.5 + 2.1*data[3] - 3.7*Math.pow(data[3], 2) + 0.25*Math.pow(data[3], 3);
          const studValue = document.getElementById('m1q4').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `When x = ${data[3]}, y = ${trueValue.toFixed(2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in the value of x into the equation and solve for y.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const studentChecked = [];
          let gradedArray = [];
          for(let question of this.problemSets.mod2){
            const trueValue = question.correct;
            const selectedAnswer = document.querySelector(`input[name=${question.id}]:checked`);
            const studValue = selectedAnswer ? selectedAnswer.value : null;
            studentChecked.push(studValue);
            const result = trueValue == studValue;
            gradedArray.push(result);
          }
          let feedbackArray = [
            {
              a1: `Question 1 is incorrect. While adding more data points can improve accuracy, that is not what we mean by "controlling for a variable".`,
              a2: `Question 1 is correct!`,
              a3: `Question 1 is incorrect. While using a larger sample size can reduce variability, that is not what we mean by "controlling for a variable".`,
              a4: `Question 1 is incorrect. Applying a logarithmic transformation to the dependent variable is not what we mean by "controlling for a variable".`,
            },
            {
              a1: `Question 2 is incorrect. Including too many variables can also be a problem, but is not the problem of omitted variable bias.`,
              a2: `Question 2 is correct!`,
              a3: `Question 2 is incorrect. Random chance can affect the results, but omitted variable bias is a different issue.`,
              a4: `Question 2 is incorrect. The error term is not the omitted variable bias.`,
            },
            {
              a1: `Question 3 is incorrect. The brightness of the nightlight was not the omitted variable.`,
              a2: `Question 3 is incorrect. The age of the infants was not the omitted variable.`,
              a3: `Question 3 is incorrect. The sleeping patterns of the infants was not the omitted variable.`,
              a4: `Question 3 is correct!`,
            },
            {
              a1: `Question 4 is incorrect. Increasing the number of dependent variables is not how multiple regression helps distinguish between correlation and causation.`,
              a2: `Question 4 is correct!`,
              a3: `Question 4 is incorrect. Simplifying the data collection process is not how multiple regression helps distinguish between correlation and causation.`,
              a4: `Question 4 is incorrect. Eliminating all random chance from the data is not how multiple regression helps distinguish between correlation and causation.`,
            },
          ];
          this.modalTitle = 'Multiple Choice Results';
          this.modalMessage = '';
          for(let i = 0; i < gradedArray.length; i++){
            if(studentChecked[i]){
              this.modalMessage += feedbackArray[i][studentChecked[i]] + '<br><br>';
            } else {
              this.modalMessage += 'You did not answer question ' + (i+1) + '<br><br>';
            }
          }

          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          for(let i = 0; i < gradedArray.length; i++){
            if(gradeMatrix[i] == 0 && gradedArray[i]){
              gradeMatrix[i] = 2;
            } 
          }

          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: studentChecked,
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3[0];
          const trueValue = 15000 + 50*data.population + 20*data.income - 10000*data.competition;
          const studValue = document.getElementById('m3q1').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `When population = ${data.population}, income = ${data.income}, and competition = ${data.competition}, y = ${trueValue.toFixed(2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in the values of population, income, and competition into the equation and predict sales.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3[1];
          const trueValue = 15000 + 50*data.population + 20*data.income - 10000*data.competition;
          const studValue = document.getElementById('m3q2').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `When population = ${data.population}, income = ${data.income}, and competition = ${data.competition}, y = ${trueValue.toFixed(2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in the values of population, income, and competition into the equation and predict sales.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3[2];
          const trueValue = 15000 + 50*data.population + 20*data.income - 10000*data.competition;
          const studValue = document.getElementById('m3q3').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `When population = ${data.population}, income = ${data.income}, and competition = ${data.competition}, y = ${trueValue.toFixed(2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in the values of population, income, and competition into the equation and predict sales.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3[3];
          const trueValue = 15000 + 50*data.population + 20*data.income - 10000*data.competition;
          const studValue = document.getElementById('m3q4').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `When population = ${data.population}, income = ${data.income}, and competition = ${data.competition}, y = ${trueValue.toFixed(2)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Plug in the values of population, income, and competition into the equation and predict sales.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },

    }, 
    computed: {
      checkM1Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod1;
          const trueValue = 0.5 + 0.3*data[0] + 0.1*Math.pow(data[0], 2);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q1;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod1;
          const trueValue = 10 + 2*Math.log(data[1]);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q2;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod1;
          const trueValue = -4 + 6*Math.sqrt(data[2]) - 2.4*Math.pow(data[2], 3);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q3;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM1Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod1;
          const trueValue = 4.5 + 2.1*data[3] - 3.7*Math.pow(data[3], 2) + 0.25*Math.pow(data[3], 3);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod1.q4;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q1() {
        if(!this.userData){
          return null;
        } else {
          let styleArray = [];
          for(let [index, question] of this.problemSets.mod2.entries()) {
            const trueValue = question.correct; 
            const studValue = this.textbookResponses.mod2[index]; 
            const result = trueValue == studValue;
            if(studValue && result){
              styleArray.push({color: 'green'})
            } else if (studValue) {
              styleArray.push({color: 'red'})
            } else {
              styleArray.push(null);
            }
          }
          return styleArray;
        }
      },
      checkM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3[0];
          const trueValue = 15000 + 50*data.population + 20*data.income - 10000*data.competition;
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q1;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3[1];
          const trueValue = 15000 + 50*data.population + 20*data.income - 10000*data.competition;
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q2;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3[2];
          const trueValue = 15000 + 50*data.population + 20*data.income - 10000*data.competition;
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q3;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3[3];
          const trueValue = 15000 + 50*data.population + 20*data.income - 10000*data.competition;
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q4;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },

      
    }
  }
  </script>
  
<style scoped>

</style>