<template>
  <div class="container login-container">
      <div class="login-div rounded shadow ">
          <div class="logo-holder">
              <img src="../assets/ProGoLoGo.png" alt="ProGoLoGo" class="img-fluid" style="width: 300px; margin: 0 auto; display: block;">
          </div>
          
              
          <div class="fw700 em2">Register</div>
              <div class="mt-2 mb-3">
                  <label for="institution">Select Your Institution: </label>
                  <select class="form-select" id="institution" name="institution" autocomplete="institution" v-model="selectedInstitution" required>
                        <option selected value="maricopa">Maricopa Community Colleges</option>
                        <option value="unaffiliated">Unaffiliated</option>
                  </select>
              </div>
              <div class="input-group">
                  <label for="email">Enter Your Email Address:</label>
                  <input type="email" id="email" name="email" autocomplete="email" required>
                  <small id="emailError">Please use your @maricopa.edu email address</small>
              </div>
              <div class="input-group">
                  <label for="password">Choose a Password:</label>
                  <input type="password" id="password1" name="password" required>
                  <small class="error" id="p1error"></small>
              </div>
              <div class="input-group">
                  <label for="password">Re-enter Password:</label>
                  <input type="password" id="password2" name="password" required>
                  <small class="error" id="p2error"></small>
              </div>
              <button class="login-button" @click="registerAccount">Register Account</button>
              <br><br>
  
      </div>
  </div>
  </template>
  
  <script>
  // Import proport functions from firebase to create a new user account
  import { auth, database } from '../main';
  import { createUserWithEmailAndPassword } from 'firebase/auth';
  import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

  import ProGoLoGo from '../assets/ProGoLoGo.png';
  
  
  export default {
    name: "loginPage",
    data() {
      return {
          ProGoLoGo,
          selectedInstitution: 'maricopa',
      }
    },
    watch: {
        selectedInstitution(newVal) {
            if (newVal === 'unaffiliated') {
                document.getElementById('emailError').innerText = '';
            } else {
                document.getElementById('emailError').innerText = 'Please use your @maricopa.edu email address';
            }
        }
    },
    methods: {
      async registerAccount() {
          document.getElementById('emailError').innerText = "Please use your @maricopa.edu email address";
          const loginEmail = document.getElementById('email').value;
          const loginPassword1 = document.getElementById('password1').value;
          const loginPassword2 = document.getElementById('password2').value;
          if (loginPassword1 !== loginPassword2) {
              document.getElementById('p2error').innerText = "Passwords do not match";
              return;
          } else {
              document.getElementById('p2error').innerText = "";
          }
          if (!loginEmail.includes('@maricopa.edu') && this.selectedInstitution === 'maricopa') {
              document.getElementById('emailError').classList.add('error');
              return;
          } else {
              document.getElementById('emailError').classList.remove('error');
          }
          try {
              const userCredential = await createUserWithEmailAndPassword(auth, loginEmail, loginPassword1);
              const user = userCredential.user;
              await setDoc(doc(database, 'users', user.uid), {
                userInfo: {
                  email: user.email,
                }
              });
              const redirect = this.$route.query.redirect || '/userinfo'; 
              this.$router.push(redirect);
          } catch (error) {
              const errorCode = error.code;
              const errorMessage = error.message;
              if (errorCode === 'auth/email-already-in-use') {
                document.getElementById('emailError').innerText = "Email address already in use";
                document.getElementById('emailError').classList.add('error');
              } else {
                console.log('Error:', errorMessage);
              }
          }
      },
    }
  }
  </script>
  
  <style scoped>
  .logo-holder {
      text-align: center;
      padding: 10px;
      border-radius: 5px;
      background: linear-gradient(90deg in oklab, blue, red);
  }
  .login-container { 
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .input-group {
      margin-bottom: 20px;
  }
  .input-group label {
      display: block;
      margin-bottom: 5px;
  }
  .input-group input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
  }
  .login-button {
      width: 100%;
      padding: 10px;
      background: linear-gradient(90deg in oklab, blue, red);
      color: white;
      font-weight: 600;
      border: none;
      border-radius: 5px;
      cursor: pointer;
  }
  .login-button:hover {
      background: linear-gradient(270deg in oklab, blue, red);
  }
  .login-div {
      left: auto;
      margin-top: 50px;
      padding: 24px;
      width: 500px;
      height: 600px;
      background-color: var(--off_white);
  }
  .error {
      color: red;
  }
  </style>