<template>
    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="fc-offwhite">Table of Contents</span>
    </a>
    <ul class="dropdown-menu">
        <li><router-link class="dropdown-item" to="/stats/1">1. Basic Statistics</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/2">2. Correlation is Not Causation</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/3">3. Never Tell Me the Odds!</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/4">4. The Improbable Legacy of Thomas Bayes</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/5">5. Please Be Discrete</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/6">6. It's Perfectly Normal</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/7">7. What are the Chances?</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/8">8. The Central Limit Theorem</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/9">9. Sampling with Confidence</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/10">10. Randomized Controlled Trials</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/11">11. Ordinary Least Squares</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/12">12. The Magic Elixer</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/13">13. The Keys to the Universe</router-link></li>
        <li><router-link class="dropdown-item" to="/stats/14">14. Good for Business</router-link></li>
    </ul>
</template>

<script>
export default {
    name: "statsTOC",
}
</script>

<style scoped>

</style>