<template>
  <NavBar ref="navbar" :course="course" :user="user"/>
  <router-view :navVh="navVh"></router-view>
</template>

<script>
import NavBar from './components/NavBar.vue';
import { auth } from './main';
import { onAuthStateChanged } from 'firebase/auth';

export default {
  name: 'App',
  components: {
    NavBar
  },
  data() {
    return {
      course: null,
      user: null,
      navVh: null,
    }
  },
  created() {
    onAuthStateChanged(auth, user => {
      if(user){
        this.user = user.email;
      } else {
        this.user = null;
      }
    });
  },
  mounted() {
    this.$nextTick(() => {
      let navbarHeightInPx = this.$refs.navbar.$el.offsetHeight;
      let viewportHeightInPx = window.innerHeight;
      let navbarHeightInVh = (navbarHeightInPx / viewportHeightInPx) * 100;
      this.navVh = navbarHeightInVh;
    });
  }
}
</script>
